import { Component, ComponentFactoryResolver, Inject, OnInit, Type, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';
import { CouponAssignmentFormComponent } from '../../components';
import { CouponAssignmentService } from '../../services';
import { AddCouponAssignmentFormContainerDirective } from './add-coupon-assignment.directive';

export interface AddCouponAssignmentDialogData {
  communityId: string;
  couponId: string;
  type: string;
  label: string;
  form: Type<CouponAssignmentFormComponent>;
}

@Component({
  selector: 'greco-add-coupon-assignment-dialog',
  templateUrl: './add-coupon-assignment.dialog.html',
  styleUrls: ['./add-coupon-assignment.dialog.scss'],
})
export class AddCouponAssignmentDialogComponent implements OnInit {
  constructor(
    private couponAsgnmtSvc: CouponAssignmentService,
    private factoryResolver: ComponentFactoryResolver,
    private dialogRef: MatDialogRef<AddCouponAssignmentDialogComponent>,
    private snacks: MatSnackBar,
    private couponAssignmentSvc: CouponAssignmentService,
    @Inject(MAT_DIALOG_DATA) private data: AddCouponAssignmentDialogData
  ) {}

  @ViewChild(AddCouponAssignmentFormContainerDirective, { static: true })
  private container: undefined | AddCouponAssignmentFormContainerDirective;

  private component: undefined | CouponAssignmentFormComponent;

  saving = false;
  communityId!: string;
  couponId!: string;

  dialogData: DialogData = {
    title: `${this.data.label} Coupon Assignment`,
    subtitle: 'Choose item(s) to assign this coupon to it.',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  get isValid() {
    return this.component?.formGroup?.valid;
  }

  ngOnInit() {
    if (this.data?.form && this.container) {
      const factory = this.factoryResolver.resolveComponentFactory(this.data.form);
      const componentRef = this.container.viewRef.createComponent<CouponAssignmentFormComponent>(factory);
      this.component = componentRef.instance;
      this.dialogData.title = `${this.data.label} Coupon Assignment`;
      if (this.data.communityId && this.data.couponId) {
        this.component.communityId = this.data.communityId;
        this.component.couponId = this.data.couponId;
        this.component.type = this.data.type;
      } else this.close();
    }
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async save() {
    this.saving = true;
    try {
      if (this.component) {
        const result = this.component.save();
        const submitResult = await this.couponAssignmentSvc.create(result.data, result.module);
        this.saving = false;
        this.close(submitResult);
        if (submitResult) {
          this.snacks.open('Coupon assigned successfully!', 'Ok', {
            duration: 3500,
            panelClass: 'mat-primary',
          });
        }
      } else this.close();
    } catch (err: any) {
      console.error(err);
      this.snacks.open('Error assigning coupon! ' + (err?.message ? err.message : err), 'Ok', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
      this.close();
    }
  }
}
