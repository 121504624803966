<div>
  <div style="margin-bottom: 8px">
    <h2 style="margin-bottom: 4px">Grant Perks</h2>
    <p style="margin: 0">
      <a
        [routerLink]="['/community', data.communityId, data.user.id]"
        [matTooltip]="data.user.email || ''"
        (click)="$event.stopImmediatePropagation()"
      >
        Grant perks to {{ data.user.displayName }}
      </a>
    </p>
  </div>

  <form [formGroup]="form">
    <!-- Perk -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Perk</mat-label>
      <greco-perk-picker [required]="true" [communityId]="data.communityId" formControlName="perk"></greco-perk-picker>
      <mat-checkbox matSuffix color="primary" labelPosition="before" formControlName="transferable">
        Transferable
      </mat-checkbox>
    </mat-form-field>

    <!-- Quantity -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Quantity</mat-label>
      <input matInput type="number" min="1" step="1" required formControlName="quantity" />
      <mat-checkbox matSuffix color="primary" labelPosition="before" formControlName="unlimited">
        Unlimited Uses
      </mat-checkbox>
    </mat-form-field>

    <!-- Expiry -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Expires In (Number of Days)</mat-label>
      <input matInput type="number" step="1" min="0" formControlName="expiry" />
      <mat-hint *ngIf="expiresOn$ | async as expiresOn">Perk(s) will expire on {{ expiresOn }}</mat-hint>
      <mat-checkbox matSuffix color="primary" labelPosition="before" formControlName="expiryNotification">
        Send Expiry Notification
      </mat-checkbox>
    </mat-form-field>

    <mat-form-field *ngIf="form.value.expiryNotification" appearance="standard" style="width: 100%">
      <mat-label>
        Notify User {{ form.value.expiryNotificationDays }} Day{{ form.value.expiryNotificationDays > 1 ? 's' : ''}}
        Before Expiry
      </mat-label>
      <input
        matInput
        type="number"
        step="1"
        min="1"
        formControlName="expiryNotificationDays"
        [max]="form.value.expiry - 1"
      />
      <mat-hint *ngIf="expiresNotification$ | async as expiresNotification">
        An expiry notification email will be sent on {{ expiresNotification }}
      </mat-hint>
    </mat-form-field>
  </form>

  <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 8px; margin-top: 16px">
    <button style="height: 32px; line-height: 16px" type="button" mat-stroked-button (click)="close()">Cancel</button>
    <button
      style="height: 32pfx; line-height: 16px"
      type="button"
      mat-stroked-button
      color="primary"
      [disabled]="form.invalid || submitting"
      (click)="submit()"
    >
      <span *ngIf="!submitting">Grant Perks</span>
      <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
    </button>
  </div>
</div>
