import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { CalendarEvent } from '@greco/booking-events';
import {
  CreateEventVideoAndInitiateUploadDto,
  EventVideo,
  EventVideoSecurityResource,
  EventVideoSecurityResourceAction,
  EventVideoUnlockSecurityResource,
  EventVideoUnlockSecurityResourceAction,
  UpdateEventVideoDto,
} from '@greco/event-videos';
import { SelectVideosDialog, VideoUploadDialog } from '@greco/ngx-community-videos';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { VideoSendingDialog, WatchVideoComponent } from '@greco/ngx-videos';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CreateVideoAndInitiateUploadDto } from '@greco/videos';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EventVideosService } from '../../services';
@Component({
  selector: 'greco-event-videos',
  templateUrl: './event-videos.component.html',
  styleUrls: ['./event-videos.component.scss'],
})
export class EventVideosComponent {
  constructor(
    private eventVideosSvc: EventVideosService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private userSvc: UserService,
    private router: Router
  ) {}

  now = new Date();

  @Input() set event(event) {
    this._event$.next(event);
    this.refresh$.next(null);
  }

  get event() {
    return this._event$.value;
  }
  private _event$ = new BehaviorSubject<CalendarEvent | null>(null);
  readonly canRead$ = this._event$.pipe(
    switchMap(async event => {
      return event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.READ
          )
        : null;
    })
  );
  refresh$ = new BehaviorSubject(null);
  videos$ = combineLatest([this._event$, this.refresh$]).pipe(
    switchMap(async ([event]) => {
      if (event) return await this.eventVideosSvc.getEventVideosByEvent(event.id);
      else return [];
    })
  );
  readonly canUpload$ = this._event$.pipe(
    switchMap(async event => {
      return event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.UPLOAD
          )
        : null;
    })
  );

  readonly canUpdate$ = this._event$.pipe(
    switchMap(async event => {
      return event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.UPDATE
          )
        : null;
    })
  );
  readonly canDelete$ = this._event$.pipe(
    switchMap(async event => {
      return event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.REMOVE
          )
        : null;
    })
  );
  readonly canManageUnlock$ = this._event$.pipe(
    switchMap(async event => {
      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventVideoUnlockSecurityResource.key,
            EventVideoUnlockSecurityResourceAction.GRANT
          )) ||
            (await this.comSecSvc.hasAccess(
              event.community.id,
              EventVideoUnlockSecurityResource.key,
              EventVideoUnlockSecurityResourceAction.UPDATE
            ))
        : null;
    })
  );

  user$ = this.userSvc.user$;

  watch(eventVideo: EventVideo) {
    if (eventVideo.communityVideo?.video) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = eventVideo.communityVideo.video;
    }
  }

  async upload() {
    if (this.event) {
      //TODO: remove createdBy in services
      const dialog = this.matDialog.open(VideoUploadDialog, { data: {}, width: '750px', maxWidth: '90%' });
      dialog.componentInstance.communityId = this.event.community.id;

      const response: { file: File; dto: CreateVideoAndInitiateUploadDto } = await toPromise(dialog.afterClosed());
      if (response?.dto) {
        const sendingDialog = this.matDialog.open(VideoSendingDialog, {
          data: {},
          width: '450px',
          maxWidth: '90%',
        });
        sendingDialog.disableClose = true;
        const eventVideoDto: CreateEventVideoAndInitiateUploadDto = {
          source: response.dto.source,
          fileSize: response.dto.fileSize,
          title: response.dto.title,
          videoTagIds: response.dto.videoTagIds,
          description: response.dto.description,
          eventId: this.event.id,
        };
        try {
          await this.eventVideosSvc.upload(response.file, eventVideoDto);
        } catch (err) {
          console.error(err);
        }
        this.refresh$.next(null);
        sendingDialog.disableClose = false;
        sendingDialog.close();
      }
    }
  }

  async update(eventVideo: EventVideo) {
    //open update dialog for video
    const dialog = this.matDialog.open(VideoUploadDialog, { data: {}, width: '100%', maxWidth: '400px' });
    const instance = dialog.componentInstance;
    instance.video = eventVideo.communityVideo;
    instance.communityId = this.event?.community.id;
    instance.availableOn = eventVideo.availableOn || undefined;

    const response = await toPromise(dialog.afterClosed());
    if (response.dto) {
      const dto: UpdateEventVideoDto = {
        title: response.dto.title || null,
        description: response.dto.description || null,
        videoTagIds: response.dto.videoTagIds || null,
        availableOn: response.dto.availableOn,
      };

      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      try {
        await this.eventVideosSvc.update(eventVideo.id, dto);
      } catch (err) {
        console.error(err);
      }
      spinnerDialog.close();
    }
    this.refresh$.next(null);
  }

  async remove(eventVideo: EventVideo) {
    //open remove dialog for video and if resolves remove
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Removal',
        //subtitle: 'Are you sure you want to remove this video?',
        content: 'This will remove the video from the event, but not the platform or Vimeo',
        buttons: [
          { label: "No, Don't Remove", role: 'no' },
          { label: 'Yes, Remove Video', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      const response = await this.eventVideosSvc.remove(eventVideo.id);
      spinnerDialog.close();
      if (!response) {
        this.snacks.open('Video removed from event', '', { duration: 2500, panelClass: 'mat-primary' });
      } else {
        this.snacks.open('Cannot remove. Archiving...', '', { duration: 2500, panelClass: 'mat-warn' });
      }
      //reload video list
      this.refresh$.next(null);
    }
  }

  async addVideo() {
    if (this.event) {
      const community = this.event.community;
      const dialog = this.matDialog.open(SelectVideosDialog, {
        data: { community: community, sources: ['vimeo'] },
        width: '1000px',
        maxWidth: '90%',
      });
      const response = await toPromise(dialog.afterClosed());

      if (response?.length) {
        let success = 0;
        let failure = 0;
        for (const item of response) {
          if (item.id) {
            try {
              await this.eventVideosSvc.linkCommunityVideoToEvent(this.event.id, item.id);
              success++;
            } catch (err) {
              console.error(err);
              failure++;
            }
          } else if (item.externalId) {
            try {
              await this.eventVideosSvc.linkVideoToEvent(this.event.id, item);
              success++;
            } catch (err) {
              console.error(err);
              failure++;
            }
          }
        }
        this.snacks.open('Successfully linked ' + success + ' videos', failure === 0 ? 'Ok!' : failure + ' errors!', {
          duration: 2500,
          panelClass: failure === 0 ? 'mat-primary' : 'mat-warn',
        });
        this.refresh$.next(null);
      }
    }
  }

  async changeStatus(video: EventVideo) {
    //open  dialog for video and if resolves update status
    const isActive =
      video.availableOn &&
      video.availableOn.getTime() < Date.now() &&
      (!video.availableUntil || video.availableUntil.getTime() > Date.now());

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm ' + (!isActive ? ' Activate' : 'Archive'),
        //subtitle: 'Are you sure you want to remove this video?',
        content: !isActive
          ? 'This video will be visible for unlock by users with the appropriate perks'
          : 'This video will not be visible for unlock, but will remain visible if already unlocked',
        buttons: [
          { label: "No, Don't Update Status", role: 'no' },
          { label: 'Yes, Update Status', role: 'yes' },
        ],
      } as DialogData,
      width: '600px',
      maxWidth: '90%',
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;

      try {
        const availableOn = isActive ? null : new Date();

        await this.eventVideosSvc.update(video.id, { availableOn });
        this.refresh$.next(null);
        this.snacks.open('Status changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        console.error(err);
        this.snacks.open('Oops, something went wrong. Please try again.', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      }

      spinnerDialog.close();

      //reload video list
      this.refresh$.next(null);
    }
  }

  async manageUnlocks(eventVideo: EventVideo) {
    if (this.event) {
      await this.router.navigate([`/scheduling/${this.event.community.id}/${this.event.id}/${eventVideo.id}`]);
    } else console.log('no event');
  }
}
