<greco-table [data]="grantedLinks" [loading]="loading" [highlight]="true">
  <ng-container *grecoTableCol="''; fitContent: true; let link">
    <mat-icon *ngIf="link.status === 'PENDING'" style="width: 32px; height: 32px; font-size: 32px" color="accent"
      >mail</mat-icon
    >
    <greco-user-avatar
      *ngIf="link.status === 'ACTIVE'"
      [user]="link.accessor"
      size="small"
      [showOnlineStatus]="false"
    ></greco-user-avatar>
  </ng-container>

  <div *grecoTableCol="'Account'; let link">
    <a *ngIf="communityId; else noLink" [routerLink]="['/community', communityId, link.accessorId]">
      <p style="margin-bottom: 0">{{ link.accessor.displayName }}</p>
      <small>{{ link.accessor.email || link.accessor.contactEmail }}</small>
    </a>
    <ng-template #noLink>
      <p style="margin-bottom: 0">{{ link.accessor.displayName }}</p>
      <small>{{ link.accessor.email || link.accessor.contactEmail }}</small></ng-template
    >
  </div>
  <ng-container *grecoLet="canUpdate$ | async as canUpdate">
    <greco-account-link-permissions
      *grecoTableCol="'Access Granted'; let link"
      [link]="link"
      view="account"
      [canUpdate]="canUpdate || false"
      (refresh)="refresh.emit()"
    >
    </greco-account-link-permissions>
  </ng-container>

  <ng-container *grecoTableCol="''; fitContent: true; let link"> </ng-container>

  <ng-container *ngIf="canRevoke$ | async">
    <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let link">
      <div *ngIf="link.status === 'PENDING'">
        <button mat-flat-button [matMenuTriggerFor]="respondMenu" color="primary" style="min-width: 136px">
          Respond
        </button>
        <mat-menu #respondMenu>
          <button mat-menu-item (click)="accept(link)" style="color: var(--primary-color)">
            <mat-icon style="color: var(--primary-color)">thumb_up</mat-icon>
            Accept
          </button>
          <button mat-menu-item (click)="revokeAccess(link)" style="color: var(--warn-color)">
            <mat-icon style="color: var(--warn-color)">thumb_down</mat-icon>
            Decline
          </button>
        </mat-menu>
      </div>
      <button *ngIf="link.status === 'ACTIVE'" mat-flat-button color="warn" (click)="revokeAccess(link)">
        Revoke Access
      </button>
    </ng-container>
  </ng-container>
</greco-table>
