import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  CalendarEvent,
  EventResourceAssignment,
  EventResourceSecurityResource,
  EventResourceSecurityResourceAction,
  PersonResource,
  Resource,
  ResourceAssignmentStatus,
  ResourceAvailabilityStatus,
  ResourceType,
} from '@greco/booking-events';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { CreateEventSeriesDto } from '@greco/nestjs-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { userDefaultTimezone } from '@greco/timezone';
import { DialogData } from '@greco/ui-dialog-simple';
import moment from 'moment';
import { RRule } from 'rrule';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { SeriesSchedule } from '../../components';
import {
  EventDetails,
  EventDetailsInputComponent,
} from '../../components/event-details-input/event-details-input.component';
import { CourseService, EventService } from '../../services';
import { BusyResourcesDialog } from '../busy-resources/busy-resources.dialog';
import { SpecifyConfirmationsDialog } from '../specify-confirmations/specify-confirmations.dialog';

@Component({
  selector: 'greco-create-event-dialog',
  templateUrl: './create-event.dialog.html',
  styleUrls: ['./create-event.dialog.scss'],
})
export class CreateEventDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resource: Resource;
      communityId: string;
      canCreateBookings: boolean;
      date?: Date;
      forSeries?: boolean;
      allowChangeResources?: boolean;
    },
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private courseSvc: CourseService,
    private formBuilder: FormBuilder,
    private comSecSvc: CommunitySecurityService,
    private dialogRef: MatDialogRef<CreateEventDialog>
  ) {
    this.communityId = data?.communityId || undefined;
  }

  createCalled = false;
  createAttendeeCalled = false;

  availability: { [key: string]: ResourceAvailabilityStatus } = {};

  weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string | undefined>(undefined);
  communityId?: string;

  @ViewChild('details') private details?: ElementRef<EventDetailsInputComponent>;

  formGroup = this.formBuilder.group({
    details: [
      {
        startDate: this.data.date,
        private: !!this.data.resource,
      },
    ],
    schedule: [null],
  });

  dialogData: DialogData = {
    title: 'Create New ' + (this.data.forSeries ? 'Series' : 'Event'),
    subtitle: 'Provide the details for the new ' + (this.data.forSeries ? 'series' : 'event'),
    showCloseButton: false,
  };

  private _onDestroy$ = new Subject<void>();

  readonly canCreateBookings$ = this._communityId$.pipe(
    switchMap(async communityId => {
      if (!communityId) return false;

      const user = await this.userSvc.getSelf();
      if (!this.data.canCreateBookings && !(this.data.resource?.groupId === user?.id)) return false;

      return await this.comSecSvc.hasAccess(communityId, ContactResource.key, ContactResourceAction.READ);
    }),
    shareReplay(1)
  );

  readonly canDoubleBook$ = this._communityId$.pipe(
    switchMap(async communityId => {
      if (communityId) {
        return await this.comSecSvc.hasAccess(
          communityId,
          EventResourceSecurityResource.key,
          EventResourceSecurityResourceAction.DOUBLE_BOOK
        );
      } else return false;
    })
  );

  cancel() {
    this.dialogRef.close({ event: null, action: 'cancel' });
  }

  async createAndAddAttendees() {
    try {
      if (this.createAttendeeCalled) return;
      this.createAttendeeCalled = true;

      const event = await this._createEvent();

      if (event) {
        await new Promise(res => setTimeout(res, 500));
        this.snacks.open('New event created!', 'Open', { duration: 8000, panelClass: 'mat-primary' });
        this.dialogRef.close({ event, action: 'create_and_add_attendee' });
      }
    } catch (err: any) {
      if (!(err instanceof HttpErrorResponse)) {
        this.snacks.open('Error. ' + err.message ? err.message : err, 'Ok', { panelClass: 'mat-warn' });
      }
      this.dialogRef.close(null);
    }
  }

  async create() {
    try {
      if (this.createCalled) return;
      this.createCalled = true;

      const event = await this._createEvent();

      if (event) {
        await new Promise(res => setTimeout(res, 500));
        this.snacks.open('New ' + (this.data.forSeries ? 'series' : 'event') + ' created!', 'Ok', {
          duration: 3000,
          panelClass: 'mat-primary',
        });
        this.dialogRef.close({ event, action: 'create' });
      }
    } catch (err: any) {
      console.error(err);
      if (!(err instanceof HttpErrorResponse)) {
        this.snacks.open('Error. ' + err.message ? err.message : err, 'Ok', { panelClass: 'mat-warn' });
      }
      this.dialogRef.close();
    }
  }

  async _createEvent() {
    const details: EventDetails = this.formGroup.value.details;

    let resourceAssignments = (details.resourceAssignments || []) as EventResourceAssignment[];
    const busyResources: EventResourceAssignment[] = [];
    const newConfirmedResources: EventResourceAssignment[] = [];

    if (details.roomAssignment) {
      resourceAssignments = resourceAssignments?.filter(assignment => assignment?.resource?.type !== ResourceType.ROOM);
      resourceAssignments = [...(resourceAssignments || []), details.roomAssignment];
    }

    if (details.zoomAssignment) {
      resourceAssignments = resourceAssignments?.filter(assignment => assignment?.resource?.type !== ResourceType.ZOOM);
      resourceAssignments = [...(resourceAssignments || []), details.zoomAssignment];
    }

    resourceAssignments.forEach(assignment => {
      // Check if resource is busy
      if (assignment.resource && this.availability[assignment.resource.id] === ResourceAvailabilityStatus.BUSY) {
        busyResources.push(assignment);
      }

      // Check if resource is newly added
      if (
        assignment.resource &&
        assignment.resource.type === ResourceType.PERSON &&
        assignment.resourceStatus === ResourceAssignmentStatus.CONFIRMED
      ) {
        newConfirmedResources.push(assignment);
      }
    });

    let confirmationResult: string[] = [];
    if (busyResources.length) {
      const result = await toPromise(
        this.matDialog
          .open(BusyResourcesDialog, {
            data: {
              resourceAssignments: [...busyResources],
              canDoubleBook$: this.canDoubleBook$,
            },
          })
          .afterClosed()
      );
      if (!result?.continue) {
        this.createCalled = false;
        this.createAttendeeCalled = false;
        return;
      }

      busyResources.forEach(item => {
        if ((item.resource as PersonResource).user?.email) {
          confirmationResult.push((item.resource as PersonResource).user?.email as string);
        }
      });

      if (newConfirmedResources.length && result?.continue) {
        const result = await toPromise(
          this.matDialog
            .open(SpecifyConfirmationsDialog, {
              data: {
                communityId: this.data.communityId,
                assignments: [...newConfirmedResources],
              },
            })
            .afterClosed()
        );

        if (!result?.emails.length) {
          this.createCalled = false;
          this.createAttendeeCalled = false;
          return;
        }

        confirmationResult = result?.emails;
      }
    }

    if (newConfirmedResources.length && !busyResources.length) {
      const result = await toPromise(
        this.matDialog
          .open(SpecifyConfirmationsDialog, {
            data: {
              communityId: this.data.communityId,
              assignments: newConfirmedResources,
            },
          })
          .afterClosed()
      );

      if (!result?.emails.length) {
        this.createCalled = false;
        this.createAttendeeCalled = false;
        return;
      }

      confirmationResult = result?.emails;
    }

    if (details.resourceAssignments?.length) {
      details.resourceAssignments.forEach(assignment => {
        delete assignment.resourceTag;
      });
    }

    const data = {
      startDate: details.startDate || new Date(),
      timezone: details.timezone || userDefaultTimezone(),

      resourceTagIds: details.resourceTags?.map(tag => tag.id) || [],
      resourceAssignments,
      autoAssign: details.autoAssign || false,
      enableUserSpotBooking: details.enableUserSpotBooking || false,
      checkInWindow: details.checkInWindow || 0,
      maxCapacity: details.maxCapacity || 10,
      description: details.description || '',
      private: details.private || false,
      calendarId: details.calendarId,
      communityId: this.data.communityId,
      duration: details.duration || 45,
      color: details.color || '',
      title: details.title || '',
      imageUrl: (details.imageUrl || null) as any,
      zoomMeetingId: details.zoomMeetingId || '',
      tags: (details.tags || []).map(tag => ({
        id: tag.id,
        communityId: this.data.communityId,
        calendarId: details.calendarId,
        label: tag.label,
      })),
      typeform: (details.typeform || []).map(({ id, reusable, required }) => ({
        formId: id,
        reusable,
        required,
      })),

      equipmentTitle: details.equipmentTitle || '',
      equipmentOptions: details.equipmentOptions || [],
    };

    if (!this.data.forSeries) {
      const event = await this.eventSvc.createEvent({ ...data, confirmationEmails: confirmationResult });
      return event;
    } else {
      const schedule: SeriesSchedule = this.formGroup.value.schedule;

      const startDate = this.formGroup.value.schedule.startDate;
      const endDate = this.formGroup.value.schedule.endDate;
      if (endDate && new Date(startDate).getTime() >= new Date(endDate).getTime()) {
        this.snacks.open('Cannot create a series with an end date before the start date!', 'Ok', {
          panelClass: 'mat-warn',
        });
      }

      let recurrence: string[] = [];
      recurrence = this.computeRecurrence(startDate);

      const seriesData: CreateEventSeriesDto = {
        ...data,
        startDate,
        endDate: endDate ? moment(endDate).endOf('day').toDate() : endDate,
        availableAsCourse: schedule.availableAsCourse || false,
        availableAsInstances: schedule.availableAsInstances || false,
        recurrence: recurrence || [],
      };

      const series = await this.eventSvc.createSeries(seriesData);

      if (schedule.courseImage) {
        const formData = new FormData();
        formData.append('file', schedule.courseImage[0]);

        await this.courseSvc.uploadCourseImage(series.id, formData);
      }

      return series;
    }
  }

  computeRecurrence(startDate: Date, days?: any) {
    const daysValue = days ? days : this.formGroup.get('schedule')?.value;
    return this.weekDays.reduce(
      (acc, day) => [
        ...acc,
        ...(daysValue[day]?.map((date: Date) =>
          new RRule({
            dtstart: startDate,
            freq: RRule.WEEKLY,
            byweekday: {
              monday: RRule.MO,
              tuesday: RRule.TU,
              wednesday: RRule.WE,
              thursday: RRule.TH,
              friday: RRule.FR,
              saturday: RRule.SA,
              sunday: RRule.SU,
            }[day],
            byhour: [moment(date).hour()],
            byminute: [moment(date).minute()],
            bysecond: [0],
          }).toString()
        ) || ([] as string[])),
      ],
      [] as string[]
    );
  }

  refreshAvailability(schedule: SeriesSchedule) {
    const { startDate, timezone, endDate, resources, availableAsCourse, availableAsInstances, courseImage, ...days } =
      schedule;

    if (days && startDate) {
      const computedRecurrence = this.computeRecurrence(startDate, days);
      if (computedRecurrence) (this.details as any)?.refreshAvailability(computedRecurrence, startDate);
    }
  }

  async ngOnInit() {
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    setTimeout(() => {
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
        this.dialogData = { ...this.dialogData };
      });
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
