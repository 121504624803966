import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { DataExport } from '@greco/data-exports';
import {
  InventoryProductAddon,
  Product,
  ProductVariantInventory,
  ProductVariantInventoryRestock,
} from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Pagination } from 'nestjs-typeorm-paginate';

@Injectable()
export class InventoryService {
  constructor(private http: HttpClient) {}

  async paginateProducts(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: PaginatedQueryParams,
    saleCategoryIds?: string[],
    showUncategorized?: boolean
  ): Promise<Pagination<Product>> {
    return await toPromise(
      this.http.get<Pagination<Product & { inventoryAddon: InventoryProductAddon }>>(
        '/api/product_inventories/paginate_products',
        {
          params: {
            ...query.queryObject,
            ...(communityId && { communityId }),
            ...{ saleCategoryIds: saleCategoryIds || [] },
            ...(showUncategorized && { showUncategorized: showUncategorized ? 'true' : 'false' }),
            page: (pagination?.page || 1).toString(),
            limit: (pagination?.limit || 10).toString(),
          },
        }
      )
    );
  }

  // @Get('addon/:addonId')
  async getVariantInventories(addonId: string) {
    return await toPromise(this.http.get<ProductVariantInventory[]>(`/api/product_inventories/addon/${addonId}`));
  }

  // @Get(':variantId')
  async getVariantInventory(variantId: string) {
    return await toPromise(this.http.get<ProductVariantInventory>(`/api/product_inventories/${variantId}`));
  }

  // @Post(':addonId/:variantId')
  async createVariantInventory(addonId: string, variantId: string) {
    return await toPromise(
      this.http.post<ProductVariantInventory>(`/api/product_inventories/${addonId}/${variantId}`, {})
    );
  }

  // @Patch(':inventoryId/status')
  async updateVariantInventoryStatus(inventoryId: string, enabled: boolean) {
    return await toPromise(
      this.http.patch<ProductVariantInventory>(`/api/product_inventories/${inventoryId}/status`, { enabled })
    );
  }

  // @Patch(':variantId/restock')
  async restockVariant(variantId: string, quantity: number) {
    return await toPromise(
      this.http.patch<ProductVariantInventoryRestock>(`/api/product_inventories/${variantId}/restock`, { quantity })
    );
  }

  // @Patch(':variantId/return')
  async returnVariant(variantId: string, quantity: number, correlationId: string) {
    return await toPromise(
      this.http.patch<ProductVariantInventoryRestock>(`/api/product_inventories/${variantId}/return`, {
        quantity,
        correlationId,
      })
    );
  }

  // @Post('export/:communityId')
  async export(communityId: string, skip?: number) {
    return await toPromise(
      this.http.post<DataExport>(`/api/product_inventories/export/${communityId}`, {
        params: { ...(skip && { skip: skip.toString() }) },
      })
    );
  }
}
