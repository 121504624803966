import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactNote } from '@greco/identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { ContactNotesService } from '../../services';

@Component({
  selector: 'greco-update-note-dialog',
  templateUrl: './update-note.dialog.html',
  styleUrls: ['./update-note.dialog.scss'],
})
export class UpdateContactNoteDialog implements OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { note: ContactNote },
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private noteSvc: ContactNotesService,
    private securitySvc: SecurityService,
    private dialogRef: MatDialogRef<UpdateContactNoteDialog>
  ) {}

  saveCalled = false;

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    note: [this.data.note.content, Validators.required],
  });

  dialogData: DialogData = {
    title: 'Update Member Note',
    subtitle: 'Provide the new details for the note',
    showCloseButton: false,
  };

  cancel() {
    this.dialogRef.close();
  }

  async update() {
    try {
      if (this.saveCalled) return;
      this.saveCalled = true;

      const response = await this.noteSvc.update(this.data.note.id, {
        content: this.formGroup.value.note,
      });

      this.snacks.open('Note updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(response);
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
