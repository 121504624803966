import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountService } from '@greco/ngx-finance-accounts';
import { SecurityService } from '@greco/ngx-security-util';
import { Purchase, PurchaseResource, PurchaseResourceAction, PurchaseStatus } from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-retry-failed-purchase-dialog',
  templateUrl: './retry-failed.dialog.html',
  styleUrls: ['./retry-failed.dialog.scss'],
})
export class RetryFailedPurchaseDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly purchase: Purchase,
    private dialogRef: MatDialogRef<RetryFailedPurchaseDialog>,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService,
    private accountSvc: AccountService
  ) {}

  readonly dialogData: DialogData = {
    title: 'Retry Failed Purchase',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  formGroup = this.formBuilder.group({
    paymentMethod: [this.purchase.payment?.paymentMethod || null, Validators.required],
    useBalance: [false],
    waiveAdminFee: [false],
  });

  processing = false;

  purchase$ = new BehaviorSubject<Purchase>(this.purchase);

  canWaiveAdminFee$ = this.securitySvc.hasAccess(
    PurchaseResource.key,
    PurchaseResourceAction.WAIVE_ADMIN_FEE,
    {},
    true
  );

  adminFee$ = this.purchase$.pipe(
    switchMap(async purchase => {
      if (!purchase) return null;
      return this.accountSvc.getAdminFee(purchase.account.id);
    })
  );

  close() {
    this.dialogRef.close();
  }

  async submit() {
    this.processing = true;

    try {
      let purchase = await this.purchaseSvc.retryPayment(
        this.purchase.id,
        this.formGroup.value.paymentMethod.id,
        this.formGroup.value.useBalance,
        this.formGroup.value.waiveAdminFee
      );
      purchase = await this.purchaseSvc.handleTerminalPayment(purchase, this.dialogRef.afterClosed());

      if (purchase.status !== PurchaseStatus.PAID && purchase.status !== PurchaseStatus.PENDING) {
        console.error('Purchase could not be completed');
        this.snacks.open('Purchase could not be completed', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        this.snacks.open('Successful purchase!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close();
  }
}
