<ng-container *grecoLet="user$ | async as user">
  <button *ngIf="user" mat-icon-button (click)="openQRs(user)">
    <mat-icon style="margin-top: 0">qr_code_2</mat-icon>
  </button>
  <button *ngIf="user; else signIn" mat-icon-button [matTooltip]="user?.email || ''" [matMenuTriggerFor]="userOptions">
    <greco-user-avatar size="small" [user]="user"></greco-user-avatar>
  </button>
  <ng-template #signIn>
    <button
      mat-stroked-button
      color="primary"
      [routerLink]="signInPath"
      routerLinkActive="hidden"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon>login</mat-icon>
      Sign-In
    </button>
  </ng-template>
  <mat-menu #userOptions="matMenu" class="profile-menu">
    <a
      mat-menu-item
      *ngIf="accountRoute.startsWith('http')"
      [href]="accountRoute"
      target="_blank"
      class="user-profile-button"
    >
      <div style="width: 28px; height: 28px; min-width: 28px">
        <greco-smart-img
          [borderColor]="'primary'"
          [src]="user?.photoURL || 'assets/lf3/icon_square_pad.png'"
          [border]="true"
          [round]="true"
        ></greco-smart-img>
      </div>
      <div class="user-profile">
        {{ user?.friendlyName || user?.displayName || 'My Profile' }}
        <small style="font-size: 75%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; color: grey">
          {{ user?.email }}
        </small>
      </div>
    </a>
    <a
      mat-menu-item
      *ngIf="!accountRoute.startsWith('http')"
      [routerLink]="accountRoute"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="enabled"
      class="user-profile-button"
    >
      <div style="width: 28px; height: 28px; min-width: 28px">
        <greco-smart-img
          [borderColor]="'primary'"
          [src]="user?.photoURL || 'assets/lf3/icon_square_pad.png'"
          [border]="true"
          [round]="true"
        ></greco-smart-img>
      </div>
      <div class="user-profile">
        {{ user?.friendlyName || user?.displayName || 'My Profile' }}
        <small style="font-size: 75%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; color: grey">
          {{ user?.email }}
        </small>
      </div>
    </a>

    <!-- <button mat-menu-item [routerLink]="'/' + accountRoute + '/payment-methods'" routerLinkActive="disabled">
    <mat-icon>payments</mat-icon>
    Payment Methods
  </button>
  <button mat-menu-item [routerLink]="'/' + accountRoute + '/purchases'" routerLinkActive="disabled">
    <mat-icon>receipt_long</mat-icon>
    Purchase History
  </button> -->
    <mat-divider></mat-divider>

    <button mat-menu-item class="warn-color" (click)="signOut()">
      <mat-icon class="warn-color">logout</mat-icon>
      Sign Out
    </button>
  </mat-menu>
</ng-container>
