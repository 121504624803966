import {
  Component,
  ElementRef,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { Contact, ContactResource, ContactResourceAction, ContactStats } from '@greco/identity-contacts';
import type { CreateUserDto } from '@greco/nestjs-identity-users-util';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { BuildDateFilter, BuildSearchFilter, BuildTextFilter, Filter } from '@greco/ngx-filters';
import { UserService } from '@greco/ngx-identity-users';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { CreateContactDialog } from '../../dialogs';
import { ContactService } from '../../services';

@Injectable()
export class ContactDisplayNameFilter extends BuildTextFilter('ContactDisplayNameFilter', {
  label: 'Display Name',
  shortLabel: 'Name',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['displayName'],
}) {}

@Injectable()
export class ContactSearchFilter extends BuildSearchFilter('ContactSearchFilter', {
  properties: ['email', 'displayName'],
  propertyLabels: ['Email Address', 'Display Name'],
}) {}

@Injectable()
export class ContactEmailFilter extends BuildTextFilter('ContactEmailFilter', {
  label: 'Email Address',
  shortLabel: 'Email',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['email'],
}) {}

@Injectable({ providedIn: 'root' })
export class ContactCreatedDateFilter extends BuildDateFilter('ContactCreatedDateFilter', {
  label: 'Created Date',
  shortLabel: 'Created',
  description: '',
  properties: ['created'],
}) {}

@Component({
  selector: 'greco-contacts-page',
  templateUrl: './contacts.page.html',
  styleUrls: ['./contacts.page.scss'],
})
export class ContactsPage implements OnChanges, OnDestroy {
  constructor(
    private contactSvc: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService,
    private userSvc: UserService
  ) {}

  @ViewChild('fileInput')
  fileInput!: ElementRef;

  @PropertyListener('community') community$ = new ReplaySubject<Community>(1);
  @Input() community!: Community;
  stats?: ContactStats;

  filterOptions: Type<Filter>[] = [
    ContactSearchFilter,
    ContactEmailFilter,
    ContactDisplayNameFilter,
    ContactCreatedDateFilter,
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  sort$ = new BehaviorSubject<Sort>({ active: 'created', direction: 'desc' });
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  contacts$ = combineLatest([this.sort$, this.filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([sort, queryBuilder, pagination]) => {
      queryBuilder = RequestQueryBuilder.create({
        sort: sort.active ? [{ field: sort.active, order: sort.direction === 'desc' ? 'DESC' : 'ASC' }] : [],
        search: JSON.parse(queryBuilder.queryObject.s.toString().replace('+', '%2b') || '{}'),
      });
      return await this.contactSvc.paginateContacts(queryBuilder, this.community?.id, pagination);
      // return this.community?.id
      //   ? await this.contactSvc.paginateContacts(queryBuilder, this.community.id, pagination)
      //   : null;
    }),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  canSubscribeMember$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(community => {
      return this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.SUBSCRIBE, {
        communityId: community?.id,
      });
    })
  );

  canCreateContact$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(community => {
      return this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.CREATE, {
        communityId: community?.id,
      });
    })
  );

  canExportContacts$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(community => {
      return this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.EXPORT, {
        communityId: community?.id,
      });
    })
  );

  canInviteContact$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(community => {
      return this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.INVITE_USER, {
        communityId: community?.id,
      });
    })
  );

  canDeleteContact$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(community => {
      return this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.DELETE, {
        communityId: community?.id,
      });
    })
  );

  canShopAs$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(community => {
      return this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.PURCHASE_AS_USER, {
        communityId: community?.id,
      });
    })
  );

  pagination: null | IPaginationMeta = null;

  loading = true;
  invited = false;
  inviteLoading = false;

  async createContact() {
    const dialog = this.dialog.open(CreateContactDialog, {
      data: { communityId: this.community?.id || 'com_greco' },
      width: '750px',
      maxWidth: '90%',
    });
    const contact = await toPromise(dialog.afterClosed());
    if (contact) await this.router.navigate([contact.id], { relativeTo: this.route });
  }

  async deleteContact(contact: Contact) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to remove <strong>${contact.displayName}</strong> from your contacts list.</p><p>This action is NOT reversible!</p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      await this.contactSvc.deleteContact(contact.id);
      this.snacks.open('Contact Deleted!', 'Ok', { duration: 3000 });
      await this.refresh();
    }
  }

  async inviteUser(contact: Contact) {
    const dto: CreateUserDto = {
      email: contact.email,
      displayName: contact.displayName,
      phoneNumber: contact.phoneNumber || undefined,
      communityId: this.community.id,
    };
    this.inviteLoading = true;

    try {
      await this.userSvc.createAndInviteUser(dto);

      this.snacks.open('Invitation sent!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
    }

    this.inviteLoading = false;
    this.invited = true;
  }

  async contactExist(email: string, communityId: string): Promise<boolean> {
    try {
      await this.contactSvc.getContactByEmail(email, communityId, true);
      return true;
    } catch (e) {
      return false;
    }
  }
  validatePhoneNumber(phoneInput: string) {
    const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const ok = re.exec(phoneInput);
    if (!ok) {
      console.log('ph failed');
      return false;
    } else {
      return true;
    }
  }

  validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const ok = re.exec(email);
    if (!ok) {
      console.log('em fail', email);

      return false;
    } else {
      return true;
    }
  }
  // async importContacts(files: FileList) {
  //   const contacts: Omit<CreateContactDto, 'communityId'>[] = [];
  //   let errorsInData = false;
  //   if (files && files.length > 0) {
  //     const file: File | null = files.item(0);
  //     if (file?.name.endsWith('.csv')) {
  //       const reader: FileReader = new FileReader();
  //       reader.readAsText(file);
  //       reader.onload = async e => {
  //         if (e) console.log(e);
  //         const csv: string = reader.result as string;
  //         const rows = csv.split('\n');
  //         const columns = rows[0].split(',');
  //         if (columns.length < 1) throw new Error();
  //         if (columns[0] != 'Display Name' || columns[1] != 'Email Address' || !columns[2].startsWith('Phone Number')) {
  //           this.snacks.open('Invalid Content detected.', 'Ok', { duration: 4500, panelClass: 'mat-warn' });
  //           throw new Error();
  //         }

  //         for (let i = 1; i < rows.length; i++) {
  //           const values = rows[i].split(',');
  //           // optional phone number
  //           if (!values[2]) values.push('');
  //           // carriage return removal
  //           if (/\r|\n/.exec(values[2])) values[2] = '';
  //           // for empty values
  //           if (rows[i] === '') continue;

  //           const new_contact = {
  //             displayName: values[0],
  //             email: values[1],
  //             phoneNumber: values[2],
  //           };

  //           if (
  //             this.validateEmail(new_contact.email) &&
  //             (!new_contact.phoneNumber ? true : this.validatePhoneNumber(new_contact.phoneNumber))
  //           ) {
  //             contacts.push(new_contact);
  //           } else {
  //             errorsInData = true;
  //             break;
  //           }
  //         }
  //         if (errorsInData) {
  //           this.snacks.open('Invalid data.', 'Ok', { duration: 4500, panelClass: 'mat-warn' });
  //           this.fileInput.nativeElement.value = '';
  //           return;
  //         }
  //         const importDto: ImportContactDto = {
  //           communityId: this.community.id,
  //           contacts: [...new Map(contacts.map(item => [item['email'], item])).values()],
  //         };
  //         // check if all the contacts are same in the file and if that already exist in db

  //         if (importDto.contacts.length === 1 && importDto.contacts[0]) {
  //           const { contacts, communityId } = importDto;
  //           const contact = await this.contactExist(contacts[0].email, communityId);
  //           if (contact) {
  //             console.log(contact);
  //             this.fileInput.nativeElement.value = '';
  //             this.snacks.open('Contact already exist', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
  //             return;
  //           }
  //         }

  //         await this.contactSvc.importContacts(importDto);

  //         this.snacks.open('Contacts Imported successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
  //         // reset file input
  //         this.fileInput.nativeElement.value = '';
  //         this.refresh();
  //       };
  //     }
  //   }
  // }

  async exportContacts(communityId: string) {
    InitiateExportDialog.open(this.dialog, {
      processorId: 'ContactsDataExportProcessor',
      initiateExport: (skip?: number) => {
        return this.contactSvc.exportContacts(this.filters$.value, communityId, skip);
      },
    });
  }
  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.filters$.complete();
    this.page$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community.previousValue !== changes.community.currentValue) {
      this.refresh();
    }
  }

  // importTemplate() {
  //   const url = encodeURI('data:text/csv;charset=utf-8,Display Name,Email Address,Phone Number\n');
  //   const anchor = document.createElement('a');
  //   anchor.setAttribute('href', url);
  //   anchor.setAttribute('target', '_blank');
  //   anchor.setAttribute('download', `Import Contacts Template.csv`);
  //   anchor.style.display = 'none';

  //   document.body.appendChild(anchor);

  //   anchor.click();
  //   anchor.parentNode?.removeChild(anchor);
  // }

  async openContact(contact: Contact) {
    await this.router.navigate(['/community', contact.community.id, contact.id]);
  }

  private async refresh() {
    this.filters$.next(this.filters$.value);
    this.stats = await this.contactSvc.getStats(this.community.id);
  }
  async subscribeUser(userId: string) {
    const response = await this.contactSvc.subscribeUserToCommunity(this.community.id, userId);
    if (response) this.snacks.open('Contact Subscribed!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    else this.snacks.open('Something went wrong', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    this.refresh();
  }
}
