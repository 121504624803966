import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { DataExport } from '@greco/data-exports';
import { Account } from '@greco/finance-accounts';
import type { PaymentMethod } from '@greco/finance-payments';
import type { CreateUserPaymentMethodDto } from '@greco/nestjs-finance-payments';
import { Subscription } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';

export interface FinanceAccountStripeLocation {
  financeAccountId: string;
  financeAccount?: Account;

  locationId: string;
}

@Injectable()
export class UserPaymentMethodService {
  constructor(private http: HttpClient) {}

  async paginate(
    userId: string,
    query: PaginatedQueryParams,
    allowTerminals = false,
    locationId?: string
  ): Promise<PaginatedDto<PaymentMethod>> {
    return await toPromise(
      this.http.get<PaginatedDto<PaymentMethod>>('/api/user-payment-methods', {
        params: {
          userId,
          ...(allowTerminals ? { allowTerminals: 'true' } : {}),
          ...(query.page ? { page: query.page.toString() } : {}),
          ...(query.limit ? { limit: query.limit.toString() } : {}),
          ...(locationId ? { locationId } : {}),
        },
      })
    );
  }

  async getAll(userId: string, allowTerminals = false, locationId?: string): Promise<PaymentMethod[]> {
    const paymentMethods: PaymentMethod[] = [];
    let pagination: IPaginationMeta | null = null;

    do {
      const page: number = (pagination?.currentPage || 0) + 1;
      const data = await this.paginate(userId, { limit: 100, page }, allowTerminals, locationId);

      paymentMethods.push(...data.items);
      pagination = data.meta;
    } while (paymentMethods.length < (pagination?.totalItems || 0));

    return paymentMethods;
  }

  getUsedPaymentMethods(userId: string) {
    return toPromise(this.http.get<string[]>(`/api/user-payment-methods/used/${userId}`));
  }

  async getPaymentMethodSubscriptions(paymentMethodId: string): Promise<Subscription[]> {
    return await toPromise(this.http.get<Subscription[]>(`/api/subscriptions/${paymentMethodId}/subscriptions`));
  }

  async getDefault(userId: string, ignoreError?: boolean): Promise<PaymentMethod> {
    try {
      return await toPromise(
        this.http.get<PaymentMethod>(`/api/user-payment-methods/default/${userId}`, {
          headers: ignoreError ? { 'X-GrecoIgnoreErrors': 'true' } : {},
        })
      );
    } catch (err) {
      if (!ignoreError) throw err;
      return null as any;
    }
  }

  async getById(paymentMethodId: string): Promise<PaymentMethod> {
    return await toPromise(this.http.get<PaymentMethod>(`/api/user-payment-methods/${paymentMethodId}`));
  }

  removePaymentMethod(paymentMethodId: string) {
    return toPromise(this.http.delete(`/api/user-payment-methods/${paymentMethodId}`));
  }

  async create(body: CreateUserPaymentMethodDto): Promise<PaymentMethod> {
    return await toPromise(this.http.post<PaymentMethod>('/api/user-payment-methods', body));
  }

  async updateExpiry(paymentMethodId: string, expiry: string): Promise<PaymentMethod> {
    return await toPromise(this.http.patch<PaymentMethod>(`/api/user-payment-methods/${paymentMethodId}`, { expiry }));
  }

  async setDefault(paymentMethodId: string): Promise<PaymentMethod> {
    return await toPromise(this.http.put<PaymentMethod>(`/api/user-payment-methods/default/${paymentMethodId}`, {}));
  }

  async exportPaymentMethods(filters: { communityId: string }, queryBuilder: RequestQueryBuilder, skip?: number) {
    return await toPromise(
      this.http.get<DataExport>('/api/user-payment-methods/exportPaymentMethods', {
        params: {
          ...filters,
          ...queryBuilder.queryObject,
          ...(skip && { skip: skip.toString() }),
        },
      })
    );
  }

  // @Get('terminals/locations/:financeAccountId')
  async getAccountLocation(financeAccountId: string) {
    return await toPromise(
      this.http.get<FinanceAccountStripeLocation>(`/api/stripe/terminals/locations/${financeAccountId}`)
    );
  }
}
