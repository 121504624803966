import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { PromotionsService } from '../../services';

@Component({
  selector: 'greco-create-promotion-dialog',
  templateUrl: './create-promotion.dialog.html',
  styleUrls: ['./create-promotion.dialog.scss'],
})
export class CreatePromotionDialog implements OnDestroy {
  form = this.fb.group({
    title: ['My Promotion', Validators.required],
    subtitle: [''],
    startDate: [moment().add(1, 'day').toDate(), Validators.required],
    endDate: [moment().add(4, 'day').toDate(), Validators.required],
  });

  dialogData: DialogData = {
    title: 'Create Promotion',
    subtitle: 'Use the following form to create a promotion.',
    content: 'You will be able to select promoted products and variants on the next screen.',
    buttons: [
      {
        label: 'Cancel',
        role: 'cancel',
      },
      {
        label: 'Create',
        role: 'create',
        disabled: this.form.invalid,
        resultFn: () => this.submit(),
      },
    ],
  };

  formStatus: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { communityId: string },
    private promotionsSvc: PromotionsService,
    private snacks: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.formStatus = this.form.statusChanges.subscribe(f => {
      this.dialogData = {
        ...this.dialogData,
        buttons: [
          {
            label: 'Cancel',
            role: 'cancel',
          },
          {
            label: 'Create',
            role: 'create',
            disabled: f === 'INVALID',
            resultFn: () => this.submit(),
          },
        ],
      };
    });
  }

  async submit() {
    try {
      if (this.form.valid) {
        const promotion = await this.promotionsSvc.create({
          communityId: this.data.communityId,
          title: this.form.value.title,
          subtitle: this.form.value.subtitle,
          startDate: this.form.value.startDate,
          endDate: this.form.value.endDate,
          productVariantIds: [],
        });
        this.snacks.open('New Promotion created', '', { duration: 2500, panelClass: 'mat-primary' });
        return promotion;
      } else {
        this.snacks.open('Unable to create Promotion', '', { duration: 2500, panelClass: 'mat-warn' });
        return null;
      }
    } catch (err) {
      this.snacks.open('Unable to create Promotion', '', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
      return null;
    }
  }

  ngOnDestroy() {
    if (!this.formStatus.closed) this.formStatus.unsubscribe();
  }
}
