import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from '@greco/ngx-security-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { ContactNotesService } from '../../services';

@Component({
  selector: 'greco-create-note-dialog',
  templateUrl: './create-note.dialog.html',
  styleUrls: ['./create-note.dialog.scss'],
})
export class CreateContactNoteDialog implements OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { contactId: string; admin: boolean },
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private noteSvc: ContactNotesService,
    private securitySvc: SecurityService,
    private dialogRef: MatDialogRef<CreateContactNoteDialog>
  ) {}

  createCalled = false;

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    note: ['', Validators.required],
    admin: [this.data.admin || false],
    pinned: [false],
  });

  dialogData: DialogData = {
    title: 'Create New Member Note',
    subtitle: 'Provide the details for the new note',
    showCloseButton: false,
  };

  cancel() {
    this.dialogRef.close();
  }

  async create() {
    try {
      if (this.createCalled) return;
      this.createCalled = true;

      const contact = await this.noteSvc.create(this.data.contactId, {
        content: this.formGroup.value.note,
        admin: this.formGroup.value.admin,
        pinned: this.formGroup.value.pinned,
      });

      this.snacks.open('New note created', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close({ contact, action: 'create' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      this.dialogRef.close({ contact: null, action: 'create' });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
