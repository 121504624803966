import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventSeries } from '@greco/booking-events';
import { DialogData } from '@greco/ui-dialog-simple';

@Component({
  selector: 'greco-update-series-from-event',
  templateUrl: './update-series-from-event.dialog.html',
  styleUrls: ['./update-series-from-event.dialog.scss'],
})
export class UpdateSeriesFromEventDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { series: EventSeries },
    private dialogRef: MatDialogRef<UpdateSeriesFromEventDialog>
  ) {}

  dialogData: DialogData = {
    title: 'Update Series',
    subtitle: `Here you can update the series linked to the event.`,
    showCloseButton: false,
    buttons: [
      {
        label: 'Cancel',
        role: 'cancel',
      },
    ],
  };

  close() {
    this.dialogRef.close('updated');
  }
}
