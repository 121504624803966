import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { AddInventoryAddonDto } from '@greco/nestjs-sales-products';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  AddonType,
  InventoryProductAddon,
  InventoryStockType,
  ProductAddon,
  ProductStatus,
  ProductVariant,
  ProductVariantInventory,
  VariantResource,
  VariantResourceAction,
} from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AddonsService, InventoryService, ProductsService, VariantsService } from '../../../../services';

export type InventoryAddonConfig = {
  inventories: Map<string, FormControl>;
  displayStockNumber: boolean;
  outOfStockMessage: string | null;
};

@Component({
  selector: 'greco-inventory-configuration-form',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InventoryFormComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: InventoryFormComponent, multi: true },
  ],
})
export class InventoryFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  constructor(
    private formBuilder: FormBuilder,
    private addonSvc: AddonsService,
    private variantSvc: VariantsService,
    private inventorySvc: InventoryService,
    private productSvc: ProductsService,
    private securitySvc: SecurityService
  ) {}

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private listeners: Subscription[] = [];

  readonly formGroup = this.formBuilder.group({
    inventories: new Map<string, FormControl>(),
    displayStockNumber: [true],
    outOfStockMessage: [null as string | null],
  });

  @PropertyListener('productId') productId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() productId!: string;

  @PropertyListener('addonId') addonId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() addonId?: string;

  @Output() created = new EventEmitter<ProductAddon>();
  @Output() inventoryCreated = new EventEmitter<ProductVariantInventory>();

  restocking = false;

  refresh$ = new BehaviorSubject<null>(null);

  canManageInventory$ = this.productId$.pipe(
    switchMap(async productId => {
      if (!productId) return null;
      return await this.productSvc.getOneProduct(productId);
    }),
    switchMap(async product => {
      return product?.community?.id
        ? await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.MANAGE_INVENTORY, {
            communityId: product.community.id,
          })
        : false;
    })
  );

  inventories$ = combineLatest([this.addonId$, this.refresh$]).pipe(
    switchMap(async ([addonId]) => {
      if (!addonId) return [];
      return (await this.inventorySvc.getVariantInventories(addonId)).sort(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (v0, v1) => v1.variant!.priority - v0.variant!.priority
      );
    }),
    map(inventories =>
      inventories.map(i => {
        return {
          ...i,
          totalStock: i.stock
            .filter(s => s.type === InventoryStockType.RESTOCK)
            .reduce((acc, stock) => acc + stock.quantity || 0, 0),
        };
      })
    )
  );

  productVariants$ = combineLatest([this.productId$, this.inventories$]).pipe(
    switchMap(async ([productId, inventories]) => {
      if (!productId) return [];

      return (
        await this.variantSvc.paginateVariants(
          { productId, pagination: { limit: 200 } },

          RequestQueryBuilder.create({
            search: {
              $and: [
                {
                  status: { $in: [ProductStatus.Active, ProductStatus.Draft] },
                  ...(inventories?.length && {
                    id: { $notin: inventories.map(i => i.variantId) },
                  }),
                },
              ],
            },
          })
        )
      ).items;
    })
  );

  async ngOnInit() {
    if (this.addonId) return;
    await this.configure();
  }

  ngOnDestroy() {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  writeValue(value: InventoryAddonConfig) {
    this.formGroup.setValue(
      {
        outOfStockMessage: value.outOfStockMessage || null,
        inventories: value.inventories,
        displayStockNumber: value.displayStockNumber ?? true,
      },
      { emitEvent: false }
    );
  }

  registerOnChange(fn: (value: InventoryAddonConfig) => void) {
    this.listeners.push(this.formGroup.valueChanges.subscribe(value => fn(value)));
  }

  registerOnTouched(_fn: () => void): void {
    // console.log('TODO: registerOnTouched', fn);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid ? null : { required: true };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static GetConfig(addon?: InventoryProductAddon): InventoryAddonConfig {
    return {
      inventories: new Map<string, FormControl>(),
      displayStockNumber: addon?.displayStockNumber ?? true,
      outOfStockMessage: addon?.outOfStockMessage || null,
    };
  }

  async configure() {
    if (this.addonId) return;

    try {
      const addon = await this.addonSvc.addAddon(this.productId, {
        type: AddonType.Inventory,
        displayStockNumber: this.formGroup.value.displayStockNumber ?? true,
        outOfStockMessage: this.formGroup.value.outOfStockMessage || null,
      } as AddInventoryAddonDto);
      this.addonId = addon.id;
      this.created.emit(addon);
    } catch (err) {
      console.error(err);
    }
  }

  async addVariant(variant: ProductVariant) {
    let newAddon: InventoryProductAddon | undefined = undefined;
    if (!this.addonId) {
      newAddon = (await this.addonSvc.addAddon(this.productId, {
        type: AddonType.Inventory,
        outOfStockMessage: this.formGroup.value.outOfStockMessage || null,
      } as AddInventoryAddonDto)) as any;

      this.addonId = newAddon?.id;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newInventory = await this.inventorySvc.createVariantInventory(this.addonId!, variant.id);

    if (newAddon) {
      newAddon.inventories = [newInventory];
      this.created.emit(newAddon);
    } else {
      this.inventoryCreated.emit(newInventory);
    }

    this.refresh$.next(null);
  }

  addRestock(variantId: string) {
    this.restocking = true;
    const newRestock = new FormControl(0);
    const value = this.formGroup.value.inventories;
    value.set(variantId, newRestock);
    this.formGroup.setValue({ ...this.formGroup.value, inventories: value });
    this.formGroup.markAsDirty();
  }

  removeRestock(variantId: string) {
    const value: Map<string, FormControl> = this.formGroup.value.inventories;
    value.delete(variantId);
    this.formGroup.setValue({ ...this.formGroup.value, inventories: value });
    this.formGroup.markAsDirty();
    if (!value.size) {
      this.restocking = false;
    }
  }
}
