import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { CollectionsService } from '../../services';

@Component({
  templateUrl: './create-new-program.dialog.html',
  styleUrls: ['./create-new-program.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateNewProgramDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<CreateNewProgramDialog>,
    private programSvc: CollectionsService
  ) {}

  private _onDestroy$ = new Subject<void>();

  selectable = true;
  removable = true;

  _creating = false;

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    //color: ['#ff0000', Validators.required],
    description: [null],
    //icon: [],
    image: [null],
  });

  dialogData: DialogData = {
    title: 'Create New Video Collection',
    subtitle: 'Provide the details for the new collection',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          return await this.createProgram();
        },
      },
    ],
  };

  async createProgram() {
    try {
      const data = this.formGroup.value;
      this._creating = true;

      try {
        const program = await this.programSvc.createProgram({
          communityId: this.data.communityId,
          description: data.description || null,
          title: data.title,
        });

        if (data.image) {
          const formData = new FormData();
          formData.append('file', data.image[0] as File);
          await this.programSvc.updateProgramImage(program.id, formData);
        }

        this.snacks.open('Video Collection created!', 'Ok', { duration: 3500, panelClass: 'mat-primary' });
        this._creating = false;
        return program;
      } catch (err) {
        console.error(err);
        this.snacks.open('Something went wrong. Please try again!', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
        this._creating = false;
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
