import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Perk } from '@greco/sales-perks';
import { DialogData } from '@greco/ui-dialog-simple';
import { PerkService } from '../../services/perk.service';

@Component({
  selector: 'greco-perk-badge-edit',
  templateUrl: './perk-badge-edit.dialog.html',
  styleUrls: ['./perk-badge-edit.dialog.scss'],
})
export class PerkBadgeEditDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { perk: Perk },
    private formBuilder: FormBuilder,
    private perkService: PerkService,
    private snackbar: MatSnackBar
  ) {
    this.perk = this.data.perk;
    this.dialogData = {
      title: data.perk?.title,
      subtitle: 'Update Badge',
      showCloseButton: true,
      buttons: [
        {
          label: 'Cancel',
          role: 'cancel',
          color: 'warn',
        },
        {
          label: 'Save',
          role: 'save',
          resultFn: () => this.save(),
        },
      ],
    };

    this.formGroup = this.formBuilder.group({
      label: [this.perk.badge.text || null],
      icon: [this.perk.badge.icon ?? null],
      color: [this.perk.badge.color || null],
    });
  }

  dialogData: DialogData;
  formGroup: FormGroup;
  perk: Perk;

  async save() {
    try {
      await this.perkService.updatePerkBadge(this.perk.id, {
        text: this.formGroup.value.label ?? this.perk.badge.text,
        icon: this.formGroup.value.icon ?? this.perk.badge.icon,
        color: this.formGroup.value.color ?? this.perk.badge.color,
      });

      this.snackbar.open('Badge Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err: any) {
      console.error(err);
      this.snackbar.open('Error updating badge!', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }
  }
}
