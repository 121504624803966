import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommunityVideo } from '@greco/community-videos';
import { DialogData } from '@greco/ui-dialog-simple';
import { CreateVideoAndInitiateUploadDto, UpdateVideoDto, VideoTag } from '@greco/videos';
import { CommunityVideoTagsService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-community-video-upload',
  templateUrl: './video-upload.dialog.html',
  styleUrls: ['./video-upload.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VideoUploadDialog implements OnInit {
  @Input() communityId?: string;
  @Input() video?: CommunityVideo;
  @Input() availableOn?: Date;
  @Input() hideAvailableOnInput = false;

  file?: File;
  form = this.formBuilder.group({
    title: [''],
    fileName: [''],
    path: [null],
    tags: [[]],
    description: [],
    source: 'vimeo',
    availableOn: null,
  });

  dialogData: DialogData = {
    title: 'Upload a Video',
    showCloseButton: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private tagSvc: CommunityVideoTagsService
  ) {}

  submit() {
    if (this.video) {
      this.update();
    } else this.upload();
  }

  close(dto?: CreateVideoAndInitiateUploadDto | UpdateVideoDto) {
    this.dialogRef.close({ file: this.file, dto: dto });
  }

  async ngOnInit() {
    this.dialogData = {
      title: this.video ? 'Update "' + this.video.video?.title + '"' : 'Upload a Video',
      showCloseButton: false,
    };

    this.form.get('source')?.setValue('vimeo');
    this.form.get('availableOn')?.setValue(this.availableOn || null);

    if (this.video) {
      this.form.get('title')?.setValue(this.video.video?.title);
      this.form.get('tags')?.setValue(this.video.video?.tags || []);
      this.form.get('description')?.setValue(this.video.video?.description || '');
      this.form.get('source')?.setValue(this.video.video?.source);
    }
  }
  compareCategoryObjects(object1: VideoTag, object2: VideoTag) {
    return object1 && object2 && object1.id == object2.id;
  }

  private upload() {
    const title = this.form.get('title')?.value;
    const tags: VideoTag[] = this.form.get('tags')?.value;
    const description = this.form.get('description')?.value;
    const source = this.form.get('source')?.value;
    if (!title || !this.file) return;
    const dto: CreateVideoAndInitiateUploadDto = {
      fileSize: this.file.size,
      title: title,
      videoTagIds: tags.map(tag => {
        return tag.id;
      }),
      description: description,
      source: source,
    };
    this.close(dto);
  }

  private async update() {
    if (!this.video) return;
    const title = this.form.get('title')?.value;
    const tags: VideoTag[] = this.form.get('tags')?.value;
    const description = this.form.get('description')?.value;
    const availableOn = this.form.get('availableOn')?.value || null;
    if (!title) return;

    const dto: UpdateVideoDto & { availableOn?: Date } = {
      title,
      description,
      availableOn,
      videoTagIds:
        tags.map(tag => {
          return tag.id;
        }) || [],
    };
    this.close(dto);
  }

  handleClick() {
    document.getElementById('upload-file')?.click();
  }

  async addAttachment(fileInput: any) {
    this.file = fileInput?.target?.files[0];
    this.form.get('fileName')?.setValue(this.file?.name);
  }

  async newTag(label: string | null) {
    if (!label || !this.communityId) return;

    const tag = await this.tagSvc.create(this.communityId, label);
    this.form.get('tags')?.setValue([...(this.form.get('tags')?.value || []), tag]);
  }
}
