/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { AccountLinkStatus } from '@greco/account-linking';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { Product, ProductVariant, VariantResource, VariantResourceAction } from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import { AccountLinkingService } from '@greco/web-account-linking';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-fit-create-quick-purchase',
  templateUrl: './create-quick-purchase.dialog.html',
  styleUrls: ['./create-quick-purchase.dialog.scss'],
})
export class CreateQuickPurchaseDialog implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CreateQuickPurchaseDialog>,
    private formBuilder: FormBuilder,
    private clipboardSvc: ClipboardService,
    private linkSvc: AccountLinkingService,
    private securitySvc: SecurityService,
    private userSvc: UserService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      variant: ProductVariant;
      communityId: string;
      accountId: Product;
      contact?: Contact;
    }
  ) {
    this.communityId = data.communityId;
    if (data.contact) {
      this.form.setValue({
        contact: data.contact,
      });
    }
    this.canSellRestrictedVariants$ = this.securitySvc.hasAccess(
      VariantResource.key,
      VariantResourceAction.SELL_RESTRICTED,
      {
        communityId: this.communityId,
      }
    );
  }
  private user: User | null = null;

  private onDestroy$ = new Subject<void>();
  communityId: string;

  billedTo?: User;
  purchasedFor?: User;

  dialogData: DialogData = {
    title: 'Quick Purchase',
    subtitle: 'Checkout a product on the behalf of a user.',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  currentStep = 0;

  form = this.formBuilder.group({
    contact: [null, Validators.required],
  });

  variantControls: { variant: FormControl; quantity: FormControl }[] = [
    {
      variant: new FormControl(null, Validators.required),
      quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    },
  ];

  canSellRestrictedVariants$: Promise<boolean> | null = null;

  private _variants$ = new BehaviorSubject<{ variant: ProductVariant | null; quantity: number }[]>([]);

  canSelectMultipleVariants$ = this._variants$.pipe(
    map(variants => {
      if (!variants.length) return false;

      return !variants.some(v => v?.variant?.recurrence?.frequency);
    })
  );

  allVariantsSelected$ = this._variants$.pipe(
    map(variants => {
      if (!variants.length) return false;

      if (!variants.map(v => !!v?.variant).every(hasVariant => hasVariant)) {
        return false;
      }
      if (variants.length > 1 && variants.some(v => v?.variant?.recurrence?.frequency)) {
        return false; //cannot purchase multiple products when selecting a recurring product
      }

      return true;
    })
  );

  variantsAndQuantities$ = this._variants$.pipe(
    map(variants => {
      return variants
        .filter(v => !!v?.variant)
        .map(v => ({
          variantId: v?.variant?.id || '',
          quantity: (v.variant?.recurrence?.frequency ? 1 : v.quantity) || 1,
        }));
    })
  );

  variantIds$ = this._variants$.pipe(
    map(variants => {
      return variants.filter(v => !!v?.variant).map(v => v?.variant?.id || '');
    })
  );

  variantChanges() {
    this._variants$.next([
      ...this.variantControls.map(c => ({ variant: c.variant.value, quantity: c.quantity.value })),
    ]);
  }

  addAdditionalProduct() {
    this.variantControls.push({
      variant: new FormControl(null, Validators.required),
      quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    });
    this.variantChanges();
    this.currentStep = this.variantControls.length;
  }

  removeVariant(index: number) {
    this.variantControls.splice(index, 1);
    this.variantControls = [...this.variantControls];
    this.variantChanges();
    this.currentStep = this.variantControls.length;
  }

  async close() {
    return this.dialogRef.close();
  }

  async ngOnInit() {
    this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this.onDestroy$)).subscribe(async () => {
      const contact = this.form.value.contact as Contact;
      this.billedTo = undefined;
      this.purchasedFor = undefined;

      // This setTimeout is required for weird timing issues. The accounts for the user and the purchasedBy update seperately so
      //  this makes sure to wait for them both to be updated by the above line before giving them new values
      setTimeout(async () => {
        if (contact?.user!.email) {
          this.billedTo = contact.user;
          this.purchasedFor = contact.user;
          return;
        } else {
          const parents = (await this.linkSvc.getGivenLinksByAccount(contact?.user!.id))
            ?.filter(link => link.status === AccountLinkStatus.ACTIVE)
            .map(link => link.accessor!);

          if (parents.length) {
            this.billedTo = parents[0];
            this.purchasedFor = contact.user;
            return;
          } else {
            this.billedTo = undefined;
            this.purchasedFor = undefined;
          }
        }
      }, 200);
    });

    this.user = await this.userSvc.getSelf();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async copyCheckoutUrlToClipboard(userId: string) {
    const variants = await toPromise(this.variantIds$);
    this.clipboardSvc.copy(
      `${window.location.origin
        .replace('-admin.', '.')
        .replace('admin.', '')
        .replace('4300', '4200')}/shop/checkout?userId=${userId}&soldById=${this.user?.id}` +
        variants.map(v => `&items=${v}`).join(''),
      'Checkout URL'
    );
  }

  setStep(index: number) {
    if (this.currentStep === index) return;

    this.currentStep = index;
  }
}
