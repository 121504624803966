import { ContentChild, Directive, HostListener, Input, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuItem } from '@angular/material/menu';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { SaleCategory } from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { PurchaseService } from '../services';

@Directive({
  exportAs: 'grecoExportPurchases',
  selector: `
    button[mat-stroked-button][grecoExportPurchases],
    button[mat-raised-button][grecoExportPurchases],
    button[mat-flat-button][grecoExportPurchases],
    button[mat-icon-button][grecoExportPurchases],
    button[mat-menu-item][grecoExportPurchases],
    button[mat-mini-fab][grecoExportPurchases],
    button[mat-button][grecoExportPurchases],
    button[mat-fab][grecoExportPurchases]
  `,
})
export class ExportPurchasesDirective {
  constructor(
    @Optional() private _button: MatButton,
    @Optional() private _item: MatMenuItem,
    private purchaseSvc: PurchaseService,
    private dialog: MatDialog
  ) {
    //console.log("ExportPurchasesDirective::ctor() called")
  }

  @ContentChild(MatIcon) private _icon?: MatIcon;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('grecoExportPurchases') config?: {
    filters?: RequestQueryBuilder;
    saleCategories?: SaleCategory[];
    variantIds?: string[];
    accountId?: string;
    userId?: string;
  };

  private get host() {
    //console.log("ExportPurchasesDirective::host() called")
    return this._button || this._item;
  }

  @HostListener('click', ['$event'])
  async onclick(event: MouseEvent) {
    //console.log("ExportPurchasesDirective::onclick() called")
    event.stopImmediatePropagation();

    this.host.disabled = true;
    const currentIcon = this._icon?._elementRef?.nativeElement?.innerText;
    if (this._icon) {
      this._icon._elementRef.nativeElement.innerText = 'loop';
      this._icon._elementRef.nativeElement.classList.add('spin');
    }

    try {
      InitiateExportDialog.open(this.dialog, {
        processorId: 'PurchasesDataExportProcessor',
        initiateExport: (skip?: number) => {
          const currentQueryBuilder = this.config?.filters || new RequestQueryBuilder();
          const newQueryBuilder = new RequestQueryBuilder();
          newQueryBuilder.search({
            $and: [
              ...JSON.parse(currentQueryBuilder.queryObject.s).$and,
              {
                'saleCategory.id': {
                  $in: this.config?.saleCategories?.length
                    ? this.config?.saleCategories.map(sc => sc.id)
                    : ['not_a_sale_category'],
                },
              },
            ],
          });

          return this.purchaseSvc.exportPurchases(
            newQueryBuilder,
            {
              ...(this.config?.variantIds?.length ? { variantIds: this.config.variantIds } : {}),
              ...(this.config?.accountId && { accountId: this.config.accountId }),
              ...(this.config?.userId && { userId: this.config.userId }),
            },
            skip
          );
        },
      });
    } catch (err) {
      console.error(err);
    }

    this.host.disabled = false;
    if (this._icon && currentIcon) {
      this._icon._elementRef.nativeElement.innerText = currentIcon;
      this._icon._elementRef.nativeElement.classList.remove('spin');
    }
  }
}
