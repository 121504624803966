import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { Subscription, SubscriptionStatus } from '@greco/sales-subscriptions';
import { DialogData } from '@greco/ui-dialog-simple';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-change-payment-method',
  templateUrl: './change-payment-method.dialog.html',
  styleUrls: ['./change-payment-method.dialog.scss'],
})
export class ChangePaymentMethodDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      subscription: Subscription;
      user?: User;
    },
    private subscriptionSvc: SubscriptionsService,
    private snacks: MatSnackBar,
    private dialogRef: MatDialogRef<ChangePaymentMethodDialog>
  ) {}

  dialogData: DialogData = {
    title: 'Change payment Method',
    buttons: [{ label: 'Cancel', role: 'cancel' }],
  };

  async updateSubscriptionPaymentMethod(subscription: Subscription, paymentMethod: PaymentMethod | null) {
    if (
      !paymentMethod ||
      subscription.paymentMethod?.id === paymentMethod.id ||
      subscription.status !== SubscriptionStatus.ACTIVE
    ) {
      return;
    }

    subscription = await this.subscriptionSvc.updatePaymentMethod(subscription.id, paymentMethod.id);
    this.close();
    this.snacks.open('Payment Method Updated!', 'Ok', {
      duration: 2500,
      panelClass: 'mat-primary',
    });
  }

  close() {
    this.dialogRef.close('updated');
  }
}
