import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommunityService } from '@greco/ngx-identity-communities';
import { UpdateSubscriptionHandlerFormComponent } from '@greco/ngx-sales-subscriptions';
import { Product } from '@greco/sales-products';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'greco-lazyformcontrol',
  template: '',
})
export class LazyFormControlComponent {
  @Input() formControl?: FormControl;
  @Input() set value(value: any) {
    setTimeout(() => {
      this.formControl?.setValue(value);
    });
  }
}

@Component({
  selector: 'greco-product-subscription-handler-form',
  templateUrl: './product-subscription-handler-form.component.html',
  styleUrls: ['./product-subscription-handler-form.component.scss'],
})
export class ProductSubscriptionHandlerFormComponent extends UpdateSubscriptionHandlerFormComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private communitySvc: CommunityService,
    public breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  form = this.formBuilder.group({ variant: [null, Validators.required] });
  // currentVariantId$ = new ReplaySubject<string>(1);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  variants$ = this.form.valueChanges.pipe(
    startWith<Product | null>(null),
    map(product => {
      if (!product) return [];
      return product.variants
        .filter(variant => variant.status === 'ACTIVE' && variant.visibility === 'VISIBLE')
        .sort((a, b) => b.priority - a.priority);
    })
  );

  communityId = '';
  preview = null;

  async ngOnInit() {
    if (this.subscription) {
      const community = await this.communitySvc.getCommunityByAccountId(this.subscription.accountId);
      this.communityId = community.id;
      // const item = this.subscription.items?.find(item => {
      //   return item.type == 'ProductSubscriptionItemEntity';
      // });

      // if (item) {
      //   this.currentVariantId$.next((item as any).variantId);
      // }
    }
  }

  async getData() {
    return { productId: this.form.value.product?.id, variantId: this.form.value.variant?.id };
  }
}
