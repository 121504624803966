import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@greco-fit/util';
import { ErrorDialog } from '@greco/ui-dialog-simple';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'greco-password-reset-dialog',
  templateUrl: 'password-reset.dialog.html',
  styleUrls: ['password-reset.dialog.scss'],
})
export class PasswordResetDialog {
  public formGroup: FormGroup;
  public matcher: ErrorStateMatcher = new ErrorStateMatcher();

  constructor(
    public fb: FormBuilder,
    private dialog: MatDialog,
    private userSvc: UserService,
    public dialogRef: MatDialogRef<PasswordResetDialog>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { email: string }
  ) {
    this.formGroup = this.fb.group({
      email: new FormControl(data?.email || '', [Validators.required, Validators.email]),
    });
  }

  async submit() {
    const error = await this.userSvc.resetPassword(this.formGroup.get('email')?.value).catch(err => err);
    this.dialogRef.close();
    if (error) {
      const dialogRef = this.dialog.open(ErrorDialog, {
        data:
          error.message === 'There is no user record corresponding to this identifier. The user may have been deleted.'
            ? {
                title: 'No User Exists With This Email',
                subtitle: 'Please create an account to proceed!',
              }
            : { error },
      });
      if (
        error.message === 'There is no user record corresponding to this identifier. The user may have been deleted.'
      ) {
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([], {
              queryParams: {
                form: 'register',
              },
            });
          });
        });
      }
    }
  }
}
