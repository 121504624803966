import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingOption,
  BookingStatus,
  CalendarEvent,
  EventSeries,
  UserBookingOptionStats,
} from '@greco/booking-events';
import { PaymentMethod } from '@greco/finance-payments';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { BookingPreview } from '@greco/nestjs-booking-events';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { PreviewBookingBookingOptionPickerComponent } from '../../pages/user/preview-booking-2/booking-option-picker/booking-option-picker.component';
import { BookingService } from '../../services';

@Component({
  selector: 'greco-add-booking-option-dialog',
  templateUrl: './add-booking-option.dialog.html',
  styleUrls: ['./add-booking-option.dialog.scss'],
})
export class AddBookingOptionDialog {
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      event: CalendarEvent;
      series: EventSeries;
      user: User;
      booking: Booking;
      contact?: Contact;
      extraEventIds?: string[];
    },
    private dialogRef: MatDialogRef<AddBookingOptionDialog>,
    private snacks: MatSnackBar,
    private bookingSvc: BookingService,
    private matDialog: MatDialog,
    private paymentMethodSvc: UserPaymentMethodService
  ) {
    if (data.contact) {
      this.userControl.setValue(data.contact);
    }
  }

  preventRedirect = true;

  confirming = false;
  loading = false;

  @ViewChild(PreviewBookingBookingOptionPickerComponent)
  private bookingOptionPicker?: PreviewBookingBookingOptionPickerComponent;

  user$ = of(this.data.user);
  booking$ = of(this.data.booking);
  paymentMethodControl = new FormControl(null);

  readonly hasPaymentMethod$ = combineLatest([
    this.user$,
    this.paymentMethodControl.valueChanges.pipe(startWith(null)),
  ]).pipe(
    switchMap(async ([user, selectedPaymentMethod]) => {
      if (selectedPaymentMethod) return true;

      const paymentMethod = user ? await this.paymentMethodSvc.getDefault(this.data.user.id, true) : null;
      return !!paymentMethod && paymentMethod.model !== 'bank';
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private selectedPaymentMethod$ = this.paymentMethodControl.valueChanges.pipe(
    startWith<PaymentMethod | null, PaymentMethod | null>(null),
    switchMap(paymentMethod => {
      if (paymentMethod) return of(paymentMethod);
      return this.user$.pipe(
        switchMap(user => (user ? this.paymentMethodSvc.getDefault(user.id, true) : of(null))),
        map(paymentMethod => paymentMethod ?? null)
      );
    }),
    distinctUntilChanged((prev, next) => prev?.id === next?.id)
  );

  readonly bookingOption$ = new ReplaySubject<BookingOption>(1);
  readonly preview$: Observable<BookingPreview | null> = combineLatest([
    this.bookingOption$,
    this.selectedPaymentMethod$,
    this.user$,
    this.booking$,
  ]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    switchMap(async ([bookingOption, pm, user, booking]) => {
      const bookingOptionStats: UserBookingOptionStats | undefined =
        this.bookingOptionPicker?._stats?.[bookingOption.id];
      const bookingOptionAvailable = !!(bookingOptionStats?.consumable || bookingOptionStats?.reusable);
      const isComplimentary: boolean = bookingOption.id === this.bookingOptionPicker?.complimentaryBookingOption?.id;

      const bookingWithnewBookingOption = await this.bookingSvc
        .previewBookingOptionChanged(
          {
            completed: bookingOptionAvailable || isComplimentary ? true : false, // False => pending
            bookingOptionId: bookingOption.id,
            paymentMethodId: pm?.id,
            applyBalance: true,
            eventId: this.data.event.id,
            userId: user.id,
            bookedById: user.id,
          },
          booking.id
        )
        .then(preview => ({
          ...preview,
          errors: preview.errors.map(err => {
            console.log(err);
            if (err.startsWith('Missing form submission:')) {
              const form = err.replace('Missing form submission: ', '').replace(/'/g, '');
              return `Please fill in the form <strong>${form}</strong>.`;
            }

            if (err.startsWith('Please add a payment method.')) {
              return 'Please add a payment method.';
            }

            if (err.startsWith('This event has already reached capacity.')) {
              return 'This event has already reached capacity.';
            }

            if (err.startsWith('Unable to book event. Past booking cutoff.')) {
              return 'This event is not available anymore.';
            }

            if (err.includes('User already booked in')) {
              return 'You are already booked into this event.';
            }

            if (err.includes('Booking window not reached')) {
              return 'This event is not yet available.';
            }

            return 'Something went wrong. Please try again.';
          }),
        }))
        .catch(() => null);
      return bookingWithnewBookingOption;
    }),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  hasBookingOptions$ = this.preview$.pipe(
    map(preview => {
      preview?.booking.bookingOption ? true : false;
    })
  );

  dialogData: DialogData = {
    title: 'Select Booking Option',
    subtitle: `<b>${this.data?.event?.title || this.data?.series?.title}</b>`,
    showCloseButton: false,
    buttons: [
      {
        label: 'Cancel',
        role: 'cancel',
        resultFn: async () => {
          try {
            return { event: null, action: 'cancel' };
          } catch (err) {
            console.error(err);
            this.snacks.open('' + err, 'Ok', { panelClass: 'mat-warn' });
            return null;
          }
        },
      },
    ],
  };

  public readonly userControl = new FormControl(null, Validators.required);

  async confirmBooking(preview: BookingPreview, bookingId: string) {
    if (this.confirming && !preview) return;
    this.confirming = true;

    try {
      if (preview.warnings?.length) {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: 'Warnings:',
            subtitle: 'Your booking has the following warnings, would you like to book anyway?',
            content: preview.warnings.map(warn => `<p>${warn}</p>`).join(''),
            buttons: [
              { label: 'Make changes', role: 'no' },
              { label: 'Confirm booking', role: 'yes' },
            ],
          } as DialogData,
        });

        const response = await toPromise(dialog.afterClosed());
        if (!response || response === 'no') {
          this.confirming = false;
          return;
        }
      }

      const booking = await this.bookingSvc.confirmPendingBooking(
        {
          booking: { ...preview.dto },
          purchaseHash: preview.purchase?.hash,
          bookingHash: preview.hash,
        },
        bookingId
      );

      if (booking.status == BookingStatus.CONFIRMED || booking.status == BookingStatus.PENDING) {
        this.snacks.open('Attendee updated!', 'Ok', {
          duration: 6000,
          panelClass: 'mat-primary',
        });

        this.dialogRef.close('yes');
      } else {
        const msg = booking.purchases?.[0]?.failureReason || '';
        this.snacks.open(`Oops, failed to confirm ${this.preventRedirect ? 'this' : 'your'} booking! ${msg}`, 'Ok', {
          duration: 10000,
          panelClass: 'mat-warn',
        });
      }
    } catch (err: any) {
      const msg = 'message' in err.error ? err.error.message : '' + err;
      this.snacks.open(`Oops, failed to confirm ${this.preventRedirect ? 'this' : 'your'} booking! ${msg}`, 'Ok', {
        duration: 10000,
        panelClass: 'mat-warn',
      });
    }
    this.confirming = false;
  }
}
