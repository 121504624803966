import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@greco/identity-users';
import { DialogData, SimpleDialog } from '@greco/ui-dialog-simple';
import { SignInConfig } from '../fields/sign-in-fields.component';

@Component({
  selector: 'greco-sign-in-form',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  @Output() public successfulSignIn = new EventEmitter<User>();

  @Input() navigateOnSignIn = false;

  @Input() showBackButton = true;

  public fieldsConfig = { allowGoogle: true, collectPhoneNumber: true };
  @Input() set config(val: SignInConfig) {
    this.fieldsConfig = { ...this.fieldsConfig, ...val };
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public data: {
      navigateOnSignIn?: boolean;
      termsDialogData: DialogData;
      privacyDialogData: DialogData;
      showBackButton?: boolean;
    },
    private router: Router,
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<SignInComponent>
  ) {
    this.navigateOnSignIn =
      data?.navigateOnSignIn !== null && data?.navigateOnSignIn !== undefined
        ? data?.navigateOnSignIn
        : this.navigateOnSignIn;
    this.showBackButton =
      data?.showBackButton !== null && data?.showBackButton !== undefined ? data.showBackButton : this.showBackButton;
  }

  async navigate(user: User) {
    if (this.dialogRef) this.dialogRef.close('success');
    if (this.navigateOnSignIn) await this.router.navigate(['/']);
    this.successfulSignIn.emit(user);
  }

  close() {
    this.dialogRef?.close();
  }

  openDialog(data: DialogData) {
    this.dialog.open(SimpleDialog, { data });
  }
}
