<greco-table
  [data]="ownedLinks"
  [loading]="loading"
  [highlight]="true"
  (rowClick)="userProfileUpdate($event); $event.stopImmediatePropagation()"
>
  <ng-container *grecoTableCol="''; fitContent: true; let link">
    <mat-icon *ngIf="link.status === 'PENDING'" style="width: 32px; height: 32px; font-size: 32px" color="accent">
      send
    </mat-icon>

    <greco-user-avatar
      style="margin-left: 0px"
      *ngIf="link.status === 'ACTIVE'"
      [user]="link.account"
      size="small"
      [showOnlineStatus]="false"
    ></greco-user-avatar>
  </ng-container>

  <div *grecoTableCol="'Account'; let link">
    <a *ngIf="communityId; else noLink" [routerLink]="['/community', communityId, link.accountId]">
      <p style="margin-bottom: 0">{{ link.account.displayName }}</p>
      <small>{{ link.account.contactEmail || link.account.email }}</small>
    </a>
    <ng-template #noLink>
      <p style="margin-bottom: 0">{{ link.account.displayName }}</p>
      <small>{{ link.account.contactEmail || link.account.email }}</small>
    </ng-template>
  </div>

  <ng-container *grecoLet="canUpdate$ | async as canUpdate">
    <greco-account-link-permissions
      *grecoTableCol="'Access Granted'; let link"
      [link]="link"
      view="accessor"
      [canUpdate]="canUpdate || false"
      (refresh)="refresh.emit()"
    >
    </greco-account-link-permissions>
  </ng-container>

  <mat-chip-list *grecoTableCol="'Login'; fitContent: false; let link">
    <mat-chip [class]="link.account.email ? 'registered' : 'child'">
      {{ link.account.email ? 'User Registered' : 'Child' }}
    </mat-chip>
  </mat-chip-list>

  <ng-container *ngIf="canRevoke$ | async">
    <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let link">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <button mat-menu-item (click)="userProfileUpdate(link)">View Details</button>
        <button mat-menu-item (click)="revokeAccess(link)">Relinquish Access</button>
      </mat-menu>
    </ng-container>
  </ng-container>
</greco-table>
