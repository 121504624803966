/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  Booking as BookingDetails,
  BookingOptionAgreementUsage,
  Calendar,
  CalendarEvent,
  EventAgreementUsage,
  EventStatus,
  ResourceType,
  WaitlistItem,
} from '@greco/booking-events';
import {
  AdditionalSpotDetails,
  Booking,
  BookingBoosters,
  BookingOptionDetails,
  BookingTotal,
  CreateMultiBookingDto,
  EquipmentDetails,
  Event,
  EventAccount,
  EventCalendar,
  EventFilter,
  EventLocation,
  EventRequirements,
  EventsByCalendar,
  EventSecurityDetails,
  EventTag,
  EventTrainer,
  EventUserAlerts,
  EventUserDetails,
  EventUserStatus,
  EventWithUserDetails,
  SpotDetails,
} from '@greco/booking-events2';
import { UserAgreementDto, UserCommunityAgreement } from '@greco/community-agreements';
import { PaymentMethod } from '@greco/finance-payments';
import { Tax } from '@greco/finance-tax';
import { User } from '@greco/identity-users';
import { BookingService } from '@greco/ngx-booking-events';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { UserService } from '@greco/ngx-identity-auth';
import { Purchase, PurchaseItem } from '@greco/sales-purchases';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

export type BookingBoostersInfo = BookingBoosters & {
  boostersRequired: boolean;
  isReusable: boolean;
  isOwned: boolean;
  total: number;
};

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  constructor(
    private router: Router,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private bookingService: BookingService,
    private communityAgreementSvc: CommunityAgreementsService,
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD'
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.forceRefresh$.next(true));
  }

  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  onDestroy$ = new Subject<void>();
  dateRange$ = new BehaviorSubject<[Date, Date]>([new Date(), new Date()]);
  filters$ = new ReplaySubject<EventFilter[]>(1);
  bookings$ = new BehaviorSubject<Booking[]>([]);
  calendars$ = new ReplaySubject<EventCalendar[]>(1);
  locations$ = new ReplaySubject<EventLocation[]>(1);
  alerts$ = new BehaviorSubject<EventUserAlerts[]>([]);

  isStaffView$ = new BehaviorSubject<boolean>(false);
  canOverrideUsageLimit$ = new BehaviorSubject<boolean>(false);

  accounts$ = new BehaviorSubject<EventAccount[]>([]);

  paymentMethods$ = new ReplaySubject<PaymentMethod[]>(1);
  paymentMethodToUse$ = new BehaviorSubject<PaymentMethod | null>(null);

  bookedSpots$ = new BehaviorSubject<SpotDetails[]>([]);
  additionalSpots$ = new BehaviorSubject<AdditionalSpotDetails[]>([]);
  ignoreAdditionalSpotCost$ = new BehaviorSubject<boolean>(false);

  equipment$ = new BehaviorSubject<EquipmentDetails[]>([]);

  bookingTotals$ = new BehaviorSubject<BookingTotal[]>([]);

  eventId$ = new ReplaySubject<string>(1);

  userId$ = new ReplaySubject<string>(1);

  eventsLoading$ = new BehaviorSubject<boolean>(true);
  forceRefresh$ = new BehaviorSubject<boolean>(true);

  agreements$ = new BehaviorSubject<EventAgreementUsage[]>([]);
  bookingOptionAgreements$ = new BehaviorSubject<(BookingOptionAgreementUsage & { userId: string })[]>([]);

  agreementSubmissions$ = new BehaviorSubject<
    (UserAgreementDto & { userId: string; signature?: string; unsigned?: boolean; fromBooking?: boolean })[]
  >([]);
  unsignedAgreements$ = new BehaviorSubject<UserCommunityAgreement[]>([]);
  profilesCompleted$ = new BehaviorSubject<{ userId: string; completed: boolean }[]>([]);
  typeformSubmissions$ = new BehaviorSubject<{ userId: string; formId: string; submissionId: string }[]>([]);

  total$ = new BehaviorSubject<number>(0);

  attendees$ = new BehaviorSubject<BookingDetails[]>([]);
  waitlist$ = new BehaviorSubject<User[]>([]);

  requirementErrors$ = new BehaviorSubject<string[]>([]);

  events$ = combineLatest([
    this.dateRange$,
    this.filters$,
    this.calendars$,
    this.locations$,
    this.userService.user$.pipe(filter(u => !!u)),
    this.forceRefresh$,
  ]).pipe(
    tap(() => this.eventsLoading$.next(true)),
    switchMap(([dateRange, filters, calendars, locations, user, forceRefresh]) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.getEvents(dateRange, filters, calendars, locations, user!, forceRefresh);
    }),
    tap(() => this.eventsLoading$.next(false)),
    shareReplay(1)
  );

  event$: Observable<EventWithUserDetails> = combineLatest([this.eventId$, this.userId$]).pipe(
    distinctUntilChanged(),
    switchMap(([eventId, userId]) => this.getEvent(eventId, userId)),
    tap(event => {
      this.accounts$.next(event.accounts);

      const parentAccount = event.accounts[0];
      const waitlistedAccounts = event.accounts.filter(account => account.userStatusInfo === 'waitlisted');

      if (
        parentAccount &&
        !event.accounts.some(account => account.userStatusInfo === 'booked') &&
        !waitlistedAccounts.length
      ) {
        return this.bookings$.next([
          {
            userId: parentAccount.user.id,
            bookingOptionId: parentAccount.bookingOptions[0]?.id,
            bookingOptionUserId: parentAccount.bookingOptions[0]?.userId,
            eventId: event.id,
          },
        ]);
      } else {
        return this.bookings$.next([]);
      }
    }),
    tap(event => {
      this.paymentMethods$.next(event.paymentMethods);
      if (event.defaultPaymentMethod) this.paymentMethodToUse$.next(event.defaultPaymentMethod);
    }),
    tap(async event => {
      const agreements = !event.requirements.agreements?.length
        ? []
        : await Promise.all(
            event.requirements.agreements.map(async agreement => {
              const communityAgreement = await this.communityAgreementSvc.getOne(agreement.agreementId);
              if (agreement.agreement) agreement.agreement.text = communityAgreement.text;
              else agreement.agreement = communityAgreement;
              return agreement;
            })
          );
      this.agreements$.next(agreements);
    }),
    tap(event => {
      this.unsignedAgreements$.next(event.requirements.unsignedAgreements ?? []);
      this.bookedSpots$.next(event.requirements.spotBooking?.bookedSpots ?? []);
      this.attendees$.next(event.accounts.filter(account => account.booking).map(account => account.booking!));
      this.waitlist$.next(
        event.accounts.filter(account => account.userStatusInfo === 'waitlisted').map(account => account.user)
      );
    }),

    shareReplay(1)
  );

  bookingsInfo$ = combineLatest([
    this.event$,
    this.accounts$,
    this.bookings$,
    this.additionalSpots$,
    this.equipment$,
  ]).pipe(
    tap(([_, accounts, bookings]) => {
      const agreementSubmissions = this.agreementSubmissions$.value;
      const bookingOptionAgreements = [] as (BookingOptionAgreementUsage & { userId: string })[];

      const agreementsToAdd: (UserAgreementDto & {
        userId: string;
        signature?: string;
        unsigned?: boolean;
        fromBooking?: boolean;
      })[] = [];
      const agreementUsagesToAdd: (BookingOptionAgreementUsage & { userId: string })[] = [];

      // Booking Option Agreements
      bookings?.forEach(booking => {
        const account = accounts.find(account => account.user.id === booking.userId);
        if (!account) return;

        const bookedByAccount = accounts.find(account => account.user.id === booking.bookingOptionUserId);
        const accountToUse = bookedByAccount?.user.id === booking.userId ? account : bookedByAccount;
        if (!accountToUse) return;

        const usedBookingOption = accountToUse.bookingOptions.find(option => option.id === booking.bookingOptionId);
        if (!usedBookingOption) return;

        const bookingAgreementsToBeSigned =
          usedBookingOption.bookingOptionAgreements?.filter(
            agreement => !account.signedAgreementIds?.includes(agreement.agreementId)
          ) || [];

        // Add booking option agreements from booking option
        bookingAgreementsToBeSigned.forEach(bookingAgreement => {
          agreementsToAdd.push({
            userId: booking.userId,
            agreementId: bookingAgreement.agreementId,
            signature: agreementSubmissions.find(
              agreement =>
                agreement.agreementId === bookingAgreement.agreementId &&
                agreement.userId === booking.userId &&
                agreement.signed === true
            )?.signature,
            signed:
              agreementSubmissions.find(
                agreement =>
                  agreement.agreementId === bookingAgreement.agreementId &&
                  agreement.userId === booking.userId &&
                  agreement.signed === true
              )?.signed ?? false,
            unsigned: true,
            fromBooking: true,
          });
          agreementUsagesToAdd.push({ ...bookingAgreement, userId: booking.userId });
        });
      });

      this.bookingOptionAgreements$.next([...bookingOptionAgreements, ...agreementUsagesToAdd]);
      this.agreementSubmissions$.next([
        ...agreementSubmissions.filter(agreement => !agreement.fromBooking),
        ...agreementsToAdd.filter(
          agreement =>
            !agreementSubmissions
              .filter(agreementSubmission => !agreementSubmission.fromBooking)
              .some(
                agreementSubmission =>
                  agreementSubmission.agreementId === agreement.agreementId &&
                  agreementSubmission.userId === agreement.userId
              )
        ),
      ]);
    }),
    tap(([event, accounts, bookings]) => {
      const bookedSpots = this.bookedSpots$.value;

      for (const booking of bookings ?? []) {
        const bookedSpotIndex = bookedSpots.findIndex(spot => spot.userId === booking.userId);

        if (booking.spotId && booking.spotId !== 'general') {
          const spot = event.requirements.spotBooking?.room.spots?.find(spot => spot.id === booking.spotId);
          if (!spot) continue;

          const bookedSpot: SpotDetails = {
            spotId: spot.id,
            spotName: spot.name,
            spotNumber: spot.spotNumber,
            spotDescription: spot.description,
            userId: booking.userId,
            photoUrl: accounts.find(account => account.user.id === booking.userId)?.user.photoURL || '',
          };

          if (bookedSpotIndex === -1) this.bookedSpots$.next([...bookedSpots, bookedSpot]);
          else if (bookedSpots[bookedSpotIndex].spotId !== booking.spotId) {
            bookedSpots[bookedSpotIndex] = bookedSpot;
            this.bookedSpots$.next(bookedSpots);
          }
        } else if (bookedSpotIndex !== -1) {
          this.bookedSpots$.next(bookedSpots.filter(spot => spot.userId !== booking.userId));
        }
      }
    }),
    map(([event, eventAccounts, bookings, additionalSpots, equipment]) => {
      if (!event || !eventAccounts || !bookings) return null;

      return bookings.map(booking => ({
        ...booking,
        spotsTaken: (additionalSpots.filter(spot => spot.userId === booking.userId)?.length || 0) + 1,
        user: eventAccounts.find(account => account.user.id === booking.userId)?.user,
        equipment: equipment.find(equipment => equipment.userId === booking.userId)?.equipment,
        option:
          booking.bookingOptionId === 'prk_complimentarybooking'
            ? event.complimentaryBookingOption
            : eventAccounts
                .find(account => account.user.id === booking.bookingOptionUserId)
                ?.bookingOptions.find(option => option.id === booking.bookingOptionId) ??
              eventAccounts
                .find(account => account.user.id === booking.bookingOptionUserId)
                ?.pendingBookingOptions.find(option => option.id === booking.bookingOptionId),
      }));
    })
  );

  bookingsBoosterInfo$ = combineLatest([this.event$, this.accounts$, this.bookingsInfo$]).pipe(
    map(([event, eventAccounts, bookings]) => {
      if (!event || !eventAccounts || !bookings) return [];

      const now = moment();

      const boostersInfo: BookingBoostersInfo[] = [];

      const parentBoosters = eventAccounts[0].boosters;

      const totalParentBoosters = parentBoosters.length;
      let parentBoostersRemaining = parentBoosters.length;

      const totalParentTransferableBooster = parentBoosters.filter(booster => booster.transferable).length;
      let transferableRemaining = parentBoosters.filter(booster => booster.transferable).length;

      const hasInfiniteParentBoosters = parentBoosters.some(booster => !booster.consumable);
      const hasInfiniteTransferableParentBooster = parentBoosters.some(
        booster => booster.transferable && !booster.consumable
      );

      const parentBooking = bookings.find(booking => booking.userId === eventAccounts[0].user.id);
      if (
        parentBooking &&
        parentBooking.option &&
        !parentBooking.option.isPending &&
        parentBooking.option.id !== 'prk_complimentarybooking'
      ) {
        const boosterInfo = this.getBoosterInfo(
          parentBooking.option,
          now,
          event.startTime,
          hasInfiniteParentBoosters,
          parentBoostersRemaining
        );

        if (!hasInfiniteParentBoosters) {
          parentBoostersRemaining -= boosterInfo.boostersToConsume;
        }

        boostersInfo.push({
          ...boosterInfo,
          userId: parentBooking.userId,
          isReusable: hasInfiniteParentBoosters,
          isOwned: true,
          total: totalParentBoosters,
        });
      }

      for (const booking of bookings) {
        if (booking.userId === eventAccounts[0].user.id) {
          continue; //calculated above
        }

        const bookingOption = booking.option;
        if (!bookingOption || bookingOption.id === 'prk_complimentarybooking' || bookingOption.isPending) continue;

        if (booking.userId !== bookingOption.userId) {
          //booking is using parent option, check transferable boosters
          const boosterInfo = this.getBoosterInfo(
            bookingOption,
            now,
            event.startTime,
            hasInfiniteTransferableParentBooster,
            transferableRemaining,
            parentBoostersRemaining
          );

          if (!hasInfiniteTransferableParentBooster) {
            transferableRemaining -= boosterInfo.boostersToConsume;
            parentBoostersRemaining -= boosterInfo.boostersToConsume;
          }

          boostersInfo.push({
            ...boosterInfo,
            userId: booking.userId,
            isReusable: hasInfiniteTransferableParentBooster,
            isOwned: false,
            total: totalParentTransferableBooster,
          });
        } else {
          //booking is using own option, check own stats.
          const bookingAccount = eventAccounts.find(account => account.user.id === booking.userId);
          if (!bookingAccount) continue;

          const hasInfiniteBoosters = bookingAccount.boosters.some(booster => !booster.consumable);

          const boosterInfo = this.getBoosterInfo(
            bookingOption,
            now,
            event.startTime,
            hasInfiniteBoosters,
            bookingAccount.boosters.length
          );

          boostersInfo.push({
            ...boosterInfo,
            userId: booking.userId,
            isReusable: hasInfiniteBoosters,
            isOwned: true,
            total: bookingAccount.boosters.length,
          });
        }
      }

      return boostersInfo;
    })
  );

  bookButtonTitle$ = combineLatest([this.bookings$, this.accounts$, this.additionalSpots$]).pipe(
    map(([bookings, accounts, additionalSpots]) => {
      let toBook = '';
      let toBookShort = '';
      let toPurchase = '';
      let toPurchaseShort = '';

      const bookingsToBook: Booking[] = [];
      const bookingsToPurchase: Booking[] = [];

      bookings.forEach(booking => {
        const bookingAccount = accounts.find(account => account.user.id === booking.userId);
        const accountOption = bookingAccount?.bookingOptions.find(option => option.id === booking.bookingOptionId);

        if (accountOption?.price) bookingsToPurchase.push(booking);
        else bookingsToBook.push(booking);
      });

      if (bookingsToBook?.length) {
        if (bookingsToBook.length === 1 && !bookingsToPurchase.length && !additionalSpots?.length) {
          if (accounts[0]?.user.id === bookingsToBook[0].userId) toBook += `Book`;
          else {
            toBook += `Book for ${
              accounts?.find(account => account.user.id === bookingsToBook[0].userId)?.user.displayName
            }`;
          }

          toBookShort += `Book`;
        } else {
          toBook += `Book ${
            !bookingsToPurchase.length && !additionalSpots?.length && bookingsToBook.length > 1
              ? `(${bookingsToBook.length})`
              : ''
          }`;
          toBookShort += toBook;
        }
      }

      if (bookingsToPurchase?.length || additionalSpots?.length) {
        if (bookingsToPurchase.length === 1 && !bookingsToBook.length && !additionalSpots?.length) {
          if (accounts[0]?.user.id === bookingsToPurchase[0].userId) toPurchase += `Purchase`;
          else {
            toPurchase += `Purchase for ${
              accounts.find(account => account.user.id === bookingsToPurchase[0].userId)?.user.displayName
            }`;
          }

          toPurchaseShort += `Purchase`;
        } else {
          toPurchase += `Purchase ${
            !bookingsToBook.length && bookingsToPurchase.length + additionalSpots?.length > 1
              ? `(${bookingsToPurchase.length + additionalSpots?.length})`
              : ''
          }`;
          toPurchaseShort += toPurchase;
        }
      }

      return {
        normal: `${toBook}${toBook && toPurchase ? ' & ' : ''}${toPurchase}`,
        short: `${toBookShort}${toBook && toPurchase ? ' & ' : ''}${toPurchaseShort}`,
      };
    })
  );

  usersWithoutEquipment$ = combineLatest([this.bookings$, this.equipment$]).pipe(
    map(([bookings, equipment]) => {
      if (!bookings) return [];
      return bookings.map(booking => booking.userId).filter(id => !equipment.map(equip => equip.userId).includes(id));
    })
  );

  alternateAccounts$ = combineLatest([this.accounts$, this.bookings$, this.attendees$]).pipe(
    map(([eventAccounts, bookings, attendees]) => {
      if (!eventAccounts) return [];
      if (!bookings) return eventAccounts;

      return eventAccounts
        .filter(account => !bookings.some(b => b.userId === account.user.id))
        .map(account => {
          return {
            ...account,
            userStatusInfo:
              account.userStatusInfo === 'booked' && !attendees.some(booking => booking.user.id === account.user.id)
                ? 'available'
                : account.userStatusInfo,
          };
        });
    })
  );

  bookingOptionErrors$ = combineLatest([this.bookingsInfo$, this.canOverrideUsageLimit$]).pipe(
    map(([bookings, canOverrideUsageLimit]) => {
      if (!bookings) return { conflicts: [], transferableConflicts: [], usageConflicts: [] };

      const conflicts: string[] = [];
      const transferableConflicts: string[] = [];
      const usageConflicts: string[] = [];

      for (const booking of bookings) {
        if (conflicts.includes(booking.userId)) continue;

        if (booking.option?.usages && !canOverrideUsageLimit) {
          if (booking.option.usedToday >= booking.option.usages) {
            usageConflicts.push(booking.userId);
            continue;
          } else {
            const otherBookingsWithOption = bookings.filter(
              otherBooking =>
                otherBooking.option?.id === booking.option?.id &&
                otherBooking.userId !== booking.userId &&
                otherBooking.option?.transferable &&
                booking.option?.transferable
            );

            if (booking.option.usedToday + otherBookingsWithOption.length >= booking.option.usages) {
              usageConflicts.push(booking.userId);
              continue;
            }
          }
        }

        const isParentOrIsAccount = booking.bookingOptionUserId === booking.userId;
        const option = booking.option;

        if (
          !option ||
          option.id === 'prk_complimentarybooking' ||
          (isParentOrIsAccount ? option.reusable : option.transferableReusable) ||
          option.isPending
        )
          continue;

        const otherBookings = bookings.filter(
          b =>
            b.userId !== booking.userId &&
            b.bookingOptionId === booking.bookingOptionId &&
            b.bookingOptionUserId === booking.bookingOptionUserId
        );

        if (!otherBookings.length) continue;

        if (otherBookings.length + 1 > option.consumable) conflicts.push(booking.userId);
        else if (!isParentOrIsAccount && otherBookings.length && otherBookings.length > option.transferable) {
          transferableConflicts.push(booking.userId);
        }
      }

      return { conflicts, transferableConflicts, usageConflicts };
    })
  );

  showTotal$ = this.bookings$.pipe(map(bookings => bookings.some(booking => !!booking.bookingOptionId)));

  paymentMethodError$ = combineLatest([this.bookingsInfo$, this.paymentMethodToUse$]).pipe(
    map(([bookings, paymentMethodToUse]) => {
      if (!bookings) return true;

      let requiresPaymentMethod = false;
      for (const booking of bookings) {
        if (!booking.option) continue;
        if (booking.option.price || (booking.option.cancellation && booking.option.cancellationPrice)) {
          requiresPaymentMethod = true;
          break;
        }
      }

      return requiresPaymentMethod && !paymentMethodToUse;
    })
  );

  allRequirementsCompleted$ = combineLatest([
    this.event$,
    this.bookingsInfo$,
    combineLatest([this.agreementSubmissions$, this.agreements$, this.bookingOptionAgreements$]).pipe(
      map(([submissions, agreements, bookingOptionAgreements]) => {
        return submissions.filter(
          s =>
            agreements.some(a => a.agreementId === s.agreementId) ||
            bookingOptionAgreements.some(a => a.agreementId === s.agreementId && a.userId === s.userId)
        );
      })
    ),
    this.profilesCompleted$,
    this.typeformSubmissions$,
    combineLatest([this.canOverrideUsageLimit$, this.isStaffView$]),
  ]).pipe(
    map(
      ([event, bookings, agreements, profilesCompleted, typeformSubmissions, [isStaffView, canOverrideUsageLimit]]) => {
        if (!bookings || !bookings.length || !bookings.every(booking => !!booking.bookingOptionId)) return false;

        const allProfilesComplete =
          isStaffView ||
          bookings.every(
            booking =>
              booking.userId !== event.accounts[0].user.id ||
              booking.user?.address?.line1 ||
              profilesCompleted.some(profile => profile.userId === booking.userId && profile.completed)
          );

        const allAgreementsComplete =
          !agreements.length ||
          bookings.every(booking =>
            agreements
              .filter(agreement => agreement.userId === booking.userId)
              .every(
                agreement => agreement.signed || (agreement.unsigned && agreement.userId !== event.accounts[0].user.id)
              )
          );

        const allFormsComplete =
          !event.requirements.forms?.length ||
          bookings.every(booking =>
            event?.requirements?.forms?.every(
              form =>
                !form.required ||
                typeformSubmissions.some(
                  submission => submission.formId === form.id && submission.userId === booking.userId
                )
            )
          );

        const allSpotsSelected =
          event.requirements.spotBooking?.spotBookingEnabled && event.requirements.spotBooking.room
            ? bookings.every(booking => !!booking.spotId)
            : true;

        let allOptionsInDailyLimit = true;
        if (!canOverrideUsageLimit) {
          const optionDailyLimits = Object.values(
            bookings
              .map(booking => booking.option)
              .reduce((acc, option) => {
                if (!option) return acc;

                if (!acc[option.userId]) {
                  acc = {
                    ...acc,
                    [option.userId]: {
                      userId: option.userId,
                      optionId: option.id,
                      usedToday: option.usedToday + 1,
                      dailyMax: option.usages,
                    },
                  };
                } else {
                  acc[option.userId].usedToday += 1;
                }

                return acc;
              }, {} as { [key: string]: { userId: string; optionId: string; usedToday: number; dailyMax?: number | null } })
          );

          if (!optionDailyLimits?.length) allOptionsInDailyLimit = true;
          else {
            allOptionsInDailyLimit = optionDailyLimits.every(
              (option: { dailyMax?: number | null; usedToday: number }) =>
                option.dailyMax ? option.dailyMax >= option.usedToday : true
            );
          }
        }

        const allEquipmentComplete = event.equipmentOptions?.length
          ? bookings.every(booking => booking.equipment)
          : true;

        const noFailedPayments = event.accounts.every(account =>
          bookings.some(booking => booking.userId === account.user.id)
            ? !event.accounts
                .filter(otherAccount => otherAccount.user.contactEmail === account.user.email)
                .reduce((acc, otherAccount) => [...acc, ...otherAccount.failedPayments], [] as Purchase[])
                .filter(payment => payment.purchasedById === account.user.id).length
            : true
        );

        return (
          allProfilesComplete &&
          allAgreementsComplete &&
          allFormsComplete &&
          allSpotsSelected &&
          allOptionsInDailyLimit &&
          allEquipmentComplete &&
          noFailedPayments
        );
      }
    ),
    shareReplay(1)
  );

  allAlertsCompleted$ = combineLatest([this.alerts$, this.bookings$, this.event$]).pipe(
    map(([alerts, bookings, event]) => {
      if (event.security.canIgnoreAlerts) return true;

      const now = new Date();
      if (event.startTime.getTime() < now.getTime()) return false;

      if (event.accounts[0]?.failedPayments?.length) return false;

      if (!alerts.length) return true;

      return bookings.every(booking => {
        const bookingAlerts = alerts.find(alert => alert.userId === booking.userId);
        if (!bookingAlerts) return true;

        return Object.keys(bookingAlerts).every(key => (key === 'userId' ? true : !(bookingAlerts as any)[key]));
      });
    })
  );

  areAllBoostersActivated$ = combineLatest([this.bookings$, this.bookingsBoosterInfo$]).pipe(
    map(([bookings, bookingBoosters]) => {
      if (!bookings) return false;
      return bookings.every(
        booking =>
          !!booking.boostersActivated ||
          !bookingBoosters.find(boosters => boosters.userId === booking.userId)?.boostersRequired
      );
    })
  );

  allBookingsAvailable$ = combineLatest([this.bookings$, this.attendees$]).pipe(
    map(([bookings, attendees]) => {
      if (!bookings) return false;

      return bookings.every(booking => !attendees.some(a => a.user.id === booking.userId));
    })
  );

  purchaseItems$ = combineLatest([
    this.event$,
    this.accounts$,
    this.bookingsInfo$,
    this.bookingsBoosterInfo$,
    this.additionalSpots$,
    this.ignoreAdditionalSpotCost$,
  ]).pipe(
    map(([event, eventAccounts, bookings, bookingBoosters, additionalSpots, ignoreAdditionalSpotCost]) => {
      const purchaseItems: PurchaseItem[] = [];
      let subtotal = 0;

      const appliedTaxes: { tax: Tax; total: number; taxNumber: string }[] = [];
      let taxTotal = 0;
      let total = 0;

      const userBalance = event.userBalance;

      if (!event || !bookings) return { purchaseItems, subtotal, taxTotal, total, appliedTaxes, userBalance: 0 };

      const bookingTotals: { userId: string; total: number }[] = [];

      for (const booking of bookings) {
        const bookingOptionAccount = eventAccounts.find(account => account.user.id === booking.bookingOptionUserId);

        const bookingOption =
          booking.bookingOptionId === 'prk_complimentarybooking'
            ? event.complimentaryBookingOption
            : bookingOptionAccount?.bookingOptions.find(option => option.id === booking.bookingOptionId) ??
              bookingOptionAccount?.pendingBookingOptions.find(option => option.id === booking.bookingOptionId);

        if (!bookingOption) continue;

        let bookingTotal = bookingOption.total;

        const boosterInfo = booking?.boostersActivated
          ? bookingBoosters.find(booster => booster.userId === booking.userId)
          : undefined;

        subtotal += bookingOption?.subtotal || 0;
        taxTotal += bookingOption?.tax || 0;
        total += bookingOption?.total || 0;

        purchaseItems.push({
          id: '',
          user: booking.user,
          created: new Date(),
          description: bookingOption.title,
          displayName: `${event.title} - ${moment(event.startTime).format('ddd MMM Do, LT')}`, //TODO: timezone?
          price: bookingOption.price,
          taxes: bookingOption.taxInfo,
          quantity: 1,
          photoURL: event.imageUrl ?? null,
          modified: new Date(),
          purchaseId: '',
          type: 'event',
        } as any);

        bookingOption.taxInfo?.forEach(tax => {
          if (appliedTaxes.some(aTax => aTax.tax.id === tax.id)) {
            const index = appliedTaxes.findIndex(aTax => aTax.tax.id === tax.id);
            appliedTaxes[index].total += (bookingOption.price / 100) * (tax.percentage / 100);
          } else {
            appliedTaxes.push({
              tax: tax,
              total: (bookingOption.price / 100) * (tax.percentage / 100),
              taxNumber: '',
            });
          }
        });

        if (boosterInfo && boosterInfo.boostersToPurchase > 0) {
          const boosterPerkTitle = 'Booking Window Booster';

          purchaseItems.push({
            id: '',
            user: booking.user,
            created: new Date(),
            modified: new Date(),
            purchaseId: '',
            photoURL: null,
            type: 'BookingBoosterPurchaseItemEntity',
            description: `${bookingOption.title} + ${boosterInfo.boostersToPurchase} missing ${
              boosterPerkTitle + (boosterInfo.boostersToPurchase > 1 ? 's' : '')
            } for ${event.title} - ${moment(event.startTime).format('ddd MMM Do, LT')}`, //TODO: timezone?
            quantity: boosterInfo.boostersToPurchase,
            displayName: boosterPerkTitle,
            price: 499,
          } as any);

          const boosterPrice = boosterInfo.boostersToPurchase * 499;

          let boosterTaxTotal = 0;
          bookingOption.taxInfo?.forEach(tax => {
            const boosterTaxAmount = boosterPrice * (tax.percentage / 100);
            boosterTaxTotal += boosterTaxAmount;
            if (appliedTaxes.some(aTax => aTax.tax.id === tax.id)) {
              const index = appliedTaxes.findIndex(aTax => aTax.tax.id === tax.id);
              appliedTaxes[index].total += boosterTaxAmount / 100;
            } else {
              appliedTaxes.push({
                tax: tax,
                total: boosterTaxAmount / 100,
                taxNumber: '',
              });
            }
          });

          subtotal += boosterPrice;
          taxTotal += boosterTaxTotal;
          total += boosterPrice + boosterTaxTotal;
          bookingTotal += boosterPrice + boosterTaxTotal;
        }

        if (!ignoreAdditionalSpotCost) {
          const bookingAdditionalSpots = additionalSpots.filter(spot => spot.userId === booking.userId);
          if (bookingAdditionalSpots.length) {
            const additionalSpotsCost = bookingAdditionalSpots.reduce((acc, spot) => acc + spot.cost, 0);

            purchaseItems.push({
              id: '',
              user: booking.user,
              created: new Date(),
              modified: new Date(),
              purchaseId: '',
              photoURL: null,
              type: 'BookingAdditionalSpotPurchaseItemEntity',
              description: `${bookingOption.title} - ${bookingAdditionalSpots.length} Additional Spots`,
              quantity: bookingAdditionalSpots.length,
              displayName: `${event.title} - ${bookingAdditionalSpots.length} Additional Spots`,
              price: bookingOption.additionalSpotCost,
            } as any);

            let additionalSpotsTaxTotal = 0;
            bookingOption.taxInfo?.forEach(tax => {
              const additionalSpotTaxAmount = additionalSpotsCost * (tax.percentage / 100);
              additionalSpotsTaxTotal += additionalSpotTaxAmount;

              if (appliedTaxes.some(aTax => aTax.tax.id === tax.id)) {
                const index = appliedTaxes.findIndex(aTax => aTax.tax.id === tax.id);
                appliedTaxes[index].total += additionalSpotTaxAmount / 100;
              } else {
                appliedTaxes.push({
                  tax: tax,
                  total: additionalSpotTaxAmount / 100,
                  taxNumber: '',
                });
              }
            });

            subtotal += additionalSpotsCost;
            taxTotal += additionalSpotsTaxTotal;
            total += additionalSpotsCost + additionalSpotsTaxTotal;
            bookingTotal += additionalSpotsCost + additionalSpotsTaxTotal;
          }
        }

        bookingTotals.push({ userId: booking.userId, total: bookingTotal });
      }

      if (userBalance < 0) {
        purchaseItems.push({
          id: '',
          user: event.accounts[0].user,
          created: new Date(),
          modified: new Date(),
          purchaseId: '',
          photoURL: null,
          type: 'BalanceCharge',
          description: this._currency.transform(userBalance / 100),
          quantity: 1,
          displayName: 'Balance Adjustment',
          price: userBalance * -1,
        } as any);

        const balancePrice = userBalance * -1;

        subtotal += balancePrice;
        total += balancePrice;
      }

      this.bookingTotals$.next(bookingTotals);

      return { purchaseItems, subtotal, taxTotal, total, appliedTaxes, userBalance };
    }),
    tap(data => this.total$.next(data.total - (data.userBalance > 0 ? Math.min(data.userBalance, data.total) : 0))),
    map(data => {
      if (!data) return [];
      const { purchaseItems, subtotal, taxTotal, total, appliedTaxes, userBalance } = data;

      return [
        ...purchaseItems.map(item => ({
          id: item.id,
          amount: this._currency.transform((item.quantity * item.price) / 100),
          unitPrice: this._currency.transform(item.price / 100),
          quantity: item.quantity,
          saleCategory: item.saleCategory,
          description: {
            user: (item as any).user,
            displayName: item.displayName,
            description: item.description,
            photoURL: item.photoURL,
          },
        })),
        ...[
          {
            description: null,
            quantity: null,
            unitPrice: '',
            unitPriceClass: 'font-bold',
            amount: this._currency.transform(subtotal / 100),
            amountClass: 'subtotal font-bold',
          },
          //Individual Taxes
          ...appliedTaxes?.map(appliedTax => {
            return {
              description: null,
              descriptionClass: 'no-borders',
              quantity: null,
              quantityClass: 'no-borders',
              unitPrice:
                (appliedTax.tax.abbreviation || appliedTax.tax.title) +
                ' ' +
                appliedTax.tax.percentage +
                '%' +
                (appliedTax.taxNumber ? ' (' + appliedTax.taxNumber + ')' : ''),
              unitPriceClass: 'no-borders',
              amount: appliedTax.total ? this._currency.transform(appliedTax.total) : '-',
              amountClass: 'no-borders',
            };
          }),
          //Total Taxes
          {
            description: null,
            quantity: null,
            unitPrice: '',
            unitPriceClass: 'font-bold',
            amount: taxTotal ? this._currency.transform(taxTotal / 100) : '-',
            amountClass: 'tax font-bold',
          },
          //User Balance
          ...(userBalance > 0
            ? [
                {
                  description: null,
                  descriptionClass: 'no-borders',
                  quantity: null,
                  quantityClass: 'no-borders',
                  unitPrice: 'Applied Balance',
                  unitPriceClass: 'no-borders',
                  amount: this._currency.transform(-Math.min(userBalance, total) / 100),
                  amountClass: 'no-borders',
                },
              ]
            : []),
          //Total
          {
            description: null,
            quantity: null,
            unitPrice: '',
            unitPriceClass: 'strong',
            amount: this._currency.transform((total - (userBalance > 0 ? Math.min(userBalance, total) : 0)) / 100),
            amountClass: 'total strong',
          },
        ],
      ];
    })
  );

  clearOldCache(userId: string, force = false) {
    const results: string[] = [];
    try {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        //If the key does not start with userId_events or it does but ends in _expiry, skip
        if (!(key?.startsWith(`${userId}_events_`) && !key.endsWith('_expiry'))) {
          continue;
        }

        const cachedEventsExpiry = JSON.parse(localStorage.getItem(key + '_expiry') || 'null');

        if (force || !cachedEventsExpiry || cachedEventsExpiry < Date.now()) {
          results.push(key);
        }
      }
    } catch (err) {
      console.error(err);
    }

    results.forEach(key => {
      localStorage.removeItem(key);
      localStorage.removeItem(key + '_expiry');
    });
  }

  async getEvent(eventId: string, userId: string): Promise<EventWithUserDetails> {
    const [event] = await Promise.all([
      toPromise(
        this.http.get<
          CalendarEvent &
            EventUserDetails & { bookings: number; requirements: EventRequirements; security: EventSecurityDetails }
        >(`/api/events2/event/${eventId}`, {
          params: { includeBooking: 'true', userId },
        })
      ),
    ]);

    return toPromise(
      of({
        id: eventId,
        calendar: {
          id: event.calendar?.id || '',
          title: event.calendar?.title || '',
          imageUrl: event.calendar?.imageUrl,
        },
        communityEmail: event.community.email,
        imageUrl: event.imageUrl,
        title: event.title,
        description: event.description || '',
        startTime: event.startDate,
        endTime: event.endDate,
        duration: event.duration,
        status: event.status,
        location: {
          id: event.community?.id || '',
          label: event.community?.name || '',
        },
        tags: event.tags?.map(t => ({ id: t.id, label: t.label })) || [],
        trainers:
          event.resourceAssignments
            ?.filter(assignment => assignment.resource?.type === ResourceType.PERSON)
            .map(assignment => ({
              id: assignment.resource?.id || '',
              label: assignment.resource?.name || '',
              imageUrl: assignment.resource?.photoURL || (assignment.resource as any).user.photoURL,
            })) || [],
        capacity: event.maxCapacity,
        capacityRemaining: event.maxCapacity - event.bookings || 0,
        userStatusInfo: event.userStatusInfo || 'available',
        accounts: event.accounts || [],
        requirements: event.requirements,
        paymentMethods: event.paymentMethods,
        defaultPaymentMethod: event.defaultPaymentMethod,
        userBalance: event.userBalance || 0,
        security: event.security,
        complimentaryBookingOption: event.complimentaryBookingOption,
        hasFrozenSubscription: event.hasFrozenSubscription,
        equipmentTitle: event.equipmentTitle,
        equipmentOptions: event.equipmentOptions,
      })
    );
  }

  async getEvents(
    [start, end]: [Date, Date],
    filters: EventFilter[],
    calendars: EventCalendar[],
    locations: EventLocation[],
    user: User,
    forceRefresh: boolean
  ): Promise<Event[]> {
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    const events: Event[] = [];
    const currentDate = moment(start);

    const promises: Promise<Event[]>[] = [];
    const asyncPromises: Promise<Event[]>[] = [];

    while (currentDate <= moment(end)) {
      const cacheKey = `${user.id}_events_${currentDate.format('YYYY-MM-DD')}`;
      const cacheExpiryKey = `${user.id}_events_${currentDate.format('YYYY-MM-DD')}_expiry`;

      const cachedEvents = JSON.parse(localStorage.getItem(cacheKey) || 'null');
      const cachedEventsExpiry = JSON.parse(localStorage.getItem(cacheExpiryKey) || 'null');

      if (cachedEvents && cachedEventsExpiry && cachedEventsExpiry > new Date().getTime()) {
        events.push(...cachedEvents);

        if (forceRefresh) {
          this.clearOldCache(user.id);
          const asyncPromise = this.getEventsForDate(currentDate.toDate());
          asyncPromises.push(asyncPromise);
          asyncPromise.then(newEvents => {
            try {
              localStorage.setItem(cacheKey, JSON.stringify(newEvents));

              const expiryTime = new Date().getTime() + 1000 * 60 * 15; // 15 minutes expiry
              localStorage.setItem(cacheExpiryKey, JSON.stringify(expiryTime));
            } catch (err) {
              console.error(err);
            }
          });
        }
      } else {
        if (cachedEvents && cachedEventsExpiry && cachedEventsExpiry < new Date().getTime()) {
          this.clearOldCache(user.id);
        }

        const promise = this.getEventsForDate(currentDate.toDate());
        promises.push(promise);

        promise.then(newEvents => {
          try {
            localStorage.setItem(cacheKey, JSON.stringify(newEvents));

            const expiryTime = new Date().getTime() + 1000 * 60 * 15; // 15 minutes expiry
            localStorage.setItem(cacheExpiryKey, JSON.stringify(expiryTime));
          } catch (err) {
            console.error(err);
            this.clearOldCache(user.id, true);
            try {
              localStorage.setItem(cacheKey, JSON.stringify(newEvents));

              const expiryTime = new Date().getTime() + 1000 * 60 * 15; // 15 minutes expiry
              localStorage.setItem(cacheExpiryKey, JSON.stringify(expiryTime));
            } catch (err) {
              console.error(err);
            }
          }

          events.push(...newEvents);
        });
      }

      currentDate.add(1, 'day');
    }

    // Wait for all promises to resolve in parallel for missing cache.
    await Promise.all(promises);

    // Wait for all async promises to resolve in parallel for cache refresh.
    if (forceRefresh && asyncPromises.length) {
      setTimeout(async () => {
        await Promise.all(asyncPromises).then(() => this.forceRefresh$.next(false));
      }, 0);
    }

    return events
      .filter(event => {
        if (event.calendar.private) return false;

        const tagFilters = filters.filter(f => f.key === 'tag').map(f => f.value);
        const trainerFilters = filters.filter(f => f.key === 'trainer').map(f => f.value);

        if (tagFilters.length && !event.tags.some(t => tagFilters.some(tf => tf.split('-').includes(t.id)))) {
          return false;
        }
        if (
          trainerFilters.length &&
          !event.trainers.some(t => trainerFilters.some(tf => tf.split('-').includes(t.id)))
        ) {
          return false;
        }
        if (!locations.some(l => l.id === event.location.id)) return false;
        if (!calendars.some(c => c.id === event.calendar.id)) return false;
        if (moment(event.startTime).subtract(15, 'minutes').isBefore(moment())) return false;

        return true;
      })
      .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));
  }

  async getEventsForDate(date: Date): Promise<Event[]> {
    return Promise.all([
      toPromise(
        this.http
          .get<
            (CalendarEvent & {
              userStatusInfo: EventUserStatus;
              requirements: EventRequirements;
              security: EventSecurityDetails;
            })[]
          >('/api/events2', {
            params: {
              includePrivate: 'false',
              includeCourse: 'false',
              onlyCalsSpecified: 'false',
              includeBookingWaitlistInfo: 'true',
              startDate: moment(date).startOf('day').toISOString(),
              endDate: moment(date).endOf('day').toISOString(),
            },
          })
          .pipe(map(events => (events || []).filter(event => event.status !== EventStatus.CANCELLED)))
      ),
    ]).then(([events]) => {
      return events.map(event => ({
        id: event.id,
        communityEmail: event.community.email,
        imageUrl: event.imageUrl,
        title: event.title,
        description: event.description || '',
        status: event.status,
        startTime: event.startDate,
        endTime: event.endDate,
        duration: event.duration,
        calendar: {
          id: event.calendar?.id || '',
          title: event.calendar?.title || '',
          imageUrl: event.calendar?.imageUrl,
          private: event.calendar?.private,
        },
        location: {
          id: event.community?.id,
          label: event.community?.name,
        },
        tags:
          event.tags?.map(t => ({
            id: t.id,
            label: t.label,
          })) || [],
        trainers:
          event.resourceAssignments
            ?.filter(resourceAssignment => resourceAssignment.resource?.type === ResourceType.PERSON)
            .map(resourceAssignment => ({
              id: resourceAssignment.resource?.id || '',
              label: resourceAssignment.resource?.name || '',
              imageUrl: resourceAssignment.resource?.photoURL || (resourceAssignment.resource as any).user.photoURL,
            })) || [],
        capacity: event.maxCapacity,
        capacityRemaining: event.maxCapacity - ((event as any)['bookings'] || 0),
        security: event.security,
        requirements: event.requirements,
        userStatusInfo: event.userStatusInfo,
      }));
    });
  }

  getCalendarTypes(): Observable<EventsByCalendar[]> {
    return this.userService.user$.pipe(
      filter(user => !!user),
      switchMap((user: any) => {
        const cacheKey = `${user.id}_calendar_types`;
        const cachedCalendarTypes = JSON.parse(localStorage.getItem(cacheKey) || 'null');
        const cacheExpiryKey = `${user.id}_calendar_types_expiry`;
        const cachedCalendarTypesExpiry = JSON.parse(localStorage.getItem(cacheExpiryKey) || 'null');
        if (cachedCalendarTypes && cachedCalendarTypesExpiry && cachedCalendarTypesExpiry > new Date().getTime()) {
          return of(cachedCalendarTypes);
        } else {
          return this.fetchCalendarTypes(user.id).then(calendarTypes => {
            try {
              localStorage.setItem(cacheKey, JSON.stringify(calendarTypes));
              const expiryTime = new Date().getTime() + 1000 * 60 * 15; // 15 minutes expiry
              localStorage.setItem(cacheExpiryKey, JSON.stringify(expiryTime));
            } catch (err) {
              console.error(err);
            }

            return calendarTypes;
          });
        }
      })
    );
  }

  fetchCalendarTypes(_userId: string): Promise<EventsByCalendar[]> {
    return Promise.all([
      toPromise(this.http.get<(Calendar & { tags: EventTag[]; trainers: EventTrainer[] })[]>(`/api/calendar/for-user`)),
    ]).then(([calendars]) => {
      const grouped = calendars.reduce((acc, calendar) => {
        const group = calendar.group || 'no-group';
        if (group !== 'no-group') {
          if (!acc[group]) acc[group] = [];
          acc[group].push(calendar);
        }
        return acc;
      }, {} as { [group: string]: (Calendar & { tags: EventTag[]; trainers: EventTrainer[] })[] });
      grouped['All Events'] = calendars;

      return Object.entries(grouped)
        .sort(([g0], [g1]) => {
          if (g0 === 'All Events') return -1;
          if (g1 === 'All Events') return 1;
          if (g0.toLowerCase().includes('lf3')) return 1;
          if (g1.toLowerCase().includes('lf3')) return -1;
          if (g0 === 'Other Calendars') return 1;
          if (g1 === 'Other Calendars') return -1;
          return g0.localeCompare(g1);
        })
        .map(([key, value]) => {
          const uniqueCalendars = value.reduce((acc, cur) => {
            if (!acc.some(c => c.id === cur.id)) {
              acc.push(cur);
            }
            return acc;
          }, [] as (Calendar & { tags: EventTag[]; trainers: EventTrainer[] })[]);
          const uniqueTags = uniqueCalendars
            .reduce((acc, cur) => {
              cur.tags.forEach(t => {
                if (!acc.some(tag => tag.id === t.id)) {
                  acc.push({ ...t, locationId: cur.community?.id || '' });
                }
              });
              return acc;
            }, [] as (EventTag & { locationId: string })[])
            .sort((a, b) => a.label.localeCompare(b.label));
          const uniqueTrainers = uniqueCalendars
            .reduce((acc, cur) => {
              cur.trainers.forEach(t => {
                if (!acc.some(trainer => trainer.id === t.id)) {
                  acc.push({ ...t, locationId: cur.community?.id || '' });
                }
              });
              return acc;
            }, [] as (EventTrainer & { locationId: string })[])
            .sort((a, b) => a.label.localeCompare(b.label));

          return {
            key,
            label: key,
            calendars: uniqueCalendars,
            locations: value.reduce((acc, cur) => {
              if (!acc.some(l => l.id === cur.community?.id)) {
                acc.push({
                  id: cur.community?.id || '',
                  label: cur.community?.name || '',
                  tags: uniqueTags.filter(t => t.locationId === cur.community?.id),
                  trainers: uniqueTrainers.filter(t => t.locationId === cur.community?.id),
                });
              }
              return acc;
            }, [] as EventLocation[]),
          };
        });
    });
  }

  removeBooking(bookingUserId: string): void {
    this.bookings$.next(this.bookings$.value.filter(b => b.userId !== bookingUserId));
  }

  addBooking(booking: Booking): void {
    this.bookings$.next([...this.bookings$.value.filter(b => b.userId !== booking.userId), booking]);
  }

  swapBooking(prevUserId: string, next: Booking): void {
    this.bookings$.next(this.bookings$.value.map(booking => (booking.userId === prevUserId ? next : booking)));
  }

  async confirmBooking(dto: CreateMultiBookingDto): Promise<Booking[] | null> {
    const bookings = await toPromise(this.http.post<Booking[]>('/api/events2/booking/confirm', dto));

    if (bookings?.length) {
      bookings.forEach(booking => {
        const bookingSent = dto.bookings.find(booked => booked.userId === (booking as any).user.id);
        if (!bookingSent) return;

        if (bookingSent.spotId && bookingSent?.spotId !== (booking as any).spot?.id) {
          this.snackbar.open('Your spot was booked by somebody else! Please select a new spot', 'Ok', {
            duration: 10000,
            panelClass: 'mat-warn',
          });
        }
      });
    }

    return bookings;
  }

  async joinWaitlistMultiple(eventId: string, userIds: string[]) {
    const response = await toPromise(
      this.http.post<WaitlistItem[]>('/api/bookings/waitlist/multiple', { eventId, userIds })
    );

    if (!response) return null;

    const newValue = Array.isArray(response) ? response : [response];

    this.waitlist$.next([...this.waitlist$.value, ...newValue.map(item => item.user!)]);
    this.bookings$.next([]);

    this.snackbar.open(`Your booking${userIds.length > 1 ? 's have' : ' has'} been added to the waitlist.`, 'Ok', {
      duration: 2500,
      panelClass: 'mat-primary',
    });

    return response;
  }

  async removeFromWaitlist(communityId: string, eventId: string, userId: string) {
    await toPromise(this.http.delete(`/api/events/${communityId}/${eventId}/leave?userId=${userId}`));

    this.waitlist$.next(this.waitlist$.value.filter(user => user.id !== userId));

    this.snackbar.open(`Removed from the waitlist.`, 'Ok', {
      duration: 2500,
      panelClass: 'mat-primary',
    });
  }

  async cancelBooking(bookingId: string, freeOfCharge?: boolean) {
    const response = await this.bookingService.cancel(bookingId, freeOfCharge);

    const attendee = this.attendees$.value.find(attendee => attendee.id === bookingId);
    if (attendee?.spotId) {
      this.bookedSpots$.next(this.bookedSpots$.value.filter(spot => spot.spotId !== attendee.spotId));
    }

    this.attendees$.next(this.attendees$.value.filter(booking => booking.id !== bookingId));

    this.snackbar.open(`The booking has been cancelled.`, 'Ok', {
      duration: 2500,
      panelClass: 'mat-primary',
    });

    return response;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  reset() {
    this.bookings$.next(null as any);
    this.alerts$.next([]);
    this.accounts$.next([]);
    this.paymentMethods$.next([]);
    this.paymentMethodToUse$.next(null);
    this.bookedSpots$.next([]);
    this.bookingTotals$.next([]);
    this.agreements$.next([]);
    this.agreementSubmissions$.next([]);
    this.profilesCompleted$.next([]);
    this.typeformSubmissions$.next([]);
    this.total$.next(0);
    this.attendees$.next([]);
    this.waitlist$.next([]);
    this.requirementErrors$.next([]);
    this.additionalSpots$.next([]);
    this.ignoreAdditionalSpotCost$.next(false);
    this.equipment$.next([]);
    this.bookingOptionAgreements$.next([]);
  }

  private calculateBookingOptionWindow(now: moment.Moment, startTime: Date, bookingOption: BookingOptionDetails) {
    const cutoff = moment(startTime).subtract(bookingOption.bookingWindow, 'minutes');
    const requiredBoosts = now.isBefore(cutoff) ? Math.ceil(cutoff.diff(now, 'days', true)) : 0;

    const bookingStart = moment(startTime).subtract(bookingOption.bookingWindow, 'minutes');
    const bookingStartWithBoost = moment(bookingStart).subtract(
      bookingOption.maxBoost === -1 ? 0 : bookingOption.maxBoost,
      'days'
    );

    return { cutoff, requiredBoosts, bookingStart, bookingStartWithBoost };
  }

  private getBoosterInfo(
    bookingOption: BookingOptionDetails,
    now: moment.Moment,
    eventStartTime: Date,
    hasInfiniteBoosters: boolean,
    totalBoosters: number,
    overallRemaining?: number
  ) {
    const { requiredBoosts, bookingStartWithBoost } = this.calculateBookingOptionWindow(
      now,
      eventStartTime,
      bookingOption
    );

    const boostersRemaining = overallRemaining != undefined ? Math.min(overallRemaining, totalBoosters) : totalBoosters;

    //calculate boosters used vs purchase
    let boostersToPurchase = 0;
    let boostersToConsume = 0;

    if (bookingStartWithBoost.isAfter(now)) {
      //not in booking window
      boostersToPurchase = 0;
      boostersToConsume = 0;
    } else if (hasInfiniteBoosters) {
      boostersToPurchase = 0;
      boostersToConsume = requiredBoosts;
    } else {
      boostersToPurchase = Math.max(requiredBoosts - boostersRemaining, 0);
      boostersToConsume = Math.max(0, Math.min(boostersRemaining, requiredBoosts));
    }

    return {
      boostersRequired: !!requiredBoosts,
      boostersToConsume,
      boostersToPurchase,
    };
  }
}
