import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { SaleCategory } from '@greco/sales-purchases';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SaleCategoryService } from '../../services';

@Component({
  selector: 'greco-sale-category-picker',
  templateUrl: './sale-category-picker.component.html',
  styleUrls: ['./sale-category-picker.component.scss'],
})
export class SaleCategoryPickerComponent {
  constructor(private saleCategoryService: SaleCategoryService, private userSvc: UserService) {}

  @PropertyListener('accountId') private accountId$ = new ReplaySubject<string>(1);

  @Input() accountId?: string;
  @Input() showUncategorizedOption = false;

  @Output() categoriesChanged = new EventEmitter<SaleCategory[]>();
  @Output() loaded = new EventEmitter<SaleCategory[]>();
  @Output() showUncategorizedChanged = new EventEmitter<boolean>();

  showUncategorized = false;
  allSelected = true;
  categoryCount = 0;

  selected$ = new BehaviorSubject<SaleCategory[]>([]);
  selectedSaleCategoryIds$ = this.selected$.pipe(map(selected => selected.map(saleCat => saleCat.id)));

  options$ = this.accountId$.pipe(
    switchMap(async accountId => (accountId ? await this.saleCategoryService.getManySecured(accountId) : [])),
    tap(options => {
      this.loaded.emit(options);
      this.selected$.next(options);
      this.categoryCount = options.length;
    }),
    shareReplay(1)
  );

  currentUser$ = this.options$.pipe(
    switchMap(() => this.userSvc.getSelf()),
    tap(currentUser => (currentUser?.isSuperAdmin ? (this.showUncategorized = true) : (this.showUncategorized = false)))
  );

  toggleAllSelection(select: MatSelect) {
    if (this.allSelected) {
      select.options.forEach((item: MatOption) => item.select());
    } else {
      select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleUncategorized() {
    this.showUncategorized = !this.showUncategorized;
    this.showUncategorizedChanged.emit(this.showUncategorized);
  }

  async onChanged(saleCategoryIds: string[], saleCategories: SaleCategory[]) {
    this.allSelected = saleCategoryIds.length === this.categoryCount;
    this.selected$.next(saleCategories.filter(option => saleCategoryIds.includes(option.id)));
    this.categoriesChanged.emit(this.selected$.value);
  }
}
