import { Component, Injectable, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { CheckoutStation, CheckoutStationSecurityActions, CheckoutStationSecurityResource } from '@greco/sales-shop';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CreateCheckoutStationDialog, UpdateCheckoutStationDialog } from '../../dialogs';
import { CheckoutStationService } from '../../services';

@Injectable({ providedIn: 'any' })
export class TitleSearchFilter extends BuildSearchFilter('TitleSearchFilter', {
  properties: ['title'],
  propertyLabels: ['Title'],
}) {}

@Component({
  selector: 'greco-checkout-stations-page',
  templateUrl: './checkout-stations.page.html',
  styleUrls: ['./checkout-stations.page.scss'],
})
export class CheckoutStationsPage {
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private securitySvc: SecurityService,
    private stationSvc: CheckoutStationService
  ) {}

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() communityId!: string;

  @Input() account!: Account;

  search$ = new BehaviorSubject('');

  refresh$ = new BehaviorSubject(null);

  filterOptions = [TitleSearchFilter];

  metadata?: IPaginationMeta;

  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  canCreate$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.securitySvc.hasAccess(CheckoutStationSecurityResource.key, CheckoutStationSecurityActions.CREATE, {
            communityId,
          })
        : false
    )
  );

  canUpdate$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.securitySvc.hasAccess(CheckoutStationSecurityResource.key, CheckoutStationSecurityActions.UPDATE, {
            communityId,
          })
        : false
    )
  );

  stations$ = combineLatest([this.communityId$, this.pagination$, this.search$, this.refresh$]).pipe(
    switchMap(async ([communityId, pagination, search]) => {
      if (!communityId) return null;
      return await this.stationSvc.paginateCheckoutStations(communityId, pagination, search || undefined);
    }),
    tap(data => (this.metadata = data?.meta)),
    map(data => {
      if (!data) return [];
      return data.items;
    })
  );

  async createStation() {
    const dialog = this.matDialog.open(CreateCheckoutStationDialog, {
      data: {
        communityId: this.communityId,
        accountId: this.account.id,
      },
      width: '750px',
      maxWidth: '90%',
    });

    await toPromise(dialog.afterClosed());
    this.refresh$.next(null);
  }

  openStation(station: CheckoutStation) {
    this.router.navigate(['sales', this.communityId, 'stations', station.id]);
  }

  async updateStation(station: CheckoutStation) {
    const dialog = this.matDialog.open(UpdateCheckoutStationDialog, {
      data: {
        communityId: this.communityId,
        accountId: this.account.id,
        stationId: station.id,
      },
      width: '750px',
      maxWidth: '90%',
    });

    await toPromise(dialog.afterClosed());
    this.refresh$.next(null);
  }

  onFilterApplied(filters: any) {
    if (!filters.length) this.search$.next('');
    else this.search$.next(filters[filters.length - 1].value);
  }
}
