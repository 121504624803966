import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarEvent } from '@greco/booking-events';
import { UpdateEventDto } from '@greco/nestjs-booking-events';
import { userDefaultTimezone } from '@greco/timezone';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { EventService } from '../../services';

@Component({
  selector: 'greco-reschedule-event-dialog',
  templateUrl: './reschedule-event.dialog.html',
  styleUrls: ['./reschedule-event.dialog.scss'],
})
export class RescheduleEventDialog {
  @Input() isCompleted = false;
  @Input() readonly = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { newStart: Date; newEnd: Date; event: CalendarEvent },
    private matDialogRef: MatDialogRef<UpdateEventDto>,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<RescheduleEventDialog>,
    private eventSvc: EventService,
    private snacks: MatSnackBar
  ) {}

  formGroup = this.formBuilder.group({
    title: [this.data.event.title, [Validators.required]],
    eventStart: [{ date: this.data.newStart, timezone: userDefaultTimezone() }, [Validators.required]],
    duration: [this.data.event.duration, [Validators.required]],
  });

  readonly stateChanges = new Subject<void>();
  _required = true;
  _readonly = false;

  @Input() get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this.formGroup.get('title')?.setValidators(validator);
    this.formGroup.get('startDate')?.setValidators(validator);
    this.formGroup.get('duration')?.setValidators(validator);
    this.stateChanges.next();
  }

  dialogData: DialogData = {
    title: 'Reschedule Event',
    subtitle: '',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Confirm',
        role: 'create',
        resultFn: async () => {
          const data: UpdateEventDto = {};
          data.title = this.formGroup.value.title;
          data.startDate = this.formGroup.value.eventStart.date;
          data.duration = this.formGroup.value.duration;

          const event = await this.eventSvc.getOneEvent(this.data.event.id);
          data.resourceAssignments = event.resourceAssignments;

          const updatedEvent = await this.eventSvc.updateEventDetails(this.data.event.id as string, data);
          this.snacks.open(this.data.event.title + ' Event Rescheduled!', 'Ok', {
            duration: 2500,
            panelClass: 'mat-primary',
          });
          this.matDialogRef.close(updatedEvent);
        },
      },
    ],
  };
}
