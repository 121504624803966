<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu>
  <!-- View Sub details -->
  <a *ngIf="!hideViewDetailsOption" mat-menu-item [routerLink]="[subscription.id]">
    <mat-icon>read_more</mat-icon>
    <span>View Details</span>
  </a>

  <!-- Manager user -->
  <a mat-menu-item [routerLink]="['/community', communityId, subscription.user?.id]">
    <mat-icon>person_search</mat-icon>
    <span>View Customer</span>
  </a>

  <button
    *ngIf="canManageMinimumCommitment$ | async"
    mat-menu-item
    (click)="updateMinimumCommitmentDialog(subscription)"
  >
    <mat-icon>edit_calendar</mat-icon>
    <span>Update Commitment End Date</span>
  </button>

  <button *ngIf="canManageSoldBy$ | async" mat-menu-item (click)="setSoldBy(subscription)">
    <mat-icon>manage_accounts</mat-icon>
    <span>Update Sold By</span>
  </button>

  <button *ngIf="canManageReferredBy$ | async" mat-menu-item (click)="setReferredBy(subscription)">
    <mat-icon>manage_accounts</mat-icon>
    <span>Update Referred By</span>
  </button>

  <button *ngIf="canManageTransferredFrom$ | async" mat-menu-item (click)="setTransferredFrom(subscription)">
    <mat-icon>manage_accounts</mat-icon>
    <span>Update Transferred From</span>
  </button>

  <ng-container *ngIf="subscription.status === 'ACTIVE'">
    <ng-container *ngIf="canCancelSubscriptions">
      <!-- Cancel Subscription  -->
      <button mat-menu-item [disabled]="cancelling" (click)="cancel()">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
    </ng-container>

    <ng-container *ngIf="canUpdateSubscriptions">
      <!-- Update Subscription  -->
      <button mat-menu-item [disabled]="updating" (click)="update()">
        <mat-icon>update</mat-icon>
        <span>Update</span>
      </button>
    </ng-container>

    <!-- Change Payment Method  -->
    <button
      *ngIf="!hidePaymentMethodOption"
      mat-menu-item
      [disabled]="updatingPaymentMethod"
      (click)="changePaymentMethodDialog(subscription)"
    >
      <mat-icon>payment</mat-icon>
      <span>Update Payment Methods</span>
    </button>
  </ng-container>
</mat-menu>
