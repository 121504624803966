import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatVerticalStepper } from '@angular/material/stepper';
import { Coupon } from '@greco/coupon';
import { DialogData } from '@greco/ui-dialog-simple';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CouponService } from '../../services';
@Component({
  selector: 'greco-grant-coupon',
  templateUrl: './grant-coupon.dialog.html',
  styleUrls: ['./grant-coupon.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GrantCouponDialog implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<GrantCouponDialog>,
    private formBuilder: FormBuilder,
    private couponSvc: CouponService,
    private snacks: MatSnackBar,

    @Inject(MAT_DIALOG_DATA)
    data: {
      communityId: string;
      selectedCoupon: Coupon;
    }
  ) {
    this.communityId = data.communityId;
    this.selectUsers = false;
    this.submitting = false;
    this.unlimitedUse = false;
    this.selectedCoupon = data.selectedCoupon?.id;
  }

  private onDestroy$ = new Subject<void>();
  communityId: string;
  selectUsers: boolean;
  submitting: boolean;
  unlimitedUse: boolean;
  selectedCoupon: string;

  refresh$ = new BehaviorSubject(null);

  dialogData: DialogData = {
    title: 'Grant Coupon',
    subtitle: 'Grant selected coupon to one/all user(s).',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({
    contact: [null, Validators.required],
    coupon: ['', Validators.required],
    transferable: [false],
    quantity: ['', [Validators.required, Validators.min(1)]],
    unlimited: [false],
    allUserSelected: [''],
    expiry: [null, Validators.min(0)],
    expiryNotification: [false],
    expiryNotificationDays: [2, Validators.min(1)],
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  expiresOn$ = this.form.get('expiry')!.valueChanges.pipe(
    startWith(null),
    map(expiry => (typeof expiry === 'number' && expiry >= 0 ? moment().add(expiry, 'days').format('ll') : null))
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  expiresNotification$ = this.form.get('expiryNotificationDays')!.valueChanges.pipe(
    startWith(null),
    map(expiry =>
      typeof expiry === 'number' && expiry >= 0
        ? moment()
            .add((this.form.value.expiry || 0) - expiry, 'days')
            .format('ll')
        : null
    )
  );

  coupons$ = combineLatest([this.refresh$]).pipe(
    switchMap(async () => await this.couponSvc.getManyExcludingArchives(this.communityId))
  );

  async allUserSelected() {
    const contactFormField = this.form.get('contact') as FormControl;
    contactFormField?.clearValidators();
    contactFormField?.updateValueAndValidity();
    this.selectUsers = false;
  }

  async pickUserSelected() {
    this.selectUsers = true;
    const contactFormField = this.form.get('contact') as FormControl;
    contactFormField?.setValidators(Validators.required);
    contactFormField?.updateValueAndValidity();
  }

  async submit() {
    this.submitting = true;

    const userId = this.selectUsers ? this.form.value.contact.user.id : undefined;
    const couponId = this.form.value.coupon;

    try {
      const result = await this.couponSvc.grantCoupon({
        transferable: this.form.value.transferable ? true : false,
        consumable: !this.unlimitedUse,
        expiryDelay: this.form.value.expiry,
        expiryNotificationDelay: this.form.value.expiryNotificationDays,
        quantity: this.form.value.quantity,
        perkId: couponId,
        userId: userId,
      });

      this.submitting = false;
      this.dialogRef.close(result);
      result
        ? this.snacks.open('Coupon granted successfully!', 'Ok', { duration: 3500, panelClass: 'mat-primary' })
        : this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
    } catch (err) {
      console.error(err);
    }
  }

  async disableQuantity() {
    this.unlimitedUse = !this.unlimitedUse;
    const quantity = this.form.get('quantity') as FormControl;
    quantity.setValue(1);

    if (this.unlimitedUse) {
      quantity?.clearValidators();
      quantity?.updateValueAndValidity();
    }

    if (!this.unlimitedUse) {
      quantity?.setValidators(Validators.required);
      quantity?.updateValueAndValidity();
    }
  }

  resetStepper(stepper: MatVerticalStepper) {
    stepper.reset();
    this.form.reset();
    this.close();
  }

  async close() {
    return this.dialogRef.close();
  }

  ngOnInit(): void {
    if (!this.selectUsers) {
      const contactFormField = this.form.get('contact') as FormControl;
      contactFormField?.clearValidators();
      contactFormField?.updateValueAndValidity();
    }

    this.refresh$.next(null);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
