import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco/ui-dialog-simple';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-webcam',
  templateUrl: './webcam.dialog.html',
  styleUrls: ['./webcam.dialog.scss'],
})
export class WebcamDialog {
  constructor(private dialog: MatDialogRef<WebcamDialog>, private breakpointObs: BreakpointObserver) {}

  webcamImage: WebcamImage | null = null;

  trigger: Subject<void> = new Subject<void>();

  readonly isMobile$ = this.breakpointObs.observe('(max-width: 600px)').pipe(map(({ matches }) => matches));

  dialogData: DialogData = {
    title: 'Take your Photo',
    subtitle: 'Say cheese!',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  triggerSnapshot() {
    this.trigger.next();
  }

  handleImage(image: WebcamImage) {
    this.webcamImage = image;
  }

  clearSnapshot() {
    this.webcamImage = null;
  }

  keepSnapshot() {
    if (this.webcamImage) {
      const otherFile = this.dataURLtoFile(this.webcamImage.imageAsBase64);
      this.dialog.close(otherFile);
    }
  }

  dataURLtoFile(base64: string) {
    const bstr = atob(base64);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'profile.jpeg', { type: 'image/jpeg' });
  }

  cancel() {
    this.dialog.close();
  }
}
