import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';

@Component({
  selector: 'greco-view-community-agreement',
  templateUrl: './view-community-agreement.dialog.html',
  styleUrls: ['./view-community-agreement.dialog.scss'],
})
export class ViewCommunityAgreementDialog {
  @Input() agreement!: any;

  dialogData!: DialogData;

  agreementText!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private snackbar: MatSnackBar) {
    const agreementData = this.data.commAgreement.commAgreement;
    this.agreementText = agreementData.text;

    this.dialogData = {
      title: 'Agreement Preview',
      subtitle: agreementData.title,
      content: '<ng-container *ngFor="ngTextContent"></ng-container>',
      buttons: [{ label: 'Close', role: 'cancel' }],
    };
  }
}
