import { Inject, Injectable, InjectionToken } from '@angular/core';

export const ZOOM_CLIENT_ID = new InjectionToken<string>('ZOOM_CLIENT_ID');

@Injectable({
  providedIn: 'root',
})
export class ZoomService {
  constructor(@Inject(ZOOM_CLIENT_ID) private clientId: string) {}

  get redirectUrl() {
    return window.location.origin + '/scheduling';
  }

  getInstallUrl(communityId: string, displayName: string) {
    return (
      'https://zoom.us/oauth/authorize?response_type=code' +
      `&client_id=${this.clientId}` +
      `&redirect_uri=${this.redirectUrl}` +
      `&state=${encodeURIComponent(JSON.stringify({ communityId, displayName }))}`
    );
  }
}
