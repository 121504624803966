import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tax } from '@greco/finance-tax';
import { DialogData } from '@greco/ui-dialog-simple';
import { CurrencyMaskConfig } from 'ngx-currency';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { BookingOptionService } from '../../services';

@Component({
  selector: 'greco-create-option-dialog',
  templateUrl: './create-option.dialog.html',
  styleUrls: ['./create-option.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateOptionDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string; accountId: string },
    private bookingOptionSvc: BookingOptionService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {
    this.accountId = data.accountId;

    this.formGroup
      .get('cancellation')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(cancellation => {
        if (cancellation) {
          this.formGroup.get('refund')?.enable();
          this.formGroup.get('cancellationPrice')?.enable();
          this.formGroup.get('cancellationWindow')?.enable();
          this.formGroup.get('cancellationTaxes')?.enable();
          this.formGroup.get('cancellationIgnoreTaxes')?.enable();
        } else {
          this.formGroup.get('refund')?.disable();
          this.formGroup.get('cancellationPrice')?.disable();
          this.formGroup.get('cancellationWindow')?.disable();
          this.formGroup.get('cancellationTaxes')?.disable();
          this.formGroup.get('cancellationIgnoreTaxes')?.disable();
        }
      });

    this.formGroup
      .get('price')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(price => {
        if (price > 0) {
          this.formGroup.get('saleCategoryBook')?.enable();
          this.scBookingVisible = true;
        } else {
          this.formGroup.get('saleCategoryBook')?.disable();
          this.formGroup.get('saleCategoryBook')?.setValue(null);
          this.scBookingVisible = false;
        }
      });

    this.formGroup
      .get('cancellationPrice')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(cancellationPrice => {
        if (cancellationPrice > 0) {
          this.formGroup.get('saleCategoryCancel')?.enable();
          this.scCancelVisible = true;
        } else {
          this.formGroup.get('saleCategoryCancel')?.disable();
          this.formGroup.get('saleCategoryCancel')?.setValue(null);
          this.scCancelVisible = false;
        }
      });

    this.formGroup
      .get('isCourseOption')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(isOption => {
        if (isOption) {
          this.formGroup.get('maxBoost')?.enable();
          this.formGroup.get('allowBoost')?.enable();
        } else {
          this.formGroup.get('maxBoost')?.disable();
          this.formGroup.get('allowBoost')?.disable();
        }
      });
  }

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  public accountId?: string;

  public scBookingVisible = false;
  public scCancelVisible = false;

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    availableToAnyone: [false, Validators.required],
    allowPendingBookings: [true],
    isCourseOption: [false, Validators.required],
    cancellation: [false, Validators.required],
    refund: [{ value: false, disabled: true }, Validators.required],
    cancellationWindow: [null],
    cancellationPrice: [null],
    price: [0],
    tags: [[], Validators.required],
    title: [null, Validators.required],
    icon: [null],
    color: ['#ff0000', Validators.required],
    description: [null],
    maxBoost: [3],
    allowBoost: [true],
    bookingWindow: [1440, [Validators.required, Validators.min(0)]],
    saleCategoryBook: [{ value: null, disabled: true }],
    saleCategoryCancel: [{ value: null, disabled: true }],
    bookingTaxes: [[]],
    bookingIgnoreTaxes: [false],
    cancellationTaxes: [[]],
    cancellationIgnoreTaxes: [false],
  });

  selectable = true;
  removable = true;

  dialogData: DialogData = {
    title: 'Create New Booking Option',
    subtitle: 'Provide the details for the new booking option',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          try {
            const data = this.formGroup.value;
            const option = await this.bookingOptionSvc.create({
              cancellationWindow: data.cancellationWindow || null,
              availableToAnyone: data.availableToAnyone || false,
              cancellationPrice: data.cancellationPrice * 100 || 0,
              cancellation: data.cancellation || false,
              description: data.description || null,
              communityId: this.data.communityId,
              bookingWindow: data.bookingWindow,
              maxBoost: data.allowBoost ? data.maxBoost || 0 : -1,
              refund: data.refund || false,
              price: Math.round(data.price * 100),
              tags: data.tags || [],
              title: data.title,
              icon: data.icon,
              hexColor: data.color,
              allowPendingBookings: data.allowPendingBookings || false,
              isCourseOption: data.isCourseOption,
              saleCategoryBookId: data.saleCategoryBook?.id ? data.saleCategoryBook?.id : null,
              saleCategoryCancelId: data.saleCategoryCancel?.id ? data.saleCategoryCancel?.id : null,
              bookingTaxes: data.bookingTaxes.map((tax: Tax) => tax.id) || [],
              bookingIgnoreTaxes: data.bookingIgnoreTaxes || false,
              cancellationTaxes: data.cancellationTaxes.map((tax: Tax) => tax.id),
              cancellationIgnoreTaxes: data.cancellationIgnoreTaxes || false,
            });

            this.snacks.open('New option created', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return option;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.accountId = this.data.accountId;

    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
