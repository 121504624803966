import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import type { UpdateUserDto } from '@greco/nestjs-identity-users-util';
import { UserService } from '@greco/ngx-identity-auth';
import { heightExpansion } from '@greco/ui-animations';
import { DialogData, SimpleDialog } from '@greco/ui-dialog-simple';
import { combineLatest, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WebcamDialog } from '../../dialogs';

@Component({
  selector: 'greco-user-profile-details-optional',
  templateUrl: './profile-details-optional.component.html',
  styleUrls: ['./profile-details-optional.component.scss'],
  animations: [heightExpansion],
})
export class UserProfileDetailsOptionalComponent implements OnChanges {
  constructor(
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private snacks: MatSnackBar,
    private dialog: MatDialog
  ) {}

  @Input() preventReload = false;
  @Input() readonly = false;
  @Input() alwaysOpen = false;

  @Input() danger = false;
  @Input() label = 'Profile Information';
  @Input() user!: User;
  @Output() saved = new EventEmitter();

  @HostBinding('class.expanded') public expanded = false;

  nowDate = new Date();

  resetValue: any = {
    firstName: '',
    lastName: '',
    email: '',
    friendlyName: '',
    phoneNumber: '',
    address: null,
    birthday: null,
    gender: null,
    genderOther: null,
    photoURL: null,
    emergencyContactName: null,
    emergencyPhoneNumber: '',
    // emergencyContactEmail: null,
    emergencyContactRelationship: null,
  };

  profilePictureFile?: File;
  form = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    email: ['', [Validators.email]],
    friendlyName: [''],
    phoneNumber: [''],
    address: [null],
    birthday: [null],
    gender: [null],
    genderOther: [null],
    photoURL: [null],
    emergencyContactName: [null],
    emergencyPhoneNumber: [''],
    // emergencyContactEmail: [null],
    emergencyContactRelationship: [null],
  });

  missingFields$ = combineLatest([
    interval(1000).pipe(startWith(-1)),
    this.form.valueChanges.pipe(startWith(null)),
    this.form.statusChanges.pipe(startWith(null)),
  ]).pipe(
    map(() => {
      const {
        firstName,
        lastName,
        phoneNumber,
        address,
        birthday,
        gender,
        genderOther,
        emergencyContactName,
        emergencyPhoneNumber,
        emergencyContactRelationship,
      } = this.form.controls;

      const result = [
        ...(!firstName.value ? ['First Name'] : []),
        ...(!lastName.value ? ['Last Name'] : []),
        ...(!phoneNumber.value ? ['Phone number'] : []),
        ...(!address.value ? ['Address'] : []),
        ...(!birthday.value ? ['Birthday'] : []),
        ...(!gender.value || (gender.value === 'Other' && !genderOther.value) ? ['Gender'] : []),
        ...(!emergencyContactName.value ? ['Emergency Contact Name'] : []),
        ...(!emergencyPhoneNumber.value ? ['Emergency Phone Number'] : []),
        ...(!emergencyContactRelationship.value ? ['Emergency Contact Relationship'] : []),
      ];

      return result.join(', ');
    })
  );

  save = async () => {
    if (this.readonly) return;

    try {
      const data: UpdateUserDto = {};

      const fullName = `${this.form.value.firstName.trim()} ${this.form.value.lastName.trim()}`;
      if (fullName !== this.user?.displayName) {
        data.displayName = fullName;
      }

      if (this.form.value.friendlyName !== (this.user?.friendlyName || '')) {
        data.friendlyName = this.form.value.friendlyName;
      }

      if (this.form.value.phoneNumber !== (this.user?.phoneNumber || '')) {
        data.phoneNumber = this.form.value.phoneNumber;
      }

      if ((this.form.value.address?.formatted || '') !== (this.user?.address?.formatted || '')) {
        data.address = this.form.value.address;
      }

      if (this.form.value.birthday?.valueOf() !== this.user?.birthday?.valueOf()) {
        data.birthday = this.form.value.birthday;
      }

      const gender =
        this.form.value.gender?.valueOf() === 'Other'
          ? this.form.value.genderOther?.valueOf() || this.form.value.gender?.valueOf()
          : this.form.value.gender?.valueOf();
      if (gender !== this.user?.gender?.valueOf()) {
        data.gender = gender;
      }

      if (this.form.value.emergencyContactName !== (this.user?.emergencyContactName || '')) {
        data.emergencyContactName = this.form.value.emergencyContactName;
      }

      if (this.form.value.emergencyPhoneNumber !== (this.user?.emergencyPhoneNumber || '')) {
        data.emergencyPhoneNumber = this.form.value.emergencyPhoneNumber;
      }

      // if (this.form.value.emergencyContactEmail !== (this.user?.emergencyContactEmail || '')) {
      //   data.emergencyContactEmail = this.form.value.emergencyContactEmail;
      // }

      if (this.form.value.emergencyContactRelationship !== (this.user?.emergencyContactRelationship || '')) {
        data.emergencyContactRelationship = this.form.value.emergencyContactRelationship;
      }

      if (!!this.form.value.email && this.form.value.email !== (this.user?.email || '') && this.user?.email) {
        const dialog = this.dialog.open(SimpleDialog, {
          data: {
            showCloseButton: false,
            title: 'Confirm Email Update',
            subtitle: 'Are you sure you want to update email?',
            content:
              "Note: Changing the user's email here may break 3rd party integrations. Please make sure to also update the user's email where needed.",
            buttons: [
              { label: 'No', role: 'no' },
              { label: 'Yes, Update Email', role: 'yes' },
            ],
          } as DialogData,
        });

        if ((await toPromise(dialog.afterClosed())) === 'yes') {
          data.email = this.form.value.email;
        } else {
          this.reset();
          return;
        }
      } else if (this.form.value.email && this.form.value.email !== this.user?.contactEmail && !this.user?.email) {
        const dialog = this.dialog.open(SimpleDialog, {
          data: {
            showCloseButton: false,
            title: 'Child Email Update',
            subtitle: "Do you want to update this child account's email?",
            content:
              "Changing the email address of a child account will not unlink it from parent accounts. Any information sent to the child's email will not be sent to the parent's email.",
            buttons: [
              { label: 'No', role: 'no' },
              { label: 'Yes, Update Email', role: 'yes' },
            ],
          } as DialogData,
        });

        if ((await toPromise(dialog.afterClosed())) === 'yes') {
          data.email = this.form.value.email;
        } else {
          this.reset();
          return;
        }
      } else {
        this.form.patchValue({ email: undefined });
      }

      const shouldUpdate = Object.keys(data).length > 0;
      if (!shouldUpdate && !this.profilePictureFile) return;

      if (shouldUpdate) {
        this.user = await this.userSvc.update(this.user.id, data);
      }

      if (this.profilePictureFile) {
        const formData = new FormData();
        formData.append('file', this.profilePictureFile);
        this.user = await this.userSvc.uploadUserPicture(this.user.id, formData);
      }

      this.reset();

      this.saved.emit();
      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      if (!this.preventReload) location.reload();
    } catch (err: any) {
      this.snacks.open(
        err.error?.message?.includes('Error: User with email')
          ? 'Oops another user with the same email already exists. Note: They may be part of a different community.'
          : 'Oops something went wrong! Make sure you are not missing any fields.',
        'Ok',
        {
          duration: 2500,
          panelClass: 'mat-warn',
        }
      );
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.previousValue !== changes.user?.currentValue) {
      this.reset();
    }
  }

  private reset() {
    this.nowDate = new Date();

    const fullName = this.user?.displayName?.split(' ');
    const firstName = fullName?.shift() || '';
    const lastName = fullName?.join(' ') || '';

    this.resetValue = {
      firstName,
      lastName,
      email: this.user?.email || this.user?.contactEmail,
      friendlyName: this.user?.friendlyName || '',
      phoneNumber: this.user?.phoneNumber || '',
      address: this.user?.address || null,
      gender: this.user?.gender
        ? ['Male', 'Female', 'Non-binary', 'Transgender', 'Intersex', 'Prefer not to say'].includes(this.user?.gender)
          ? this.user?.gender
          : 'Other'
        : null,
      genderOther: this.user?.gender
        ? ['Male', 'Female', 'Non-binary', 'Transgender', 'Intersex', 'Prefer not to say'].includes(this.user?.gender)
          ? null
          : this.user?.gender
        : null,
      birthday: this.user?.birthday || null,
      photoURL: this.user?.photoURL || null,
      emergencyContactName: this.user?.emergencyContactName || null,
      emergencyPhoneNumber: this.user?.emergencyPhoneNumber || '',
      // emergencyContactEmail: this.user?.emergencyContactEmail || null,
      emergencyContactRelationship: this.user?.emergencyContactRelationship || null,
    };

    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  setProfilePicture(file: File | null) {
    if (file) {
      this.profilePictureFile = file;
      this.form.setValue({ ...this.form.value, photoURL: URL.createObjectURL(file) });
    } else {
      this.profilePictureFile = undefined;
      this.form.setValue({ ...this.form.value, photoURL: this.user?.photoURL || null });
    }
  }

  async takePhoto() {
    const image = await toPromise(this.dialog.open(WebcamDialog).afterClosed());
    this.setProfilePicture(image);
  }
}
