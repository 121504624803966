/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingOption,
  BookingRequirement,
  BookingStatus,
  CalendarEvent,
  EventAgreementUsage,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventSeries,
  ResourceType,
  RoomResourceSpot,
  TypeformBookingRequirement,
  UserBookingOptionStats,
} from '@greco/booking-events';
import { AgreementType, UserAgreementDto } from '@greco/community-agreements';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { BookingPreview } from '@greco/nestjs-booking-events';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SignatureService } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { AccountLinkingService } from '@greco/web-account-linking';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { ConfirmAddAttendeeDialog } from '../../../dialogs/confirm-add-attendee/confirm-add-attendee.dialog';
import { BookingService, EventService, ResourcesService } from '../../../services';
import { EventCommunityAgreementsUsageService } from '../../../services/event-community-agreements-usage.service';
import { PreviewBookingBookingOptionPickerComponent } from './booking-option-picker/booking-option-picker.component';

@Component({
  selector: 'greco-preview-booking-2-page',
  templateUrl: './preview-booking-2.page.html',
  styleUrls: ['./preview-booking-2.page.scss'],
})
export class PreviewBooking2Page implements OnDestroy {
  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private formGroup: FormBuilder,
    public bottomSheet: MatBottomSheet,
    private bookingSvc: BookingService,
    private resourceSvc: ResourcesService,
    private linkSvc: AccountLinkingService,
    private signatureSvc: SignatureService,
    private securitySvc: CommunitySecurityService,
    private paymentMethodSvc: UserPaymentMethodService,
    private communityAgreementSvc: CommunityAgreementsService,
    private agreementUsageSvc: EventCommunityAgreementsUsageService
  ) {}

  @Output() booked = new EventEmitter<Booking>(true);
  @Output() previewUpdated = new EventEmitter<BookingPreview | null>();
  @Output() previewLoading = new EventEmitter<boolean>(false);
  @Output() spotSelected = new EventEmitter<string | null>(false);
  @Output() bookingOptionChanged = new EventEmitter(false);

  @Output() requirementsUpdated = new EventEmitter<BehaviorSubject<{ [id: string]: string }>>();
  @Output() signaturesUpdated = new EventEmitter<{ agreementId: string; signature: string }[]>();

  @Input() preventRedirect = false;
  @Input() disableSpotIds: string[] = [];
  @Input() forMultiple = false;
  @Input() readonly = false;

  @Input() event!: CalendarEvent | EventSeries;
  @PropertyListener('event') private event$ = new BehaviorSubject(this.event);

  loadedEvent$ = this.event$.pipe(switchMap(e => this.eventSvc.getOneEvent(e.id)));

  @Input() user?: User = undefined;
  @PropertyListener('user') private user$ = new BehaviorSubject(this.user);

  @Input() bookedBy?: User = undefined;
  @PropertyListener('bookedBy') private bookedBy$ = new BehaviorSubject(this.bookedBy);

  @PropertyListener('overridePaymentMethod') overridePaymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);
  @Input() overridePaymentMethod: PaymentMethod | null = null;

  @Input() hasMultiBookingOptionError = false;
  hasMultiBookingOptionError$ = new BehaviorSubject(false);

  @PropertyListener('hasMultiBookingSpotError') hasMultiBookingSpotError$ = new BehaviorSubject<boolean>(false);
  @Input() hasMultiBookingSpotError = false;

  _overridePaymentMethod$ = this.overridePaymentMethod$.pipe(
    tap(paymentMethod => {
      this.paymentMethodControl.setValue(paymentMethod);
    })
  );

  @Input() isAddAttendee?: boolean = false;
  @Input() allowPendingBookings = false;
  @Input() extraEventIds?: string[] = [];

  @ViewChild(PreviewBookingBookingOptionPickerComponent)
  private bookingOptionPicker?: PreviewBookingBookingOptionPickerComponent;

  bookingOptionId = '';

  agreementsForm = this.formGroup.group({});
  agreementsSigned = 0;
  controlsConfigDetails: { [key: string]: any } = {};
  signatures: { agreementId: string; signature: string }[] = [];

  loading = false;
  confirming = false;
  hideSignLater = false;

  readonly currentUserId$ = this.userSvc.getUserId().pipe(shareReplay(1));

  readonly canManageSpots$ = this.event$.pipe(
    switchMap(async event => {
      if (!event) return false;
      return await this.securitySvc.hasAccess(
        event.community.id,
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.MANAGE_SPOTS
      );
    })
  );

  paymentMethodControl = new FormControl(null);

  readonly canUsePerks$ = combineLatest([this.bookedBy$, this.user$]).pipe(
    switchMap(async ([bookedBy, user]) => {
      if (!bookedBy) return true;
      if (!user) return false;
      if (bookedBy.id === user?.id) return true;
      const link = (await this.linkSvc.getPrivilegeLinksForAccessor(bookedBy.id))?.find(
        link => link.accountId === user.id
      );
      if (!link) return false;
      return link.canUsePerks;
    })
  );

  readonly hasPaymentMethod$ = combineLatest([
    this.bookedBy$,
    this.paymentMethodControl.valueChanges.pipe(startWith(null)),
  ]).pipe(
    switchMap(async ([user, selectedPaymentMethod]) => {
      if (selectedPaymentMethod) return true;

      const paymentMethod = user ? await this.paymentMethodSvc.getDefault(user.id, true) : null;
      return !!paymentMethod && paymentMethod.model !== 'bank';
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private requirementValues$ = new BehaviorSubject<{ [id: string]: string }>({});
  typeformRequirements$ = this.loadedEvent$.pipe(
    map(event => (event?.requirements || []).filter(req => req.type === 'typeform') as TypeformBookingRequirement[])
  );

  refresh$ = new BehaviorSubject(null);
  refreshPreview$ = new BehaviorSubject(null);

  statusFilter = false;
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: IPaginationMeta;
  loaded = false;
  agreementData$ = new BehaviorSubject<(UserAgreementDto & { unsigned?: boolean })[]>([]);

  readonly seriesAgrUsage$ = combineLatest([this.filters$, this.pagination$, this.event$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = false))),
    debounceTime(500),
    switchMap(async ([filters, options, event]) => {
      const agreements: PaginatedDto<EventAgreementUsage> = await this.agreementUsageSvc.paginateEventAgreementUsages(
        event.id,
        options,
        filters
      );
      agreements.items = await Promise.all(
        agreements.items.map(async agreement => {
          const argmnt = await this.communityAgreementSvc.getOne(agreement.agreementId);
          if (agreement.agreement) agreement.agreement.text = argmnt.text;
          return agreement;
        })
      );
      return agreements;
    }),
    tap(agreements => {
      if (this.loaded == false) {
        agreements.items.forEach(agreement => {
          switch (agreement.agreement?.agreementType) {
            case AgreementType.AUTO_CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [true, Validators.requiredTrue];
              this.agreementData$.value.push({ agreementId: agreement.agreementId, signed: true });
              break;
            }
            case AgreementType.CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData$.value.push({ agreementId: agreement.agreementId, signed: false });
              break;
            }
            case AgreementType.DIGITAL_SIGNATURE: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData$.value.push({ agreementId: agreement.agreementId, signed: false });
              break;
            }
          }
        });
      }
      this.loaded = true;
      this.agreementsForm = this.formGroup.group({ ...this.controlsConfigDetails });
    }),
    tap(({ meta }) => setTimeout(() => (this.paginationMeta = meta))),
    map(({ items }) => items),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  readonly canLeaveUnsigned$ = combineLatest([this.bookedBy$, this.user$, this.event$, this.seriesAgrUsage$]).pipe(
    switchMap(async ([bookedBy, bookedFor, event, agreementUsages]) => {
      if (!bookedFor || !bookedBy || !event) return false;

      const controls = this.agreementsForm.controls;
      if (bookedFor?.id !== bookedBy?.id) {
        Object.keys(controls).forEach(control => {
          const agreementId = agreementUsages.find(usage => usage.id === control)?.agreementId;
          if (agreementId) this.setAgreementRequired(control, agreementId, false);
        });

        this.hideSignLater = true;
        return true;
      } else {
        Object.keys(controls).forEach(control => {
          const agreementId = agreementUsages.find(usage => usage.id === control)?.agreementId;
          if (agreementId) this.setAgreementRequired(control, agreementId, true);
        });

        return false;
      }
    })
  );

  readonly bookingOption$ = new ReplaySubject<BookingOption>(1);
  readonly useBookedByPerks$ = new ReplaySubject<boolean>(1);
  readonly spot$ = new BehaviorSubject<RoomResourceSpot | null>(null);

  readonly isAlreadyBooked$ = combineLatest([
    this.event$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
    this.user$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
  ]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    switchMap(async ([event, user]) =>
      event && user
        ? await this.bookingSvc.paginate(
            new RequestQueryBuilder().search({
              'user.id': user.id,
              'event.id': event.id,
              status: { $in: [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.PENDING] },
            }),
            event.community.id,
            { limit: 1 }
          )
        : null
    ),
    map(data => !!data?.items?.[0])
  );

  private selectedPaymentMethod$ = this.paymentMethodControl.valueChanges.pipe(
    startWith<PaymentMethod | null, PaymentMethod | null>(null),
    switchMap(pm => {
      if (pm) return of(pm);
      return this.bookedBy$.pipe(
        switchMap(user => (user ? this.paymentMethodSvc.getDefault(user.id, true) : of(null))),
        map(pm => pm ?? null)
      );
    }),
    distinctUntilChanged((prev, next) => prev?.id === next?.id)
  );

  room$ = combineLatest([this.event$, this.canManageSpots$]).pipe(
    switchMap(async ([event, isStaff]) => {
      const roomAssignment = event.resourceAssignments.find(
        assignment => assignment?.resource?.type === ResourceType.ROOM
      );
      if (!roomAssignment || !roomAssignment.resource || (!event.enableUserSpotBooking && !isStaff)) {
        this.spot$.next(null);
        this.spotSelected.emit(null);
        return null;
      }
      return await this.resourceSvc.getRoomResource(roomAssignment.resource.id);
    })
  );
  readonly userAndBookedBy$ = combineLatest([this.user$, this.bookedBy$]).pipe(
    map(([user, bookedBy]) => {
      return { user, bookedBy };
    })
  );
  preview!: BookingPreview | null;

  readonly preview$: Observable<BookingPreview | null> = combineLatest([
    this.isAlreadyBooked$ as Observable<any>,
    this.userAndBookedBy$,
    this.event$,
    this.bookingOption$.pipe(
      tap(option => {
        this.bookingOptionId = option.id;
        this.bookingOptionChanged.emit(option.id);
      })
    ),
    this.spot$,
    this.requirementValues$,
    this.selectedPaymentMethod$,
    this.agreementData$,
    this.seriesAgrUsage$,
    this.hasMultiBookingOptionError$,
  ]).pipe(
    tap(() =>
      setTimeout(() => {
        this.loading = true;
        this.previewLoading.emit(true);
      })
    ),
    map(
      ([
        _isAlreadyBooked,
        userAndBookedBy,
        event,
        bookingOption,
        spot,
        reqs,
        pm,
        agreements,
        _series,
        hasMultiBookingOptionError,
      ]) => {
        const { user, bookedBy } = userAndBookedBy;
        if (!user || !event || !bookingOption) return null;

        const requirements = Object.entries((reqs as { [id: string]: string }) || {}).reduce(
          (acc, [key, value]) => [...acc, { requirementId: key, value }],
          [] as { requirementId: string; value: string }[]
        );
        const useBookedByPerks = this.bookingOptionPicker?.bookedByPerksAreSelected || false;
        const bookingOptionStats: UserBookingOptionStats | undefined = useBookedByPerks
          ? this.bookingOptionPicker?._bookedByStats?.[bookingOption.id]
          : this.bookingOptionPicker?._stats?.[bookingOption.id];
        const bookingOptionAvailable = !!(bookingOptionStats?.consumable || bookingOptionStats?.reusable);
        const isComplimentary: boolean = bookingOption.id === this.bookingOptionPicker?.complimentaryBookingOption?.id;

        return {
          completed: (bookingOptionAvailable && !hasMultiBookingOptionError) || isComplimentary ? true : false, // False => pending
          bookingOptionId: bookingOption.id,
          paymentMethodId: pm?.id,
          applyBalance: true,
          eventId: event.id,
          userId: user.id,
          useBookedByPerks,
          bookedById: bookedBy?.id || user.id,
          requirements: [...requirements], //distinctUntilChanged() doesn't recognize change unless we create a new object/array
          agreements: [...agreements],
          spotId: spot ? spot.id : null,
          allowPendingBookings: this.allowPendingBookings,
          hasMultiBookingOptionError: hasMultiBookingOptionError,
        };
      }
    ),
    switchMap(dto => {
      return this.refreshPreview$.pipe(map(() => dto));
    }),
    switchMap(async dto => {
      if (!dto) return null;
      return await this.bookingSvc.preview(dto).catch(() => null);
    }),
    tap(() =>
      setTimeout(() => {
        this.loading = false;
        this.previewLoading.emit(false);
      })
    ),
    tap(async preview => {
      if (preview?.errors.includes('Event already full')) {
        await this.joinWaitlist();
      }

      if (preview?.errors.includes('All agreements must be signed')) {
        const allSigned = this.agreementData$.value.every(agreement => agreement.signed || agreement.unsigned);

        if (allSigned) {
          preview.errors = preview.errors.filter(error => error !== 'All agreements must be signed');
        }
      }
    }),
    tap(preview => this.previewUpdated.emit(preview)),
    shareReplay(1)
  );

  hasBookingOptions$ = this.preview$.pipe(
    map(preview => {
      preview?.booking.bookingOption ? true : false;
    })
  );

  requirementFilledIn(requirement: BookingRequirement, value: string) {
    this.requirementValues$.next({ ...this.requirementValues$.value, [requirement.id]: value });
    this.requirementsUpdated.emit(this.requirementValues$);
  }

  async leaveWaitlist() {
    const event = this.event;
    if (!event) return;

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Leave Waitlist',
        showCloseButton: false,
        subtitle: `${event.title} - ${moment(event.startDate).format('ll hh:mm A')}`,
        content: 'If you leave the waitlist you will no longer be notified as soon as a spot opens up.',
        buttons: [
          { label: 'No, stay in the waitlist', role: 'no' },
          { label: 'Yes, leave the waitlist', role: 'yes' },
        ],
      } as DialogData,
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const userId = await toPromise(this.userSvc.getUserId());
      if (!userId) return;
      await this.bookingSvc.removeFromWaitlist(event.community.id, { userId, eventId: event.id });

      if (!this.preventRedirect) await this.router.navigate(['/']);
      this.snacks.open('Removed from waitlist!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    }
  }

  async joinWaitlist() {
    const event = this.event;
    if (!event) return;

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Join Waitlist',
        showCloseButton: false,
        subtitle: `${event.title} - ${moment(event.startDate).format('ll hh:mm A')}`,
        content: 'The event is currently fully booked. Join the waitlist to be notified as soon as a spot opens up.',
        buttons: [
          { label: "No, I don't want to join the waitlist", role: 'no' },
          { label: 'Yes, add me to the waitlist', role: 'yes' },
        ],
      } as DialogData,
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const userId = await toPromise(this.userSvc.getUserId());
      if (!userId) return;
      await this.bookingSvc.joinWaitlist({ userId, eventId: event.id });

      if (!this.preventRedirect) await this.router.navigate(['/']);
      this.snacks.open('Added to waitlist!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    }
  }

  async confirmBooking(preview: BookingPreview | null) {
    if (!preview || this.confirming) return;

    const agreementControlKeys = Object.keys(this.agreementsForm.controls);
    const leftUnsigned = agreementControlKeys.some(key => !this.agreementsForm.controls[key].value);

    if (leftUnsigned) {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Unsigned Agreements',
          content:
            'This booking has an agreement that is currently unsigned. Let the member know that before completing any bookings or purchases, they will need to ensure all their agreements are signed',
          buttons: [
            { label: 'Cancel', role: 'no' },
            { label: 'Confirm', role: 'yes', color: 'primary' },
          ],
        } as DialogData,
        width: '500px',
        maxWidth: '100%',
      });

      if ((await toPromise(dialog.afterClosed())) === 'no') return;
    }

    this.confirming = true;

    // const user = preview.booking.user;
    // if (
    //   !user.displayName ||
    //   !user.phoneNumber ||
    //   !user.birthday ||
    //   !user.emergencyContactEmail ||
    //   !user.emergencyContactName ||
    //   !user.emergencyContactRelationship
    // ) {
    //   await this.completeUserProfile(user);
    // }
    try {
      if (preview.warnings?.length) {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: 'Warnings:',
            content: preview.warnings.map(warn => `<p>${warn}</p>`).join(''),
            buttons: [
              { label: 'No, I want to make some changes first', role: 'no' },
              { label: 'Yes, Confirm the Booking', role: 'yes' },
            ],
          } as DialogData,
        });

        if ((await toPromise(dialog.afterClosed())) === 'no') {
          this.confirming = false;
          return;
        }
      }

      const requirements = Object.entries(this.requirementValues$.value || {}).reduce(
        (acc, [key, value]) => [...acc, { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );

      await Promise.all(
        this.signatures.map(async linkedSignature => {
          try {
            await this.signatureSvc.getOne(preview.booking.user.id);
            await this.signatureSvc.update(preview.booking.user.id, linkedSignature.signature);
          } catch (err) {
            console.log('No signature found, creating one. Error:', err);
            await this.signatureSvc.create({ userId: preview.booking.user.id, signature: linkedSignature.signature });
          }
        })
      );
      let booking = null;
      let dialog = null;

      if (this.isAddAttendee) {
        dialog = await this.matDialog
          .open(ConfirmAddAttendeeDialog, {
            data: { title: 'Confirm Adding Attendee', userName: this.user?.displayName, eventTitle: this.event?.title },
            width: '500px',
            maxWidth: '90%',
          })
          .afterClosed()
          .toPromise();

        if (dialog === 'confirm')
          booking = await this.bookingSvc.confirm({
            booking: { ...preview.dto, requirements },
            purchaseHash: preview.purchase?.hash,
            bookingHash: preview.hash,
          });
        this.confirming = false;
      }

      if (!this.isAddAttendee) {
        booking = await this.bookingSvc.confirm({
          booking: { ...preview.dto, requirements },
          purchaseHash: preview.purchase?.hash,
          bookingHash: preview.hash,
        });
      }

      if (this.extraEventIds?.length) {
        await Promise.all(
          this.extraEventIds.map(async eventId => {
            const firstPreview = preview.dto;
            const extraPreview = await this.bookingSvc.preview({
              eventId,
              completed: firstPreview.completed,
              bookingOptionId: preview.dto.bookingOptionId,
              paymentMethodId: firstPreview.paymentMethodId,
              applyBalance: true,
              userId: firstPreview.userId,
              bookedById: firstPreview.bookedById,
              requirements,
              agreements: firstPreview.agreements,
              spotId: firstPreview.spotId,
              allowPendingBookings: this.allowPendingBookings,
              hasMultiBookingOptionError: this.hasMultiBookingOptionError$.value,
            });
            await this.bookingSvc.confirm({
              booking: { ...extraPreview.dto, requirements },
              purchaseHash: extraPreview.purchase?.hash,
              bookingHash: extraPreview.hash,
            });
          })
        );
      }

      if (booking?.status == BookingStatus.CONFIRMED || booking?.status == BookingStatus.PENDING) {
        if (!this.preventRedirect) await this.router.navigate(['/']);
        this.snacks.open(this.preventRedirect ? 'Attendee added!' : 'Event added to your schedule!', 'Ok', {
          duration: 6000,
          panelClass: 'mat-primary',
        });
        this.booked.emit(booking);
      } else {
        const msg = booking?.purchases?.[0]?.failureReason || '';
        this.snacks.open(`Oops, failed to confirm ${this.preventRedirect ? 'this' : 'your'} booking! ${msg}`, 'Ok', {
          duration: 10000,
          panelClass: 'mat-warn',
        });
      }
    } catch (err: any) {
      const msg = 'message' in err.error ? err.error.message : '' + err;
      this.snacks.open(`Oops, failed to confirm ${this.preventRedirect ? 'this' : 'your'} booking! ${msg}`, 'Ok', {
        duration: 10000,
        panelClass: 'mat-warn',
      });

      if (msg.includes('Event already full')) {
        await this.joinWaitlist();
      }
    }

    this.confirming = false;
  }

  setCheck(usageId: string, agreementId: string) {
    const checked = this.agreementsForm.get(usageId)?.value;
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);
    this.agreementsForm.patchValue({ [`${usageId}`]: !checked });
    this.agreementData$.value[index] = { agreementId, signed: !checked };
    this.agreementData$.next(this.agreementData$.value);
  }

  setAgreementRequired(usageId: string, agreementId: string, required: boolean) {
    const control = this.agreementsForm.controls[usageId];

    if (required) control.setValidators(Validators.requiredTrue);
    else {
      control.setErrors(null);
      control.clearValidators();
    }

    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);
    this.agreementData$.value[index].unsigned = !required;
    this.agreementData$.next(this.agreementData$.value);
  }

  setSignature(signature: string, usageId: string, agreementId: string) {
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);

    const linkedSignature = this.signatures.findIndex(signature => signature.agreementId === agreementId);
    if (linkedSignature !== -1) this.signatures[linkedSignature] = { agreementId, signature };
    else this.signatures.push({ agreementId, signature });

    if (signature !== '') {
      this.agreementsForm.patchValue({ [`${usageId}`]: true });
      this.agreementData$.value[index] = { agreementId, signed: true };
    } else {
      this.agreementsForm.patchValue({ [`${usageId}`]: false });
      this.agreementData$.value[index] = { agreementId, signed: false };
    }
    this.agreementData$.next(this.agreementData$.value);
    this.signaturesUpdated.emit(this.signatures);
  }

  // async completeUserProfile(user: User) {
  //   if (!user) return;
  //   const dialog = this.matDialog.open(AccountCompletionDialog, {
  //     data: { user: user },
  //   });
  //   return await dialog.afterClosed().toPromise();
  // }

  ngOnDestroy() {
    this.event$.complete();
    this.bookingOption$.complete();
    this.spot$.complete();
  }
}
