import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ErrorStateMatcher } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { heightExpansion } from '@greco/ui-animations';
import { ErrorDialog } from '@greco/ui-dialog-simple';
import firebase from 'firebase/app';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PasswordResetDialog } from '../../dialogs/password-reset/password-reset.dialog';
import { UserService } from '../../services/user.service';

export interface SignInConfig {
  allowAnonymous?: boolean;
  allowFacebook?: boolean;
  allowGoogle?: boolean;
  preventRegister?: boolean;
  preventSignIn?: boolean;
  startOnRegister?: boolean;
  collectPhoneNumber?: boolean;
  initialValues?: {
    displayName?: string;
    email?: string;
  };
  linkTarget?: string;
  externalRegistrationLink?: string | null;
  externalRegistrationLabel?: string | null;
  hideTabs?: boolean;

  googleTokenFetch?: () => Promise<string>;

  facebookTokenFetch?: () => Promise<string>;

  onSuccess?: (creds: firebase.auth.UserCredential, router: Router) => Promise<any>;

  beforeSignIn?: () => boolean | Promise<boolean>;
}

@Component({
  selector: 'greco-sign-in-fields',
  templateUrl: './sign-in-fields.component.html',
  styleUrls: ['./sign-in-fields.component.scss'],
  animations: [heightExpansion],
})
export class SignInFieldsComponent implements OnInit, OnDestroy {
  @Output() public successfulSignIn = new EventEmitter<User>();
  @Input() public config!: SignInConfig;

  public formGroup: FormGroup;
  public matcher = new ErrorStateMatcher();

  public tabIndex = 0;
  public passwordVisible = false;

  public user$?: Observable<User>;

  public processingCode = false;
  public verificationError = '';

  onDestroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e instanceof NavigationEnd),
        map(e => e as NavigationEnd)
      )
      .subscribe(_e => {
        const form = new URLSearchParams(window.location.search).get('form') || 'sign-in';
        this.tabIndex = form === 'sign-in' ? 0 : 1;
      });

    const firstName = this.route.snapshot.queryParamMap.get('firstName');
    const lastName = this.route.snapshot.queryParamMap.get('lastName');

    const email = this.route.snapshot.queryParamMap.get('email');
    const phoneNumber = this.route.snapshot.queryParamMap.get('phoneNumber');
    const form = this.route.snapshot.queryParamMap.get('form') || 'sign-in';
    this.tabIndex = form === 'sign-in' ? 0 : 1;

    this.formGroup = this.fb.group({
      firstName: new FormControl(
        firstName || '',
        (control: AbstractControl) =>
          (this.tabIndex === 1 && !control.value ? { required: 'First Name is required' } : null) as ValidationErrors
      ),
      lastName: new FormControl(
        lastName || '',
        (control: AbstractControl) =>
          (this.tabIndex === 1 && !control.value ? { required: 'Last Name is required' } : null) as ValidationErrors
      ),
      phoneNumber: new FormControl(phoneNumber || '', []),
      email: new FormControl(email || '', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    if (this.config?.startOnRegister) this.tabIndex = 1;
    if (this.config?.collectPhoneNumber) {
      this.formGroup
        .get('phoneNumber')
        ?.setValidators([
          (control: AbstractControl) =>
            (this.tabIndex === 1 && !control.value
              ? { required: 'Phone Number is required' }
              : null) as ValidationErrors,
        ]);
    }
  }

  async signOut() {
    await this.userService.signOut();
  }

  resetPassword() {
    this.dialog.open(PasswordResetDialog, { data: { email: this.formGroup.get('email')?.value } });
  }

  async submit() {
    const allowed = await this.validateSignInRequest();
    if (!allowed) return;

    let p: Promise<any>;
    const email = this.formGroup.get('email')?.value;
    const password = this.formGroup.get('password')?.value;
    const displayName = `${this.formGroup.get('firstName')?.value.trim()} ${this.formGroup
      .get('lastName')
      ?.value.trim()}`;
    const phoneNumber = this.formGroup.get('phoneNumber')?.value;

    switch (this.tabIndex) {
      case 0: {
        p = this.userService.signIn(email, password);
        break;
      }
      case 1:
      default: {
        p = this.userService.register(email, password, { displayName, phoneNumber });
        break;
      }
    }

    await p
      .then(async (userCreds: firebase.auth.UserCredential) => {
        await this.success(userCreds);
      })
      .catch(async err => {
        console.error(err);
        this.dialog.open(ErrorDialog, { data: { error: err } });
      });
  }

  async google() {
    const allowed = await this.validateSignInRequest();
    if (!allowed) return;

    const credentials = await this.userService.signIn(
      this.config.googleTokenFetch
        ? firebase.auth.GoogleAuthProvider.credential(await this.config.googleTokenFetch())
        : new firebase.auth.GoogleAuthProvider()
    );
    await this.success(credentials);
  }

  private async validateSignInRequest(): Promise<boolean> {
    if (typeof this.config.beforeSignIn === 'function') return await this.config.beforeSignIn();
    return true;
  }

  private async success(creds: firebase.auth.UserCredential) {
    const user = creds.user?.uid ? await this.userService.getUser(creds.user.uid) : null;
    if (user) this.successfulSignIn.emit(user);
    if (this.config?.onSuccess) await this.config.onSuccess(creds, this.router);
  }
}
