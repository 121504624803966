import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateVariantPerkDto } from '@greco/nestjs-sales-products';
import {
  Product,
  ProductVariant,
  VariantPerk,
  VariantPerkExpiryEvent,
  VariantPerkGranted,
} from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import { VariantsService } from '../../services';

const IntegerValidator: ValidatorFn = control =>
  control.value ? (!(control.value % 1) ? null : { integer: true }) : null;

@Component({
  selector: 'greco-update-variant-perk',
  templateUrl: './update-variant-perk.dialog.html',
  styleUrls: ['./update-variant-perk.dialog.scss'],
})
export class UpdateVariantPerkDialog implements OnInit {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private variantSvc: VariantsService,
    private dialogRef: MatDialogRef<UpdateVariantPerkDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { product: Product; variant: ProductVariant; variantPerk: VariantPerk }
  ) {}

  dialogData: DialogData = {
    title: 'Update Perk',
    subtitle: 'Fill out the necessary fields to update this variant perk',
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({
    quantity: [null, [Validators.min(1), IntegerValidator]],
    granted: [null, [Validators.required, Validators.minLength(1)]],
    expiryDelay: [null, [Validators.min(1), IntegerValidator]],
    expiryEvent: [null, Validators.minLength(1)],
    transferable: [null],
    reusable: [false],
    freezable: [true],
  });
  resetValue: any = {
    quantity: null,
    granted: null,
    expiryDelay: null,
    expiryEvent: null,
    transferable: null,
    reusable: false,
    freezable: true,
  };

  processing = false;

  ngOnInit() {
    this.reset();
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const formData = this.form.value;
      const perkData = this.data.variantPerk;
      const data: UpdateVariantPerkDto = {};

      if (formData.quantity !== perkData.quantity) data.quantity = formData.quantity;

      let formLength = formData.granted.length;
      let perkLength = perkData.granted.length;
      let lengthsAreDiff = formLength !== perkLength;
      let lengthsAreOne = formLength === 1 && perkLength === 1;
      let valuesAreDiff = formData.granted[0] !== perkData.granted[0];

      if (lengthsAreDiff || (lengthsAreOne && valuesAreDiff)) {
        data.granted = formData.granted as VariantPerkGranted[];
      }

      if (formData.expiryDelay !== perkData.expiryDelay) data.expiryDelay = formData.expiryDelay;

      if ((!formData.expiryEvent.length && perkData.expiryEvent) || (formData.expiryEvent && !perkData.expiryEvent)) {
        data.expiryEvent = formData.expiryEvent;
      } else if (formData.expiryEvent && perkData.expiryEvent) {
        formLength = formData.expiryEvent.length;
        perkLength = perkData.expiryEvent.length;
        lengthsAreDiff = formLength !== perkLength;
        lengthsAreOne = formLength === 1 && perkLength === 1;
        valuesAreDiff = formData.expiryEvent[0] !== perkData.expiryEvent[0];

        if (lengthsAreDiff || (lengthsAreOne && valuesAreDiff)) {
          data.expiryEvent = formData.expiryEvent as VariantPerkExpiryEvent[];
        }
      }

      if (formData.transferable !== perkData.transferable) data.transferable = formData.transferable;
      if (formData.reusable !== perkData.reusable) data.reusable = formData.reusable;
      if (formData.freezable !== perkData.freezable) data.freezable = formData.freezable;

      if (!Object.keys(data).length) throw new Error("You haven't made any changes");

      this.data.variantPerk = await this.variantSvc.updateVariantPerk({
        variantPerkId: this.data.variantPerk.id,
        productId: this.data.product.id,
        variantId: this.data.variant.id,
        data,
      });

      this.close(this.data.variantPerk);
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
      this.close(null);
    }

    this.processing = false;
  }

  reset() {
    this.resetValue = {
      quantity: this.data.variantPerk.quantity,
      granted: this.data.variantPerk.granted,
      expiryDelay: this.data.variantPerk.expiryDelay,
      expiryEvent: this.data.variantPerk.expiryEvent,
      transferable: this.data.variantPerk.transferable,
      reusable: this.data.variantPerk.reusable,
      freezable: this.data.variantPerk.freezable,
    };
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }
}
