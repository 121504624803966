import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LetModule } from '@greco/ngx-let-util';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
// import { UserAvatarModule } from '@greco-fit/core/identity-old/ngx'; // TODO(adaoust): Refactor
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SmartImgModule } from '@greco/ui-smart-img';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { SignInFieldsComponent } from './components/fields/sign-in-fields.component';
import { ProfileButtonComponent } from './components/profile-button/profile-button.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { PasswordResetDialog } from './dialogs/password-reset/password-reset.dialog';
import { QRCodesDialog } from './dialogs/qr-codes/qr-codes.dialog';

export * from './components/sign-in/sign-in.component';
export * from './dialogs/qr-codes/qr-codes.dialog';

@NgModule({
  imports: [
    CommonModule,
    QRCodeModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    SimpleDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    SmartImgModule,
    UserAvatarModule,
    MatMenuModule,
    MatSelectModule,
    NgxMaskModule.forChild(),
    NgxMaskModule,
    NgPipesModule,
    LetModule,
  ],
  providers: [],
  exports: [SignInComponent, ProfileButtonComponent, QRCodesDialog],
  declarations: [SignInComponent, SignInFieldsComponent, PasswordResetDialog, ProfileButtonComponent, QRCodesDialog],
})
export class AuthModule {}
