import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import type { PurchasePreview } from '@greco/nestjs-sales-purchases';
import { ContactService } from '@greco/ngx-identity-contacts';
import { PurchaseStatus } from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { PurchaseService, UserBalanceService } from '../../services';
import { FundUserBalanceConfimationDialog } from '../fund-user-balance-confimation-dialog/fund-user-balance-confimation.dialog';

export interface FundUserBalanceDialogData {
  user: User;
  accountId: string;
  community: Community;
}

@Component({
  selector: 'greco-fund-user-balance-dialog',
  templateUrl: './fund-user-balance-dialog.dialog.html',
  styleUrls: ['./fund-user-balance-dialog.dialog.scss'],
})
export class FundUserBalanceDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FundUserBalanceDialogData,
    private dialogRef: MatDialogRef<FundUserBalanceDialogData>,
    private formBuilder: FormBuilder,
    private contactSvc: ContactService,
    private balanceSvc: UserBalanceService,
    private purchaseSvc: PurchaseService,
    private snacks: MatSnackBar,
    private matDialog: MatDialog
  ) {
    data.user === undefined ? this.dialogRef.close(null) : (this.user = data.user);
    this.community = data?.community || null;
    this.showCommunityPicker = !this.community;
    this.dialogData.subtitle =
      'Add funds to ' +
      (this.showCommunityPicker && this.user ? 'your ' : this.user ? this.user.displayName + "'s " : 'an ') +
      'account balance ' +
      (this.showCommunityPicker && this.user ? '' : 'in ' + (this.community ? this.community.name : ' a community'));
    this.preview$.subscribe();
  }

  private user$ = new BehaviorSubject<User>(this.data?.user);
  set user(user: User) {
    user === undefined ? this.dialogRef.close(null) : this.user$.next(user);
  }
  get user() {
    return this.user$.value;
  }
  private community$ = new BehaviorSubject<Community | null>(null);
  set community(community: Community | null) {
    this.community$.next(community);
    this.form.setValue({ ...this.form.value, community: community }, { emitEvent: true });
  }
  get community() {
    return this.community$.value;
  }
  processing = false;
  typing = false;
  showCommunityPicker?: boolean;
  subtitle?: string;

  private onDestroy$ = new Subject<void>();

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  dialogData: DialogData = {
    title: 'Fund Account',
    subtitle: 'Add funds to your account balance',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  form = this.formBuilder.group({
    community: [null, Validators.required],
    amount: [0, [Validators.required, Validators.min(0.51)]],
    paymentMethod: [null, Validators.required],
  });

  balance$ = this.form.valueChanges.pipe(
    switchMap(async value => {
      const balances = await this.balanceSvc.getUserBalance(this.user.id);
      return value.community ? balances.find(balance => value.community.name === balance.account.name) : null;
    }),
    map(balance => balance?.amount || 0)
  );

  communities$ = this.user$.pipe(
    switchMap(async user => {
      const userId = user?.id || null;
      return userId ? await this.contactSvc.getUserContacts(userId) : [];
    }),
    map(data => data.map(contact => contact.community).sort((a, b) => a.name.localeCompare(b.name))),
    tap(communities => {
      const selectedCommunity = this.data?.accountId
        ? communities.find(c => c.financeAccountId === this.data?.accountId)
        : communities[0];
      this.form.get('community')?.setValue(selectedCommunity);
    }),
    shareReplay(1)
  );

  preview$ = this.form.valueChanges.pipe(
    tap(() => (this.typing = true)),
    switchMap(async value => {
      if (this.form.invalid || !this.user) return null;

      return await this.balanceSvc.previewFundUserBalance({
        accountId: value.community.financeAccountId,
        amount: Math.round(value.amount * 100),
        paymentMethodId: value.paymentMethod.id,
        userId: this.user.id,
      });
    }),
    tap(() => (this.typing = false))
  );

  async submit(preview: PurchasePreview | null) {
    if (!preview) return;

    const dialog = this.matDialog.open(FundUserBalanceConfimationDialog, {
      data: { userId: this.user.id, communityId: this.community?.name, amount: this.form.value.amount * 100 },
    });
    const result = await toPromise(dialog.afterClosed());
    if (!result) return;

    this.processing = true;

    let purchase = await this.purchaseSvc.createPurchase(preview.dto, preview.hash, []);
    purchase = await this.purchaseSvc.handleTerminalPayment(purchase, this.dialogRef.afterClosed());

    if (purchase.status === PurchaseStatus.PAID) {
      this.snacks.open('Payment Success! Your balance will be available shortly.', 'Ok', {
        panelClass: 'mat-primary',
        duration: 5000,
      });
    } else {
      this.snacks.open('Oops! Something went wrong, please try again', 'Ok', {
        panelClass: 'mat-warn',
        duration: 5000,
      });
    }

    this.processing = false;
    this.dialogRef.close(purchase);
  }

  close() {
    this.dialogRef.close(null);
  }
}
