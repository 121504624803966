import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { PurchaseService, RetryFailedPurchaseDialog } from '@greco/ngx-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase, PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { VoidPurchaseDialog } from '../../dialogs';

@Component({
  selector: 'greco-outstanding-purchase-card',
  templateUrl: './outstanding-purchase-card.component.html',
  styleUrls: ['./outstanding-purchase-card.component.scss'],
})
export class OutstandingPurchaseCardComponent {
  constructor(
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private purchaseSvc: PurchaseService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('OutstandingPurchase') private OutstandingPurchase$ = new BehaviorSubject<Purchase | null>(null);
  @Input() outstandingPurchase!: Purchase;

  @Input() userSide = false;

  @Output() refreshPurchase = new EventEmitter<string>();

  canVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true);
  canRetry$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.RETRY, {}, true);

  canRefund$ = this.OutstandingPurchase$.pipe(
    switchMap(async purchase => {
      if (this.userSide) return false;

      const [canRefundToBalance, canRefundToPaymentMethod] = await Promise.all([
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_BALANCE, {}, true),

        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_PAYMENT_METHOD, {}, true),
      ]);

      if (!canRefundToBalance && !canRefundToPaymentMethod) return false;

      if (!canRefundToBalance && !purchase?.payment) return false;

      return true;
    })
  );

  async retryFailedPurchase(purchaseRef: Purchase) {
    const purchase = await this.purchaseSvc.getOne(purchaseRef.id);
    await toPromise(this.dialog.open(RetryFailedPurchaseDialog, { data: purchase }).afterClosed());
    this.refreshPurchase.emit('RetriedPurchase');
  }

  async voidPurchase(purchase: Purchase) {
    if (this.userSide) return;

    const closed = await toPromise(this.dialog.open(VoidPurchaseDialog, { data: purchase }).afterClosed());
    if (closed.submit) this.refreshPurchase.emit('VoidedPurchase');
  }

  async sendPurchaseFailedEmail(purchaseId: string) {
    if (this.userSide) return;

    await this.purchaseSvc.sendPurchaseFailedEmail(purchaseId);
    this.snacks.open('Email has been sent', 'Ok', { duration: 3500, panelClass: 'mat-primary' });
  }
}
