/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentMethod } from '@greco/finance-payments';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { SaleCategoryService } from '@greco/ngx-sales-purchases';
import { TerminalService } from '@greco/ngx-stripe-payment-gateway';
import { PropertyListener } from '@greco/property-listener-util';
import { SaleCategory } from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CheckoutStationService } from '../../services';

@Component({
  selector: 'greco-update-checkout-station-dialog',
  templateUrl: './update-checkout-station.dialog.html',
  styleUrls: ['./update-checkout-station.dialog.scss'],
})
export class UpdateCheckoutStationDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private terminalSvc: TerminalService,
    private categorySvc: SaleCategoryService,
    private stationSvc: CheckoutStationService,
    private dialogRef: MatDialogRef<UpdateCheckoutStationDialog>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      communityId: string;
      accountId: string;
      stationId: string;
    }
  ) {
    this.communityId = data.communityId;
    this.accountId = data.accountId;
    this.stationId = data.stationId;
  }

  @PropertyListener('accountId') accountId$ = new BehaviorSubject<string | null>(null);
  accountId: string;

  @PropertyListener('stationId') stationId$ = new BehaviorSubject<string | null>(null);
  stationId: string;

  billedTo?: User;

  communityId: string;

  dialogData: DialogData = {
    title: 'Update Checkout Station',
    subtitle: 'Checkout stations provide a more traditional POS checkout experience for staff to sell products.',
    buttons: [
      { label: 'Cancel', role: 'cancel', resultFn: () => this.close() },
      { label: 'Save', role: 'create', resultFn: () => this.submit() },
    ],
    hideDefaultButton: false,
  };

  form = this.formBuilder.group({
    title: [null as string | null, Validators.required],
    saleCategories: [null as SaleCategory[] | null, Validators.required],
    defaultUser: [null as Contact | null],
    terminals: [null as PaymentMethod[] | null],
    includeUncategorized: [false],
  });

  saleCategories$ = this.accountId$.pipe(
    switchMap(async accountId => {
      if (!accountId) {
        return [];
      }
      return await this.categorySvc.getMany(accountId);
    })
  );

  terminals$ = this.accountId$.pipe(
    switchMap(accountId => this.terminalSvc.getTerminalPaymentMethods(accountId ?? undefined))
  );

  station$ = this.stationId$.pipe(
    switchMap(async stationId => {
      if (!stationId) return null;

      return await this.stationSvc.getCheckoutStation(stationId);
    }),
    tap(station => {
      if (!station) this.form.reset();

      this.form.patchValue({
        title: station?.title || null,
        saleCategories: station?.saleCategories || [],
        defaultUser: station?.defaultUser || null,
        terminals: station?.terminals || [],
        includeUncategorized: station?.includeUncategorized || false,
      });
    })
  );

  async close() {
    return this.dialogRef.close();
  }

  async submit() {
    const title = this.form.value.title;
    const saleCategories = this.form.value.saleCategories;
    const includeUncategorized = this.form.value.includeUncategorized;
    const defaultUser = this.form.value.defaultUser;
    const terminals = this.form.value.terminals;

    const checkoutStation = await this.stationSvc.updateCheckoutStation(this.stationId, {
      title,
      includeUncategorized,
      defaultUserId: defaultUser?.id,
      terminals: terminals.map((t: PaymentMethod) => t.id),
      saleCategoryIds: saleCategories.map((sc: SaleCategory) => sc.id),
    });

    if (checkoutStation) {
      this.snacks.open(`${title} updated!`, 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(checkoutStation);
    }
  }

  compareSaleCategories(c1: SaleCategory, c2: SaleCategory) {
    return c1.id === c2.id;
  }
}
