import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco/ui-dialog-simple';
import { CondOperator } from '@nestjsx/crud-request';
import { Filter } from '../../models';

const OperatorValidator: ValidatorFn = (control: AbstractControl) => {
  return !Object.values(CondOperator).includes(control.value) ? { operator: true } : null;
};

@Component({
  selector: 'greco-filter3-dialog',
  templateUrl: './filter.dialog.html',
  styleUrls: ['./filter.dialog.scss'],
})
export class FilterDialog {
  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<FilterDialog>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { filter: Filter; value: null | any; operator: null | CondOperator }
  ) {}

  readonly simpleDialogData: DialogData = {
    title: 'Filter',
    subtitle: this.data.filter.label,
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({
    operator: [
      this.data.operator || this.data.filter.allowedOperators[0] || null,
      [Validators.required, OperatorValidator],
    ],
    value: [this.data.value || null, Validators.required],
  });

  selectOption(option: any) {
    if ((this.form.value.value as any[])?.map(item => item.id).includes(option?.id)) {
      this.form.patchValue({ value: (this.form.value.value as any[]).filter(item => item.id !== option?.id) });
    } else {
      if (this.form.value.value?.length) {
        this.form.patchValue({ value: [...this.form.value.value, option] });
      } else {
        this.form.patchValue({ value: [option] });
      }
    }
  }

  cancel() {
    this.matDialogRef.close();
  }

  submit() {
    this.matDialogRef.close({
      ...this.data,
      operator: this.form.value.operator,
      value: this.form.value.value,
    });
  }
}
