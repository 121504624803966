import { ContentChild, Directive, HostListener, Input, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuItem } from '@angular/material/menu';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { SubscriptionsService } from '../services';

@Directive({
  exportAs: 'grecoExportSubscriptions',
  selector: `
    button[mat-stroked-button][grecoExportSubscriptions],
    button[mat-raised-button][grecoExportSubscriptions],
    button[mat-flat-button][grecoExportSubscriptions],
    button[mat-icon-button][grecoExportSubscriptions],
    button[mat-menu-item][grecoExportSubscriptions],
    button[mat-mini-fab][grecoExportSubscriptions],
    button[mat-button][grecoExportSubscriptions],
    button[mat-fab][grecoExportSubscriptions]
  `,
})
export class ExportSubscriptionsDirective {
  constructor(
    @Optional() private _button: MatButton,
    @Optional() private _item: MatMenuItem,
    private subscriptionSvc: SubscriptionsService,
    private dialog: MatDialog
  ) {}

  @ContentChild(MatIcon) private _icon?: MatIcon;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('grecoExportSubscriptions') config?: {
    filters?: RequestQueryBuilder;
    action?: RequestQueryBuilder;
    accountId?: string;
    userId?: string;
    data?: any;
    productVariantIds?: string[];
  };

  private get host() {
    return this._button || this._item;
  }

  @HostListener('click', ['$event'])
  async onclick(event: MouseEvent) {
    event.stopImmediatePropagation();

    this.host.disabled = true;
    const currentIcon = this._icon?._elementRef?.nativeElement?.innerText;
    if (this._icon) {
      this._icon._elementRef.nativeElement.innerText = 'loop';
      this._icon._elementRef.nativeElement.classList.add('spin');
    }

    try {
      InitiateExportDialog.open(this.dialog, {
        processorId: 'SubscriptionsDataExportProcessor',
        initiateExport: (skip?: number) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return this.subscriptionSvc.exportSubscriptions(
            this.config?.filters || new RequestQueryBuilder(),
            this.config?.action || new RequestQueryBuilder(),
            {
              ...(this.config?.accountId && { accountId: this.config.accountId }),
              ...(this.config?.userId && { userId: this.config.userId }),
              ...(this.config?.productVariantIds && { productVariantIds: this.config.productVariantIds }),
            },
            skip
          );
        },
      });
    } catch (err) {
      console.error(err);
    }

    this.host.disabled = false;
    if (this._icon && currentIcon) {
      this._icon._elementRef.nativeElement.innerText = currentIcon;
      this._icon._elementRef.nativeElement.classList.remove('spin');
    }
  }
}
