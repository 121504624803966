import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunityVideo } from '@greco/community-videos';
import {
  EventVideo,
  EventVideoSecurityResource,
  EventVideoSecurityResourceAction,
  EventVideoUnlockSecurityResource,
  EventVideoUnlockSecurityResourceAction,
} from '@greco/event-videos';
import { CommunityVideosService } from '@greco/ngx-community-videos';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EventVideosService } from '../../services';

@Component({
  selector: 'greco-community-video-events-table',
  templateUrl: './community-video-events-table.component.html',
  styleUrls: ['./community-video-events-table.component.scss'],
})
export class CommunityVideoEventsTableComponent {
  constructor(
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private communityVideosSvc: CommunityVideosService,
    private eventVideosSvc: EventVideosService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @Input() set communityVideo(communityVideo) {
    this._communityVideo$.next(communityVideo);
    this.refresh$.next(null);
  }

  get communityVideo() {
    return this._communityVideo$.value;
  }
  private _communityVideo$ = new BehaviorSubject<CommunityVideo | null>(null);

  refresh$ = new BehaviorSubject(null);

  readonly canRead$ = this._communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.READ
          )
        : null;
    })
  );

  readonly canUpdate$ = this._communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.UPDATE
          )
        : null;
    })
  );
  readonly canDelete$ = this._communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            EventVideoSecurityResource.key,
            EventVideoSecurityResourceAction.REMOVE
          )
        : null;
    })
  );
  readonly canManageUnlock$ = this._communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? (await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            EventVideoUnlockSecurityResource.key,
            EventVideoUnlockSecurityResourceAction.GRANT
          )) ||
            (await this.comSecSvc.hasAccess(
              communityVideo.communityId,
              EventVideoUnlockSecurityResource.key,
              EventVideoUnlockSecurityResourceAction.UPDATE
            ))
        : null;
    })
  );

  readonly data$ = this._communityVideo$.pipe(
    switchMap(async communityVideo => {
      const eventVideos = (communityVideo as any).eventVideos;
      return eventVideos;
    })
  );

  async manage(eventVideo: EventVideo) {
    if (this.communityVideo)
      await this.router.navigate([`/scheduling/${this.communityVideo.communityId}/${eventVideo.eventId}`]);
  }
}
