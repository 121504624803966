import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { CalendarEvent, EventStatus, ResourceAssignmentStatus } from '@greco/booking-events';
import { heightExpansion } from '@greco/ui-animations';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CalendarEventTimesChangedEvent, CalendarView, CalendarEvent as NgCalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { AddAttendeeDialog, UpdateAssignmentsDialog } from '../../dialogs';
import { EventService } from '../../services';

@Component({
  selector: 'greco-events-calendar',
  templateUrl: './events-calendar.components.html',
  styleUrls: ['./events-calendar.components.scss'],
  animations: [heightExpansion],
})
export class EventsCalendarComponent {
  constructor(private matDialog: MatDialog, private eventSvc: EventService, private snacks: MatSnackBar) {}

  @Output() dateRange = new EventEmitter<[Date, Date]>(true);
  @Output() eventClicked = new EventEmitter<CalendarEvent>(true);
  @Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>(true);

  @Input() loading = false;
  @Input() draggable = false;
  @Output() dateClicked = new EventEmitter<[Date, undefined] | [Date, string]>();
  @Input() resourceIds: string[] = [];
  @Input() fromResourceScheduling = false;

  @Input() hideDayView = false;
  @Input() hideWeekView = false;
  @Input() hideMonthView = false;

  @Input() startingView = CalendarView.Week;
  @Input() refreshCalendarEmitter = new Subject<void>();
  @Input() communityId = '';

  @Input() canUpdateEvents = false;
  @Input() canCreateBookings = false;
  @Input() canCancelEvents = false;

  ngCalendarEvents: NgCalendarEvent[] = [];

  private _events: CalendarEvent[] = [];
  @Input() get events() {
    return this._events;
  }
  set events(events) {
    this._events = events.filter(
      e =>
        e.status !== EventStatus.CANCELLED || (e.status === EventStatus.CANCELLED && (e as any).cancelledBookings > 0)
    );
    this.ngCalendarEvents = this._events.map(event => ({
      start: event.startDate,
      title: event.title,
      end: event.endDate,
      duration: event.duration,
      draggable: this.draggable,
      allDay: false,
      id: event.id,
      color: {
        primary: event.status === EventStatus.CANCELLED ? 'var(--warn-color)' : event.color,
        secondary: 'transparent',
      },
      meta: event,
    }));
  }

  groupBy?: (evt: NgCalendarEvent<CalendarEvent>) => { label: string; metadata: string }[];
  @Input() sortGroups?: (a: string, b: string) => 1 | -1;

  private _dayViewGroupBy?: (event: CalendarEvent) => { label: string; metadata: string }[];
  @Input() get dayViewGroupBy() {
    return this._dayViewGroupBy;
  }
  set dayViewGroupBy(dayViewGroupBy) {
    this._dayViewGroupBy = dayViewGroupBy;
    if (this._dayViewGroupBy) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.groupBy = (evt: NgCalendarEvent<CalendarEvent>) => (evt.meta ? this._dayViewGroupBy!(evt.meta) : []);
    } else this.groupBy = undefined;
  }

  async addAttendee(event: any) {
    const dialog = this.matDialog.open(AddAttendeeDialog, { data: { event }, width: '750px', maxWidth: '90%' });
    if (await toPromise(dialog.afterClosed())) {
      event = { ...event };
    }
  }

  async cancelEvent(eventId: string) {
    if (!eventId) throw new Error();

    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Confirm Cancellation',
          subtitle: 'Are you sure you want to cancel this event?',
          content: 'This will cancel and void all active bookings. No user will be charged.',
          buttons: [
            { label: "No, Don't Cancel", role: 'no' },
            { label: 'Yes, Cancel Event', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.eventSvc.cancelEvent(eventId);
        this.snacks.open('Event cancelled', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
        location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async requestSub(event: any, disableChanges?: boolean) {
    const result = await toPromise(
      this.matDialog
        .open(UpdateAssignmentsDialog, {
          data: {
            event,
            communityId: this.communityId || event.communityId,
            disableChanges: !!disableChanges,
            fromResourceScheduling: this.fromResourceScheduling,
          },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    );

    if (result) event.resourceAssignments = result.assignments;
  }

  requiresSub(event: CalendarEvent) {
    const inEvent = event.resourceAssignments?.some(assignment =>
      this.resourceIds.includes(assignment?.resourceId || '')
    );
    if (inEvent) return false;
    else {
      return event.resourceAssignments?.some(
        assignment => !assignment.resourceId || assignment.resourceStatus !== ResourceAssignmentStatus.CONFIRMED
      );
    }
  }
}
