import { InjectionToken } from '@angular/core';
import { User } from '@greco/identity-users';
import { PlatformLink } from '@greco/ui-platform-layout';

export interface AppConfig {
  routeTitlePrefix: string;
  name: string;
  url: string;
  email: string;
  admin?: boolean;
  userLinks: (user: User | null, openSignIn: () => void) => PlatformLink[];
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
