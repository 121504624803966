import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CommunityVideo } from '@greco/community-videos';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { WatchVideoComponent } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CommunityVideosService } from '../../services';

@Injectable({ providedIn: 'any' })
export class TitleSearchFilter extends BuildSearchFilter('TitleSearchFilter', {
  properties: ['video.title'],
  propertyLabels: ['Title'],
}) {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-select-community-videos',
  templateUrl: './select-community-videos.component.html',
  styleUrls: ['./select-community-videos.component.scss'],
})
export class SelectCommunityVideosComponent {
  checked: CommunityVideo[] = [];

  constructor(private comVidSvc: CommunityVideosService, private matDialog: MatDialog) {}

  selectAll = false;
  loading = false;
  filterOptions = [TitleSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination?: IPaginationMeta;
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  @Output() confirmed = new EventEmitter<CommunityVideo[]>();

  @PropertyListener('community') private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() community!: Community;

  videos$: Observable<{ communityVideo: CommunityVideo; checked: boolean }[]> = combineLatest([
    this.filters$,
    this.page$,
    this._community$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([query, page, community]) => {
      const data: { items: { communityVideo: CommunityVideo; checked: boolean }[]; meta: IPaginationMeta } = {
        items: [],
        meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 },
      };
      const response: PaginatedDto<CommunityVideo> = community
        ? await this.comVidSvc.paginate(query, community.id, page)
        : { items: [], meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } };
      const videos = response.items;
      data.meta = response.meta;
      for (const commVid of videos) {
        const checked = this.checked.some(item => item.id === commVid.id);
        data.items.push({ communityVideo: commVid, checked });
      }
      this.verifySelectAllCheck(data.items);
      return data;
    }),
    tap(data => (this.pagination = data.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  watch(communityVideo: CommunityVideo) {
    if (communityVideo.video) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = communityVideo.video;
    }
  }

  itemChecked(item: { communityVideo: CommunityVideo; checked: boolean }, checked: boolean) {
    item.checked = checked;
    const index = this.checked.findIndex(i => i.id === item.communityVideo.id);
    if (index > -1 && !checked) {
      this.checked.splice(index, 1);
    }
    if (index === -1 && checked) this.checked.push(item.communityVideo);
  }
  async clear() {
    this.checked = [];
    this.page$.next(this.page$.value);
  }

  async save() {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Selection',
        subtitle: 'Are you sure you want to add these videos?',
        content: this.checked.map(item => item.video?.title).join(', '),
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      this.confirmed.emit(this.checked);
    }
  }

  async checkAll(event: MatCheckboxChange, items: { communityVideo: CommunityVideo; checked: boolean }[]) {
    const checked = event.checked;
    this.selectAll = checked;
    for (const item of items) {
      this.itemChecked(item, checked);
    }
  }

  verifySelectAllCheck(items?: { communityVideo: CommunityVideo; checked: boolean }[]) {
    let check = true;
    if (items && items.length) {
      for (const item of items) {
        if (!item.checked) {
          check = false;
          break;
        }
      }
    } else {
      check = false;
    }
    this.selectAll = check;
  }

  rowClick(item: { communityVideo: CommunityVideo; checked: boolean }) {
    item.checked = !item.checked;
    this.itemChecked(item, item.checked);
  }
}
