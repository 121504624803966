import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { BookingOption, BookingOptionAgreementUsage } from '@greco/booking-events';
import { AgreementUsageByEventDto } from '@greco/nestjs-booking-events';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable({
  providedIn: 'root',
})
export class BookingOptionCommunityAgreementsUsageService {
  constructor(private http: HttpClient) {}

  async paginateBookingOptionAgreementUsages(
    bookingOptionId: string,
    pagination: IPaginationOptions
  ): Promise<PaginatedDto<BookingOptionAgreementUsage>> {
    const response = await toPromise(
      this.http.get<PaginatedDto<BookingOptionAgreementUsage>>(`/api/booking-option-agreement-usage`, {
        params: {
          bookingOptionId,
          ...paginationQueryParams(pagination),
        },
      })
    );
    return response;
  }

  async linkAgreementToBookingOption(bookingOption: BookingOption, agreementId: string) {
    if (!bookingOption) return;

    const communityId = bookingOption.community?.id;
    if (!communityId) return;

    return toPromise(
      this.http.post<AgreementUsageByEventDto>(`/api/booking-option-agreement-usage`, {
        agreementId,
        bookingOptionId: bookingOption.id,
        url: `/scheduling/${communityId}/${bookingOption.id}`,
        label: bookingOption.title,
        type: 'booking-option',
      })
    );
  }

  async deleteBookingOptionAgreementUsage(usageId: string) {
    return toPromise(this.http.delete<any | null>(`/api/booking-option-agreement-usage/${usageId}`));
  }
}
