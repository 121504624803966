import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Promotion } from '@greco/promotions';
import { DialogData } from '@greco/ui-dialog-simple';

@Component({
  selector: 'greco-promotions-dialog',
  templateUrl: './promotions.dialog.html',
  styleUrls: ['./promotions.dialog.scss'],
})
export class PromotionsDialog {
  dialogData: DialogData = {
    title: 'Browse Available Offers!',
    buttons: [
      {
        label: 'Skip these offers..',
        role: 'cancel',
      },
    ],
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: { promotions: Promotion[] }) {}
}
