<div>
  <ng-container>
    <greco-filter-bar
      [hideSeparator]="true"
      [filterOptions]="filterOptions"
      (changed)="filters$.next($event[1]); onFilterApplied()"
    >
    </greco-filter-bar>
  </ng-container>

  <greco-table [highlight]="true" [loading]="loading" [data]="(videos$ | async) || []">
    <mat-icon *grecoTableCol="''; fitContent: true; let video" [matTooltip]="video.id">video_settings</mat-icon>

    <p *grecoTableCol="'Title'; let unlock" [matTooltip]="unlock.video.title">
      {{ unlock.video.title || '_' }}
    </p>

    <a
      *grecoTableCol="'User'; let unlock"
      [routerLink]="['/community', community?.id, unlock.user.id]"
      [matTooltip]="unlock.user.contactEmail"
      (click)="$event.stopImmediatePropagation()"
    >
      {{ unlock.user.displayName || '_' }}
    </a>

    <p *grecoTableCol="'Unlock Date'; let unlock" [matTooltip]="(unlock.created | date : 'full') || ''">
      {{ unlock.created | date }}
    </p>

    <p
      *grecoTableCol="'Expiry Date'; let unlock"
      [matTooltip]="unlock.expiresOn ? (unlock.expiresOn | date : 'full') || '' : 'Never'"
    >
      {{ unlock.expiresOn ? (unlock.expiresOn | date) : 'Never' }}
    </p>

    <p *grecoTableCol="'Unlocked/Granted Via'; let unlock" [matTooltip]="'Unlock/Granted Via'">
      {{
        unlock.eventVideo ? 'Events' : unlock.programVideo ? 'Programs' : unlock.communityVideo ? 'Community' : 'Video'
      }}
    </p>

    <p *grecoTableCol="'Unlocked With'; let unlock" [matTooltip]="unlock.unlockOption ? unlock.unlockOption.id : ''">
      <greco-perk-badge
        *ngIf="unlock.unlockOption"
        [small]="true"
        [icon]="unlock.unlockOption.badge.icon"
        [color]="unlock.unlockOption.badge.color"
        [matTooltip]="unlock.unlockOption.title"
      ></greco-perk-badge>
      <a *ngIf="!unlock.unlockOption">Staff</a>
    </p>

    <ng-container *grecoTableCol="'Status'; fitContent: true; let unlock">
      <mat-chip
        [matTooltip]="
          !unlock.expiresOn
            ? 'Unlocked'
            : unlock.expiresOn > now
            ? 'Rented until ' + (unlock.expiresOn | date)
            : 'Expired'
        "
      >
        <mat-icon style="line-height: 18px; font-size: 18px; width: 18px; height: 18px; margin-right: 4px">
          {{ !unlock.expiresOn ? 'lock_open' : unlock.expiresOn > now ? 'timer' : 'timer_off' }}
        </mat-icon>
        {{ !unlock.expiresOn ? 'Unlocked' : unlock.expiresOn > now ? 'Rented' : 'Expired' }}
      </mat-chip>
    </ng-container>

    <greco-video-watch-progress-bar
      *grecoTableCol="'Watch Time'; let unlock"
      [progress]="unlock.watchTime?.watchDuration || 0"
      [duration]="unlock.video.duration"
    ></greco-video-watch-progress-bar>
  </greco-table>

  <mat-paginator
    *ngIf="paginationMeta?.totalItems"
    showFirstLastButtons
    [length]="paginationMeta!.totalItems || 0"
    [pageSize]="paginationMeta!.itemsPerPage || 10"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</div>
