import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface InitiateExportDialogData {
  processorId: string;
  initiateExport: (skip?: number) => Promise<any>;
}

@Component({
  templateUrl: './initiate-export.dialog.html',
  styleUrls: ['./initiate-export.dialog.scss'],
})
export class InitiateExportDialog {
  constructor(
    private dialogRef: MatDialogRef<InitiateExportDialog>,
    @Inject(MAT_DIALOG_DATA) private data: InitiateExportDialogData
  ) {
    this.startExport();
  }

  items: any[] = [];
  totalItems = 0;

  exporting = false;
  error = false;

  async startExport() {
    this.exporting = true;

    try {
      let exportData = await this.data.initiateExport();

      this.items.push(...exportData.data);
      this.totalItems = exportData.totalItems;

      while (this.exporting && this.items.length - 1 < this.totalItems) {
        exportData = await this.data.initiateExport(this.items.length - 1);
        this.items.push(...exportData.data);
        this.totalItems = exportData.totalItems;
      }
    } catch (err) {
      console.error(err);
      this.error = true;
      this.exporting = false;
    }

    this.exporting = false;
  }

  close() {
    this.dialogRef.close();
  }

  download() {
    const csvData = this.items.join('');
    const blob = new Blob([csvData], { type: 'text/csv' });

    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', 'Export.csv');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static open(dialogSvc: MatDialog, data: InitiateExportDialogData) {
    dialogSvc.open(InitiateExportDialog, { data, autoFocus: false });
  }
}
