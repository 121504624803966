import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { TileGroup } from '@greco/tiles';
import { DialogData } from '@greco/ui-dialog-simple';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TilesService } from '../../services/tiles.service';

@Component({
  selector: 'greco-manage-tile-group.dialog',
  templateUrl: './manage-tile-group.dialog.html',
  styleUrls: ['./manage-tile-group.dialog.scss'],
})
export class ManageTileGroupDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private tilesSvc: TilesService,
    private snacks: MatSnackBar,
    private clipboardSvc: ClipboardService
  ) {}

  loading = true;
  private _community$ = of(this.data.communityId);

  tileGroups$ = this._community$.pipe(
    switchMap(async communityId => {
      return this.tilesSvc.getGroupsByCommunity(communityId, true);
    }),
    tap(() => (this.loading = false)),
    map(groups => {
      return groups.map(group => {
        return {
          group,
          nameFormControl: new FormControl(group.name, Validators.required),
          iconFormControl: new FormControl(group.icon),
          priorityFormControl: new FormControl(group.priority, Validators.required),
        };
      });
    })
  );

  // Dialog Data
  dialogData: DialogData = {
    title: 'Manage Tile Groups',
    subtitle: 'Here you can edit the name, icon, and status of Tile Groups.',
    showCloseButton: false,
    buttons: [{ label: 'Close', role: 'cancel' }],
  };
  // Toggle Status Button Functionaliy
  async updateStatus(tg: TileGroup, $event: MatSlideToggleChange) {
    if (tg.enabled !== $event.checked) {
      const response = await this.tilesSvc.updateTileGroup(tg.id, {
        enabled: $event.checked,
        communityId: tg.communityId,
      });
      tg.enabled = response?.enabled;
      this.snacks.open('Status Updated!', 'Ok', { duration: 1000, panelClass: 'mat-primary' });
    }
  }

  resetTileGroup(data: {
    group: TileGroup;
    nameFormControl: FormControl;
    iconFormControl: FormControl;
    priorityFormControl: FormControl;
  }) {
    data.nameFormControl.reset(data.group.name);
    data.nameFormControl.markAsPristine();
    data.iconFormControl.reset(data.group.icon);
    data.iconFormControl.markAsPristine();
    data.priorityFormControl.reset(data.group.priority);
    data.priorityFormControl.markAsPristine();
  }

  async updateTileGroup(data: {
    group: TileGroup;
    nameFormControl: FormControl;
    iconFormControl: FormControl;
    priorityFormControl: FormControl;
  }) {
    const response = await this.tilesSvc.updateTileGroup(data.group.id, {
      name: data.nameFormControl.value,
      communityId: data.group.communityId,
      ...(data.iconFormControl.value && { icon: data.iconFormControl.value }),
      priority: data.priorityFormControl.value,
    });

    if (response) {
      this.snacks.open('Updated!', 'Ok', { duration: 1000, panelClass: 'mat-primary' });
      data.group.name = data.nameFormControl.value;
      data.group.icon = data.iconFormControl.value;
      data.group.priority = data.priorityFormControl.value;
      return;
    }
    this.snacks.open('Duplicate Group Name!', 'Ok', { duration: 1000, panelClass: 'mat-warn' });
  }
  copyTileGroupUrlToClipboard(data: { group: TileGroup; nameFormControl: FormControl; iconFormControl: FormControl }) {
    this.clipboardSvc.copy(`${window.location.origin}/quick/${data.group.id}`, 'Tile Group URL');
  }
}
