import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from '@greco/sales-subscriptions';
import { DialogData } from '@greco/ui-dialog-simple';
import { SubscriptionsService } from '../../services';

@Component({
  templateUrl: './set-referred-by.dialog.html',
})
export class SetSubscriptionReferredByDialog implements OnInit {
  constructor(
    private subscriptionSvc: SubscriptionsService,
    @Inject(MAT_DIALOG_DATA) private subscription: Subscription,
    private matDialogRef: MatDialogRef<SetSubscriptionReferredByDialog>
  ) {
    if (subscription.account) this.accountId = subscription.account.id;
  }

  readonly simpleDialogData: DialogData = {
    title: 'Referred by',
    subtitle: "Update the 'Referred by' property of this subscription with an existing user email.",
    hideDefaultButton: true,
  };

  soldByFormControl = new FormControl(null);

  accountId!: string;

  cancel() {
    this.matDialogRef.close();
  }

  async submit() {
    try {
      const update = await this.subscriptionSvc.setReferredBy(
        this.subscription.id,
        this.soldByFormControl.value?.email ?? null
      );
      this.matDialogRef.close(update);
    } catch (err) {
      console.error(err);
    }
  }

  ngOnInit() {
    setTimeout(() => this.soldByFormControl.reset(this.subscription.referredBy));
  }
}
