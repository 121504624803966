/* eslint-disable @angular-eslint/no-input-rename */
import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Booking,
  BookingOption,
  CalendarEvent,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventSeries,
  EventType,
  UserBookingOptionStats,
  calculateBoosterRequirements,
  isAvailableNow,
} from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SubscriptionsService } from '@greco/ngx-sales-subscriptions';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { UserPerk } from '@greco/sales-perks';
import { ProductVariant, VariantPerkGranted } from '@greco/sales-products';
import { SubscriptionStatus } from '@greco/sales-subscriptions';
import { heightExpansion } from '@greco/ui-animations';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MinutesPipe } from '../../../../pipes';
import { BookingOptionService, EventService } from '../../../../services';

@Component({
  selector: 'greco-pre-booking-option-picker',
  templateUrl: './pre-booking-option-picker.component.html',
  styleUrls: ['./pre-booking-option-picker.component.scss'],
  animations: [heightExpansion],
})
export class PreBookingOptionPickerComponent implements OnDestroy, OnInit {
  constructor(
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private comSecSvc: CommunitySecurityService,
    private subscriptionSvc: SubscriptionsService,
    private bookingOptionSvc: BookingOptionService,
    private securitySvc: SecurityService,
    private userSvc: UserService
  ) {}

  expanded = false;
  closestBookingWindowInHours = -1;
  bookingAvailableIn: string | null = null;
  selectedBookingOptionLoaded = false;
  selectedBookingOption: BookingOption | null = null;

  _boosters: { [id: string]: number } = {};
  _stats?: { [id: string]: UserBookingOptionStats };
  _bookedByStats?: { [id: string]: UserBookingOptionStats };

  private _onDestroy$ = new Subject();
  private initialBookingOption: BookingOption | null = null;
  private initialBookedByBookingOption: BookingOption | null = null;

  @PropertyListener('complimentaryBookingOption')
  private complimentaryBookingOption$ = new BehaviorSubject<BookingOption | null>(null);
  complimentaryBookingOption: BookingOption | null = null;

  private _complimentary = false;
  get complimentaryIsSelected() {
    return this._complimentary;
  }

  @Output() selected = new EventEmitter<BookingOption>();
  @Output() useBookedByPerks = new EventEmitter<boolean>();

  @Input() instancesToBook = 1;
  @PropertyListener('instancesToBook') private instancesToBook$ = new BehaviorSubject(1);

  @Input('event') private _event?: CalendarEvent | EventSeries = undefined;
  @PropertyListener('_event') private event$ = new BehaviorSubject(this._event);

  @Input('user') _user?: User = undefined;
  @PropertyListener('_user') private user$ = new BehaviorSubject(this._user);

  @Input('bookedBy') _bookedBy?: User = undefined;
  @PropertyListener('_bookedBy') private bookedBy$ = new BehaviorSubject(this._bookedBy);
  @Input() disableUserPerks = false;
  @PropertyListener('disableUserPerks') private disableUserPerks$ = new BehaviorSubject(false);

  @Input('onlyAvailablePerks') private _onlyAvailablePerks = true;
  @PropertyListener('_onlyAvailablePerks') private onlyAvailablePerks$ = new BehaviorSubject(this._onlyAvailablePerks);

  @Input('booking') private _booking?: Booking = undefined;
  @PropertyListener('_booking') private booking$ = new BehaviorSubject(this._booking);

  @Input() readonly = false;

  canBookPending = false;
  userBookingOptionReusable = 0;
  userBookingOptionConsumable = 0;

  updateInstanceCount$ = this.instancesToBook$.pipe(
    tap(() => {
      if (this.selectedBookingOption) {
        this.selected.emit({
          ...this.selectedBookingOption,
          canBookPending: this.canBookPending,
          requiredPendingBookings: !this.userBookingOptionReusable
            ? Math.max(0, (this.instancesToBook || 1) - (this.userBookingOptionConsumable || 0))
            : 0,
        } as any);
      }
    })
  );

  private userAndBookedBy$ = combineLatest([this.user$, this.bookedBy$]).pipe(
    map(([user, bookedBy]) => {
      return { user, bookedBy };
    })
  );

  private canBookComplimentary$ = combineLatest([this.userSvc.getUserId(), this.event$]).pipe(
    switchMap(async ([userId, event]) => {
      if (!userId || !event) return false;
      return await this.securitySvc.hasAccess(
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.COMPLIMENTARY,
        { userId, communityId: event.community.id }
      );
    })
  );

  canCreatePending$ = this.event$.pipe(
    switchMap(async event => {
      return await this.comSecSvc.hasAccess(
        event?.community.id || '',
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.CREATE_PENDING
      );
    })
  );

  canCreatePendingFromSubscription$ = this.event$.pipe(
    switchMap(async event => {
      return await this.comSecSvc.hasAccess(
        event?.community.id || '',
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.CREATE_PENDING_FROM_SUBSCRIPTION
      );
    })
  );

  @PropertyListener('_useBookedByPerks') private useBookedByPerks$ = new BehaviorSubject<boolean>(false);
  private _useBookedByPerks = false;
  get bookedByPerksAreSelected() {
    if (!this._bookedBy) return false;
    return this._useBookedByPerks;
  }

  readonly bookedByBookingOptions$: Observable<BookingOption[]> = combineLatest([
    this.event$,
    this.userAndBookedBy$,
    this.onlyAvailablePerks$,
    this.booking$,
    this.canCreatePending$,
    this.canCreatePendingFromSubscription$,
  ]).pipe(
    switchMap(
      async ([
        event,
        { user, bookedBy },
        onlyAvailablePerks,
        booking,
        canCreatePending,
        canCreatePendingFromSubscription,
      ]) => {
        if (!event || !bookedBy) return {};
        let options;
        const createPending = canCreatePending || canCreatePendingFromSubscription;

        if (event.type === EventType.EVENT || event.type === EventType.EVENT_SERIES_INSTANCE) {
          options = event.tags?.length
            ? await this.eventSvc.getBookingOptionsByEventTags(
                event.tags.map(tag => tag.id),
                onlyAvailablePerks || !createPending ? bookedBy.id : undefined
              )
            : [];
        }

        if (booking && options) options = options.filter(option => option.id !== booking?.bookingOption.id);

        return { options, event, user, bookedBy, canCreatePending, canCreatePendingFromSubscription };
      }
    ),
    switchMap(data => combineLatest([of(data), this.complimentaryBookingOption$])),
    switchMap(
      async ([
        { options, event, user, bookedBy, canCreatePending, canCreatePendingFromSubscription },
        complimentaryBookingOption,
      ]) => {
        if (!options || !event || !bookedBy) return [];

        const optionIds = [
          ...options.map(opt => opt.id),
          ...(complimentaryBookingOption ? [complimentaryBookingOption.id] : []),
        ];

        const stats = await this.bookingOptionSvc.getUserBookingOptionStats(bookedBy.id, optionIds);
        this._bookedByStats = stats.reduce((acc, stat) => ({ ...acc, [stat.bookingOptionId]: stat }), {});

        // Filter based on booking window and boosters config
        options = options.filter(option => this.readonly || isAvailableNow(event, option));

        options.forEach(option => {
          const boosters = calculateBoosterRequirements(event, option);
          this._boosters[option.id] = boosters;
        });

        if (!this.readonly && !canCreatePending && canCreatePendingFromSubscription) {
          options = await this.filterByOptionsFromSubscriptions(bookedBy, options);
        }

        // Filter out pending options, that cannot be used as pending.
        options = options.filter(option => {
          if (option.id === this.complimentaryBookingOption?.id || this.readonly) return true;
          const stats = this._bookedByStats?.[option.id];
          return stats?.consumable || stats?.reusable || option.allowPendingBookings;
        });

        const sortedOptions = this.sortOptions(options).map(option => {
          return {
            ...option,
            disabled: (option as any).userPerks
              ? !(option as any).userPerks?.some(
                  (up: UserPerk) => up.transferable || up.user === null || user?.id === bookedBy?.id
                )
              : false,
          };
        });

        this.initialBookedByBookingOption = sortedOptions[0] ?? null;
        this._useBookedByPerks = true;
        if (!this.disableUserPerks) this.toggleBookedByPerks();
        else
          this.selectBookingOption(
            canCreatePending || false,
            canCreatePendingFromSubscription || false,
            stats.find(stat => stat.bookingOptionId === this.initialBookedByBookingOption?.id) || null,
            this.initialBookedByBookingOption
          );

        return sortedOptions;
      }
    )
  );

  private combined = combineLatest([
    combineLatest([this.event$, this.user$, this.onlyAvailablePerks$, this.disableUserPerks$]),
    combineLatest([this.canCreatePending$, this.canCreatePendingFromSubscription$, this.booking$]),
  ]);
  readonly userBookingOptions$: Observable<BookingOption[]> = this.combined.pipe(
    switchMap(
      async ([
        [event, user, onlyAvailablePerks, disableUserPerks],
        [canCreatePending, canCreatePendingFromSubscription, _booking],
      ]) => {
        if (!event || !user || disableUserPerks) return {};
        let options;

        const createPending = canCreatePending || canCreatePendingFromSubscription;

        if (event.type === EventType.EVENT) {
          options = event.tags?.length
            ? await this.eventSvc.getBookingOptionsByEventTags(
                event.tags.map(tag => tag.id),
                onlyAvailablePerks || !createPending ? user.id : undefined
              )
            : [];
        }
        // if (!this.readonly && booking && options)
        //   options = options.filter(option => option.id !== booking?.bookingOption.id);
        return { options, event, user, canCreatePending, canCreatePendingFromSubscription };
      }
    ),
    switchMap(data => combineLatest([of(data), this.complimentaryBookingOption$])),
    switchMap(
      async ([
        { options, event, user, canCreatePending, canCreatePendingFromSubscription },
        complimentaryBookingOption,
      ]) => {
        if (!options || !event || !user) return [];

        const optionIds = [
          ...options.map(opt => opt.id),
          ...(complimentaryBookingOption ? [complimentaryBookingOption.id] : []),
        ];

        const stats = await this.bookingOptionSvc.getUserBookingOptionStats(user.id, optionIds);
        this._stats = stats.reduce((acc, stat) => ({ ...acc, [stat.bookingOptionId]: stat }), {});

        // console.log([...options]);
        this.closestBookingWindowInHours = options.reduce((acc, option) => {
          const stat = this._stats?.[option.id];
          // console.log(option.id, stat);
          if (stat?.reusable || stat?.consumable) {
            return Math.max(acc, option.bookingWindow / 60);
          }
          return acc;
        }, -1);

        this.bookingAvailableIn = moment(event.startDate)
          .subtract(this.closestBookingWindowInHours, 'hours')
          .fromNow(true);

        // Filter based on booking window and boosters config
        options = options.filter(option => isAvailableNow(event, option));

        options.forEach(option => {
          const boosters = calculateBoosterRequirements(event, option);
          this._boosters[option.id] = boosters;
        });

        if (!canCreatePending && canCreatePendingFromSubscription) {
          options = await this.filterByOptionsFromSubscriptions(user, options);
        }

        const sortedOptions = this.sortOptions(options);
        this.initialBookingOption = options[0] ?? null;
        if (!this.selectedBookingOption)
          this.selectBookingOption(
            canCreatePending || false,
            canCreatePendingFromSubscription || false,
            stats?.find(stat => stat.bookingOptionId === this.initialBookingOption?.id) || null,
            this.initialBookingOption
          );
        return sortedOptions;
      }
    )
  );

  readonly bookingOptions$ = combineLatest([
    this.bookedByBookingOptions$,
    this.userBookingOptions$,
    this.useBookedByPerks$,
    this.bookedBy$,
  ]).pipe(
    map(([bookedByBookingOptions, userBookingOptions, useMyPerks, bookedBy]) => {
      return useMyPerks && bookedBy ? bookedByBookingOptions : userBookingOptions;
    })
  );

  async filterByOptionsFromSubscriptions(user: User, options: BookingOption[]) {
    const userSubscriptions = await this.subscriptionSvc.getSubscriptionsByUserAndAccount(user.id);
    const subscriptionPerks: string[] = [];

    userSubscriptions.forEach(subscription => {
      if (subscription.status === SubscriptionStatus.ACTIVE) {
        subscription.items?.forEach(item => {
          const variant = (item as any).variant as ProductVariant;

          variant.variantPerks.forEach(variantPerk => {
            if (
              subscription.startDate &&
              new Date().getTime() > subscription.startDate?.getTime() &&
              !variantPerk.granted.includes(VariantPerkGranted.Initial)
            ) {
              if (variantPerk.perk) subscriptionPerks.push(variantPerk.perk.id);
            }
          });
        });
      }
    });

    const subscriptionOptions = options.filter(
      option =>
        this._stats &&
        !(this._stats[option.id]?.consumable || this._stats[option.id]?.reusable) &&
        subscriptionPerks.includes(option.id)
    );

    options = options.filter(
      option =>
        this._stats &&
        (this._stats[option.id]?.consumable || this._stats[option.id]?.reusable) &&
        !subscriptionPerks.includes(option.id)
    );

    return (options = [...options, ...subscriptionOptions]);
  }

  sortOptions(bookingOptions: BookingOption[]) {
    return bookingOptions.sort((a, b) => {
      const aPrice = (a.price || 0) + (this._boosters[a.id] || 0) * 499;
      const bPrice = (b.price || 0) + (this._boosters[b.id] || 0) * 499;

      const aAvailable = !!this._stats?.[a.id]?.reusable || !!this._stats?.[a.id]?.consumable;
      const bAvailable = !!this._stats?.[b.id]?.reusable || !!this._stats?.[b.id]?.consumable;

      if (aAvailable !== bAvailable) {
        return aAvailable ? -1 : 1;
      }

      if (aAvailable && bAvailable) {
        const aReusable = !!this._stats?.[a.id]?.reusable;
        const bReusable = !!this._stats?.[b.id]?.reusable;

        if (!!aPrice !== !!bPrice) {
          return aPrice < bPrice ? -1 : 1;
        }

        if (aReusable !== bReusable) {
          return aReusable ? -1 : 1;
        }

        if (aPrice !== bPrice) {
          return aPrice < bPrice ? -1 : 1;
        }
      }

      const aSubs = !!this._stats?.[a.id]?.subscriptions?.length;
      const bSubs = !!this._stats?.[b.id]?.subscriptions?.length;

      if (aSubs !== bSubs) {
        return aSubs ? -1 : 1;
      }

      if (aPrice !== bPrice) {
        return aPrice < bPrice ? -1 : 1;
      }

      const aUsed = !!this._stats?.[a.id]?.used;
      const bUsed = !!this._stats?.[b.id]?.used;

      if (aUsed !== bUsed) {
        return aUsed ? -1 : 1;
      }

      return a.title.localeCompare(b.title);
    });
  }

  selectBookingOption(
    canBookPending: boolean,
    canBookPendingWithSub: boolean,
    userBookingOption: UserBookingOptionStats | null,
    option: BookingOption | null
  ) {
    if (this.selectedBookingOption?.id !== option?.id) {
      this.expanded = false;
    }

    this.selectedBookingOption = option;
    if (this.selectedBookingOption) {
      this.canBookPending = canBookPending || (canBookPendingWithSub && !!userBookingOption?.subscriptions.length);
      this.userBookingOptionReusable = userBookingOption?.reusable || 0;
      this.userBookingOptionConsumable = userBookingOption?.consumable || 0;
      this.selected.emit({
        ...this.selectedBookingOption,
        canBookPending: this.canBookPending,
        requiredPendingBookings: !this.userBookingOptionReusable
          ? Math.max(0, (this.instancesToBook || 1) - (this.userBookingOptionConsumable || 0))
          : 0,
      } as any);
    }
    this.selectedBookingOptionLoaded = true;
  }

  toggleComplimentary(canBookPending: boolean, canBookPendingWithSub: boolean) {
    this._complimentary = this.complimentaryBookingOption ? !this._complimentary : false;
    this.selectBookingOption(
      this._complimentary ? true : canBookPending,
      this._complimentary ? true : canBookPendingWithSub,
      this._stats && this.initialBookingOption?.id ? this._stats[this.initialBookingOption?.id] || null : null,
      this._complimentary
        ? this.complimentaryBookingOption
        : this.bookedByPerksAreSelected
        ? this.initialBookedByBookingOption
        : this.initialBookingOption
    );
  }

  toggleBookedByPerks() {
    this._useBookedByPerks = !this._useBookedByPerks;
    this.selectBookingOption(
      true,
      true,
      (this.selectedBookingOption?.id && this._bookedByStats
        ? this._bookedByStats[this.selectedBookingOption?.id]
        : null) || null,
      this._complimentary
        ? this.complimentaryBookingOption
        : this.bookedByPerksAreSelected
        ? this.initialBookedByBookingOption
        : this.initialBookingOption
    );
    this.useBookedByPerks.emit(this.bookedByPerksAreSelected);
  }

  openInfoDialog(option: BookingOption) {
    let title = option.title;

    if (option.bookingWindow) {
      title += ` (${new MinutesPipe().transform(option.bookingWindow)})`;
    }

    if (option.price) {
      title += ` | ${new CurrencyPipe('en-CA').transform(option.price / 100)} Booking Fee`;
    }

    this.matDialog.open(SimpleDialog, {
      maxWidth: 700,
      data: {
        subtitle: title,
        showCloseButton: false,
        title: 'Your Booking Option',
        content: option.description,
      } as DialogData,
    });
  }

  ngOnInit() {
    this.canBookComplimentary$
      .pipe(
        takeUntil(this._onDestroy$),
        switchMap(async allowed => (allowed ? await this.bookingOptionSvc.getComplimentaryOption() : null)),
        tap(complimentaryOption => (this.complimentaryBookingOption = complimentaryOption ?? null))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this.user$.complete();
    this.event$.complete();
    this.onlyAvailablePerks$.complete();
  }
}
