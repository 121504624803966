import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { ResourceTag } from '@greco/booking-events';
import { CreateResourceTagDto, UpdateResourceTagDto } from '@greco/nestjs-booking-events';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { ResourceTagService } from '../../services/resource-tag.service';
import { UpdateTagDialog } from '../update-tag/update-tag.dialog';
@Component({
  selector: 'greco-manage-tags',
  templateUrl: './manage-tags.dialog.html',
  styleUrls: ['./manage-tags.dialog.scss'],
})
export class ManageTagsDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private tagSvc: ResourceTagService
  ) {}

  private _onDestroy$ = new Subject<void>();

  tags: ResourceTag[] = [];
  tagsToAdd: CreateResourceTagDto[] = [];
  tagsToRemove: string[] = [];
  tagsToUpdate: UpdateResourceTagDto[] = [];

  isTyping = false;

  dialogData: DialogData = {
    title: 'Manage Tags',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Confirm',
        role: 'confirm',
        resultFn: async () => {
          try {
            for (const tag of this.tagsToAdd) {
              await this.tagSvc.createResourceTag(tag);
            }

            for (const tagId of this.tagsToRemove) {
              this.tagsToUpdate.map((tag, index) => {
                if (tag.id === tagId) {
                  this.tagsToUpdate.splice(index, 1);
                  return;
                }
              });
              await this.tagSvc.removeResourceTag(tagId);
            }
            for (const tag of this.tagsToUpdate) await this.tagSvc.updateResourceTag(tag);

            if (!this.tagsToRemove.length)
              this.snacks.open('Tags updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return this.tagsToRemove;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.tags = await this.tagSvc.getResourceTags(this.data.communityId);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  newTag(label: string) {
    this.tagsToAdd.push({ label, communityId: this.data.communityId });
    this.tags.push({ id: '', label, communityId: this.data.communityId });
  }

  async updateTag(tag: ResourceTag) {
    const _dialog = this.dialog.open(UpdateTagDialog, {
      data: { tagId: tag.id, communityId: this.data.communityId, label: tag.label },
      width: '750px',
      maxWidth: '90%',
    });
    const result = (await toPromise(_dialog.afterClosed())) as UpdateResourceTagDto;
    if (result.label) {
      const index = this.tagsToAdd.findIndex(t => t.label === tag.label);
      if (index > -1) this.tagsToAdd[index].label = result.label;
      else {
        this.tagsToUpdate.push(result);
        this.tags.map(t => {
          if (t.id === tag.id) tag.label = result.label;
        });
      }
    }
  }

  removeTag(tagId?: string, label?: string) {
    if (tagId && tagId !== '') this.tagsToRemove.push(tagId);
    else if (label) {
      let index = this.tags.findIndex(tag => tag.label === label);
      if (index > -1) this.tags.splice(index);
      index = this.tagsToAdd.findIndex(tag => tag.label === label);
      if (index > -1) this.tagsToAdd.splice(index);
      index = this.tagsToUpdate.findIndex(tag => tag.label === label);
      if (index > -1) this.tagsToUpdate.splice(index);
    }
  }

  dontRemoveTag(tagId: string) {
    const index = this.tagsToRemove.findIndex(tagToRemove => tagToRemove === tagId);
    this.tagsToRemove.splice(index, 1);
  }
}
