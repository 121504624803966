import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { map } from 'rxjs/operators';
import { ChangePasswordDialog } from '../../dialogs/change-password';

@Component({
  selector: 'greco-user-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class UserAccountDetailsComponent implements OnChanges {
  constructor(
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar
  ) {}

  @Input() readonly = false;

  @Input() user!: User;

  @Output() saved = new EventEmitter();

  isSignedIn = false;
  isSignedIn$ = this.userSvc.user$.pipe(
    map(signedInUser => {
      return this.user?.id === signedInUser?.id && this.user?.authProviders?.some(p => p === 'password');
    })
  );

  resetValue = { email: '' };
  form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  save = async () => {
    if (this.readonly) return;

    // console.log(this.form.value);
    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Confirm Email Update',
          subtitle: 'Are you sure you want to update email?',
          content:
            "Note: Changing the user's email here may break 3rd party integrations. Please make sure to also update the user's email where needed.",
          buttons: [
            { label: 'No', role: 'no' },
            { label: 'Yes, Update Email', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        this.user = await this.userSvc.update(this.user.id, { email: this.form.value.email });
        this.reset();
        this.saved.emit();
        this.snacks.open('Updated email address!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
      }
    } catch (err: any) {
      if (err instanceof HttpErrorResponse) {
        console.error((err as HttpErrorResponse).error.message);
        this.snacks.open((err as HttpErrorResponse).error.message, 'Ok', { duration: 6000, panelClass: 'mat-warn' });
      } else {
        console.error(err);
        this.snacks.open('Unable to update email address!', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
      }
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('Readonly: ' + this.readonly);

    if (changes.user?.previousValue !== changes.user?.currentValue) {
      this.reset();
    }
  }

  private reset() {
    this.resetValue = { email: this.user?.email || '' };
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  async changeEmail() {
    // Open Typeform to change email
  }

  async changePassword() {
    const _dialog = this.matDialog.open(ChangePasswordDialog, {
      width: '100%',
      maxWidth: '400px',
    });
    await toPromise(_dialog.afterClosed());
  }
}
