/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountLinkStatus } from '@greco/account-linking';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { GiftCardConfigService } from '@greco/ngx-gift-cards';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Product, ProductVariantVisibility, VariantResource, VariantResourceAction } from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import { AccountLinkingService } from '@greco/web-account-linking';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-fit-create-quick-gift-card',
  templateUrl: './create-quick-gift-card.dialog.html',
  styleUrls: ['./create-quick-gift-card.dialog.scss'],
})
export class CreateQuickGiftCardPurchaseDialog implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CreateQuickGiftCardPurchaseDialog>,
    private formBuilder: FormBuilder,
    private clipboardSvc: ClipboardService,
    private linkSvc: AccountLinkingService,
    private securitySvc: SecurityService,
    private userSvc: UserService,
    private giftCardConfigSvc: GiftCardConfigService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      communityId: string;
      accountId: Product;
      contact?: Contact;
    }
  ) {
    this.communityId = data.communityId;
    if (data.contact) {
      this.form.setValue({
        contact: data.contact,
      });
    }
  }
  private user: User | null = null;

  private onDestroy$ = new Subject<void>();

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  communityId: string;

  billedTo?: User;

  dialogData: DialogData = {
    title: 'Quick Gift Card Purchase',
    subtitle: 'Send a gift card on the behalf of a user.',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  currentStep = 0;

  form = this.formBuilder.group({
    contact: [null, Validators.required],
  });

  self$ = this.userSvc.getSelf();

  canSellRestrictedVariants$ = this._communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.SELL_RESTRICTED, {
            communityId: this.communityId,
          })
        : false
    ),
    shareReplay(1)
  );

  selectedConfig$ = combineLatest([this._communityId$, this.canSellRestrictedVariants$]).pipe(
    switchMap(async ([communityId, canSellRestricted]) => {
      const config = communityId ? await this.giftCardConfigSvc.getOne(communityId) : null;
      if (config && !canSellRestricted) {
        config.options = config.options.filter(option => option.visibility !== ProductVariantVisibility.RESTRICTED);
      }
      return config;
    })
  );

  async close() {
    return this.dialogRef.close();
  }

  async ngOnInit() {
    this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this.onDestroy$)).subscribe(async () => {
      const contact = this.form.value.contact as Contact;
      this.billedTo = undefined;

      // This setTimeout is required for weird timing issues. The accounts for the user and the purchasedBy update seperately so
      //  this makes sure to wait for them both to be updated by the above line before giving them new values
      setTimeout(async () => {
        if (contact?.user!.email) {
          this.billedTo = contact.user;
          return;
        } else {
          const parents = (await this.linkSvc.getGivenLinksByAccount(contact?.user!.id))
            ?.filter(link => link.status === AccountLinkStatus.ACTIVE)
            .map(link => link.accessor!);

          if (parents.length) {
            this.billedTo = parents[0];
            return;
          } else {
            this.billedTo = undefined;
          }
        }
      }, 200);
    });

    this.user = await this.userSvc.getSelf();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setStep(index: number) {
    if (this.currentStep === index) return;

    this.currentStep = index;
  }
}
