import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SafePipeModule } from 'safe-pipe';
import { DateInputDialog } from './date-input-dialog/date-input-dialog.component';
import { ErrorDialog } from './error-dialog/error.dialog';
import { SimpleDialog } from './simple-dialog/simple.dialog';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    SafePipeModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
  ],
  declarations: [SimpleDialog, ErrorDialog, DateInputDialog],
  exports: [SimpleDialog, ErrorDialog, DateInputDialog],
})
export class SimpleDialogModule {}
