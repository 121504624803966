import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  CalendarEvent,
  EventResourceAssignment,
  EventSeries,
  Resource,
  ResourceAssignmentStatus,
} from '@greco/booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { Subject } from 'rxjs';
import { EventService, ResourcesService } from '../../services';

@Component({
  selector: 'greco-update-assignments-dialog',
  templateUrl: './update-assignments.dialog.html',
  styleUrls: ['./update-assignments.dialog.scss'],
})
export class UpdateAssignmentsDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      event: CalendarEvent;
      series: EventSeries;
      communityId: string;
      disableChanges: boolean;
      fromResourceScheduling: boolean;
    },
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private formBuilder: FormBuilder,
    private resourceSvc: ResourcesService,
    private dialogRef: MatDialogRef<UpdateAssignmentsDialog>
  ) {}

  form = this.formBuilder.group({
    resourceAssignments: [[], []],
  });

  loading = false;
  userResource: Resource | null = null;
  assignmentsForSub: EventResourceAssignment[] | null = null;
  initialAssignments: EventResourceAssignment[] | null = null;

  private _onDestroy$ = new Subject<void>();

  dialogData: DialogData = {
    title: this.data.fromResourceScheduling ? 'Substitute for an Event' : 'Update Assignments',
    subtitle: `<b>${this.data?.event?.title || this.data?.series?.title}</b>&nbsp;&nbsp;|&nbsp;&nbsp;${moment(
      this.data?.event?.startDate || this.data?.series?.startDate
    ).format('MMMM Do, hh:mm A')} (${this.data?.event?.duration || this.data?.series?.duration}mins)`,
    showCloseButton: false,
  };

  public readonly userControl = new FormControl(null, Validators.required);

  cancel() {
    try {
      this.dialogRef.close();
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { panelClass: 'mat-warn' });
      this.dialogRef.close(err);
    }
  }

  async substitute(resourceForSub: EventResourceAssignment) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Confirm Substitution',
        content:
          'Are you sure you want to substitute for ' +
          (resourceForSub?.resource?.name || 'the ' + resourceForSub?.resourceTag?.label + ' tag') +
          '?',
        buttons: [
          { label: 'Cancel', role: 'cancel' },
          {
            label: 'Confirm',
            role: 'confirm',
          },
        ],
      },
    });

    const result = await toPromise(dialog.afterClosed());
    if (result === 'confirm') {
      this.loading = true;

      try {
        this.substituteResource(resourceForSub);

        await this.eventSvc.updateEventDetails(this.data.event.id, {
          resourceAssignments: this.form.value.resourceAssignments as EventResourceAssignment[],
        });

        this.snacks.open('Substitution' + (this.data.event.autoAssign ? '' : ' request') + ' sent!', 'Ok', {
          duration: 2500,
          panelClass: 'mat-primary',
        });
        this.dialogRef.close({ assignments: this.form.value.resourceAssignments, action: 'substitution' });
      } catch (err) {
        console.error(err);
        this.dialogRef.close(err);
      }
    }
  }

  async confirm() {
    this.loading = true;

    try {
      await this.eventSvc.updateEventDetails(this.data.event.id, {
        resourceAssignments: this.form.value.resourceAssignments,
      });
      this.snacks.open('Assignments updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close({ assignments: this.form.value.resourceAssignments, action: 'confirm' });
    } catch (err) {
      console.error(err);
      this.dialogRef.close(err);
    }
  }

  substituteResource(resourceForSub: EventResourceAssignment) {
    const resourceAssignments = this.form.value.resourceAssignments as EventResourceAssignment[];

    if (!resourceForSub.resource && this.userResource) {
      resourceForSub.resource = this.userResource;
      if (this.data.event.autoAssign) resourceForSub.resourceStatus = ResourceAssignmentStatus.CONFIRMED;
      else resourceForSub.resourceStatus = ResourceAssignmentStatus.PENDING;
    } else {
      const pendingAssignment: EventResourceAssignment = {
        id: '',
        eventId: this.data.event.id,
        resource: this.userResource || undefined,
        resourceId: this.userResource?.id,
        resourceTag: resourceForSub?.resourceTag,
        resourceTagId: resourceForSub?.resourceTag?.id,
        resourceStatus: this.data.event.autoAssign
          ? ResourceAssignmentStatus.CONFIRMED
          : ResourceAssignmentStatus.PENDING,
      };

      resourceAssignments.push(pendingAssignment);

      if (this.data.event.autoAssign) {
        const index = resourceAssignments.findIndex(
          assignment =>
            assignment.resourceTagId === resourceForSub?.resourceTag?.id &&
            (!assignment?.resourceId ||
              (assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION &&
                this.data.event.autoAssign))
        );

        if (index !== -1) resourceAssignments.splice(index, 1);
      }
    }

    this.form.patchValue({ resourceAssignments });
  }

  async ngOnInit() {
    this.initialAssignments = (this.data.event.resourceAssignments as EventResourceAssignment[])?.map(assignment => {
      return { ...assignment } as EventResourceAssignment;
    });
    this.form.patchValue({ resourceAssignments: [...this.initialAssignments] });

    if (!this.data.fromResourceScheduling) return;

    const user = await this.userSvc.getSelf();
    const userGroup = await this.resourceSvc.getResourceGroup(user?.id || '');
    this.userResource = userGroup.find(resource => resource.community.id === this.data.communityId) || null;

    this.assignmentsForSub = this.initialAssignments.filter(
      assignment =>
        assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION &&
        assignment.resourceId !== this.userResource?.id &&
        this.userResource?.resourceTags?.map(tag => tag.id)?.includes(assignment?.resourceTagId || '')
    );

    if (this.userResource && this.assignmentsForSub?.length === 1) {
      this.substituteResource(this.assignmentsForSub[0]);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
