import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunityVideo } from '@greco/community-videos';
import { CommunityVideosService } from '@greco/ngx-community-videos';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import {
  CollectionVideoSecurityResource,
  CollectionVideoSecurityResourceAction,
  VideoCollectionSecurityResource,
  VideoCollectionSecurityResourceAction,
} from '@greco/video-library';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-community-video-programs-table',
  templateUrl: './community-video-programs-table.component.html',
  styleUrls: ['./community-video-programs-table.component.scss'],
})
export class CommunityVideoProgramsTableComponent {
  constructor(
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private communityVideosSvc: CommunityVideosService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @Input() set communityVideo(communityVideo) {
    this._communityVideo$.next(communityVideo);
    this.refresh$.next(null);
  }

  get communityVideo() {
    return this._communityVideo$.value;
  }
  private _communityVideo$ = new BehaviorSubject<CommunityVideo | null>(null);

  refresh$ = new BehaviorSubject(null);

  readonly data$ = this._communityVideo$.pipe(
    switchMap(async communityVideo => {
      // const programs = (communityVideo as any).programs;
      // return programs;
      return (communityVideo as any)?.collectionVideos || [];
    })
  );

  readonly canRead$ = this._communityVideo$.pipe(
    switchMap(async video => {
      return video
        ? await this.comSecSvc.hasAccess(
            video.communityId,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.READ
          )
        : null;
    })
  );

  readonly canManageCollection$ = this._communityVideo$.pipe(
    switchMap(async video => {
      return video
        ? await this.comSecSvc.hasAccess(
            video.communityId,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.MANAGE
          )
        : null;
    })
  );

  readonly canManageVideo$ = this._communityVideo$.pipe(
    switchMap(async video => {
      return video
        ? await this.comSecSvc.hasAccess(
            video.communityId,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.READ
          )
        : null;
    })
  );

  async navigateToCollection(collectionVideo: any) {
    if (this.communityVideo)
      await this.router.navigate(
        [`/videos/${this.communityVideo.communityId}/collections/${collectionVideo.collectionId}`],
        { queryParams: { subcollectionId: collectionVideo.subcollectionId } }
      );
  }
}
