import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from '@greco/ngx-security-util';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { CurrencyMaskConfig } from 'ngx-currency';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-bulk-void-purchase-dialog',
  templateUrl: './bulk-void.dialog.html',
  styleUrls: ['./bulk-void.dialog.scss'],
})
export class BulkVoidPurchaseDialog {
  voidReasons = ['Bad Debt', 'Admin Error'].sort();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      voidableTotal: number;
      pageTotal: number;
      queryBuilder: RequestQueryBuilder;
      filters?: {
        accountId?: string;
        userId?: string;
        saleCategoryIds?: string[];

        variantIds?: string[];
      };
      showUncategorized?: boolean;
    },
    private dialogRef: MatDialogRef<BulkVoidPurchaseDialog>,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {}

  readonly dialogData: DialogData = {
    title: 'Bulk Void Purchases',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  formGroup = this.formBuilder.group({
    voidReason: ['', [Validators.required]],
  });

  processing = false;

  canVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true);

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const response = await this.purchaseSvc.bulkVoid(
        this.data.queryBuilder,
        this.formGroup.value.voidReason,
        this.data.filters,
        this.data.showUncategorized
      );

      if (response?.errors.length) {
        this.snacks.open(`${response.errors.length} purchases could not be voided.`, 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      } else {
        this.snacks.open(`Bulk void executed, email notification pending.`, 'Ok', {
          duration: 3000,
          panelClass: 'mat-primary',
        });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close({
      submit: true,
    });
  }
}
