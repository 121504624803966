import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { DialogData } from '@greco/ui-dialog-simple';

@Component({
  selector: 'greco-account-completion',
  templateUrl: './account-completion.dialog.html',
  styleUrls: ['./account-completion.dialog.scss'],
})
export class AccountCompletionDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User; name: string },
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private snacks: MatSnackBar
  ) {}

  nowDate = new Date();
  formCtrl = new FormControl();
  validEmail = true;
  birthday: any;

  completionForm = this.formBuilder.group({
    displayName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    birthday: ['', Validators.required],
    emergencyPhoneNumber: ['', Validators.required],
  });

  dialogData: DialogData = {
    title: 'Missing Profile Credentials' + (this.data.name ? ' for ' + this.data.name : ''),
    subtitle: 'Please fill out the required fields to complete your booking!',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Confirm',
        role: 'confirm',
        resultFn: async () => {
          const formValues = this.completionForm.value;
          const user = this.data.user;

          if (!this.validateResponse(formValues)) {
            //checks if the form values are empty or not
            this.snacks.open('Please fill out the missing credentials', 'Ok', {
              duration: 2500,
              panelClass: 'mat-warn',
            });
            throw new Error('fields cannot be empty');
          }

          const updated = await this.userSvc.update(user.id, {
            displayName: formValues.displayName || user.displayName, // Only the missing details will appear in the dialog, so to account for every case, uses formValues if they are not empty or null, otherwise use existing values
            phoneNumber: formValues.phoneNumber || user.phoneNumber,
            birthday: this.birthday || user.birthday,
            emergencyPhoneNumber: formValues.emergencyPhoneNumber || user.emergencyPhoneNumber,
          });

          return updated;
        },
      },
    ],
  };

  validateResponse(values: any): boolean {
    const user = this.data.user;

    if (
      (values.displayName || user.displayName) &&
      (values.phoneNumber || user.phoneNumber) &&
      (this.birthday || user.birthday) &&
      (values.emergencyPhoneNumber || user.emergencyPhoneNumber)
    )
      return true;
    else return false;
  }

  addBirthday(birthday: MatDatepickerInputEvent<Date>) {
    this.birthday = birthday.value;
  }
}
