<ng-container *grecoLet="_contact$ | async">
  <mat-form-field appearance="outline" style="width: 100%" *ngIf="community$ | async as community">
    <mat-label>Check-in Member</mat-label>

    <greco-contact-picker
      [communityId]="community.id"
      [formControl]="userControl"
      [withUser]="true"
      [required]="true"
      [clearOnPaste]="autoCheckIn"
      (paste)="pasteMemberNumber($event, community.id)"
      (contact)="autoCheckIn === true ? checkInUser($event?.user?.id, station) : false"
      placeholder="Search by name, email, or member number!"
    ></greco-contact-picker>

    <button matSuffix mat-stroked-button (click)="createContact()" *ngIf="canSearchAndInviteUser$ | async">
      Invite Member
    </button>
  </mat-form-field>
  <ng-container *grecoLet="community$ | async as community">
    <ng-container *ngIf="isMobile$ | async">
      <ng-container>
        <ng-container *ngIf="brivoCommunitySite$ | async as brivoCommunitySite">
          <!-- <div style="display: flex; justify-content: space-between; margin-top: 12px">
            <div class="heading">Gate</div>

            <mat-checkbox
              style="margin-top: -2px"
              color="primary"
              [checked]="syncEntries"
              (change)="toggleSyncEntries()"
            >
              <label>Display Gate Activity</label>
            </mat-checkbox>
          </div> -->
          <div
            style="display: flex; justify-content: space-between; width: 100%; gap: 8px; flex-wrap: wrap"
            *grecoLet="linkedAccessPoints$ | async as linkedAccessPoints"
          >
            <mat-form-field appearance="outline" style="flex-grow: 1; flex-shrink: 0">
              <!-- <mat-label>Gate</mat-label> -->
              <mat-select [formControl]="gateControl" placeholder="Select a gate...">
                <mat-option *ngIf="gateControl.value" [value]="gateControl.value">{{
                  gateControl.value?.accessPointName
                }}</mat-option>
                <ng-container *ngFor="let accessPoint of linkedAccessPoints">
                  <mat-option
                    *ngIf="accessPoint.accessPointExternalId !== gateControl.value?.accessPointExternalId"
                    [value]="accessPoint"
                  >
                    {{ accessPoint.accessPointName }}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="canLinkAccessPoint$ | async">
                  <ng-container *grecoLet="unlinkedAccessPoints$ | async as unlinkedAccessPoints">
                    <ng-container *ngIf="unlinkedAccessPoints?.length">
                      <mat-divider></mat-divider>
                      <mat-option disabled *ngIf="!linkedAccessPoints?.length">No gates linked!</mat-option>
                      <button
                        style="width: 100%"
                        mat-button
                        (click)="$event.stopImmediatePropagation()"
                        [matMenuTriggerFor]="unlinkedAccessPointsMenu"
                      >
                        <mat-icon>add</mat-icon>
                        <span>Link another gate</span>
                      </button>
                      <mat-menu #unlinkedAccessPointsMenu="matMenu">
                        <button
                          *ngFor="let accessPoint of unlinkedAccessPoints"
                          mat-menu-item
                          (click)="addAccessPoint(accessPoint.id, station!.id)"
                        >
                          <span>{{ accessPoint.name }}</span>
                        </button>
                      </mat-menu>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <button
              mat-stroked-button
              style="margin-top: 4px; margin-bottom: 4px; width: 100%; flex: 1"
              [disabled]="!gateControl.value"
              (click)="openGate(gateControl.value?.accessPointExternalId)"
            >
              Open Gate
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="community" class="container">
      <div *grecoLet="contact$ | async as contact" class="content">
        <div *ngIf="currentUser$ | async as user" class="user-detail-card">
          <!--
          Removed sale category filters based on this: https://app.clickup.com/t/86dqpru6h
          [saleCategoryIds]="((saleCategoryIds$ | async) || []).join(',')"
        -->
          <greco-user-detail-card
            [contact]="contact"
            [currentUser]="user"
            [community]="community"
            [autoCheckIn]="!autoCheckIn"
            (refreshBookingTable)="refreshBookingTable($event)"
            (refreshPurchaseHistory)="refreshPurchaseHistory($event)"
            (checkInUser)="autoCheckIn === false ? checkInUser($event.id, station) : false"
          ></greco-user-detail-card>
        </div>

        <greco-contact-notes-section *ngIf="contact" [contact]="contact"></greco-contact-notes-section>

        <greco-collapsible-section
          [expanded]="false"
          *ngIf="contact?.user?.id"
          [header]="{ title: 'Alert History', icon: 'notifications_active' }"
        >
          <greco-user-alert-history-table [userId]="contact!.user!.id"></greco-user-alert-history-table>
        </greco-collapsible-section>

        <div *grecoLet="canReadBookings$ | async as canReadBookings" class="booking">
          <greco-collapsible-section
            #details
            [header]="{ title: 'Bookings', icon: 'bookings' }"
            [expanded]="expanded"
            style="max-width: 70%"
          >
            <div header agreementButton class="booking-button-container" *ngIf="canCreateBookings$ | async">
              <button
                color="primary"
                *ngIf="contact?.user as user"
                (click)="openNewBookingDialog(user, contact); $event.stopImmediatePropagation()"
                mat-flat-button
              >
                <mat-icon>add</mat-icon>
                <span>Booking</span>
              </button>
            </div>

            <greco-tabs
              [tabs]="[
                { label: 'Today', template: today },
                { label: 'All Bookings', template: allBookings }
              ]"
              (tabChanged)="selectedTabIndex = $event.index"
            ></greco-tabs>

            <ng-template #today>
              <greco-bookings-table
                [hideCheckIn]="true"
                [hideDate]="true"
                [hideUser]="true"
                [onlyToday]="true"
                [hideResource]="false"
                [sortByEventDate]="true"
                [communityId]="canReadBookings ? community.id : ''"
                [filters]="(bookingFilters$ | async) || staticFilter"
                [onlyStaffCalendars]="true"
                [allowSpotReassignment]="false"
              >
              </greco-bookings-table>
            </ng-template>

            <ng-template #allBookings>
              <greco-bookings-table
                [hideCheckIn]="true"
                [hideUser]="true"
                [hideResource]="false"
                [sortByEventDate]="true"
                [communityId]="canReadBookings ? community.id : ''"
                [filters]="(bookingFilters$ | async) || staticFilter"
                [allowSpotReassignment]="false"
                [onlyStaffCalendars]="true"
              >
              </greco-bookings-table>
            </ng-template>
          </greco-collapsible-section>
        </div>

        <div class="payment">
          <!-- subscriptions -->
          <ng-template #subscriptions matTabContent>
            <ng-container *ngIf="canReadSubscriptions$ | async">
              <greco-subscriptions-table
                *ngIf="contact?.user as user"
                (rowClick)="openSubscriptionSummary($event, community.id, user)"
                [pageSizes]="[5]"
                [user]="contact?.user"
              ></greco-subscriptions-table>
            </ng-container>
          </ng-template>

          <!-- purchase -->
          <ng-template #purchases matTabContent>
            <ng-container *ngIf="canReadPurchases$ | async">
              <!--
              Removed sale category filters based on this: https://app.clickup.com/t/86dqpru6h
              [saleCategoryIds]="((saleCategoryIds$ | async) || []).join(',')"
            -->
              <greco-purchases-table
                *ngIf="contact?.user as user"
                #purchasesTable
                [userId]="user?.id"
                [pageSizes]="[5]"
                [isAdminPage]="true"
                [communityId]="community.id"
                [allCommunities]="allCommunities"
                [accountId]="community.financeAccountId"
                [saleCategoryIds]="(saleCategoryIds$ | async) || ''"
                [columns]="[
                  'icon',
                  'title',
                  'created',
                  'status',
                  'subtotal',
                  'taxes',
                  'total',
                  'balance_used',
                  'user_options'
                ]"
                (rowClick)="openPurchaseSummary($event, community.id, contact?.user)"
              ></greco-purchases-table>
            </ng-container>
          </ng-template>

          <!-- payment -->
          <ng-template #payment matTabContent>
            <ng-container *ngIf="canViewPaymentMethods$ | async">
              <greco-manage-user-payment-methods
                *ngIf="contact?.user as user"
                [userId]="user?.id"
                (defaultPaymentMethodUpdated)="defaultPaymentMethodUpdated($event, user)"
              ></greco-manage-user-payment-methods>
            </ng-container>
          </ng-template>

          <!-- perks -->
          <ng-template #perks matTabContent>
            <ng-container *grecoLet="canRevokePerks$ | async as canRevokePerks">
              <ng-container *ngIf="canReadPerks$ | async">
                <greco-user-perks-table
                  *ngIf="contact?.user as user"
                  [userId]="user?.id"
                  [communityId]="community?.id"
                  [canRevokePerks]="!!canRevokePerks"
                ></greco-user-perks-table>
              </ng-container>
            </ng-container>
          </ng-template>

          <!-- balance -->
          <ng-template #balance matTabContent>
            <ng-container *ngIf="currentUser$ | async as user">
              <ng-container *ngIf="canReadBalance$ | async">
                <greco-balance-history-section [userId]="contact?.user?.id"></greco-balance-history-section>
              </ng-container>
            </ng-container>
          </ng-template>

          <!-- Payment and Billings Section -->
          <ng-container *ngIf="tabs$ | async as tabs">
            <greco-collapsible-section
              *ngIf="tabs.length"
              [header]="{ title: 'Payments & Billings', icon: 'receipt_long' }"
              [expanded]="expanded"
            >
              <div [ngSwitch]="selectedTab" header class="payment-tabs">
                <ng-container *ngSwitchCase="'Purchase History'">
                  <mat-slide-toggle
                    color="primary"
                    [checked]="allCommunities"
                    (change)="allCommunities = $event.checked"
                    (click)="$event.stopImmediatePropagation()"
                  >
                    <span>All Communities</span>
                  </mat-slide-toggle>
                </ng-container>

                <ng-container *ngSwitchCase="'Payment Methods'">
                  <ng-container *ngIf="(canAddPaymentMethods$ | async) && contact?.user">
                    <greco-add-payment-method-button
                      header
                      style="margin-right: 12px"
                      [userId]="contact?.user?.id"
                      (saved)="userPaymentMethods.refresh()"
                      (click)="$event.stopImmediatePropagation()"
                    ></greco-add-payment-method-button>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'Access Perks'">
                  <ng-container *ngIf="(canGrantPerks$ | async) && contact?.user">
                    <button
                      *ngIf="canGrantPerks$ | async"
                      mat-flat-button
                      color="primary"
                      (click)="grantPerk(contact?.user)"
                    >
                      <mat-icon>add</mat-icon>
                      <span>Grant Perks</span>
                    </button>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'Account Balance'">
                  <ng-container *grecoLet="canFundBalance$ | async as canFundBalance">
                    <ng-container *grecoLet="canAdjustBalance$ | async as canAdjustBalance">
                      <ng-container *ngIf="(canAdjustBalance || canFundBalance) && contact?.user">
                        <button
                          mat-stroked-button
                          type="button"
                          color="primary"
                          class="quick-actions-button"
                          [matMenuTriggerFor]="accountBalanceMenu"
                          (click)="$event.stopImmediatePropagation()"
                        >
                          <span>Quick Actions</span>
                          <mat-icon>arrow_drop_down</mat-icon>
                        </button>

                        <mat-menu #accountBalanceMenu="matMenu" class="account-balance-menu">
                          <button *ngIf="canAdjustBalance" mat-menu-item (click)="adjustBalance(contact?.user)">
                            <mat-icon>account_balance_wallet</mat-icon>
                            <span>Adjust Balance</span>
                          </button>

                          <button *ngIf="canFundBalance" mat-menu-item (click)="fundAccount(contact?.user)">
                            <mat-icon>credit_card</mat-icon>
                            <span>Fund Account</span>
                          </button>
                        </mat-menu>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <greco-tabs [tabs]="tabs" (tabChanged)="selectedTab = tabs[$event.index].label || ''"></greco-tabs>
            </greco-collapsible-section>
          </ng-container>
        </div>

        <div class="linked-accounts">
          <greco-collapsible-section
            [header]="{ title: 'Linked Accounts', icon: 'supervisor_account' }"
            [expanded]="expanded"
            *ngIf="!contact; else linkedAccounts"
          ></greco-collapsible-section>
          <ng-template #linkedAccounts>
            <greco-linked-accounts-section
              *ngIf="contact && contact.user"
              [user]="contact.user"
              [communityId]="contact.community.id"
              [expanded]="false"
            ></greco-linked-accounts-section>
          </ng-template>
        </div>

        <!-- Check-ins -->
        <greco-collapsible-section
          *ngIf="contact && contact.user"
          id="bookings-section"
          [header]="{ title: 'Check-Ins', icon: 'location_on' }"
          [expanded]="false"
        >
          <greco-check-in-table [userId]="contact.user.id" [community]="contact.community"></greco-check-in-table>
        </greco-collapsible-section>

        <div class="agreements" *ngIf="canReadAgreement$ | async">
          <!--Agreements-->
          <greco-collapsible-section
            #details
            class="agreements"
            [header]="{ title: 'Agreements', icon: 'assignment' }"
            [expanded]="expanded"
          >
            <div header agreementButton class="agreement-button-container" *ngIf="canUpdateAgreement$ | async">
              <button
                *ngIf="contact"
                color="primary"
                mat-flat-button
                (click)="openSignAgreement(contact); $event.stopImmediatePropagation()"
              >
                <mat-icon>edit</mat-icon>
                <span>Sign</span>
              </button>
            </div>

            <greco-user-agreements-table
              #table
              *ngIf="contact?.user as user"
              [userId]="user.id"
              [pageSizes]="pageSizes"
              (rowClick)="table.previewPDF($event)"
            ></greco-user-agreements-table>
          </greco-collapsible-section>
        </div>
      </div>

      <div class="sidebar" *ngIf="canReadCheckInList$ | async">
        <ng-container *ngIf="(isMobile$ | async) !== true">
          <ng-container>
            <ng-container *ngIf="brivoCommunitySite$ | async as brivoCommunitySite">
              <!-- <div style="display: flex; justify-content: space-between">
                <div class="heading">Gate</div>

                <mat-checkbox
                  style="margin-top: -2px"
                  color="primary"
                  [checked]="syncEntries"
                  (change)="toggleSyncEntries()"
                >
                  <label>Display Gate Activity</label>
                </mat-checkbox>
              </div> -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  gap: 8px;
                  flex-wrap: wrap;
                  margin-bottom: 12px;
                "
                *grecoLet="linkedAccessPoints$ | async as linkedAccessPoints"
              >
                <mat-form-field appearance="outline" style="flex: 1">
                  <!-- <mat-label>Gate</mat-label> -->
                  <mat-select [formControl]="gateControl" placeholder="Select a gate...">
                    <mat-option *ngIf="gateControl.value" [value]="gateControl.value">{{
                      gateControl.value?.accessPointName
                    }}</mat-option>
                    <ng-container *ngFor="let accessPoint of linkedAccessPoints">
                      <mat-option
                        *ngIf="accessPoint.accessPointExternalId !== gateControl.value?.accessPointExternalId"
                        [value]="accessPoint"
                      >
                        {{ accessPoint.accessPointName }}
                      </mat-option>
                    </ng-container>
                    <ng-container *ngIf="canLinkAccessPoint$ | async">
                      <ng-container *grecoLet="unlinkedAccessPoints$ | async as unlinkedAccessPoints">
                        <ng-container *ngIf="unlinkedAccessPoints?.length">
                          <mat-divider></mat-divider>
                          <mat-option disabled *ngIf="!linkedAccessPoints?.length">No gates linked!</mat-option>
                          <button
                            style="width: 100%"
                            mat-button
                            (click)="$event.stopImmediatePropagation()"
                            [matMenuTriggerFor]="unlinkedAccessPointsMenu"
                          >
                            <mat-icon>add</mat-icon>
                            <span>Link another gate</span>
                          </button>
                          <mat-menu #unlinkedAccessPointsMenu="matMenu">
                            <button
                              *ngFor="let accessPoint of unlinkedAccessPoints"
                              mat-menu-item
                              (click)="addAccessPoint(accessPoint.id, station!.id)"
                            >
                              <span>{{ accessPoint.name }}</span>
                            </button>
                          </mat-menu>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

                <button
                  mat-stroked-button
                  style="margin-top: 4px; margin-bottom: 4px; width: 100%; flex: 1"
                  [disabled]="!gateControl.value"
                  (click)="openGate(gateControl.value?.accessPointExternalId)"
                >
                  Open Gate
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="station">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div class="heading">
              <button [disabled]="loading" [class.spin]="loading" mat-icon-button (click)="checkInList.refresh$.next()">
                <mat-icon>refresh</mat-icon>
              </button>
              Latest Check-Ins
            </div>
            <mat-checkbox
              style="margin-top: -2px"
              color="primary"
              [checked]="autoCheckIn"
              (change)="toggleAutoCheckIn()"
            >
              <label>Auto Check-In</label>
            </mat-checkbox>
          </div>
          <greco-station-checkin-list
            #checkInList
            [stationId]="station.id"
            [communityId]="community.id"
            [autoSync]="!!gateControl.value && syncEntries"
            (loading)="loading = $event"
            (refreshEntries)="getMostRecentAccessPointActivity()"
          >
          </greco-station-checkin-list
        ></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
