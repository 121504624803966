import { MatSpinner } from '@angular/material/progress-spinner';
import { HelpTypeformResolver } from '@greco/ngx-platform-customer-support';
import { WelcomeFormResolver } from '@greco/ngx-platform-dashboard';
import { GrecoRoute } from '@greco/ngx-routes-util';
import { TypeformAuthorizationResolver, TypeformEmbedPage, TypeformResolver } from '@greco/ngx-typeform';
import { EmbedComponent } from '@greco/ui-embed';
import { AdminRouteGuard, OnboardingGuard } from './guards';
import { AgreementTempPage, NotFoundPage, OnboardingPage, PrivacyPage, ScheduleWrapperPage, TermsPage } from './pages';

/**
 * !!!IMPORTANT!!! Remembed to update paths in: libs/greco/platform/util-reserved-paths/src/lib/reserved-paths.ts
 */
const root: GrecoRoute = {
  path: '',
  data: { title: '$APP' },
  canActivateChild: [OnboardingGuard],
  children: [
    {
      path: 'onboarding',
      component: OnboardingPage,
      data: {
        hideNavigation: true,
        hideBackButton: true,
      },
    },
    {
      path: 'typeform/registration',
      resolve: { typeform: TypeformAuthorizationResolver },
      component: MatSpinner,
    },

    {
      path: 'privacy',
      component: PrivacyPage,
    },
    {
      path: 'terms',
      component: TermsPage,
    },

    {
      path: 'account',
      data: { title: 'My Account' },
      loadChildren: () => import('@greco/ngx-route-user-profile').then(r => r.UserProfileRoutingModule),
    },

    // {
    //   path: 'workouts',
    //   data: { title: 'Workouts & Classes' },
    //   loadChildren: () => import('@greco/ngx-route-user-booking').then(r => r.UserBookingRoutingModule),
    // },

    {
      path: 'workouts',
      data: { title: 'Workouts & Classes' },
      loadChildren: () => import('@greco/ngx-route-booking').then(r => r.RouteBookingModule),
    },

    {
      path: 'workouts-new',
      data: { title: 'Workouts & Classes' },
      loadChildren: () => import('@greco/ngx-route-booking').then(r => r.RouteBookingModule),
    },

    {
      path: 'schedule/:communityId',
      data: { hideNavigation: true, hideEndContent: true, hideBackButton: true },
      component: ScheduleWrapperPage,
    },

    {
      path: 'membership-agreement',
      data: { title: 'Membership Agreement', hideNavigation: true },
      component: AgreementTempPage,
    },
    {
      path: 'music',
      data: {
        title: 'Workout Beats',
        link: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/687818006&color=%23685a8f&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
      },
      component: EmbedComponent,
    },

    {
      path: 'shop',
      data: { title: 'Shop & Perks' },
      loadChildren: () => import('@greco/ngx-route-shop').then(r => r.ShopRoutingModule),
    },
    // {
    //   path: 'cart',
    //   data: { title: 'Cart', hideNavigation: true },
    //   component: CartPage,
    // },
    // {
    //   path: 'checkout',
    //   data: { title: 'Checkout', hideNavigation: true },
    //   resolve: {
    //     items: CartItemsResolver,
    //   },
    //   component: CheckoutPage,
    // },
    {
      path: 'form/:formId',
      resolve: {
        typeform: TypeformResolver,
      },
      data: {
        hideNavigation: true,
      },
      component: TypeformEmbedPage,
    },

    {
      path: 'help',
      data: { hideNavigation: true },
      resolve: {
        typeform: HelpTypeformResolver,
      },
      component: TypeformEmbedPage,
    },

    {
      path: '',
      data: { title: 'Home' },
      loadChildren: () => import('@greco/ngx-route-dashboard').then(r => r.DashboardRoutingModule),
    },
    {
      path: 'welcome/:communityId',
      resolve: {
        typeform: WelcomeFormResolver,
      },
      data: {
        hideNavigation: true,
      },
      component: TypeformEmbedPage,
    },
    {
      path: 'admin',
      canActivateChild: [AdminRouteGuard],
      children: [
        {
          path: '**',
          component: NotFoundPage,
        },
      ],
    },
  ],
};

export const routes: GrecoRoute[] = [root];
