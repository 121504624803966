import { Component, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { DialogData } from '@greco/ui-dialog-simple';
import { WebcamDialog } from '../webcam';

@Component({
  selector: 'greco-change-profile-picture',
  templateUrl: './change-profile-picture.dialog.html',
  styleUrls: ['./change-profile-picture.dialog.scss'],
})
export class ChangeProfilePictureDialog implements OnChanges {
  error = false;
  errorString = '';
  dialogData: DialogData = {
    title: 'Change Profile Picture',
    showCloseButton: false,
  };

  user!: User;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ChangeProfilePictureDialog>,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    @Inject(MAT_DIALOG_DATA) private data: { user: User }
  ) {
    this.user = data.user;
  }

  resetValue: any = {
    photoURL: null,
  };

  profilePictureFile?: File;
  form = this.formBuilder.group({
    photoURL: [null],
  });

  save = async () => {
    try {
      if (this.profilePictureFile) {
        const formData = new FormData();
        formData.append('file', this.profilePictureFile);
        this.user = await this.userSvc.uploadUserPicture(this.user.id, formData);
      }

      this.reset();
      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.close();
    } catch (err) {
      this.snacks.open('' + 'Please input complete info.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
  };

  close() {
    this.dialogRef.close(this.user);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.previousValue !== changes.user?.currentValue) {
      this.reset();
    }
  }

  private reset() {
    this.resetValue = {
      photoURL: this.user?.photoURL || null,
    };

    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  setProfilePicture(file: File | null) {
    if (file) {
      this.profilePictureFile = file;
      this.form.setValue({ ...this.form.value, photoURL: URL.createObjectURL(file) });
    } else {
      this.profilePictureFile = undefined;
      this.form.setValue({ ...this.form.value, photoURL: this.user?.photoURL || null });
    }
  }

  async takePhoto() {
    const image = await toPromise(this.dialog.open(WebcamDialog).afterClosed());
    this.setProfilePicture(image);
  }
}
