import { HttpClientModule } from '@angular/common/http';
import { NgModule, Type } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CouponsModule } from '@greco/feature-coupons';
import { ADMIN_FEE_PURCHASE_ITEM_TYPE } from '@greco/finance-accounts';
import { AlertsModule } from '@greco/ngx-alerts';
import { ApiUtilsModule } from '@greco/ngx-api-util';
import { AppConfigModule } from '@greco/ngx-app-config';
import { BrivoWebModule } from '@greco/ngx-brivo';
import { PAYMENT_METHOD_VALIDATORS, PaymentsModule } from '@greco/ngx-finance-payments';
import { GiftCardsWebModule } from '@greco/ngx-gift-cards';
import { PlatformComponent, PlatformRootModule } from '@greco/ngx-route-platform-root';
import {
  DYNAMIC_FORM_CONTROL_TYPE_CATEGORY_PICKER,
  DYNAMIC_FORM_CONTROL_TYPE_PRODUCT_VARIANT_PICKER,
  DynamicFormControlCategoryPickerComponent,
  DynamicFormControlProductVariantPickerComponent,
  PRODUCT_CONDITION_CONFIG_ACTIVE_SUBSCRIPTION,
  PRODUCT_CONDITION_CONFIG_DATE_RANGE,
  PRODUCT_CONDITION_CONFIG_PURCHASE_CATEGORY_COUNT,
  PRODUCT_CONDITION_CONFIG_PURCHASE_COUNT,
  PRODUCT_CONDITION_CONFIG_SUBSCRIPTION_SALE_CATEGORY,
  PRODUCT_CONDITION_CONFIG_UNAVAILABLE,
  ProductCouponAssignmentFormComponent,
  ProductsModule,
  ProductSubscriptionHandlerFormComponent,
  ReturnInventoryDialog,
  SaleCategoryCouponAssignmentFormComponent,
} from '@greco/ngx-sales-products';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { SubscriptionsModule } from '@greco/ngx-sales-subscriptions';
import { SecurityUtilsModule } from '@greco/ngx-security-util';
import {
  BankAccountPaymentFormComponent,
  CreditCardPaymentMethodFormComponent,
  ManageTerminalsDialog,
  StripePaymentGatewayModule,
  TerminalService,
} from '@greco/ngx-stripe-payment-gateway';
import { TypeformModule } from '@greco/ngx-typeform';
import { ZoomModule } from '@greco/ngx-zoom';
import * as _productDomain from '@greco/sales-products';
import { IconPickerModule } from '@greco/ui-icon-picker';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { AccountLinkingModule } from '@greco/web-account-linking';
import {
  DISABLED_MATCHER_PROVIDER,
  DYNAMIC_FORM_CONTROL_MAP_FN,
  DynamicFormControl,
  DynamicFormControlModel,
  HIDDEN_MATCHER_PROVIDER,
  REQUIRED_MATCHER_PROVIDER,
} from '@ng-dynamic-forms/core';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { CustomUserToast } from '../custom.toast';
import { environment } from '../environments/environment';

// ENSURES CONDITIONS ARE LOADED
console.log(_productDomain);

@NgModule({
  entryComponents: [DynamicFormControlProductVariantPickerComponent, CustomUserToast],
  imports: [
    // Angular Default Dependencies
    HttpClientModule,

    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: CustomUserToast,
      preventDuplicates: false,
    }),

    SecurityUtilsModule.forRoot(false),

    AppConfigModule.forRoot({
      routeTitlePrefix: 'Altea',
      name: 'Altea',
      url: 'https://myaltea.club/',
      email: 'support@altea.club',

      userLinks: (_user, _openSignIn) => {
        return [
          {
            group: '',
            label: 'Booking',
            description: '',
            icon: 'today',
            url: '/workouts',
            color: 'accent',
          },
          {
            group: '',
            label: 'Shop',
            description: '',
            icon: 'shopping_bag',
            url: '/shop',
            color: 'accent',
          },
          // {
          //   group: '',
          //   label: 'LF3 Custom Meal Plan',
          //   description: '',
          //   icon: 'menu_book',
          //   url: '/form/P0Rt68le',
          //   disabled: !user,
          //   color: null,
          // },
          {
            group: '',
            label: 'Help / FAQ',
            description: '',
            icon: 'help_outline',
            url: '/help',
            color: null,
          },
        ];
      },
    }),
    IconPickerModule,

    // Subscriptions
    SubscriptionsModule.forRoot([
      {
        subscriptionType: 'ProductSubscriptionEntity',
        formComponent: ProductSubscriptionHandlerFormComponent,
        stepLabel: 'Product',
      },
    ]),
    ReactiveFormsModule,
    DynamicFormsMaterialUIModule,

    // Firebase Dependencies for Authentication
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,

    // Api Dependencies
    ApiUtilsModule.forRoot(environment.api.baseUrl),

    // Payment Gateways
    StripePaymentGatewayModule.forRoot(environment.stripe.clientID, environment.stripe.publishableKey, {
      useSimulator: environment.env === 'dev',
    }),
    PaymentsModule.forRoot(
      [
        { label: 'Credit Card', gateway: 'stripe', matIcon: 'payment', form: CreditCardPaymentMethodFormComponent },
        { label: 'Bank Account', gateway: 'stripe', matIcon: 'account_balance', form: BankAccountPaymentFormComponent },
      ],
      [
        { label: 'Manage Terminals', icon: 'open_in_new', dialog: ManageTerminalsDialog }, //
      ],
      [
        { provide: PAYMENT_METHOD_VALIDATORS, multi: true, useClass: TerminalService }, //
      ]
    ),

    CouponsModule.forRoot([
      {
        label: 'Product',
        matIcon: 'storefront',
        type: 'product',
        module: 'sales',
        form: ProductCouponAssignmentFormComponent,
      },
      {
        label: 'Sale Category',
        matIcon: 'category',
        type: 'saleCategory',
        module: 'sales',
        form: SaleCategoryCouponAssignmentFormComponent,
      },
    ]),

    ProductsModule.forRoot([
      /* Purchase Count */ PRODUCT_CONDITION_CONFIG_PURCHASE_COUNT,
      /* Purchase Category Count */ PRODUCT_CONDITION_CONFIG_PURCHASE_CATEGORY_COUNT,
      /* Active Subscriptions */ PRODUCT_CONDITION_CONFIG_ACTIVE_SUBSCRIPTION,
      /* Category Subscriptions */ PRODUCT_CONDITION_CONFIG_SUBSCRIPTION_SALE_CATEGORY,
      /* Date Range */ PRODUCT_CONDITION_CONFIG_DATE_RANGE,
      /* Unavailable */ PRODUCT_CONDITION_CONFIG_UNAVAILABLE,
    ]),
    PurchasesModule.forRoot(
      [
        {
          label: 'Admin Fee',
          matIcon: 'production_quantity_limits',
          type: ADMIN_FEE_PURCHASE_ITEM_TYPE,
        },
        {
          label: 'Balance Funding',
          matIcon: 'credit_card',
          type: 'FundUserBalancePurchaseItemEntity',
        },
        {
          label: 'Bookings',
          matIcon: 'event',
          type: 'BOOKING_PURCHASE_ITEM',
        },
        {
          label: 'Booking Boosters',
          matIcon: 'more_time',
          type: 'BOOKING_BOOSTER_PURCHASE_ITEM',
        },
        {
          label: 'Booking Late Cancellations',
          matIcon: 'event_busy',
          type: 'BOOKING_CANCELLATION_PURCHASE_ITEM',
        },
        {
          label: 'Custom Charge',
          matIcon: 'shopping_bag',
          type: 'Custom Charge',
        },
        {
          label: 'Products',
          matIcon: 'storefront',
          type: 'ProductPurchaseItemEntity',
        },
        {
          label: 'Subscriptions',
          matIcon: 'autorenew',
          type: 'SubscriptionPurchaseItemEntity',
        },
        {
          label: 'Gift Cards',
          matIcon: 'redeem',
          type: 'GIFT_CARD_PURCHASE_ITEM',
        },
        {
          label: 'Video Unlocks',
          matIcon: 'smart_display',
          type: 'VIDEO_PURCHASE_ITEM',
        },
        {
          label: 'LF3 Go Videos',
          matIcon: 'reset_tv',
          type: 'EVENT_VIDEO_PURCHASE_ITEM',
        },
        {
          label: 'Program Videos',
          matIcon: 'video_library',
          type: 'COLLECTION_VIDEO_PURCHASE_ITEM',
        },
      ],
      ReturnInventoryDialog,
      [TerminalService]
    ),

    NgxMaskModule.forRoot(),

    ZoomModule.forRoot(environment.zoom.clientId),
    TypeformModule.forRoot(environment.typeform),

    // App Routing
    PlatformRootModule.forRoot(environment.adminUrl),
    CalendarModule.forRoot(
      { provide: DateAdapter, useFactory: () => adapterFactory(moment) },
      { dateFormatter: { provide: CalendarDateFormatter, useClass: CalendarMomentDateFormatter } }
    ),
    AccountLinkingModule,
    AlertsModule,
    BrivoWebModule,
    UserAvatarModule,
    GiftCardsWebModule,
  ],
  providers: [
    { provide: MOMENT, useValue: moment },
    DISABLED_MATCHER_PROVIDER,
    REQUIRED_MATCHER_PROVIDER,
    HIDDEN_MATCHER_PROVIDER,
    {
      provide: DYNAMIC_FORM_CONTROL_MAP_FN,
      useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null => {
        switch (model.type) {
          case DYNAMIC_FORM_CONTROL_TYPE_CATEGORY_PICKER:
            return DynamicFormControlCategoryPickerComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_PRODUCT_VARIANT_PICKER:
            return DynamicFormControlProductVariantPickerComponent;
        }
      },
    },
  ],
  bootstrap: [PlatformComponent],
  declarations: [CustomUserToast],
})
export class AppModule {}
