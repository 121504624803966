import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Purchase } from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { PurchaseService } from '../../services';

@Component({
  templateUrl: './set-sold-by.dialog.html',
})
export class SetPurchaseSoldByDialog implements OnInit {
  readonly simpleDialogData: DialogData = {
    title: 'Sold by',
    subtitle: "Update the 'Sold by' property of this purchase.",
    hideDefaultButton: true,
  };

  soldByFormControl = new FormControl(null);

  accountId: string;

  constructor(
    private purchaseSvc: PurchaseService,
    @Inject(MAT_DIALOG_DATA) private purchase: Purchase,
    private matDialogRef: MatDialogRef<SetPurchaseSoldByDialog>
  ) {
    this.accountId = purchase.account.id;
  }

  ngOnInit() {
    setTimeout(() => this.soldByFormControl.reset(this.purchase.soldBy));
  }

  cancel() {
    this.matDialogRef.close();
  }

  async submit() {
    try {
      const update = await this.purchaseSvc.setSoldBy(this.purchase.id, this.soldByFormControl.value?.id ?? null);
      this.matDialogRef.close(update);
    } catch (err) {
      console.error(err);
    }
  }
}
