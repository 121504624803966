import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { ADMIN_URL } from '../tokens';

@Injectable()
export class AdminRouteGuard implements CanActivate, CanActivateChild {
  constructor(@Inject(ADMIN_URL) private adminUrl: string) {}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const url = route.url.map(segment => segment.path).join('/');

    const newUrl = `${this.adminUrl}/${url.replace('admin/', '')}`;

    let params = '';
    if (route.queryParams) {
      params = `?${Object.keys(route.queryParams)
        .map(key => {
          if (Array.isArray(route.queryParams[key])) {
            return route.queryParams[key].map(item => `${key}=${item}`).join('&');
          } else {
            const value = `${key}=${route.queryParams[key]}`;

            return value;
          }
        })
        .join('&')}`;
    }

    window.location.href = newUrl + params;

    return true;
  }
}
