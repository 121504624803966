import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Invoice } from '@greco/finance-invoices';
import { DialogData, SimpleDialog } from '@greco/ui-dialog-simple';
import type { Stripe } from 'stripe';
import { GrecoPrice } from '../../models';
import { GrecoPriceService } from '../../services';
import { GrecoStudioService } from '../../services/studio.service';

interface GrecoTransactionFee {
  type: 'service-fee' | 'royalties' | 'payment-processing-fee' | 'covid-fee' | 'covid-fee-2';
  title: string;
  subtitle?: string;
  info?: string;
  amount: number;
}

@Component({
  selector: 'greco-invoice-page',
  templateUrl: './invoice.page.html',
  styleUrls: ['./invoice.page.scss'],
})
export class InvoicePage implements OnChanges {
  constructor(private dialog: MatDialog, private priceSvc: GrecoPriceService, private studioSvc: GrecoStudioService) {}

  @Input() invoice!: Invoice;

  totalAfterFees?: number;
  totalRefundedAmount?: number;

  balanceApplied?: number;
  discount?: number;

  fees?: GrecoTransactionFee[];
  totalFees?: number;

  recurring?: boolean;
  covid?: boolean;

  expanded: null | string = null;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.invoice.previousValue !== changes.invoice.currentValue) {
      const [prices, studios] = await Promise.all([
        toPromise(this.priceSvc.prices$),
        toPromise(this.studioSvc.studios$),
      ]);

      const studio = studios.find(s => s.id === this.invoice.studio);

      this.recurring = !!this.invoice.data.subscription;
      this.balanceApplied = Math.min(
        this.invoice.data.ending_balance - this.invoice.data.starting_balance,
        this.invoice.data.total
      );
      this.discount = this.getCouponSavings(this.invoice.data.subtotal, this.invoice.data.discount?.coupon);

      const covidEndDate = studio ? studio.retry?.from?.toDate() : null;
      const isPostCovid = studio ? (covidEndDate ? this.invoice.data.created.toDate() >= covidEndDate : true) : false;

      const onlineFees =
        this.invoice.voidFees || studio?.id === 'online'
          ? []
          : this.invoice.data.lines.data
              .filter(line => line?.price)
              .map(line => {
                const price = prices.find(p => p.ID === line.price?.id);
                const covidRatio =
                  !price?.ignoreCovid && !isPostCovid
                    ? Math.min(
                        1,
                        ((covidEndDate ? covidEndDate.getTime() / 1000 : line.period.end) - line.period.start) /
                          (line.period.end - line.period.start)
                      )
                    : 0;
                // TODO(adaoust): Un-hardcode that Online Class Booking Credits are 50% (use product for credit packs?)
                const feeAmount =
                  (line.amount + (line.tax_amounts || []).reduce((acc, ta) => acc + ta?.amount || 0, 0)) *
                  ((line.description?.includes('Online Class Booking Credit')
                    ? 50
                    : price?.price.transferPercentage || 0) /
                    100) *
                  (line.quantity || 0);
                return {
                  type: 'service-fee',
                  title: 'Online Service Fee',
                  subtitle: line.description,
                  amount: (1 - covidRatio) * feeAmount,
                };
              });

      const chargeAmount = this.getChargeAmount(this.invoice.data);
      const paymentProcessingFee =
        this.invoice.data.post_payment_credit_notes_amount === this.invoice.data.amount_due
          ? 0
          : this.getPaymentProcessingFee(this.invoice.data);

      this.covid =
        (studio &&
          !isPostCovid &&
          this.invoice.data.subscription &&
          this.invoice.data.lines.data
            ?.map(l => prices.find(p => p?.ID === l?.price?.id))
            .some(p => !p?.ignoreCovid)) ||
        false;

      const covidFees = this.covid ? this.getCovidFees(this.invoice.data, prices, covidEndDate || undefined) : null;

      const preRoyaltiesFees = [
        ...(studio && covidFees?.length ? covidFees.filter(c => c.type === 'covid-fee') : []),
        ...(studio ? onlineFees : ([] as any)),
      ].reduce((acc, fee) => acc + fee.amount, 0);

      this.totalRefundedAmount = this.invoice.data.charge?.amount_refunded || 0;
      this.fees = [
        ...(studio && covidFees?.length ? covidFees.filter(c => c.type === 'covid-fee') : []),
        ...(studio && covidFees?.length ? covidFees.filter(c => c.type === 'covid-fee-2') : ([] as any)),
        ...(studio ? onlineFees : ([] as any)),
        {
          type: 'payment-processing-fee',
          title: 'Payment Processing Fee',
          amount: paymentProcessingFee,
          subtitle: 'Merchant and processing fees',
          info:
            '<strong>Amex:</strong> 3.2% + $0.30<br/><strong>Other Cards:</strong> 2.7% + $0.30<br/><br/>' +
            (this.recurring ? '+0.5% Recurring Billing Fee' : ''),
        },
        {
          type: 'royalties',
          title: 'Royalties',
          amount:
            (Math.max(0, chargeAmount - this.totalRefundedAmount - preRoyaltiesFees) *
              (studio && studio.royalties ? studio.royalties : 0)) /
            100,
          subtitle: 'Greco Studio Royalties',
        },
      ].filter(f => f.amount);

      this.totalFees = this.fees.reduce((acc, fee) => acc + fee.amount, 0);
      this.totalAfterFees = chargeAmount - this.totalRefundedAmount - this.totalFees;
    }
  }

  openPaymentProcessingFeeDialog() {
    this.dialog.open(SimpleDialog, {
      data: {
        title: 'Payment Processing Fee',
        content: `<strong>Amex:</strong> 3.2% + $0.30<br><strong>Other Cards:</strong> 2.7% + $0.30`,
        showCloseButton: false,
        buttons: [{ label: 'Got it', role: 'cancel' }],
      } as DialogData,
    });
  }

  private getCouponSavings(price: number, coupon?: Stripe.Coupon) {
    if (coupon?.amount_off) return Math.min(coupon.amount_off, price);
    if (coupon?.percent_off) return (coupon.percent_off / 100) * price;
    return 0;
  }

  private getChargeAmount(invoice: Stripe.Invoice & { charge: Stripe.Charge }) {
    return invoice?.charge
      ? invoice?.charge.amount >= 50
        ? invoice?.charge.amount
        : 0
      : invoice?.metadata?.payment_intent
      ? invoice?.amount_remaining || 0
      : 0;
  }

  private getPaymentProcessingFee(invoice: Stripe.Invoice & { charge: Stripe.Charge }) {
    const newStripeFeeAgreement = new Date('08-20-2020').getTime() >= invoice.created * 1000;
    const chargeAmount = this.getChargeAmount(invoice);
    return chargeAmount > 0
      ? newStripeFeeAgreement
        ? chargeAmount * (0.027 + (invoice?.subscription ? 0.005 : 0)) + 30
        : chargeAmount * (0.029 + (invoice?.subscription ? 0.005 : 0)) + 30
      : 0;
  }

  private getCovidFees(
    invoice: Stripe.Invoice & { charge: Stripe.Charge },
    allPrices: GrecoPrice[],
    covidEndDate?: Date
  ) {
    const covidItems = invoice.lines.data
      ?.map(l => ({ p: allPrices.find(p => p?.ID === l?.price?.id), l }))
      .filter(p => !p?.p?.ignoreCovid)
      .map(p => p?.l);
    const covidFees = covidItems.map(line => {
      const ratio = Math.min(
        1,
        ((covidEndDate ? covidEndDate.getTime() / 1000 : line.period.end) - line.period.start) /
          (line.period.end - line.period.start)
      );
      return {
        type: 'covid-fee',
        ratio,
        title: 'GMS (Government Mandated Shutdown)',
        amount:
          (line.amount + (line.tax_amounts?.reduce((acc, ta) => acc + (ta.amount || 0), 0) || 0)) *
          ratio *
          (line.quantity || 0),
        subtitle: line.description,
        info: 'Refer to Section 5 "Greco Method at Home" in the Amendment to the Franchise Agreement for detail on how these fees have been treated during the Government Mandated Shutdown.',
      };
    });

    const totalCovidFees = covidFees.reduce((acc, cur) => acc + (cur?.amount || 0), 0);

    // Remove fees & refunds from all items based on their ratio.
    const paymentProcessingFees = this.getPaymentProcessingFee(invoice);
    const refundAmount = invoice.charge?.amount_refunded || 0;
    const balanceUsedAmount = (invoice.ending_balance || 0) - invoice.starting_balance;

    return [
      ...covidFees.map(fee => {
        // const itemRatio = totalCovidFees > 0 ? fee.amount / totalCovidFees : 0;
        return {
          ...fee,
          amount: fee.amount,
        };
      }),
      ...covidFees.map(fee => {
        const itemRatio = totalCovidFees > 0 ? fee.amount / totalCovidFees : 0;
        return {
          ...fee,
          type: 'covid-fee-2',
          title: 'GMS (Adjustements | Fees, Refunds, Balance)',
          amount: -fee.ratio * itemRatio * (refundAmount + paymentProcessingFees + balanceUsedAmount),
        };
      }),
    ];
  }
}
