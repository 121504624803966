import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco/ui-dialog-simple';
import { VideoSourceRequestDto } from '@greco/videos';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './video-select.component.html',
  styleUrls: ['./video-select.component.scss'],
})
export class VideoSelectComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { source: string },
    public dialogRef: MatDialogRef<VideoSelectComponent>
  ) {}

  dialogData: DialogData = {
    title: 'Select Videos to Add',
    showCloseButton: true,
  };

  selected$ = new BehaviorSubject<VideoSourceRequestDto[] | null>(null);

  confirmed(items: VideoSourceRequestDto[]) {
    this.dialogRef.close(items);
  }
}
