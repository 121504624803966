import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tax } from '@greco/finance-tax';
import { Product, ProductOption, ProductVariantVisibility } from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import moment from 'moment';
import { VariantsService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-create-variant',
  templateUrl: './create-variant.dialog.html',
  styleUrls: ['./create-variant.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateVariantDialog implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CreateVariantDialog>,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private variantsService: VariantsService,
    @Inject(MAT_DIALOG_DATA) private data: { options: ProductOption[]; product: Product }
  ) {}

  product = this.data.product;

  options = this.data.options;

  dialogData: DialogData = {
    title: 'Create Variant',
    subtitle: 'Fill out the necessary fields to create a variant',
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({});
  controlsConfig: { [key: string]: any } = {};

  processing = false;
  isValidForProrateStart = false;

  minimalDate = moment().add(1, 'days').toDate();
  maximalDate = moment().add(5, 'years').toDate();

  ngOnInit() {
    this.options.forEach(option => {
      this.controlsConfig[`${option.id}`] = [null, Validators.required];
    });
    this.controlsConfig['variantPrice'] = [
      { price: 0, recurrence: null, autoUpgradeVariant: null, taxes: [], ignoreTaxes: false },
      Validators.required,
    ];
    this.controlsConfig['prorateStart'] = null;
    this.controlsConfig['visibility'] = ProductVariantVisibility.VISIBLE;
    this.form = this.formBuilder.group(this.controlsConfig);

    this.onChange();
  }

  onChange() {
    this.form.get('variantPrice')?.valueChanges.subscribe(x => {
      //isValid only if indefinite && weekly
      this.isValidForProrateStart = x?.recurrence?.cycles === null && x?.recurrence?.period === 'WEEKLY' ? true : false;
    });
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const variant = await this.variantsService.createVariant({
        productId: this.data.product.id,
        data: {
          paymentTypes: this.form.value.variantPrice.paymentTypes || ['card'],
          price: this.form.value.variantPrice.price,
          recurrence: this.form.value.variantPrice.recurrence
            ? {
                frequency: this.form.value.variantPrice.recurrence.frequency,
                period: this.form.value.variantPrice.recurrence.period,
                cycles: this.form.value.variantPrice.recurrence.cycles,
              }
            : { frequency: null, period: null, cycles: null },
          minimumCommitment: this.form.value.variantPrice.minimumCommitment
            ? {
                length: this.form.value.variantPrice.minimumCommitment.length,
                period: this.form.value.variantPrice.minimumCommitment.period,
              }
            : { length: null, period: null },
          autoUpgradeVariantId: this.form.value.variantPrice?.autoUpgradeVariant?.id || null,
          variantOpts: this.getVariantOptions(),
          imageId: this.data.product.images[0]?.id, // TODO: Change this @diegobajetti
          prorateStart: new Date(moment(this.form.value.prorateStart).format('YYYY-MM-DD HH:mm:ss')) || null,

          visibility: this.form.value.visibility,

          enrolmentFee: this.form.value.variantPrice.enrolmentFee || null,
          enrolmentFeeName: this.form.value.variantPrice.enrolmentFeeName || null,
          enrolmentFeeDescription: this.form.value.variantPrice.enrolmentFeeDescription || null,
          enrolmentFeeSalesCategoryId: this.form.value.variantPrice.enrolmentFeeSaleCategory?.id || null,

          taxes: this.form.value.variantPrice.taxes?.map((tax: Tax) => tax.id) || [],
          ignoreTaxes: this.form.value.variantPrice.ignoreTaxes || false,
        },
      });
      this.snacks.open('Variant created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.close(variant);
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
      this.close(null);
    }

    this.processing = false;
  }

  private getVariantOptions() {
    const result = [];
    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      result.push({ productOptionId: option.id, value: eval(`this.form.value.${option.id}`) });
    }
    return result;
  }
}
