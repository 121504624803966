import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  BookingOption,
  CalendarEvent,
  Event,
  EventOverview,
  EventSeries,
  EventSeriesInstance,
  ResourceAvailabilityStatus,
  WaitlistItem,
} from '@greco/booking-events';
import { DataExport } from '@greco/data-exports';
import type {
  CheckSeriesResourceAvailabilityDto,
  CreateEventDto,
  CreateEventFromTemplateDto,
  CreateEventSeriesDto,
  CreateSeriesFromTemplateDto,
  PreviewUpdateSeriesScheduleDto,
  QueryEventsByDateDto,
  UpdateEventDto,
  UpdateSeriesDetailsDto,
  UpdateSeriesScheduleDto,
} from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class EventService {
  constructor(private http: HttpClient) {}

  // Get(:events)
  async getBookingOptionsByEvent(eventId: string, userId?: string, isCourseOption?: boolean) {
    return await toPromise(
      this.http.get<BookingOption[]>(`/api/events/${eventId}/options`, {
        params: {
          ...(userId && { userId }),
          ...(isCourseOption && { isCourseOption: isCourseOption.toString() }),
        },
      })
    );
  }
  // Get(:events)
  async getBookingOptionsByEventTags(tags: string[], userId?: string, isCourseOption?: boolean) {
    return await toPromise(
      this.http.get<BookingOption[]>(`/api/events/options-by-tags`, {
        params: {
          tags: tags.join(','),
          ...(userId && { userId }),
          ...(isCourseOption && { isCourseOption: isCourseOption.toString() }),
        },
      })
    );
  }

  // @Get(':seriesId/options')
  async getBookingOptionsBySeries(seriesId: string, userId?: string) {
    return await toPromise(
      this.http.get<BookingOption[]>(`/api/events/${seriesId}/options/series`, {
        params: { ...(userId && { userId }) },
      })
    );
  }

  // @Get('paginate-series')
  async paginateSeries(
    query: RequestQueryBuilder,
    communityId: string,
    calendarIds: string[],
    pagination?: Partial<PaginatedQueryParams>,
    includeInactive?: boolean
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<EventSeries>>('/api/events/paginate-series', {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          communityId,
          calendarIds: calendarIds.join(','),
          includeInactive: includeInactive === true ? 'true' : 'false',
        },
      })
    );
  }

  // @Post()
  async createEvent(dto: CreateEventDto) {
    const fd = new FormData();
    if (typeof dto?.imageUrl !== 'string' && dto?.imageUrl) fd.append('file', dto.imageUrl[0]);
    fd.append('data', JSON.stringify(dto));
    return await toPromise(this.http.post<Event>('/api/events/create-event', fd));
  }

  // @Post(create-series)
  async createSeries(dto: CreateEventSeriesDto) {
    const fd = new FormData();
    if (typeof dto?.imageUrl !== 'string' && dto?.imageUrl) fd.append('file', dto.imageUrl[0]);
    fd.append('data', JSON.stringify(dto));
    return await toPromise(this.http.post<EventSeries>('/api/events/create-series', fd));
  }

  async createEventFromTemplate(body: CreateEventFromTemplateDto) {
    const fd = new FormData();

    if (typeof body.createEventDto?.imageUrl !== 'string' && body.createEventDto?.imageUrl) {
      fd.append('file', body.createEventDto.imageUrl[0]);
    }

    fd.append('data', JSON.stringify(body));

    return await toPromise(this.http.post<Event>('/api/events/event-from-template', fd));
  }

  async createSeriesFromTemplate(body: CreateSeriesFromTemplateDto) {
    const fd = new FormData();
    if (typeof body.createEventSeriesDto?.imageUrl !== 'string' && body.createEventSeriesDto?.imageUrl)
      fd.append('file', body.createEventSeriesDto.imageUrl[0]);
    fd.append('data', JSON.stringify(body));
    return await toPromise(this.http.post<EventSeries>('/api/events/series-from-template', fd));
  }

  // @Get(':eventId')
  async getOneEvent(eventId: string, includeBooking = false) {
    return await toPromise(
      this.http.get<CalendarEvent>(`/api/events/event/${eventId}`, {
        params: { includeBooking: includeBooking.toString() },
      })
    );
  }

  // @Get(':seriesId')
  async getOneSeries(seriesId: string) {
    return await toPromise(this.http.get<EventSeries>(`/api/events/series/${seriesId}`));
  }

  // @Put(':eventId')
  async updateEventDetails(eventId: string, dto: UpdateEventDto) {
    const fd = new FormData();
    if (typeof dto?.imageUrl !== 'string' && dto?.imageUrl) fd.append('file', dto.imageUrl[0]);
    fd.append('data', JSON.stringify(dto));
    return await toPromise(this.http.put<CalendarEvent>(`/api/events/${eventId}`, fd));
  }

  // @Put(':seriesId/details')
  async updateSeriesDetails(seriesId: string, dto: UpdateSeriesDetailsDto) {
    const fd = new FormData();
    if (typeof dto?.imageUrl !== 'string' && dto?.imageUrl) fd.append('file', dto.imageUrl[0]);
    fd.append('data', JSON.stringify(dto));
    return await toPromise(this.http.put<EventSeries>(`/api/events/${seriesId}/details`, fd));
  }

  // @Put(':seriesId/schedule')
  async updateSeriesSchedule(seriesId: string, dto: UpdateSeriesScheduleDto) {
    return await toPromise(this.http.put<EventSeries>(`/api/events/${seriesId}/schedule`, dto));
  }

  // @Post(':seriesId/schedule')
  async previewUpdateSeriesSchedule(seriesId: string, dto: PreviewUpdateSeriesScheduleDto) {
    return await toPromise(
      this.http.post<{ toCancel: EventSeriesInstance[] }>(`/api/events/${seriesId}/schedule`, dto)
    );
  }

  // @Post(':eventId/cancel')
  async cancelEvent(eventId: string) {
    return await toPromise(this.http.post<CalendarEvent>(`/api/events/${eventId}/cancel`, null));
  }

  getEventsByDate(dto: QueryEventsByDateDto, forceCalFilter = false): Promise<CalendarEvent[]> {
    return toPromise(
      this.http.get<CalendarEvent[]>('/api/events/public', {
        params: {
          ...(dto.calendars?.length || forceCalFilter ? { calendars: dto.calendars ?? [] } : {}),
          ...(dto.communities?.length ? { communities: dto.communities } : {}),
          includePrivate: dto.includePrivate === true ? 'true' : 'false',
          ...(dto.resources?.length ? { resources: dto.resources } : {}),
          ...(dto.resourceTagIds?.length ? { resourceTagIds: dto.resourceTagIds } : {}),
          includeOpenEvents: dto.includeOpenEvents === true ? 'true' : 'false',
          ...(dto.statuses?.length ? { statuses: dto.statuses } : {}),
          ...(dto.description && { description: dto.description }),
          ...(dto.tags?.length ? { tags: dto.tags } : {}),
          ...(dto.search && { search: dto.search }),
          ...(dto.title && { title: dto.title }),
          includeCourse: dto.includeCourse === true ? 'true' : 'false',
          eventHasConfirmedResource: dto.eventHasConfirmedResource === true ? 'true' : 'false',
          eventNeedsSubstitution: dto.eventNeedsSubstitution === true ? 'true' : 'false',
          eventNeedsResource: dto.eventNeedsResource === true ? 'true' : 'false',
          showCourseInstances: dto.showCourseInstances === true ? 'true' : 'false',
          showOnlyCourseInstances: dto.showOnlyCourseInstances === true ? 'true' : 'false',
          onlyCalsSpecified: forceCalFilter === true ? 'true' : 'false',
          startDate: dto.startDate.toISOString(),
          endDate: dto.endDate.toISOString(),
        },
      })
    );
  }

  getEventsForSchedule(dto: QueryEventsByDateDto, forceCalFilter = false): Promise<EventOverview[]> {
    return toPromise(
      this.http.get<EventOverview[]>('/api/events/public/schedule', {
        params: {
          ...(dto.calendars?.length || forceCalFilter ? { calendars: dto.calendars ?? [] } : {}),
          ...(dto.communities?.length ? { communities: dto.communities } : {}),
          includePrivate: dto.includePrivate === true ? 'true' : 'false',
          ...(dto.resources?.length ? { resources: dto.resources } : {}),
          ...(dto.resourceTagIds?.length ? { resourceTagIds: dto.resourceTagIds } : {}),
          includeOpenEvents: dto.includeOpenEvents === true ? 'true' : 'false',
          ...(dto.statuses?.length ? { statuses: dto.statuses } : {}),
          ...(dto.description && { description: dto.description }),
          ...(dto.tags?.length ? { tags: dto.tags } : {}),
          ...(dto.search && { search: dto.search }),
          ...(dto.title && { title: dto.title }),
          includeCourse: dto.includeCourse === true ? 'true' : 'false',
          eventHasConfirmedResource: dto.eventHasConfirmedResource === true ? 'true' : 'false',
          eventNeedsSubstitution: dto.eventNeedsSubstitution === true ? 'true' : 'false',
          eventNeedsResource: dto.eventNeedsResource === true ? 'true' : 'false',
          showCourseInstances: dto.showCourseInstances === true ? 'true' : 'false',
          showOnlyCourseInstances: dto.showOnlyCourseInstances === true ? 'true' : 'false',
          onlyCalsSpecified: forceCalFilter === true ? 'true' : 'false',
          startDate: dto.startDate.toISOString(),
          endDate: dto.endDate.toISOString(),
        },
      })
    );
  }

  // @Get('event-availability/all-busy')
  async getBusyResources(startDate: Date, endDate: Date, eventId?: string) {
    return await toPromise(
      this.http.get<{ [id: string]: ResourceAvailabilityStatus }>(`/api/events/event-availability/all-busy`, {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          ...(eventId && { eventId }),
        },
      })
    );
  }

  //@Get('event-availability/:resourceId')
  async verifyEventResourceAvailability(resourceId: string, startDate: Date, endDate: Date, eventId?: string) {
    const result = await toPromise(
      this.http.get<{ status: ResourceAvailabilityStatus }>(`/api/events/event-availability/${resourceId}`, {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          ...(eventId && { eventId }),
        },
      })
    );
    return result.status;
  }

  //@Get('series-availability/:resourceId')
  async verifySeriesResourceAvailability(resourceId: string, dto: CheckSeriesResourceAvailabilityDto) {
    const result = await toPromise(
      this.http.get<{ status: ResourceAvailabilityStatus }>(`/api/events/series-availability/${resourceId}`, {
        params: {
          ...(dto.seriesId && { seriesId: dto.seriesId }),
          seriesRecurrence: dto.seriesRecurrence || [],
          startDate: dto.startDate.toISOString(),
          ...(dto.endDate && { endDate: dto.endDate.toISOString() }),
          duration: dto.duration,
        },
      })
    );
    return result.status;
  }

  // @Get(':eventId/waitlist')
  async getWaitlist(eventId: string, query?: RequestQueryBuilder, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<WaitlistItem>>(`/api/events/${eventId}/waitlist`, {
        params: {
          ...query?.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // Get('export')
  async export(dto: QueryEventsByDateDto, skip?: number) {
    return await toPromise(
      this.http.post<DataExport>('/api/events/export', { ...dto, params: { ...(skip && { skip: skip.toString() }) } })
    );
  }
}
