import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { TerminalResource, TerminalResourceAction } from '@greco/finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  Purchase,
  PurchaseItem,
  PurchaseResource,
  PurchaseResourceAction,
  PurchaseStatus,
} from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RETURN_TO_INVENTORY_DIALOG } from '../../return-to-inventory.token';
import { PurchaseService } from '../../services';

function validateBalanceRefundAmount(maxRefund: number) {
  return (formGroup: FormGroup) => {
    const balanceRefundAmount = formGroup.controls['balanceRefundAmount'];
    const paymentRefundAmount = formGroup.controls['paymentRefundAmount'];

    if (maxRefund < 0) {
      if (balanceRefundAmount.value > (maxRefund - paymentRefundAmount.value) * -1) {
        balanceRefundAmount.setErrors({ invalid: true });
      } else balanceRefundAmount.setErrors(null);
    } else if (balanceRefundAmount.value > maxRefund - paymentRefundAmount.value) {
      balanceRefundAmount.setErrors({ invalid: true });
    } else balanceRefundAmount.setErrors(null);
  };
}

@Component({
  selector: 'greco-refund-purchase-dialog',
  templateUrl: './refund.dialog.html',
  styleUrls: ['./refund.dialog.scss'],
})
export class RefundPurchaseDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<RefundPurchaseDialog>,
    @Inject(MAT_DIALOG_DATA) private readonly _purchase: Purchase,
    @Inject(RETURN_TO_INVENTORY_DIALOG) public readonly returnInventoryDialog: any
  ) {
    this.purchase = _purchase;
  }

  readonly terminalControl = new FormControl(null);

  readonly dialogData: DialogData = {
    title: 'Refund Purchase',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  @PropertyListener('purchase') purchase$ = new BehaviorSubject<Purchase | null>(null);
  purchase!: Purchase;

  @PropertyListener('selectedRefundItem') selectedRefundItem$ = new BehaviorSubject<PurchaseItem | ''>('');
  selectedRefundItem: PurchaseItem | '' = '';

  formGroup = this.formBuilder.group({
    balanceRefundAmount: [{ value: 0, disabled: false }],
    paymentRefundAmount: [{ value: 0, disabled: !this.purchase?.payment?.amount }],
    refundReason: ['', [Validators.required]],
    returnToInventory: [false],
  });

  refundReasons = [
    'Admin Error',
    'Banned Member',
    'Double Charge',
    'Guest Pass Refund for New Member Sign-Up',
    'Incorrect Product Purchased',
    'Incorrect Booking',
    'Incorrect payment method',
    'Member Experience',
    'Service Quality',
    'Club Service Changed',
    "Membership Hold wasn't applied",
    'Realign Billing Period',
    'Product Change',
    'Price Change',
    'Product Revoked',
    'Promotional Discount',
    'Tax Exemption',
    'Other',
  ];

  membershipCancellationReasons = [
    'Membership Cancellation',
    'Membership Cancelled Rescind',
    'Membership Cancelled Relocation',
    'Membership Cancelled Medical',
    'Member Cancelled Service Quality',
    'Member Cancelled End of Commitment Period',
    'Member Cancelled Other',
  ];

  membershipTransferReasons = ['Membership Transfer', 'Home Club Transfer'];

  personalTrainingReasons = [
    'PT Cancellation',
    'PT Cancelled Rescind',
    'PT Cancelled Medical',
    'PT Cancelled Relocation',
    'PT Package Change',
    'PT Cancelled Service Quality',
    'PT Cancelled Other',
  ];

  activeKidsClubReasons = [
    'AKC Cancellation',
    'AKC Cancelled Rescind',
    'AKC Cancelled Relocation',
    'AKC Cancelled Medical',
    'AKC Cancelled Service Quality',
    'AKC Cancelled Other',
  ];

  waiveLateFeeReasons = [
    'Waive (AKC)',
    'Waive (Booking Error)',
    'Waive (Facilities/Equipment)',
    'Waive (Medical)',
    'Waive (Member Experience)',
    'Waive (Missed Check-In)',
    'Waive (Other)',
  ];

  processing = false;

  canRefundToBalance$ = this.securitySvc.hasAccess(
    PurchaseResource.key,
    PurchaseResourceAction.REFUND_TO_BALANCE,
    {},
    true
  );

  canRefundToPayment$ = Promise.all([
    this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_PAYMENT_METHOD, {}, true),
    this.securitySvc.hasAccess(TerminalResource.key, TerminalResourceAction.USE_TERMINALS, {}, true),
  ]).then(
    ([canRefund, canUseTerminals]) =>
      canRefund && (this._purchase.payment?.gatewayMetadata === 'interac_refund' ? canUseTerminals : true)
  );

  isInteracPayment = this._purchase.payment?.gatewayMetadata?.payment_method_type === 'interac_present';

  items$ = this.purchase$.pipe(
    map(
      purchase =>
        purchase?.items
          .filter(item => !item.voidedDate && item.price > 0)
          .map(item => {
            const subtotal = item.price * item.quantity;
            const taxes =
              subtotal * (item.taxes?.map(tax => tax.percentage / 100).reduce((acc, tax) => acc + tax, 0) || 0);
            return { ...item, total: Math.round(subtotal + taxes) };
          }) || []
    )
  );

  otherRefundsAmountForItem$ = this.selectedRefundItem$.pipe(
    switchMap(async selectedRefundItem => {
      if (!selectedRefundItem) return 0;

      const otherRefunds = (await this.purchaseSvc.getPurchaseRefunds(selectedRefundItem.purchaseId)).filter(
        refund => refund.itemId === selectedRefundItem.id
      );
      if (!otherRefunds.length) return 0;
      return otherRefunds.reduce(
        (acc, refund) =>
          acc + (refund.balanceRefundedAmount + refund.paymentMethodRefundedAmount - (refund.balanceUndoneAmount || 0)),
        0
      );
    })
  );

  maxRefund$ = combineLatest([this.purchase$, this.selectedRefundItem$, this.otherRefundsAmountForItem$]).pipe(
    map(([purchase, selectedRefundItem, otherItemRefundsAmount]) => {
      if (!purchase) return 0;

      const maxRefund =
        (purchase.balanceUsed -
          (purchase.balanceRefundedAmount || 0) +
          (purchase.payment?.amount || 0) -
          (purchase.payment?.refundedAmount || 0)) /
        100;

      if (!selectedRefundItem) return maxRefund;

      const taxes =
        (purchase.items?.find(item => item.id === selectedRefundItem.id)?.taxes?.length
          ? purchase.items
              ?.find(item => item.id === selectedRefundItem.id)
              ?.taxes?.reduce(
                (acc, tax) => acc + selectedRefundItem.price * selectedRefundItem.quantity * (tax.percentage / 100),
                0
              )
          : 0) ?? 0;

      return Math.min(
        maxRefund,
        Math.round(selectedRefundItem.price * selectedRefundItem.quantity + taxes - otherItemRefundsAmount) / 100
      );
    })
  );

  maxRefundPositive$ = this.maxRefund$.pipe(map(maxRefund => maxRefund > 0));

  maxPaymentRefund$ = combineLatest([this.purchase$, this.selectedRefundItem$, this.maxRefund$]).pipe(
    map(([purchase, selectedRefundItem, maxRefund]) => {
      const taxes =
        selectedRefundItem && purchase?.taxes?.length
          ? purchase.taxes?.reduce(
              (acc, tax) => acc + selectedRefundItem.price * selectedRefundItem.quantity * (tax.percentage / 100),
              0
            )
          : 0;
      const maxPaymentRefund = !purchase?.payment?.amount
        ? 0
        : selectedRefundItem
        ? Math.min(
            (purchase.payment?.amount || 0) - (purchase.payment?.refundedAmount || 0),
            Math.round(selectedRefundItem.price * selectedRefundItem.quantity + taxes)
          ) / 100
        : ((purchase.payment?.amount || 0) - (purchase.payment?.refundedAmount || 0)) / 100;

      const formValues = this.formGroup.value;

      this.formGroup = this.formBuilder.group(
        {
          balanceRefundAmount: [{ value: 0, disabled: false }],
          paymentRefundAmount: [
            { value: 0, disabled: !purchase?.payment?.amount },
            [Validators.min(0), Validators.max(this.getMinValue([maxRefund, maxPaymentRefund]))],
          ],
          refundReason: ['', [Validators.required]],
          returnToInventory: [false],
        },
        { validator: validateBalanceRefundAmount(maxRefund) }
      );

      this.formGroup.setValue({
        balanceRefundAmount: 0,
        paymentRefundAmount: 0,
        refundReason: formValues.refundReason || '',
        returnToInventory: formValues.returnToInventory || false,
      });

      return maxPaymentRefund;
    })
  );

  close(result?: any) {
    this.dialogRef.close(result);
  }

  getMinValue(values: number[]) {
    return Math.min(...values);
  }

  async submit(maxRefundPositive: boolean, maxRefund: number) {
    this.processing = true;

    try {
      if (!this.formGroup.value.balanceRefundAmount) this.formGroup.value.balanceRefundAmount = 0;
      if (!this.formGroup.value.paymentRefundAmount) this.formGroup.value.paymentRefundAmount = 0;
      if (
        !(
          this.formGroup.value.balanceRefundAmount + this.formGroup.value.paymentRefundAmount <=
          (maxRefundPositive ? maxRefund : maxRefund * -1)
        )
      ) {
        // TODO(adaoust): Add additional validation.
        this.snacks.open('Refund Limit Exceeded.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        this.close(null);
        return;
      }

      const returnToInventory = this.formGroup.value.returnToInventory;

      const paymentRefundAmount = Math.round(this.formGroup.value.paymentRefundAmount * 100);

      const terminalId = this.terminalControl.value?.externalId;
      if (paymentRefundAmount && this.isInteracPayment && !terminalId) throw new Error();

      const refundId =
        paymentRefundAmount && this.isInteracPayment
          ? await this.purchaseSvc.handleInteracRefund(
              this._purchase,
              terminalId,
              paymentRefundAmount,
              this.dialogRef.afterClosed()
            )
          : null;

      const purchase = await this.purchaseSvc.refund(
        this.purchase.id,
        Math.round(this.formGroup.value.balanceRefundAmount * 100) * (maxRefundPositive ? 1 : -1),
        paymentRefundAmount,
        this.formGroup.value.refundReason,
        refundId ?? undefined,
        this.selectedRefundItem ? this.selectedRefundItem.id : undefined
      );

      if (purchase.status !== PurchaseStatus.REFUNDED && purchase.status !== PurchaseStatus.PARTIALLY_REFUNDED) {
        console.error('Purchase could not be refunded');
        this.snacks.open('Purchase could not be refunded', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        if (returnToInventory) {
          const inventoryDialog = this.matDialog.open(this.returnInventoryDialog, {
            data: {
              purchaseId: purchase.id,
              variants: purchase.items
                .filter(item => (item as any).variantId)
                .map(item => {
                  return {
                    variantId: (item as any).variantId,
                    quantity: item.quantity,
                    title: item.displayName,
                    variant: (item as any).variant,
                  };
                }),
            },
          });
          await toPromise(inventoryDialog.afterClosed());
        }
        this.snacks.open('Refund successful!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;

    this.close({
      submit: true,
      balanceRefundAmount: this.formGroup.value.balanceRefundAmount,
      paymentRefundAmount: this.formGroup.value.paymentRefundAmount,
    });
  }
}
