/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentMethod } from '@greco/finance-payments';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { SaleCategoryService } from '@greco/ngx-sales-purchases';
import { TerminalService } from '@greco/ngx-stripe-payment-gateway';
import { PropertyListener } from '@greco/property-listener-util';
import { SaleCategory } from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CheckoutStationService } from '../../services';

@Component({
  selector: 'greco-create-checkout-station-dialog',
  templateUrl: './create-checkout-station.dialog.html',
  styleUrls: ['./create-checkout-station.dialog.scss'],
})
export class CreateCheckoutStationDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private terminalSvc: TerminalService,
    private categorySvc: SaleCategoryService,
    private stationSvc: CheckoutStationService,
    private dialogRef: MatDialogRef<CreateCheckoutStationDialog>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      communityId: string;
      accountId: string;
    }
  ) {
    this.communityId = data.communityId;
    this.accountId = data.accountId;
  }

  communityId: string;

  @PropertyListener('accountId') accountId$ = new BehaviorSubject<string | null>(null);
  accountId: string;

  billedTo?: User;

  dialogData: DialogData = {
    title: 'Create Checkout Station',
    subtitle: 'Checkout stations provide a more traditional POS checkout experience for staff to sell products.',
    buttons: [
      { label: 'Cancel', role: 'cancel', resultFn: () => this.close() },
      { label: 'Create', role: 'create', resultFn: () => this.submit() },
    ],
    showCloseButton: true,
    hideDefaultButton: false,
  };

  form = this.formBuilder.group({
    title: [null as string | null, Validators.required],
    saleCategories: [null as SaleCategory[] | null, Validators.required],
    contact: [null as Contact | null],
    terminals: [null as PaymentMethod[] | null],
    includeUncategorized: [false],
  });

  saleCategories$ = this.accountId$.pipe(
    switchMap(async accountId => {
      if (!accountId) {
        return [];
      }
      return await this.categorySvc.getMany(accountId);
    })
  );

  terminals$ = this.accountId$.pipe(
    switchMap(accountId => this.terminalSvc.getTerminalPaymentMethods(accountId ?? undefined))
  );

  async close() {
    return this.dialogRef.close();
  }

  async submit() {
    const title = this.form.value.title;
    const saleCategories = this.form.value.saleCategories;
    const includeUncategorized = this.form.value.includeUncategorized;
    const contact = this.form.value.contact;
    const terminals = this.form.value.terminals;

    const checkoutStation = await this.stationSvc.createCheckoutStation(this.communityId, {
      title,
      includeUncategorized,
      defaultUserId: contact?.user?.id,
      terminals: terminals?.map((terminal: PaymentMethod) => terminal.id),
      saleCategoryIds: saleCategories?.map((saleCategory: SaleCategory) => saleCategory.id),
    });

    if (checkoutStation) {
      this.snacks.open(`${title} created!`, 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(checkoutStation);
    }
  }
}
