import { Component, Injectable, Input, OnDestroy, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  Calendar,
  CalendarEvent,
  EVENT_SECURITY_RESOURCE,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventSecurityResource,
  EventSecurityResourceAction,
  EventTemplate,
  Resource,
  ResourceAssignmentStatus,
} from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { QueryEventsByDateDto } from '@greco/nestjs-booking-events';
import { BuildSearchFilter, BuildTextFilter, Filter, FilterBarComponent } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { heightExpansion } from '@greco/ui-animations';
import { CondOperator, RequestQueryBuilder, SFieldOperator, SFields } from '@nestjsx/crud-request';
import { CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EventsCalendarComponent } from '../../components';
import {
  AddAttendeeDialog,
  CreateEventDialog,
  CreateEventFromTemplateDialog,
  DateRangePickerDialog,
  RescheduleEventDialog,
} from '../../dialogs';
import { CalendarService, EventService, EventTemplateService, ResourcesService } from '../../services';
import {
  IncludePrivateFilter,
  ResourceFilter,
  ResourceSubstitutionFilter,
  ResourceSubstitutionFilterType,
  TagsSelectFilter,
} from './filters';

@Injectable({ providedIn: 'any' })
export class EventTitleFilter extends BuildTextFilter('EventTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['title'],
}) {}

@Injectable({ providedIn: 'any' })
export class EventSearchFilter extends BuildSearchFilter('EventSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Injectable({ providedIn: 'any' })
export class EventDescriptionFilter extends BuildTextFilter('EventDescriptionFilter', {
  label: 'Description',
  shortLabel: 'Description',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['description'],
}) {}

@Component({
  selector: 'greco-events-page',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
  animations: [heightExpansion],
})
export class EventsPage implements OnDestroy {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private tagFilter: TagsSelectFilter,
    private calendarSvc: CalendarService,
    private securitySvc: SecurityService,
    private resourceSvc: ResourcesService,
    private resourceFilter: ResourceFilter,
    private comSecSvc: CommunitySecurityService,
    private eventTemplateSvc: EventTemplateService
  ) {}

  filterOptions$: Type<Filter>[] = [
    EventSearchFilter,
    EventTitleFilter,
    EventDescriptionFilter,
    ResourceFilter,
    ResourceSubstitutionFilter,
    TagsSelectFilter,
    IncludePrivateFilter,
  ];

  filters$ = new ReplaySubject<RequestQueryBuilder>(1);

  initialCalendars: string[] = [];

  readonly STARTING_VIEW = CalendarView.Week;

  private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() get community() {
    return this._community$.value;
  }
  set community(community) {
    this._community$.next(community);
    this.resourceFilter.communityId = community?.id || '';
    this.tagFilter.communityId = community?.id || '';
  }
  refreshCalendarEmitter = new Subject<void>();

  private _calendar$ = new BehaviorSubject<Calendar | null>(null);
  @Input() get calendar() {
    return this._calendar$.value;
  }
  set calendar(calendar) {
    this._calendar$.next(calendar);
  }

  calAndTemplates: { calendar: Calendar; templates: EventTemplate[] }[] | null = [];

  calendarIdToAdd?: string;

  calendarsList$ = this._community$.pipe(
    switchMap(async community => (community ? await this.calendarSvc.getManySecuredCached(community.id) : null))
  );

  canCreateEvents$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(community.id, EventSecurityResource.key, EventSecurityResourceAction.CREATE)
        : false
    ),
    shareReplay(1)
  );

  canCancelEvents$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(community.id, EventSecurityResource.key, EventSecurityResourceAction.CANCEL)
        : false
    ),
    shareReplay(1)
  );

  canCreateCustomEvents$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(
            community.id,
            EventSecurityResource.key,
            EventSecurityResourceAction.CREATE_CUSTOM
          )
        : false
    )
  );

  canUpdateEvents$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(community.id, EventSecurityResource.key, EventSecurityResourceAction.UPDATE)
        : false
    )
  );

  canExportEvents$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(community.id, EventSecurityResource.key, EventSecurityResourceAction.EXPORT)
        : false
    )
  );

  canCreateBookings$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          )
        : false
    )
  );

  private _resources$ = new BehaviorSubject<string[]>([]);
  @Input() get resources() {
    return this._resources$.value;
  }
  set resources(resources) {
    this._resources$.next(resources);
  }

  loading = false;

  private _includePrivateEvents$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(
            community.id,
            EVENT_SECURITY_RESOURCE,
            EventSecurityResourceAction.READ_PRIVATE
          )
        : false
    )
  );

  private _canReadPrivate$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(EventSecurityResource.key, EventSecurityResourceAction.READ_PRIVATE, {
            communityId: community.id,
          })
        : false;
    })
  );

  readonly dates$ = new BehaviorSubject<[Date, Date] | null>(null);

  readonly events$: Observable<CalendarEvent[]> = combineLatest([
    this.dates$,
    this._community$,
    this._resources$,
    this._includePrivateEvents$,
    this.filters$,
  ]).pipe(
    switchMap(async ([dates, community, resources, includePrivate, queryBuilder]) => {
      const calendarIds = await this.calendarIdfromRoute();
      return { dates, community, resources, includePrivate, queryBuilder, calendarIds };
    }),
    debounceTime(750),
    tap(() => setTimeout(() => (this.loading = true))),
    switchMap(async ({ dates, community, calendarIds, resources, queryBuilder }) => {
      if (!dates || (!community && !resources.length)) return [];

      const searchObj = JSON.parse(queryBuilder.queryObject.s || '{}');

      const tagConditions: SFields[] = (searchObj.$and || []).filter((cond: SFields) => !!cond['tags.id']);
      const tagIds = tagConditions.reduce(
        (acc, cond) => [
          ...acc,
          ...((cond['tags.id'] as SFieldOperator).$in as string[]).filter(id => !acc.includes(id)),
        ],
        [] as string[]
      );

      let resourceIds: string[] | [] = resources || [];
      if (!resourceIds.length) {
        const resourceConditions: SFields[] = (searchObj.$and || []).filter((cond: SFields) => !!cond['resources.id']);
        resourceIds = resourceConditions.reduce(
          (acc, cond) => [
            ...acc,
            ...((cond['resources.id'] as SFieldOperator).$in as string[]).filter(id => !acc.includes(id)),
          ],
          [] as string[]
        );
      }

      const searchCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['$or']);
      const titleCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['title']);
      const descriptionCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['description']);
      const substitutionCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['resourceSubstitution']);
      const privateCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['private']);

      return await this.eventSvc.getEventsByDate(
        {
          description: descriptionCondition?.description?.$contL,
          search: searchCondition?.$or?.[0]?.title?.$contL,
          communities: this.community ? [this.community.id] : [],
          calendars: calendarIds,
          title: titleCondition?.title?.$contL,
          resources: resourceIds,
          startDate: dates[0],
          endDate: dates[1],
          includePrivate: !!privateCondition?.private?.$eq,
          eventHasConfirmedResource: substitutionCondition?.resourceSubstitution?.$in?.includes(
            ResourceSubstitutionFilterType.RESOURCE_CONFIRMED
          ),
          eventNeedsSubstitution: substitutionCondition?.resourceSubstitution?.$in?.includes(
            ResourceSubstitutionFilterType.NEEDS_SUBSTITUTION
          ),
          eventNeedsResource: substitutionCondition?.resourceSubstitution?.$in?.includes(
            ResourceSubstitutionFilterType.NEEDS_RESOURCE
          ),
          tags: tagIds,
          includeCourse: true,
          showCourseInstances: true,
        },
        true
      );
    }),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  readonly GROUP_BY: EventsCalendarComponent['dayViewGroupBy'] = evt => {
    const resources = evt?.resourceAssignments?.reduce((acc, assignment) => {
      if (assignment?.resource) acc.push(assignment.resource);
      return acc;
    }, [] as Resource[]);
    const resData = resources.map(resource => ({ label: resource.name || '', metadata: resource.id || '' }));
    const requestingSubstitution = evt?.resourceAssignments?.filter(
      assignment => assignment?.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION
    );

    if (requestingSubstitution?.length) {
      resData.push({
        label: 'Substitution Requested',
        metadata: '',
      });
    }
    return resData?.length ? resData : [{ label: 'No Staff Assigned', metadata: '' }];
  };

  readonly SORT_GROUPS: EventsCalendarComponent['sortGroups'] = (a: string, b: string) => {
    if (a === 'No Staff Assigned') return -1;
    else if (b === 'No Staff Assigned') return 1;
    return a.localeCompare(b) as 1 | -1;
  };

  async calendarIdfromRoute() {
    const query = await toPromise(this.route.queryParamMap);
    const calendarIds = query.get('calendarIds')?.split(',') ?? [];
    this.initialCalendars = calendarIds;
    return calendarIds;
  }

  async exportEvents() {
    try {
      const calendarIds = await this.calendarIdfromRoute();
      const community = this.community?.id ? this.community.id : '';

      const _filters = await toPromise(this.filters$);
      const searchObj = JSON.parse(_filters.queryObject.s || '{}');

      const tagConditions: SFields[] = (searchObj.$and || []).filter((cond: SFields) => !!cond['tags.id']);
      const tagIds = tagConditions.reduce(
        (acc, cond) => [
          ...acc,
          ...((cond['tags.id'] as SFieldOperator).$in as string[]).filter(id => !acc.includes(id)),
        ],
        [] as string[]
      );

      let resourceIds: string[] | [] = this.resources || [];
      if (!resourceIds.length) {
        const resourceConditions: SFields[] = (searchObj.$and || []).filter((cond: SFields) => !!cond['resources.id']);
        resourceIds = resourceConditions.reduce(
          (acc, cond) => [
            ...acc,
            ...((cond['resources.id'] as SFieldOperator).$in as string[]).filter(id => !acc.includes(id)),
          ],
          [] as string[]
        );
      }

      const searchCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['$or']);
      const titleCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['title']);
      const descriptionCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['description']);
      const substitutionCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['resourceSubstitution']);
      const privateCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['private']);

      const data: QueryEventsByDateDto = {
        startDate: new Date(),
        endDate: new Date(),
        calendars: calendarIds,
        communities: [community],

        description: descriptionCondition?.description?.$contL,
        search: searchCondition?.$or?.[0]?.title?.$contL,
        title: titleCondition?.title?.$contL,
        resources: resourceIds,
        includePrivate: !!privateCondition?.private?.$eq,
        eventHasConfirmedResource: substitutionCondition?.resourceSubstitution?.$in?.includes(
          ResourceSubstitutionFilterType.RESOURCE_CONFIRMED
        ),
        eventNeedsSubstitution: substitutionCondition?.resourceSubstitution?.$in?.includes(
          ResourceSubstitutionFilterType.NEEDS_SUBSTITUTION
        ),
        eventNeedsResource: substitutionCondition?.resourceSubstitution?.$in?.includes(
          ResourceSubstitutionFilterType.NEEDS_RESOURCE
        ),
        tags: tagIds,
        includeCourse: true,
        showCourseInstances: true,
      };
      DateRangePickerDialog.open(this.dialog, {
        processorId: 'DATE_RANGE_PICKER',
        dto: data,
        communityId: community,
      });
    } catch (err) {
      this.snacks.open('There was an issue creating the query for your export', 'OK', {
        duration: 10000,
        panelClass: 'mat-warn',
      });
    }
  }

  async createEvent(date?: Date, resourceId?: string) {
    if (!this.community?.id) return;

    let addAttendee = false;
    let event: CalendarEvent | null = null;
    const resource = resourceId ? await this.resourceSvc.getResource(resourceId) : null;
    let capacity = 0;
    const canCreateBookings = await toPromise(this.canCreateBookings$);

    await toPromise(
      this.dialog
        .open(CreateEventDialog, {
          data: {
            communityId: this.community.id,
            date,
            resource,
            allowChangeResources: true,
            canCreateBookings,
          },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    ).then(res => {
      if (res?.event) {
        event = res.event as CalendarEvent;

        this.calendarIdToAdd = undefined;
        setTimeout(() => (this.calendarIdToAdd = res.event.calendarId));
      }
      res?.action === 'create_and_add_attendee' ? (addAttendee = true) : (addAttendee = false);
    });

    try {
      if (event && addAttendee) {
        capacity = (event as CalendarEvent).maxCapacity;
        this.matDialog
          .open(AddAttendeeDialog, { data: { event }, width: '750px', maxWidth: '90%' })
          .afterClosed()
          .subscribe(res => {
            if (res.action !== 'cancel' && event) this.keepOpeningAttendeeDialog(event, capacity, 1);
          });
      }
    } catch (err) {
      console.log('Event creation cancelled:', err);
    }

    this.refresh();
  }

  async createEventFromTemplate(templateId: string, calendarId: string) {
    if (!this.community?.id) return;

    const eventTemplate = await this.eventTemplateSvc.getOne(templateId);
    if (this.community.id != eventTemplate?.communityId) return;

    let addAttendee = false;
    let event: CalendarEvent | null = null;
    let capacity = 0;

    await toPromise(
      this.dialog
        .open(CreateEventFromTemplateDialog, {
          data: { calendarId, eventTemplate, communityId: this.community.id },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    ).then(res => {
      if (res?.event) {
        event = res.event as CalendarEvent;

        this.calendarIdToAdd = undefined;
        setTimeout(() => (this.calendarIdToAdd = res.event.calendarId));
      }
      res?.action === 'create_and_add_attendee' ? (addAttendee = true) : (addAttendee = false);
    });
    try {
      if (event && addAttendee) {
        capacity = (event as CalendarEvent).maxCapacity;
        this.matDialog
          .open(AddAttendeeDialog, { data: { event }, width: '750px', maxWidth: '90%' })
          .afterClosed()
          .subscribe(res => {
            if (res.action !== 'cancel' && event) this.keepOpeningAttendeeDialog(event, capacity, 1);
          });
      }
    } catch (err) {
      console.log('Event creation cancelled:', err);
    }

    this.refresh();
  }

  async openEvent(event?: CalendarEvent) {
    if (event) await this.router.navigate([event.id], { relativeTo: this.route });
  }

  async keepOpeningAttendeeDialog(event: CalendarEvent, capacity: number, attendees: number) {
    if (attendees < capacity) {
      this.matDialog
        .open(AddAttendeeDialog, { data: { event }, width: '750px', maxWidth: '90%' })
        .afterClosed()
        .subscribe(res => {
          if (res?.action !== 'cancel') this.keepOpeningAttendeeDialog(event, capacity, attendees++);
        });
    } else {
      this.snacks.open('Event capacity reached!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    }
    this.refresh();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static buildUrlTree(router: Router, communityId: string, resourceService: ResourcesService, resource?: Resource) {
    const filterParams = resource
      ? [new ResourceFilter(resourceService).formatAsQueryParam(CondOperator.EQUALS, [resource])]
      : [];

    return router.createUrlTree(['/scheduling', communityId], {
      queryParams: {
        ...(filterParams?.length ? { filters: filterParams } : {}),
      },
    });
  }

  ngOnDestroy() {
    this.dates$.complete();
    this._community$.complete();
  }

  refresh() {
    this.dates$.next(this.dates$.value);
  }

  generateSchedule() {
    let route = this.router.url.split('/scheduling')[1];
    route =
      window.location.origin.replace('-admin.', '.').replace('admin.', '').replace('4300', '4200') +
      '/schedule' +
      route;
    window.open(route, '_blank');
  }

  validateEventTimesChanged = async ({ event, newStart }: CalendarEventTimesChangedEvent) => {
    if (
      !(
        this.community &&
        (await this.comSecSvc.hasAccess(
          this.community.id,
          EventSecurityResource.key,
          EventSecurityResourceAction.UPDATE
        ))
      )
    ) {
      this.snacks.open('Cannot Reschedule ' + event.title + ' Event : You do not have the proper permissions', 'Ok', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
      return false;
    }
    if (newStart) {
      if (newStart.getTime() != event.start.getTime()) {
        if (event.start.getTime() < Date.now()) {
          this.snacks.open('Cannot Reschedule ' + event.title + ' Event : It has already completed', 'Ok', {
            duration: 6000,
            panelClass: 'mat-warn',
          });
          return false;
        } else if (newStart.getTime() < Date.now()) {
          this.snacks.open('Cannot Reschedule ' + event.title + ' Event : Start Date is in the past', 'Ok', {
            duration: 6000,
            panelClass: 'mat-warn',
          });
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  async eventTimesChanged(eventTimesChangedEvent: CalendarEventTimesChangedEvent) {
    try {
      if (await this.validateEventTimesChanged(eventTimesChangedEvent)) {
        const { event, newStart, newEnd } = eventTimesChangedEvent;
        const updateEvent = event;

        if (!updateEvent) throw new Error();

        const prevStartDate = event.start;
        const preEndDate = event.end;

        event.start = newStart;
        event.end = newEnd;
        this.refreshCalendarEmitter.next();

        const dialog = this.matDialog.open(RescheduleEventDialog, {
          data: { newStart: newStart, newEnd: newEnd, event: updateEvent },
          width: '750px',
          maxWidth: '90%',
        });

        const result = await toPromise(dialog.afterClosed());
        if (result == 'cancel') {
          event.start = prevStartDate;
          event.end = preEndDate;
          this.refreshCalendarEmitter.next();
        } else {
          this.refresh();
        }
      }
    } catch (err: any) {
      let errorMessage = 'Failed to update';
      if ('message' in err?.error) {
        errorMessage += ': ' + err.error?.message;
      }
      console.error(err);
      this.snacks.open('' + errorMessage, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }
  }

  async setInitialFilters(filterBar: FilterBarComponent) {
    if (filterBar.values.length === 0) {
      const hasAccess = await toPromise(this._canReadPrivate$);
      if (hasAccess) {
        filterBar.optionSelected(new IncludePrivateFilter(), true);
        // filterBar.values = [{ filter: new IncludePrivateFilter(), operator: CondOperator.EQUALS, value: true }];
      }
    }
  }
}
