import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunityAgreement } from '@greco/community-agreements';
import type { AddonDto } from '@greco/nestjs-sales-products';
import {
  AddonType,
  InventoryProductAddon,
  ProductAddon,
  ShopProductAddon,
  UpgradeProductAddon,
  UserAvailabilityAddon,
} from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import { map, startWith } from 'rxjs/operators';
import { AddonsService, InventoryService, ProductAgreementAddonsService } from '../../services';
import { ShopFormComponent } from './forms/shop/shop.component';

const AddonTypeValidator: ValidatorFn = control => {
  const valid = Object.values(AddonType).includes(control.value);
  return !valid ? { addonType: { value: control.value } } : null;
};

@Component({
  selector: 'greco-configure-addon-dialog',
  templateUrl: './configure-addon.dialog.html',
  styleUrls: ['./configure-addon.dialog.scss'],
})
export class ConfigureAddonDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) data: { productId: string; addon: ProductAddon; communityId: string; refresh: () => void },
    private dialogRef: MatDialogRef<ConfigureAddonDialog>,
    private formBuilder: FormBuilder,
    private addonSvc: AddonsService,
    private snacks: MatSnackBar,
    private inventorySvc: InventoryService,
    private productAgreemntAddonSVC: ProductAgreementAddonsService
  ) {
    if (!data.productId) throw new Error();
    this.productId = data.productId;
    this.communityId = data.communityId;

    this.addonId = data.addon.id;
    this.addon = data.addon;
    this.formGroup.setValue({
      addonType: data.addon.type,
      config: this._getConfigFromAddon(data.addon),
    });
  }

  addonTypes?: AddonType[];

  productAgreements: CommunityAgreement[] = [];

  readonly communityId: string;
  readonly productId: string;

  addonId: string;

  addon?: ProductAddon;

  readonly simpleDialog: DialogData = {
    title: 'Configure Extension',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly formGroup = this.formBuilder.group({
    addonType: [null, [Validators.required, AddonTypeValidator]],
    config: [null, Validators.required], //TODO: fix validation for Tags.
  });

  valid$ = this.formGroup.valueChanges.pipe(
    startWith(null),
    map(() => this.formGroup.valid)
  );

  submitting = false;

  cancel() {
    this.dialogRef.close();
  }

  async submit() {
    try {
      const data: AddonDto =
        this.formGroup.value.addonType === AddonType.Upgrade
          ? {
              type: this.formGroup.value.addonType,
              upgradeCandidates: this.formGroup.value.config.map((p: any) => ({ id: p.id })),
            }
          : this.formGroup.value.addonType === AddonType.Shop
          ? {
              ...this.formGroup.value.config,
              type: this.formGroup.value.addonType,
            }
          : { ...this.formGroup.value.config, type: this.formGroup.value.addonType };

      this.submitting = true;

      const addon = this.addonId
        ? await this.addonSvc.updateAddonConfiguration(this.productId, this.addonId, data)
        : await this.addonSvc.addAddon(this.productId, data);

      if (addon.type === AddonType.Inventory) {
        const inventories: Map<string, FormControl> = (data as any).inventories;
        await Promise.all(
          Array.from(inventories).map(([id, control]) =>
            control.value ? this.inventorySvc.restockVariant(id, control.value) : {}
          )
        );
      }

      this.snacks.open('Extension configured!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(addon);
    } catch (err) {
      console.error(err);
    }
    this.submitting = false;
  }

  private _getConfigFromAddon(addon: ProductAddon) {
    switch (addon.type) {
      case AddonType.Shop:
        return ShopFormComponent.GetConfig(addon as ShopProductAddon);
      case AddonType.Agreement:
        return {
          agreements: this.productAgreements,
        };
      case AddonType.UserAvailability:
        return { conditions: (addon as UserAvailabilityAddon).conditions };
      case AddonType.Upgrade:
        return (addon as UpgradeProductAddon).upgradeCandidates || [];
      case AddonType.Inventory:
        return {
          inventories: new Map<string, FormControl>(),
          displayStockNumber: (addon as InventoryProductAddon).displayStockNumber || false,
          outOfStockMessage: (addon as InventoryProductAddon).outOfStockMessage || null,
        };
      default:
        return null;
    }
  }

  //add to UI
  addUsage(agreements: CommunityAgreement[]) {
    if (this.addon) {
      this.productAgreements = [];
      agreements.forEach(agr => {
        this.productAgreements = [...this.productAgreements, agr];
      });
      this.formGroup.setValue({ ...this.formGroup.value, config: { agreements: [...this.productAgreements] } });
    }
  }

  addonCreated(addon: ProductAddon) {
    this.addon = addon;
    this.addonId = addon.id;
  }
}
