<greco-collapsible-section
  *ngIf="subscription"
  id="subscription-info-section"
  [header]="{ title: 'Subscription Info', icon: 'card_membership' }"
  [disabled]="disabled || false"
  [expanded]="expanded"
>
  <greco-subscription-options
    header
    *ngIf="communityId"
    [communityId]="communityId"
    [subscription]="subscription"
    [hideViewDetailsOption]="true"
    [hidePaymentMethodOption]="true"
    [canCancelSubscriptions]="false"
    [canUpdateSubscriptions]="false"
    (click)="$event.stopImmediatePropagation()"
    (refresh)="subscriptionChanged.emit(subscription)"
    (subscriptionUpdated)="subscriptionChanged.emit(subscription)"
    (paymentMethodUpdated)="subscriptionChanged.emit(subscription)"
    (subscriptionCancelled)="subscriptionChanged.emit(subscription)"
  ></greco-subscription-options>

  <!-- Status -->
  <mat-chip-list header>
    <mat-chip
      *ngIf="subscription | nextRenewal | async as label"
      [matTooltip]="(label | test : '^(?:Cancelling|Renewing|Starting)') ? label : ''"
    >
      {{
        ((label | test : '^Cancelling') ? 'Cancelling' : (label | test : '^Starting'))
          ? 'Not Started'
          : subscription.lockedForProcessing
          ? ('PROCESSING' | titlecase)
          : (subscription.status | titlecase)
      }}
    </mat-chip>
  </mat-chip-list>

  <button
    mat-stroked-button
    (click)="viewSubscriptionSchedule(); $event.stopImmediatePropagation()"
    *ngIf="canMakeChange"
    header
  >
    View Schedule
  </button>

  <div *ngIf="canMakeChange" style="padding: 0 16px">
    <br />
    <ng-container [ngTemplateOutlet]="subscriptionInfo"></ng-container>
    <br />
  </div>
  <!-- Info -->
  <div id="info-grid">
    <greco-multi-avatar
      [images]="subscription.user?.photoURL ? [$any(subscription.user?.photoURL)] : []"
    ></greco-multi-avatar>
    <div>
      <h3>Purchased For</h3>
      <p>
        <a
          *ngIf="communityId; else noCommunityId"
          style="color: var(--primary-color)"
          [routerLink]="['/community', communityId, subscription.userId]"
        >
          <mat-icon> link </mat-icon>
          <span>{{ subscription.user?.displayName || 'Failed to load user data' }}</span>
        </a>

        <ng-template #noCommunityId>{{ subscription.user?.displayName || 'Failed to load user data' }}</ng-template>
      </p>

      <p>
        <small>{{ subscription.user?.contactEmail || 'Failed to load user data' }}</small>
      </p>
    </div>
    <mat-divider [vertical]="true" style="margin-left: 24px; margin-right: 24px; max-height: 64px"></mat-divider>

    <div>
      <h3>Billed To</h3>

      <p>
        <a
          *ngIf="communityId; else noCommunityId"
          style="color: var(--primary-color)"
          [routerLink]="['/community', communityId, subscription.subscribedById]"
        >
          <mat-icon> link </mat-icon>
          <span>{{ subscription.subscribedBy?.displayName || 'Failed to load user data' }}</span>
        </a>

        <ng-template #noCommunityId>
          {{ subscription.subscribedBy?.displayName || 'Failed to load user data' }}
        </ng-template>
      </p>

      <p>
        <small>{{ subscription.subscribedBy?.contactEmail || 'Failed to load user data' }}</small>
      </p>
    </div>

    <div id="right-info-grid">
      <strong>Account</strong>
      <span>{{ subscription.account?.name || 'Failed to load account data' }}</span>

      <strong>Subscription ID</strong>
      <span>{{ subscription.id }}</span>

      <ng-container *grecoLet="'Created on ' + (subscription.created | date) as tooltip">
        <strong [matTooltip]="tooltip || ''">Started on</strong>
        <span [matTooltip]="tooltip || ''" [style.font-style]="subscription.startDate ? 'normal' : 'italic'">
          {{ subscription.startDate ? (subscription.startDate | date) : 'Not Started' }}
        </span>
      </ng-container>

      <ng-container *ngIf="subscription.minimumCommitmentDate">
        <ng-container *grecoLet="'Commitment End ' + (subscription.minimumCommitmentDate | date) as tooltip">
          <strong [matTooltip]="tooltip || ''">Commitment End</strong>
          <span
            [matTooltip]="tooltip || ''"
            [style.font-style]="subscription.minimumCommitmentDate ? 'normal' : 'italic'"
          >
            {{ subscription.minimumCommitmentDate ? (subscription.minimumCommitmentDate | date) : '-' }}
          </span>
        </ng-container>
      </ng-container>

      <strong>Sold By</strong>
      <span>{{ subscription.soldBy?.displayName || subscription.soldBy?.contactEmail || '-' }}</span>

      <ng-container *ngIf="subscription?.referredBy?.displayName || subscription?.referredByEmail as referredBy">
        <strong>Referred By</strong>
        <span>{{ referredBy }}</span>
      </ng-container>

      <ng-container *ngIf="subscription?.transferredFrom?.displayName">
        <strong>Transferred From</strong>
        <span>{{ subscription.transferredFrom?.displayName }}</span>
      </ng-container>
    </div>
  </div>

  <!-- Items Table -->
  <div id="items-grid">
    <!-- Header -->
    <div class="row header">
      <div
        class="column"
        matTooltip="This is not a purchase preview, this is a list of subscription items considered by the subscription engine during the execution of actions in the above schedule."
      >
        Subscribed To
        <mat-icon style="width: 14px; height: 14px; line-height: 14px; font-size: 14px; margin-left: 4px"
          >info</mat-icon
        >
      </div>

      <div class="column">Qty</div>
      <div class="column align-right border-right">Unit Price</div>
      <div class="column align-right">Amount</div>
    </div>

    <!-- Items -->
    <div *ngFor="let item of subscription.items" class="row line">
      <div class="column" style="gap: 15px">
        <div class="item-image">
          <greco-smart-img [roundedBorder]="true" [src]="item.photoURL || undefined"></greco-smart-img>
        </div>

        <div>
          <div>{{ item.displayName }}</div>
          <div style="font-size: small">
            {{ item.description }}

            <small *ngIf="item.type === 'CouponSubscriptionItemEntity'" style="color: var(--warn-color)">
              <strong> On initial purchase only</strong>
            </small>

            <small *ngIf="subscription.recurrence.cycles" style="color: var(--warn-color)">
              <strong>
                Total subscription length:
                {{ subscription.recurrence.cycles * subscription.recurrence.frequency }}
                {{
                  subscription.recurrence.period === 'DAILY'
                    ? 'day'
                    : subscription.recurrence.period === 'WEEKLY'
                    ? 'week'
                    : subscription.recurrence.period === 'MONTHLY'
                    ? 'month'
                    : subscription.recurrence.period === 'YEARLY'
                    ? 'year'
                    : 'unknown'
                }}{{ subscription.recurrence.cycles * subscription.recurrence.frequency == 1 ? '' : 's' }}
              </strong>

              <em *ngIf="subscription.recurrence.cycles > 1">
                (1 initial payment + {{ subscription.recurrence.cycles - 1 }} renewal{{
                  subscription.recurrence.cycles - 1 > 1 ? 's every' : ' after'
                }}
                {{ subscription.recurrence.frequency }}
                {{
                  subscription.recurrence.period === 'DAILY'
                    ? 'day'
                    : subscription.recurrence.period === 'WEEKLY'
                    ? 'week'
                    : subscription.recurrence.period === 'MONTHLY'
                    ? 'month'
                    : subscription.recurrence.period === 'YEARLY'
                    ? 'year'
                    : 'unknown'
                }}{{ subscription.recurrence.frequency == 1 ? '' : 's' }})
              </em>
            </small>
          </div>
        </div>
      </div>

      <div class="column align-right">{{ item.quantity }}</div>

      <div class="column align-right border-right">{{ item.price / 100 | currency }}</div>

      <div class="column align-right">{{ (item.quantity * item.price) / 100 | currency }}</div>
    </div>

    <!-- Subtotal -->
    <!-- <div class="row">
      <div class="column align-right border-right" style="grid-column: 1 / span 3">Subtotal</div>
      <div class="column align-right">{{ subscription.subtotal / 100 | currency }}</div>
    </div> -->

    <!-- Taxes -->
    <!-- <div class="row small">
      <div class="column align-right border-right" style="grid-column: 1 / span 3">Taxes</div>
      <div class="column align-right">{{ subscription.tax / 100 | currency }}</div>
    </div> -->

    <!-- Total -->
    <!-- <div class="row">
      <div class="column align-right border-right" style="grid-column: 1 / span 3">
        <strong>Total</strong>
      </div>
      <div class="column align-right">
        <strong>{{ subscription.total / 100 | currency }}</strong>
      </div>
    </div> -->
  </div>

  <!-- Payment Method -->
  <div id="payment-method" *ngIf="!hidePaymentMethod">
    <ng-container *ngIf="isNotPurchaser">
      <div
        *ngIf="subscription?.paymentMethod"
        style="display: flex; align-items: center; justify-content: flex-end; padding: 16px 32px; gap: 12px"
      >
        <img
          [src]="
            subscription.paymentMethod
              ? 'assets/payment-methods/' + subscription.paymentMethod.model + '.png'
              : 'assets/lf3/favicon/favicon.ico'
          "
          [style.margin]="'0'"
          style="width: 38px; vertical-align: middle"
        />
        <div>
          {{ (subscription.paymentMethod!.model | titlecase) + ' ' + subscription.paymentMethod?.label }}
          <input readonly style="display: none" type="text" [formControl]="_paymentMethodControl" />
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="subscription.status === 'ACTIVE'">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu>
          <button mat-menu-item (click)="assumeDialog()">Assume Control</button>
        </mat-menu>
      </div>
    </ng-container>

    <div class="payment-method-container">
      <p style="margin-bottom: 0; width: 100%; padding-top: 8px"></p>
      <mat-form-field appearance="standard" *ngIf="!isNotPurchaser">
        <i *ngIf="!_paymentMethodControl.value" style="position: absolute; transform: translateY(-2px)">
          <small>Add a Payment Method!</small>
        </i>

        <greco-select-payment-method
          #selectPaymentMethodInput
          [formControl]="_paymentMethodControl"
          [userId]="subscription.subscribedById"
          [required]="true"
          [allowBankPayments]="allowBankPayments"
        ></greco-select-payment-method>

        <greco-add-payment-method-button
          matSuffix
          [onlyIcon]="true"
          [userId]="subscription.subscribedById"
          (saved)="$event && selectPaymentMethodInput.refreshPaymentMethods()"
        ></greco-add-payment-method-button>
      </mat-form-field>
    </div>
  </div>
</greco-collapsible-section>
