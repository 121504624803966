import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventResourceAssignment, PersonResource, ResourceType } from '@greco/booking-events';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
@Component({
  selector: 'greco-specify-confirmations',
  templateUrl: './specify-confirmations.dialog.html',
  styleUrls: ['./specify-confirmations.dialog.scss'],
})
export class SpecifyConfirmationsDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      communityId: string;
      assignments: EventResourceAssignment[];
      title: string;
      content: string;
    },
    private dialogRef: MatDialogRef<SpecifyConfirmationsDialog>
  ) {}

  private _onDestroy$ = new Subject<void>();

  emails: string[] = [];
  allSelected = false;

  dialogData: DialogData = {
    title: this.data.title ? this.data.title : 'A Resource Has Been Assigned To This Event',
    content: this.data.content
      ? this.data.content
      : 'Specify which resources you would like to notify about this assignment',
  };

  select(resource: PersonResource) {
    this.emails.includes(resource?.user?.email || '') ? this.removeEmail(resource) : this.addEmail(resource);
  }

  addEmail(resource: PersonResource) {
    this.emails.push(resource?.user?.email || '');
    if (this.emails.length === this.data.assignments.length) this.allSelected = true;
  }

  removeEmail(resource: PersonResource) {
    const email = resource?.user?.email || '';
    const index = this.emails.findIndex(item => item === email);
    this.emails.splice(index, 1);
    this.allSelected = false;
  }

  selectAll() {
    this.data.assignments.forEach(assignment => {
      if (!this.emails.includes((assignment.resource as PersonResource)?.user?.email || '')) {
        this.addEmail(assignment.resource as PersonResource);
      }
    });
    this.allSelected = true;
  }

  removeAll() {
    this.data.assignments.forEach(assignment => {
      if (this.emails.includes((assignment.resource as PersonResource)?.user?.email || '')) {
        this.removeEmail(assignment.resource as PersonResource);
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  notify() {
    this.dialogRef.close({ emails: this.emails.length ? this.emails : ['no emails'] });
  }

  ngOnInit() {
    this.selectAll();
    this.allSelected = true;

    this.data.assignments = this.data.assignments.filter(
      assignment => assignment.resource?.type === ResourceType.PERSON
    );
  }
}
