// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'dev',
  api: {
    baseUrl: 'https://greco-fitness-platform-dev.uc.r.appspot.com',
  },
  typeform: {
    clientId: 'ETqx5uUKvTY2nX2B1ZTf4T1PMHBTZ5mA4FiD6QdLKLn8',
    relativeRedirectUrl: '/typeform/registration',
  },
  firebase: {
    apiKey: 'AIzaSyDW02kYtVOOJm1zXc_4MHU7-fP_16kaKCY',
    authDomain: 'greco-fitness-platform-dev.firebaseapp.com',
    databaseURL: 'https://greco-fitness-platform-dev.firebaseio.com',
    projectId: 'greco-fitness-platform-dev',
    storageBucket: 'greco-fitness-platform-dev.appspot.com',
    messagingSenderId: '725235364002',
    appId: '1:725235364002:web:fe27506d1b52ab4907e7fb',
    measurementId: 'G-MESHR4KZ1G',
  },
  google: {
    maps: 'AIzaSyBxVmVtXTm4_SjzgaB7KYrruNE7FlR8jeI',
  },
  stripe: {
    publishableKey: 'pk_test_j4aWumwKJwncA6ef1JwRXc9k',
    clientID: 'ca_HFzdNs59FsY99Dso84L9M1adNam0IW2D',
  },
  zoom: {
    clientId: 'zA8iyvC4RxyrRc3wUq30KA',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
