import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingStatus,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
} from '@greco/booking-events';
import { CommunityAgreementSecurityActions, CommunityAgreementSecurityResource } from '@greco/community-agreements';
import {
  BrivoSecurityActions,
  BrivoSecurityResource,
  BrivoStationAccessPoint,
  BrivoStationAccessPointActivity,
} from '@greco/domain-brivo';
import { PaymentMethod, UserPaymentMethodResource, UserPaymentMethodResourceAction } from '@greco/finance-payments';
import { Community } from '@greco/identity-communities';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { BookingService } from '@greco/ngx-booking-events';
import { BrivoService } from '@greco/ngx-brivo';
import { CreateUserAgreementDialog } from '@greco/ngx-community-agreements';
import { PaymentMethodSubscriptionsDialog, UserPaymentMethodsComponent } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { ContactPickerComponent, ContactService, CreateContactDialog } from '@greco/ngx-identity-contacts';
import { GrantPerkDialog, UserPerksTableComponent } from '@greco/ngx-sales-perks';
import {
  AdjustBalanceDialog,
  BalanceHistorySectionComponent,
  FundUserBalanceDialog,
  FundUserBalanceDialogData,
  PurchasesTableComponent,
  SaleCategoryService,
} from '@greco/ngx-sales-purchases';
import { SubscriptionsService, SubscriptionsTableComponent } from '@greco/ngx-sales-subscriptions';
import { SecurityService } from '@greco/ngx-security-util';
import { PerkResource, PerkResourceAction } from '@greco/sales-perks';
import {
  Purchase,
  PurchaseResource,
  PurchaseResourceAction,
  UserBalanceResource,
  UserBalanceResourceAction,
} from '@greco/sales-purchases';
import { Subscription, SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { CheckInSecurityActions, CheckInSecurityResource, Station } from '@greco/stations';
import { DialogData, SimpleDialog } from '@greco/ui-dialog-simple';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription as RXJSSubscription } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ActiveSubscriptionDialog, NewBookingComponent, PurchaseInformationDialog } from '../../dialogs';
import { CheckInService } from '../../services';
import { StationCheckInListComponent } from '../station-checkin-list/station-checkin-list.component';
import { UserDetailCardComponent } from '../user-detail-card/user-detail-card.component';
@Component({
  selector: 'greco-station-details',
  templateUrl: './station-details.component.html',
  styleUrls: ['./station-details.component.scss'],
})
export class StationDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private brivoSvc: BrivoService,
    private bookingSvc: BookingService,
    private contactSvc: ContactService,
    private securitySvc: SecurityService,
    private checkedInUserSvc: CheckInService,
    private saleCategorySvc: SaleCategoryService,
    private subscriptionSvc: SubscriptionsService,
    private breakpointObserver: BreakpointObserver,
    private communitySecuritySvc: CommunitySecurityService
  ) {}

  @ViewChild(UserPerksTableComponent) private perksTableComp?: UserPerksTableComponent;
  @ViewChild(BalanceHistorySectionComponent) private balanceSection?: BalanceHistorySectionComponent;
  @ViewChild(UserDetailCardComponent) private userDetailCardComponent!: UserDetailCardComponent;
  @ViewChild(PurchasesTableComponent) private purchasesTableComponent!: PurchasesTableComponent;
  @ViewChild(UserPaymentMethodsComponent) userPaymentMethods!: UserPaymentMethodsComponent;
  @ViewChild(SubscriptionsTableComponent) private subscriptionsTable?: SubscriptionsTableComponent;
  @ViewChild(ContactPickerComponent) contactPickerComponent!: ContactPickerComponent;
  @ViewChild(StationCheckInListComponent) stationCheckInListComponent!: StationCheckInListComponent;

  @ViewChild('subscriptions') private subscriptionsTemplate?: TemplateRef<any>;
  @ViewChild('purchases') private purchasesTemplate?: TemplateRef<any>;
  @ViewChild('perks') private perksTemplate?: TemplateRef<any>;
  @ViewChild('payment') private paymentTemplate?: TemplateRef<any>;
  @ViewChild('balance') private balanceTemplate?: TemplateRef<any>;

  contactId$: Observable<string> = this.route.queryParams.pipe(
    map(({ contact }) => contact),
    shareReplay(1)
  );

  autoCheckIn = true;
  selectedTabIndex = 0;
  selectedTab = '';
  invoiceTableVisibility = true;
  selectedAccountId: string | null = null;
  expanded = false;
  pageSizes = [5, 10, 20, 50];
  loading = true;

  latestAccessPointActivity: BrivoStationAccessPointActivity[] = [];

  isMobile$ = this.breakpointObserver.observe('(max-width: 1000px)').pipe(map(result => result.matches));

  allCommunities = false;

  readonly _station$ = new BehaviorSubject<Station | null>(null);
  @Input() set station(station: Station | null) {
    this._station$.next(station);
  }
  get station() {
    return this._station$.value;
  }

  readonly _syncEntries$ = new BehaviorSubject<boolean>(false);
  set syncEntries(syncEntries: boolean) {
    this._syncEntries$.next(syncEntries);
  }
  get syncEntries() {
    return this._syncEntries$.value;
  }

  readonly staticFilter = new RequestQueryBuilder().search({
    'user.id': '',
  });

  currentUser$ = this._station$?.pipe(
    filter(c => !!c),
    switchMap(async () => {
      return await this.userSvc.getSelf();
    })
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  community$: Observable<Community> = this.route.parent!.parent!.data.pipe(map(data => data.community));

  public readonly userControl = new FormControl(null, Validators.required);
  public readonly gateControl = new FormControl(null as BrivoStationAccessPoint | null);

  contact$: Observable<Contact> = this.userControl.valueChanges;

  $gateValueChanges: RXJSSubscription | null = null;

  readonly _contact$ = combineLatest([this.contact$, this.community$, this._station$]).pipe(
    tap(([contact, community, station]) => {
      if (!community || !station) return;
      this.selectInput(contact);
      this.router.navigate([`/community/${community.id}/stations/${station.id}`], {
        queryParams: { contact: contact?.id },
        relativeTo: this.route,
      });
    })
  );

  readonly _refreshBookings$ = new BehaviorSubject<null>(null);

  bookingFilters$ = combineLatest([this.contact$, this._refreshBookings$]).pipe(
    map(([contact]) => {
      return new RequestQueryBuilder().search({
        'user.id': contact?.user?.id,
      });
    })
  );

  saleCategoryIds$ = from(this.saleCategorySvc.getMany()).pipe(
    shareReplay(1),
    map(scs => scs.map(sc => sc.id).join(','))
  );

  saving = false;

  canInviteUser$ = this._station$.pipe(
    filter(c => !!c),
    switchMap(async station => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.CREATE, {
        communityId: station?.community?.id,
      });
    }),
    shareReplay(1)
  );

  canSearchAndInviteUser$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.CREATE, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canGrantPerks$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.GRANT, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canRevokePerks$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.REVOKE, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canReadPerks$ = this.community$.pipe(
    filter(community => !!community),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.VIEW_USER_PERKS, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canReadBalance$ = this.community$.pipe(
    filter(community => !!community),
    switchMap(async community => {
      return await this.communitySecuritySvc.hasAccess(
        community.id,
        UserBalanceResource.key,
        UserBalanceResourceAction.READ
      );
    }),
    shareReplay(1)
  );

  canFundBalance$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            UserBalanceResource.key,
            UserBalanceResourceAction.CREATE_TRANSACTION
          )
        : false
    ),
    shareReplay(1)
  );

  canAdjustBalance$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            UserBalanceResource.key,
            UserBalanceResourceAction.MANUALLY_ADJUST
          )
        : false
    ),
    shareReplay(1)
  );

  canReadPurchases$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(community.id, PurchaseResource.key, PurchaseResourceAction.READ)
        : false
    ),
    shareReplay(1)
  );

  canReadSubscriptions$ = combineLatest([this.community$, this.currentUser$]).pipe(
    switchMap(async ([community, currentUser]) => {
      return await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.READ, {
        accountId: community?.financeAccount?.id,
        userId: currentUser?.id,
      });
    }),
    shareReplay(1)
  );

  canAddPaymentMethods$ = this.currentUser$.pipe(
    filter(c => !!c),
    switchMap(async currentUser => {
      return await this.securitySvc.hasAccess(UserPaymentMethodResource.key, UserPaymentMethodResourceAction.ADD, {
        userId: currentUser?.id,
      });
    }),
    shareReplay(1)
  );

  canViewPaymentMethods$ = this.currentUser$.pipe(
    filter(c => !!c),
    switchMap(async currentUser => {
      return await this.securitySvc.hasAccess(UserPaymentMethodResource.key, UserPaymentMethodResourceAction.READ, {
        userId: currentUser?.id,
      });
    }),
    shareReplay(1)
  );

  canReadCheckInList$ = this.community$.pipe(
    switchMap(async community => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.READ, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canUpdateAgreement$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.CREATE,
            {
              communityId: community.id,
            }
          )
        : false;
    }),
    shareReplay(1)
  );

  canReadAgreement$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.READ,
            {
              communityId: community.id,
            }
          )
        : false;
    }),
    shareReplay(1)
  );

  canCreateBookings$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          )
        : false
    ),
    shareReplay(1)
  );

  canReadBookings$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.LIST
          )
        : false
    ),
    shareReplay(1)
  );

  canOpenAccessPoint$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            BrivoSecurityResource.key,
            BrivoSecurityActions.OPEN_ACCESS_POINT
          )
        : false
    ),
    shareReplay(1)
  );

  canLinkAccessPoint$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            BrivoSecurityResource.key,
            BrivoSecurityActions.LINK_ACCESS_POINT
          )
        : false
    ),
    shareReplay(1)
  );

  tabs$ = combineLatest([
    this.canReadSubscriptions$,
    this.canReadPurchases$,
    this.canReadPerks$,
    this.canViewPaymentMethods$,
    this.canReadBalance$,
  ]).pipe(
    map(([subscriptions, purchases, canReadPerks, canViewPaymentMethods, canReadBalance]) => [
      ...(subscriptions ? [{ label: 'Subscriptions', template: this.subscriptionsTemplate }] : []),
      ...(purchases ? [{ label: 'Purchase History', template: this.purchasesTemplate }] : []),
      ...(canReadPerks ? [{ label: 'Access Perks', template: this.perksTemplate }] : []),
      ...(canViewPaymentMethods ? [{ label: 'Payment Methods', template: this.paymentTemplate }] : []),
      ...(canReadBalance ? [{ label: 'Account Balance', template: this.balanceTemplate }] : []),
    ]),
    shareReplay(1)
  );

  brivoCommunitySite$ = this.community$.pipe(
    switchMap(community => (community ? this.brivoSvc.getCommunitySite(community.id) : of(null)))
    //tap(site => (this.syncEntries = !!site))
  );

  private _refreshAccessPoints$ = new BehaviorSubject(null);

  linkedAccessPoints$ = combineLatest([this.brivoCommunitySite$, this._station$, this._refreshAccessPoints$]).pipe(
    switchMap(([site, station]) => (site && station ? this.brivoSvc.getAccessPointsForStation(station.id) : of([]))),
    tap(accessPoints => {
      if (!this.gateControl.value) {
        this.gateControl.setValue(accessPoints?.length ? accessPoints[0] : null);
      }
    })
  );

  unlinkedAccessPoints$ = combineLatest([this.brivoCommunitySite$, this._refreshAccessPoints$]).pipe(
    switchMap(async ([site]) => {
      if (!site) return { items: [] };

      return await this.brivoSvc.listAccessPointsForCommunity(site.communityId, 1, 100);
    }),
    map(data => data.items.filter(ap => !ap.stationAccessPoint))
  );

  async openNewBookingDialog(user: User | null, contact: Contact | null) {
    const dialog = this.matDialog.open(NewBookingComponent, {
      width: '1000px',
      data: {
        showCloseButton: false,
        title: 'New Booking',
        user: user,
        contact: contact,
      } as { title: string; user: User; contact: Contact },
    });

    const booking: Booking[] | undefined = await toPromise(dialog.afterClosed());

    if (booking?.length) {
      this._refreshBookings$.next(null);
      this.userDetailCardComponent.refreshBookings('IsPending');
    }
  }

  async openSignAgreement(contact: Contact | undefined) {
    const dialog = await toPromise(
      this.matDialog
        .open(CreateUserAgreementDialog, {
          width: '750px',
          maxWidth: '90%',
          data: {
            disableContact: true,
            contact,
            communityId: contact?.community.id,
          },
        })
        .afterClosed()
    );

    if (dialog) this.pageSizes = [...this.pageSizes];
  }

  async openSubscriptionSummary(subscription: Subscription, communityId: string, user: User) {
    const dialog = this.matDialog.open(ActiveSubscriptionDialog, {
      data: {
        showCloseButton: false,
        user,
        communityId: communityId,
        subscription,
      } as { user: User; communityId: string; subscription: Subscription },
    });
    await toPromise(dialog.afterClosed());
  }

  async openPurchaseSummary(purchase: Purchase, communityId: string, user: User | undefined) {
    const dialog = this.matDialog.open(PurchaseInformationDialog, {
      width: '1000px',
      data: {
        showCloseButton: false,
        user,
        communityId: communityId,
        purchase,
      } as { user: User; communityId: string; purchase: Purchase },
    });
    await toPromise(dialog.afterClosed());
  }

  async grantPerk(user: User | undefined) {
    if (this.community$) {
      const community = await toPromise(this.community$);
      if (!user || !community?.financeAccount) return;
      const dialog = this.matDialog.open(GrantPerkDialog, {
        data: {
          user,
          communityId: community.id,
        } as { user: User; communityId: string },
      });
      const result = await toPromise(dialog.afterClosed());
      if (result) this.perksTableComp?.refresh?.();
    }
  }

  async adjustBalance(user: User | undefined) {
    if (this.community$) {
      const community = await toPromise(this.community$);

      if (!user || !community.financeAccount) return;

      const dialog = this.matDialog.open(AdjustBalanceDialog, {
        data: {
          user,
          account: community.financeAccount,
        },
      });

      const result = await toPromise(dialog.afterClosed());
      if (result) this.balanceSection?.refresh?.();
    }
  }

  async fundAccount(user: User | undefined) {
    if (this.community$) {
      const community = await toPromise(this.community$);
      if (!user || !community.financeAccount) return;

      const dialog = this.matDialog.open(FundUserBalanceDialog, {
        data: { user, community } as FundUserBalanceDialogData,
      });

      const result = await toPromise(dialog.afterClosed());
      if (result) this.balanceSection?.refresh?.();
    }
  }

  async createContact() {
    const dialog = this.matDialog.open(CreateContactDialog, {
      data: { communityId: 'com_greco', forceUserInvite: true },
      width: '750px',
      maxWidth: '90%',
    });
    const contact: Contact = await toPromise(dialog.afterClosed());
    if (contact?.user?.id) {
      this.userControl.setValue(contact);
    }
  }
  refreshBookingTable(bookingStatus: string) {
    if (bookingStatus === 'TriedConfirming') {
      this._refreshBookings$.next(null);
    }
  }

  refreshPurchaseHistory(purchaseStatus: string) {
    if (purchaseStatus === 'NewPurchase') {
      this.purchasesTableComponent.refresh();
    }
  }

  async pasteMemberNumber(event: ClipboardEvent, communityId: string) {
    if (!this.autoCheckIn) return;

    let foundContact = true;

    const data = event.clipboardData?.getData('text') || '';
    if (data.includes('@')) {
      const contact = await this.contactSvc.getContactByEmail(data, communityId, true).catch(console.error);
      if (contact) {
        this.userControl.setValue(contact);
        this.selectInput(contact);
        return;
      } else foundContact = false;
    } else {
      const contact = await this.contactSvc.getContactByMemberNumber(data).catch(console.error);
      if (contact) {
        this.userControl.setValue(contact);
        this.selectInput(contact);
        return;
      } else foundContact = false;
    }

    if (!foundContact) {
      this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Invalid Member Number!',
          content:
            'The member number that was just scanned does not match any contacts in the system.<br><ul><li><em>Confirm the member number is correctly configured in the member contact you are trying to scan.</em></li></ul>',
          buttons: [{ label: 'Continue', role: 'yes' }],
        } as DialogData,
        width: '100%',
        maxWidth: '400px',
      });
    }

    this.userControl.setValue(null);
    this.selectInput();
  }

  async checkInUser(userId: string | undefined, station: Station | null) {
    if (!userId || !station) return;

    const accessPoint = this.gateControl.value;

    await this.checkedInUserSvc
      .createCheckedInUser({
        userId: userId,
        stationId: station.id,
        accessPointExternalId: accessPoint?.accessPointExternalId,
        accessPointName: accessPoint?.accessPointName,
      })
      .then(() => this.stationCheckInListComponent.refreshList());

    if (accessPoint) await this.openGateForUser(accessPoint, userId);

    // Auto check-in user if event is within the next hour
    if (!station?.checkInBookings) return;
    const bookings = await this.bookingSvc.getByDate({
      startDate: new Date(),
      endDate: moment(new Date()).add(1, 'hour').toDate(),
      userId,
      statuses: [BookingStatus.CONFIRMED], // Don't check-in pending bookings, they need to be confirmed manually
    });

    if (bookings.length) {
      await Promise.all(bookings.map(async booking => await this.bookingSvc.checkIn(booking.id)));

      this.userDetailCardComponent._refreshBookings$.next(null);
    }
  }

  selectInput(contact?: Contact) {
    if (this.contactPickerComponent?.input) {
      this.contactPickerComponent.input.value = contact?.user?.displayName || '';
      setTimeout(() => this.contactPickerComponent.nativeInput?.nativeElement.select());
    }
  }

  async openGateForUser(accessPoint: BrivoStationAccessPoint, userId: string) {
    const latestActivity = await this.brivoSvc.openAccessPoint(
      accessPoint.stationId,
      accessPoint.accessPointExternalId,
      userId
    );

    if (this.syncEntries) {
      return;
    }

    const user = latestActivity.user;
    //temporarily while gate scanners don't work, instead of the autoSync feature
    const toast = latestActivity.allowedAccess
      ? this.toastr.success(`Opened at ${latestActivity.accessPointName}`, user?.displayName, {
          timeOut: 5000,
          progressBar: true,
          progressAnimation: 'increasing',
        })
      : this.toastr.error(`Denied at ${latestActivity.accessPointName}`, user?.displayName, {
          timeOut: 5000,
          progressBar: true,
          progressAnimation: 'increasing',
        });

    toast.toastRef.componentInstance.user = user;

    toast.onTap.pipe(take(1)).subscribe(async () => await this.activityToastClicked(user));
  }

  toggleAutoCheckIn() {
    this.autoCheckIn = !this.autoCheckIn;
    localStorage.setItem('autoCheckIn' + this.station?.id, this.autoCheckIn ? 'true' : 'false');
  }

  toggleSyncEntries() {
    this.syncEntries = !this.syncEntries;
    // localStorage.setItem('syncEntries' + this.station?.id, this.syncEntries ? 'true' : 'false');
  }

  defaultPaymentMethodUpdated(paymentMethod: PaymentMethod, user: User) {
    this.matDialog
      .open(PaymentMethodSubscriptionsDialog, { data: { paymentMethod, userId: user.id, updateDefault: true } })
      .beforeClosed()
      .pipe(first())
      .subscribe(async data => {
        if (data?.subscriptions?.length) {
          await this.subscriptionSvc.updatePaymentMethodMultiple(
            data.subscriptions.map((subscription: Subscription) => subscription.id),
            paymentMethod.id
          );

          this.subscriptionsTable?.refresh();
          this.userPaymentMethods?.refresh();
        }
      });
  }

  async ngOnInit() {
    this.autoCheckIn = localStorage.getItem('autoCheckIn' + this.station?.id) === 'false' ? false : true;
    // this.syncEntries = localStorage.getItem('syncEntries' + this.station?.id) === 'false' ? false : true;

    const contactId = await toPromise(this.contactId$);
    if (contactId) {
      const contact = await this.contactSvc.getContact(contactId);
      this.userControl.setValue(contact);
    }

    const storedGate = localStorage.getItem('gate' + this.station?.id);

    if (storedGate) {
      this.gateControl.setValue(JSON.parse(storedGate));
    }

    this.$gateValueChanges = this.gateControl.valueChanges.subscribe(value => {
      value
        ? localStorage.setItem('gate' + this.station?.id, JSON.stringify(value))
        : localStorage.removeItem('gate' + this.station?.id);
    });

    await this.getMostRecentAccessPointActivity(false);
  }

  ngOnDestroy(): void {
    this.$gateValueChanges?.unsubscribe();
  }

  async openGate(accessPointExternalId?: string) {
    if (!accessPointExternalId || !this.station) return;

    await this.brivoSvc.openAccessPoint(this.station.id, accessPointExternalId);
  }

  async addAccessPoint(accessPointId: string | number, stationId: string) {
    const accessPoint = await this.brivoSvc.linkAccessPointToStation(stationId, accessPointId.toString());

    if (accessPoint) {
      this.gateControl.setValue(accessPoint);
      this._refreshAccessPoints$.next(null);
    }
  }

  async getMostRecentAccessPointActivity(showToast = true) {
    if (!this.station) return;

    const latestActivities = await this.brivoSvc.getMostRecentStationAccessPointActivity(this.station.id);

    if (!showToast) {
      this.latestAccessPointActivity = latestActivities;
      return;
    }

    for (const latestActivity of latestActivities) {
      if (this.latestAccessPointActivity.some(alreadySeen => alreadySeen.id === latestActivity.id)) {
        continue;
      }

      const user = latestActivity.user;

      const toast = latestActivity.allowedAccess
        ? this.toastr.success(`Opened at ${latestActivity.accessPointName}`, user?.displayName, {
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
          })
        : this.toastr.error(`Denied at ${latestActivity.accessPointName}`, user?.displayName, {
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
          });

      toast.toastRef.componentInstance.user = user;

      toast.onTap.pipe(take(1)).subscribe(async () => await this.activityToastClicked(user));
    }

    this.latestAccessPointActivity = latestActivities;
  }

  private async activityToastClicked(user?: User) {
    if (!user || !this.station) return;
    const contact = await this.contactSvc.getContactByUserId(user.id, this.station.communityId, true);
    if (contact) {
      if (this.autoCheckIn) {
        this.toggleAutoCheckIn();
        this.snacks.open('Auto Check-in has been disabled', 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }

      // this.selectInput(contact);
      this.userControl.setValue(contact);
    } else {
      this.snacks.open('Cannot find contact for ' + user.displayName, 'Ok!', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
    }
  }
}
