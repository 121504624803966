import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventOverview, PersonResource, ResourceType, RoomResource } from '@greco/booking-events';
import moment from 'moment';
import { BehaviorSubject, Subject, combineLatest, interval } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-schedule-content',
  templateUrl: './schedule-content.component.html',
  styleUrls: ['./schedule-content.component.scss'],
})
export class ScheduleContentComponent implements OnInit, OnDestroy {
  public events$ = new BehaviorSubject<EventOverview[]>([]);
  @Input() set events(value: EventOverview[]) {
    this.events$.next(value);
  }
  get events() {
    return this.events$.value;
  }

  public dates$ = new BehaviorSubject<Date[]>([]);
  @Input() set dates(value: Date[]) {
    this.dates$.next(value);
  }
  get dates() {
    return this.dates$.value;
  }

  moment = moment;
  url = location.origin.replace('-admin.', '.').replace('admin.', '').replace('4300', '4200');

  private _refresh$ = new BehaviorSubject(null);
  private _onDestroy$ = new Subject<void>();

  now$ = interval(60000).pipe(
    startWith(new Date()),
    map(() => new Date())
  );

  eventsAndDates$ = combineLatest([this.events$, this.dates$]).pipe(
    map(([events, dates]) => {
      const eventsAndDates: { date: Date; events: EventOverview[] }[] = [];

      dates?.forEach(date => {
        const eventsOnDate: EventOverview[] = [];

        events.forEach(event => {
          if (new Date(moment(event.startDate).startOf('day').toString()).toString() === date.toString()) {
            eventsOnDate.push(event);
          }
        });

        if (eventsOnDate?.length) eventsAndDates.push({ date, events: eventsOnDate });
      });

      return eventsAndDates;
    })
  );

  getTrainers(event: EventOverview) {
    return (
      event?.trainers?.reduce((acc, assignment) => {
        if (assignment.resource?.type === ResourceType.PERSON) {
          acc.push(assignment.resource);
        }
        return acc;
      }, [] as PersonResource[]) || []
    );
  }

  getRooms(event: EventOverview) {
    return (
      event?.rooms?.reduce((acc, assignment) => {
        if (assignment.resource?.type === ResourceType.ROOM) {
          acc.push(assignment.resource);
        }
        return acc;
      }, [] as RoomResource[]) || []
    );
  }

  makeTime(date: Date) {
    const time =
      (date.getHours() % 12 === 0 ? 12 : date.getHours() % 12) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    const suffix = date.getHours() >= 12 ? 'PM' : 'AM';
    return time + ' ' + suffix;
  }

  makeDuration(duration: number) {
    if (duration < 60) return duration + ' min';
    else {
      const hours = Math.floor(duration / 60);
      const mins = duration % 60;
      return hours + (mins > 0 ? 'h ' + mins + 'min' : hours > 1 ? ' hours ' : ' hour');
    }
  }

  async ngOnInit() {
    this.events$.pipe(startWith(null), takeUntil(this._onDestroy$)).subscribe(async () => {
      // await new Promise(res => setTimeout(res, 30000));
      // this._refresh$.next(null);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  trackById(_: number, event: EventOverview) {
    return event.id;
  }
}
