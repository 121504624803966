import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { BookingOption } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { BookingOptionService } from '../../services';

@Component({
  selector: 'greco-booking-options-table',
  templateUrl: './booking-options-table.component.html',
  styleUrls: ['./booking-options-table.component.scss'],
})
export class BookingOptionsTableComponent implements OnDestroy {
  constructor(
    private bookingOptionSvc: BookingOptionService,
    private route: ActivatedRoute,
    private router: Router,
    private snacks: MatSnackBar,
    private matDialog: MatDialog
  ) {}

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>(1);
  @Input() communityId!: string;

  @PropertyListener('query') private _query$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  @Input() query?: RequestQueryBuilder;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  loading = false;
  pagination?: IPaginationMeta;

  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  bookingOptions$: Observable<BookingOption[]> = combineLatest([this._communityId$, this._query$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([communityId, query, page]) => {
      return communityId && query ? await this.bookingOptionSvc.paginate(query, communityId, page) : null;
    }),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  private _onDestroy$ = new Subject<void>();
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._query$.complete();
    this._communityId$.complete();
  }
  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
  refresh() {
    this.page$.next(this.page$.value);
  }

  async openOption(option: BookingOption) {
    return this.router.navigate([option?.id], {
      relativeTo: this.route,
    });
  }

  async removeBookingOption(bookingOption: BookingOption) {
    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Remove Booking Option',
          subtitle: bookingOption.title,
          content: `You are about to delete the '${bookingOption.title}' booking option. Are you sure you want to continue?`,
          showCloseButton: false,
          buttons: [
            { label: 'No, keep it', role: 'no' },
            { label: 'Yes, delete it', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.bookingOptionSvc.removeBookingOption(bookingOption.id);
        this.snacks.open('Booking option removed!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
        this.refresh();
      }
    } catch (err) {
      console.error(err);
    }
  }
}
