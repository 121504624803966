import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentDispute } from '@greco/finance-payments';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-payment-dispute-card',
  templateUrl: './payment-dispute-card.component.html',
  styleUrls: ['./payment-dispute-card.component.scss'],
})
export class PaymentDisputeCardComponent {
  constructor(private router: Router) {}

  @PropertyListener('paymentDispute') paymentDispute$ = new BehaviorSubject<PaymentDispute | null>(null);
  @Input() paymentDispute!: PaymentDispute;

  @Input() communityId!: string;

  subtitle$ = this.paymentDispute$.pipe(
    map(dispute => {
      if (!dispute) return;
      return `${dispute.status.toUpperCase()} - ${dispute.reason.toUpperCase()}`;
    })
  );

  async navigate(disputeId: string) {
    if (!this.communityId) await this.router.navigate([`/account/purchases`]);
    await this.router.navigate([`/sales/${this.communityId}/payment-disputes/${disputeId}`]);
  }
}
