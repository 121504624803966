import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { QRCodesDialog } from '../../dialogs/qr-codes/qr-codes.dialog';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'greco-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
})
export class ProfileButtonComponent {
  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  @Input() accountRoute = '/account';
  @Input() signInPath = '/';
  @Input() signOutRedirect = '/';

  user$ = this.userService.user$;

  openQRs(user: User) {
    this.matDialog.open(QRCodesDialog, {
      data: {
        userId: user.id,
      },
    });
  }

  async signOut() {
    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Are you sure you want to logout?',
          showCloseButton: false,
          buttons: [
            { label: 'No', role: 'no' },
            { label: 'Yes, Logout!', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        this.userService.signOut().then(() => {
          this.snackBar.open('You have successfully been signed out of your account!', undefined, {
            duration: 2500,
            panelClass: 'mat-primary',
          });

          return this.router.navigate([this.signOutRedirect]);
        });
      }
    } catch (err) {
      console.error(err);
    }
  }
}
