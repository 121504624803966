import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { ContactService } from '../../services';

@Component({
  selector: 'greco-create-contact-dialog',
  templateUrl: './create-contact.dialog.html',
  styleUrls: ['./create-contact.dialog.scss'],
})
export class CreateContactDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { communityId: string; forceUserInvite?: boolean },
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private formBuilder: FormBuilder,
    private contactSvc: ContactService,
    private securitySvc: SecurityService,
    private dialogRef: MatDialogRef<CreateContactDialog>
  ) {}

  createCalled = false;
  canInviteContact$ = this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.INVITE_USER, {
    communityId: this.data.communityId,
  });
  canSubscribeMember$ = this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.SUBSCRIBE, {
    communityId: this.data.communityId,
  });

  private _onDestroy$ = new Subject<void>();

  isNewUserSelected = true;

  duplicateEmail = false;
  user$ = this.userSvc.user$;
  formGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
    subscription: [false],
    newUser: [true],
    memberNumber: [''],
  });

  dialogData: DialogData = {
    title: 'Create New Contact',
    subtitle: 'Provide the details for the new contact',
    showCloseButton: false,
  };

  cancel() {
    this.dialogRef.close({ event: null, action: 'cancel' });
  }

  async create() {
    try {
      if (this.createCalled) return;
      this.createCalled = true;

      const displayName = `${this.formGroup.value.firstName.trim()} ${this.formGroup.value.lastName.trim()}`;

      const contact = await this.contactSvc.createContact({
        phoneNumber: this.formGroup.value.phoneNumber,
        email: this.formGroup.value.email,
        displayName,
        communityId: this.data.communityId,
        addUser: this.data.forceUserInvite || this.formGroup.value.newUser,
        memberNumber: this.formGroup.value.memberNumber,
      });

      if (
        contact.user &&
        (this.data.forceUserInvite || this.formGroup.value.subscription) &&
        (this.data.forceUserInvite || this.formGroup.value.newUser)
      ) {
        await this.contactSvc.subscribeUserToCommunity(this.data.communityId, contact.user.id);
      }

      this.snacks.open('New contact created', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close({ contact, action: 'create' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Contact already exists', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      this.dialogRef.close({ contact: null, action: 'create' });
    }
  }

  async ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      // This section disables the create button if a contact already exists
      // let valid = false;

      // if (this.formGroup.valid && emailRegex.test(this.formGroup.value.email)) {
      //   try {
      //     valid = !(await this.contactSvc.getContactByEmail(this.formGroup.value.email, this.data.communityId, true));
      //     this.duplicateEmail = true;
      //   } catch (err) {
      //     valid = true;
      //     this.duplicateEmail = false;
      //   }
      // }

      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid; // || !valid
          return btn;
        }),
      };
    });

    if (this.data.forceUserInvite) {
      this.formGroup.controls.newUser.patchValue(this.data.forceUserInvite);
      this.formGroup.controls.newUser.disable();
      this.formGroup.controls.subscription.patchValue(this.data.forceUserInvite);
      this.formGroup.controls.subscription.disable();
      this.isNewUserSelected = this.data.forceUserInvite;
    }
  }

  changeSubscriptionOption(event: any) {
    this.isNewUserSelected = event.checked;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
