import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomResourceSpot } from '@greco/booking-events';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
@Component({
  selector: 'greco-configure-room-spots',
  templateUrl: './configure-room-spots.dialog.html',
  styleUrls: ['./configure-room-spots.dialog.scss'],
})
export class ConfigureRoomSpotsDialog implements OnInit, OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageURL: string; spots: RoomResourceSpot[] }) {}

  private _onDestroy$ = new Subject<void>();

  @ViewChild('canvas', { static: true }) private canvas?: ElementRef<HTMLCanvasElement>;
  canvasContext: CanvasRenderingContext2D | null = null;
  image: HTMLImageElement | null = null;

  listening = false;
  listeningId = '';
  topLeft: number[] = [];
  configs: { [key: string]: { x: number; y: number; width: number; height: number } } = {};

  dialogData: DialogData = {
    title: 'Room Spot Configuraion',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Confirm',
        role: 'confirm',
        resultFn: () => {
          try {
            return this.configs;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  setCoordinates(id: string) {
    this.listening = true;
    this.listeningId = id;
  }

  imageClick(event: MouseEvent) {
    if (this.listening && !this.topLeft.length) {
      this.topLeft.push(event.offsetX);
      this.topLeft.push(event.offsetY);
    } else if (this.listening && this.topLeft.length) {
      this.configs[this.listeningId] = {
        x: this.topLeft[0],
        y: this.topLeft[1],
        width: event.offsetX - this.topLeft[0],
        height: event.offsetY - this.topLeft[1],
      };

      this.drawSpots(this.listeningId);

      this.listening = false;
      this.listeningId = '';
      this.topLeft = [];
    }
  }

  drawSpots(spotId?: string, pos?: string, value?: string) {
    if (spotId && pos && value) {
      switch (pos) {
        case 'x':
          this.configs[spotId].x = Number(value);
          break;
        case 'y':
          this.configs[spotId].y = Number(value);
          break;
        case 'w':
          this.configs[spotId].width = Number(value);
          break;
        case 'h':
          this.configs[spotId].height = Number(value);
          break;
        default:
          return;
      }
    }

    if (this.image && this.canvasContext) {
      this.drawImageScaled(this.image, this.canvasContext);

      this.canvasContext.fillStyle = 'rgba(208, 227, 109, .5)';
      Object.keys(this.configs).forEach(id => {
        const config = this.configs[id];
        this.canvasContext?.fillRect(config.x, config.y, config.width, config.height);
      });
    }
  }

  drawImageScaled(image: HTMLImageElement, canvasContext: CanvasRenderingContext2D) {
    const canvas = canvasContext.canvas;
    canvas.width = image.width;
    canvas.height = image.height;

    const wRatio = canvas.width / image.width;
    const scaledHeight = image.height * wRatio;

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(image, 0, 0, canvas.width, scaledHeight);
  }

  ngOnInit() {
    if (this.canvas) {
      this.canvasContext = this.canvas.nativeElement.getContext('2d');
      if (this.canvasContext) {
        this.image = new Image();
        this.image.src = this.data.imageURL;
        this.drawImageScaled(this.image, this.canvasContext);
      }
    }

    this.data.spots.forEach(spot => {
      if (spot.x && spot.y && spot.width && spot.height) {
        this.configs[spot.id] = {
          x: spot.x,
          y: spot.y,
          width: spot.width,
          height: spot.height,
        };

        this.drawSpots(spot.id);
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
