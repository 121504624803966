import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco/ui-dialog-simple';
import { VideoTag } from '@greco/videos';
import { VideoTagService } from '../../services';

@Component({
  selector: 'greco-video-tag-input',
  templateUrl: './video-tag-input.component.html',
  styleUrls: ['./video-tag-input.component.scss'],
})
export class VideoTagInputComponent {
  form = this.formBuilder.group({
    label: [''],
  });
  dialogData: DialogData = {
    title: 'Upload a Video',
    showCloseButton: true,
  };
  tagOptions: VideoTag[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<VideoTagInputComponent>,
    private tagSvc: VideoTagService
  ) {}
  async save() {
    const label: string = this.form.get('label')?.value;
    if (!label) return;
    const tagSearch = this.tagOptions.find(tag => tag.label.toLocaleLowerCase() === label.toLocaleLowerCase());
    if (tagSearch) {
      this.close(tagSearch);
      return;
    }
    const tag = await this.tagSvc.create(label);
    this.close(tag);
  }
  close(tag?: VideoTag) {
    this.dialogRef.close(tag);
  }
}
