import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { DataExport } from '@greco/data-exports';
import { UserService } from '@greco/ngx-identity-auth';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { SecurityUtilsModule } from '../security.module';
import { DO_SECURITY_CHECKS } from '../tokens';

const HOUR_IN_MS = 60 * 60 * 1000;

@Injectable({ providedIn: SecurityUtilsModule })
export class SecurityService {
  private static _uid?: string | null;
  private static _userSub?: Subscription;
  private static _initialized$ = new ReplaySubject<boolean>(1);
  private static _requestQueue = new Map<string, Observable<boolean>>();

  private static CACHE_KEY = 'PERMISSIONS_CACHE';
  private static CACHE_EXPIRES_KEY = 'PERMISSIONS_CACHE_EXPIRES';

  constructor(
    private http: HttpClient,
    private userSvc: UserService,
    @Inject(DO_SECURITY_CHECKS) private doSecurityChecks: boolean
  ) {
    SecurityService._userSub =
      SecurityService._userSub ??
      this.userSvc.authUser$
        .pipe(distinctUntilChanged((p, n) => p?.uid === n?.uid))
        .subscribe(user => SecurityService.init(user?.uid));
  }

  private static init(uid?: string | null) {
    this._uid = uid;

    const expires = Number(localStorage.getItem(this.CACHE_EXPIRES_KEY) || '0');
    if (expires <= Date.now()) {
      localStorage.setItem(this.CACHE_KEY, '{}');
      localStorage.setItem(this.CACHE_EXPIRES_KEY, '' + (Date.now() + HOUR_IN_MS));
    }

    SecurityService._initialized$.next(true);
  }

  async hasAccess(resource: string, action: string, context: any, anyCtx = false): Promise<boolean> {
    if (!this.doSecurityChecks) return false;

    await toPromise(SecurityService._initialized$);
    if (!SecurityService._uid) return false;

    const key = `${SecurityService._uid}_${resource}_${action}_${JSON.stringify(context || {})}_${anyCtx}`;

    const fromCache = this.getCachedHasAccess(key);
    if (fromCache !== null) return fromCache;

    if (SecurityService._requestQueue.has(key)) {
      // Wait for the ongoing request to complete
      // console.log('Using cached request');
      return toPromise(SecurityService._requestQueue.get(key) as any);
    }

    // Create a new request
    const hasAccessRequest = this.http
      .post<boolean>('/api/security/has-access', { resource, action, context, anyCtx })
      .pipe(shareReplay(1));

    SecurityService._requestQueue.set(key, hasAccessRequest);

    const hasAccess = await toPromise(hasAccessRequest).catch(error => {
      console.error(error);
      return false;
    });

    this.updateCache(key, hasAccess);
    SecurityService._requestQueue.delete(key);

    return hasAccess;
  }

  private getCachedHasAccess(key: string): boolean | null {
    const storage = JSON.parse(localStorage.getItem(SecurityService.CACHE_KEY) || '{}');

    const cache = storage[key];

    const expires = cache?.expires ?? 0;
    if (expires <= Date.now()) return null;

    return cache?.hasAccess ?? false;
  }

  private updateCache(key: string, hasAccess: boolean) {
    const storage = JSON.parse(localStorage.getItem(SecurityService.CACHE_KEY) || '{}');

    storage[key] = { hasAccess, expires: Date.now() + HOUR_IN_MS };

    localStorage.setItem(SecurityService.CACHE_KEY, JSON.stringify(storage));
  }

  exportPermissions(skip?: number) {
    return toPromise(
      this.http.get<DataExport>('/api/security/export-permissions', {
        params: { ...(skip && { skip: skip.toString() }) },
      })
    );
  }
}
