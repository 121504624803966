import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { CouponCorrelationType } from '@greco/coupon';
import { SecurityService } from '@greco/ngx-security-util';
import {
  Purchase,
  PurchaseItem,
  PurchaseResource,
  PurchaseResourceAction,
  PurchaseStatus,
} from '@greco/sales-purchases';
import { DialogData } from '@greco/ui-dialog-simple';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RETURN_TO_INVENTORY_DIALOG } from '../../return-to-inventory.token';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-void-purchase-dialog',
  templateUrl: './void.dialog.html',
  styleUrls: ['./void.dialog.scss'],
})
export class VoidPurchaseDialog {
  voidReasons = ['Bad Debt', 'Admin Error'].sort();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly purchase: Purchase,
    private dialogRef: MatDialogRef<VoidPurchaseDialog>,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(RETURN_TO_INVENTORY_DIALOG) public readonly returnInventoryDialog: any
  ) {}

  purchase$ = new BehaviorSubject<Purchase>(this.purchase);

  readonly separatorKeysCodes = [ENTER, COMMA];

  readonly dialogData: DialogData = {
    title: 'Void Purchase',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  formGroup = this.formBuilder.group({
    voidAll: [true],
    items: [[] as PurchaseItem[]],
    voidReason: ['', [Validators.required]],
    returnToInventory: [false],
  });

  processing = false;

  canVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true);

  voidableItems$ = this.purchase$.pipe(
    map(purchase => {
      return (
        purchase?.items
          .filter(item => item.type !== 'coupon' && !item.voidedDate)
          .map(item => {
            const coupons = purchase.items.filter(i => {
              if (i.type !== 'coupon') return false;
              const correlationType: CouponCorrelationType = (i as any).correlationType;
              let correlationId: string | null = null;
              switch (correlationType) {
                case CouponCorrelationType.VARIANT:
                  correlationId = (item as any).variantId;
                  break;
              }
              return correlationId === (i as any).correlationId;
            });
            return {
              ...item,
              coupons: coupons || [],
              amountWithCoupons:
                item.price * item.quantity + coupons.reduce((acc, coupon) => acc + coupon.price * coupon.quantity, 0),
            };
          }) || []
      );
    })
  );

  close(result?: any) {
    this.dialogRef.close(result);
  }

  selectItem(item: PurchaseItem) {
    this.formGroup.patchValue({ items: [...this.formGroup.value.items, item] });
  }

  removeItem(item: PurchaseItem) {
    this.formGroup.patchValue({
      items: this.formGroup.value.items.filter((i: PurchaseItem) => i.id !== item.id),
    });
  }

  async submit() {
    this.processing = true;

    const voidAll = this.formGroup.value.voidAll;

    try {
      const purchase = voidAll
        ? await this.purchaseSvc.void(this.purchase.id, this.formGroup.value.voidReason)
        : await this.purchaseSvc.voidLineItems(
            this.purchase.id,
            this.formGroup.value.voidReason,
            this.formGroup.value.items.map((i: PurchaseItem) => i.id)
          );

      if (voidAll && purchase.status !== PurchaseStatus.VOIDED) {
        console.error('Purchase could not be voided');
        this.snacks.open('Purchase could not be voided', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        if (this.formGroup.value.returnToInventory) {
          const inventoryDialog = this.matDialog.open(this.returnInventoryDialog, {
            data: {
              purchaseId: purchase.id,
              variants: purchase.items
                .filter(
                  item =>
                    (item as any).variantId &&
                    (voidAll ? true : this.formGroup.value.items.some((i: PurchaseItem) => i.id === item.id))
                )
                .map(item => {
                  return {
                    variantId: (item as any).variantId,
                    quantity: item.quantity,
                    title: item.displayName,
                    variant: (item as any).variant,
                  };
                }),
            },
          });
          await toPromise(inventoryDialog.afterClosed());
        }
        this.snacks.open('Voided successfully!', 'Ok', { duration: 3000 });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close({
      submit: true,
    });
  }
}
