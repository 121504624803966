/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';

@Component({
  selector: 'greco-checkout-station-pay-dialog',
  templateUrl: './checkout-station-pay.dialog.html',
  styleUrls: ['./checkout-station-pay.dialog.scss'],
})
export class CheckoutStationPayDialog implements OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CheckoutStationPayDialog>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      user: User;
      billedTo: User;
      communityId: string;
      applyCoupons: boolean;
      onlyTerminals: boolean;
      selectedPaymentMethod: PaymentMethod | null;
      variants: { variantId: string; quantity: number }[];
    }
  ) {
    this.user = data.user;
    this.billedTo = data.billedTo;
    this.variants = data.variants;
    this.communityId = data.communityId;
    this.applyCoupons = data.applyCoupons;
    this.onlyTerminals = data.onlyTerminals;
    this.selectedPaymentMethod = data.selectedPaymentMethod;
  }

  user!: User;
  billedTo!: User;
  selectedPaymentMethod: PaymentMethod | null;
  variants: { variantId: string; quantity: number }[] = [];
  onlyTerminals = false;
  applyCoupons = true;

  private onDestroy$ = new Subject<void>();

  communityId: string;

  dialogData: DialogData = {
    title: 'Checkout',
    subtitle: '',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  async close(success = false) {
    return this.dialogRef.close(success);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
