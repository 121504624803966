<ng-container *grecoLet="otherRefundsAmountForItem$ | async">
  <ng-container *grecoLet="maxRefund$ | async as maxRefund">
    <ng-container *grecoLet="maxRefundPositive$ | async as maxRefundPositive">
      <ng-container *grecoLet="maxPaymentRefund$ | async as maxPaymentRefund">
        <greco-simple-dialog [data]="dialogData">
          <p>
            Please specify the amount to refund for this purchase. You may refund to the payment method of the customer
            or directly to their balance, but no more than the amount left to refund.
          </p>

          <form [formGroup]="formGroup" (ngSubmit)="submit(maxRefundPositive || false, maxRefund || 0)">
            <mat-form-field class="flex-auto" style="margin-bottom: -8px" appearance="outline" floatLabel="always">
              <mat-label>Refund Item (Optional)</mat-label>
              <mat-select [value]="selectedRefundItem" (selectionChange)="selectedRefundItem = $event.value">
                <mat-option [value]="''">Entire Purchase</mat-option>
                <mat-option *ngFor="let item of items$ | async" [value]="item"
                  >{{ item.displayName }} ({{(item.total/100) | currency}})</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-divider></mat-divider>
            <p style="margin-bottom: 8px; margin-top: 8px; text-align: center">
              Amount {{ maxRefundPositive ? 'Refundable' : 'to Reclaim' }}:
              <strong>{{ (maxRefund || 0) * (maxRefundPositive ? 1 : -1) | currency }}</strong>
              (Taxes included)
              <br />
              <span *ngIf="!maxRefundPositive" style="font-size: 12px; line-height: 0">
                Because this purchase was negative, the amount was automatically added to balance. Use this refund
                dialog to undo this transaction up to the amount added to balance.
              </span>
            </p>
            <mat-divider style="margin-bottom: 16px"></mat-divider>

            <mat-form-field *ngIf="(canRefundToBalance$ | async)" appearance="standard">
              <mat-label
                >Balance Refund Amount ( Max Amount :
                <strong>
                  {{ ((maxRefund || 0) - (formGroup.get('paymentRefundAmount')?.value || 0)) | currency }} </strong
                >)</mat-label
              >
              <input
                matInput
                currencyMask
                type="text"
                formControlName="balanceRefundAmount"
                [readonly]="processing"
                [options]="currencyMaskConfig"
              />
              <!-- <mat-error
                *ngIf="formGroup.get('balanceRefundAmount')?.errors && formGroup.get('balanceRefundAmount')?.hasError('invalid')"
              >
                Amount exceeded the maximum refund <span *ngIf="!maxRefundPositive">reclaim</span> limit of
                <strong>
                  {{ ((maxRefund || 0) - (formGroup.get('paymentRefundAmount')?.value || 0)) * (maxRefundPositive ? 1 :
                  -1) | currency }}
                </strong>
              </mat-error> -->
            </mat-form-field>

            <mat-form-field *ngIf="canRefundToPayment$ | async" appearance="standard">
              <mat-label>
                Payment {{ maxRefundPositive ? 'Refund' : 'Reclaim' }} Amount ( Max Amount :
                <strong> {{ getMinValue([ (maxPaymentRefund || 0), (maxRefund || 0)])| currency }} </strong>)
              </mat-label>

              <input
                matInput
                type="text"
                formControlName="paymentRefundAmount"
                currencyMask
                [options]="currencyMaskConfig"
                [readonly]="processing"
              />

              <!-- <mat-error
                *ngIf="formGroup.get('paymentRefundAmount')?.errors && formGroup.get('paymentRefundAmount')?.hasError('max')"
              >
                Amount exceeded the maximum refund limit of
                <strong> {{ getMinValue([ (maxPaymentRefund || 0), (maxRefund || 0)]) | currency }} </strong
                >.</mat-error
              > -->
            </mat-form-field>

            <small
              *ngIf="(formGroup.get('paymentRefundAmount')?.errors  && formGroup.get('paymentRefundAmount')?.hasError('max') ) || (formGroup.get('balanceRefundAmount')?.errors && formGroup.get('balanceRefundAmount')?.hasError('invalid')) || ((formGroup.get('paymentRefundAmount')?.value || 0 ) + (formGroup.get('balanceRefundAmount')?.value || 0) > (maxRefund || 0)) || ((formGroup.get('paymentRefundAmount')?.value || 0 )  > (maxPaymentRefund || 0))"
              style="margin-top: 16px"
            >
              <mat-error>
                Please ensure that the combined total of your balance and payment refund amounts is not greater than
                <strong> {{ (maxRefund || 0) | currency }}</strong>.
              </mat-error>
              <mat-error
                *ngIf="(maxPaymentRefund !== maxRefund && (formGroup.get('paymentRefundAmount')?.value || 0) > (maxPaymentRefund || 0))"
              >
                Please ensure your payment refund amount is not greater than
                <strong> {{ (maxPaymentRefund || 0) | currency }}</strong>.
              </mat-error>
            </small>

            <mat-form-field *ngIf="isInteracPayment" appearance="standard" floatLabel="always">
              <mat-label>Terminal</mat-label>
              <greco-select-payment-method
                [placeholder]="'Select terminal'"
                [formControl]="terminalControl"
                [userId]="purchase.user.id"
                [onlyTerminals]="true"
                [required]="true"
                [accountId]="purchase.account.id"
              ></greco-select-payment-method>
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always">
              <mat-label>Reason for Refund</mat-label>

              <mat-select
                formControlName="refundReason"
                placeholder="Select reason for refund"
                [required]="true"
                [disabled]="processing"
                [matMenuTriggerFor]="refundReasonsMenu"
              >
                <mat-menu #refundReasonsMenu>
                  <mat-option *ngFor="let reason of refundReasons" [value]="reason">{{ reason }}</mat-option>

                  <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="membershipCancellation">
                    Membership Cancellation

                    <mat-menu #membershipCancellation>
                      <mat-option
                        *ngFor="let membershipCancellationReason of membershipCancellationReasons"
                        [value]="membershipCancellationReason"
                      >
                        {{ membershipCancellationReason }}
                      </mat-option>
                    </mat-menu>
                  </button>

                  <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="membershipTransfer">
                    Membership Transfer
                    <mat-menu #membershipTransfer>
                      <mat-option
                        *ngFor="let membershipTransferReason of membershipTransferReasons"
                        [value]="membershipTransferReason"
                      >
                        {{ membershipTransferReason }}
                      </mat-option>
                    </mat-menu>
                  </button>

                  <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="personalTraining">
                    Personal Training

                    <mat-menu #personalTraining>
                      <mat-option
                        *ngFor="let personalTrainingReason of personalTrainingReasons"
                        [value]="personalTrainingReason"
                      >
                        {{ personalTrainingReason }}
                      </mat-option>
                    </mat-menu>
                  </button>

                  <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="activeKidsClub">
                    Active Kids Club
                    <mat-menu #activeKidsClub>
                      <mat-option
                        *ngFor="let activeKidsClubReason of activeKidsClubReasons"
                        [value]="activeKidsClubReason"
                      >
                        {{ activeKidsClubReason }}
                      </mat-option>
                    </mat-menu>
                  </button>

                  <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="waiveLateFee">
                    Waive Late Fee
                    <mat-menu #waiveLateFee>
                      <mat-option *ngFor="let waiveLateFeeReason of waiveLateFeeReasons" [value]="waiveLateFeeReason">
                        {{ waiveLateFeeReason }}
                      </mat-option>
                    </mat-menu>
                  </button>
                </mat-menu>
              </mat-select>
            </mat-form-field>

            <mat-checkbox
              *ngIf="
        purchase.type === 'ProductPurchaseEntity'
          || purchase.type === 'SubscriptionStartPurchaseEntity'
          || purchase.type === 'SubscriptionUpdatePurchaseEntity'
      "
              style="margin-bottom: 16px"
              formControlName="returnToInventory"
              [disabled]="processing"
            >
              Return item(s) to inventory if applicable
            </mat-checkbox>

            <div
              *ngIf="isInteracPayment && (canRefundToPayment$ | async)"
              style="
                display: flex;
                align-items: center;

                padding: 8px 16px;
                border-radius: 8px;
                margin-bottom: 16px;

                color: rgb(233, 119, 21);
                background-color: rgba(233, 119, 21, 0.2);
              "
            >
              <mat-icon>warning</mat-icon>

              <p style="margin-bottom: 0; margin-left: 16px">
                This purchase was paid with a <strong>debit/interac</strong> card. Refunds MUST be processed in-person
                using the same debit card used for payment.
              </p>
            </div>

            <div style="display: flex; align-items: center; gap: 8px">
              <button mat-stroked-button type="button" (click)="close()" style="flex: 1">Cancel</button>

              <button
                mat-stroked-button
                type="submit"
                color="primary"
                [disabled]="formGroup.invalid || processing || (isInteracPayment && formGroup.value.paymentRefundAmount ? !terminalControl.value : false) || (!formGroup.value.balanceRefundAmount && !formGroup.value.paymentRefundAmount)"
                style="flex: 1"
              >
                Confirm
                <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
              </button>
            </div>
          </form>
        </greco-simple-dialog>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
