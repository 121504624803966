import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InventoryService, VariantsService } from '../../services';

@Component({
  selector: 'greco-add-inventory-dialog',
  templateUrl: './add-to-inventory.dialog.html',
  styleUrls: ['./add-to-inventory.dialog.scss'],
})
export class AddInventoryDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private variantsSvc: VariantsService,
    private inventorySvc: InventoryService,
    private dialogRef: MatDialogRef<AddInventoryDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: { variantId: string; addonId: string }
  ) {
    this.addonId = data.addonId;
    this.variantId = data.variantId;
  }

  dialogData: DialogData = {
    title: 'Restock Inventory',
    subtitle: 'Confirm the quantity you wish to adjust the inventory by',
    buttons: [
      {
        label: 'Cancel',
        role: 'no',
        resultFn: () => this.close(),
      },
      {
        label: 'Confirm',
        role: 'yes',
        resultFn: () => this.submit(),
      },
    ],
  };

  processing = false;

  readonly formGroup = this.formBuilder.group({
    restock: [null as number | null],
  });

  @PropertyListener('variantId') variantId$ = new BehaviorSubject<string | undefined>(undefined);
  readonly variantId!: string;

  @PropertyListener('addonId') addonId$ = new BehaviorSubject<string | undefined>(undefined);
  readonly addonId!: string;

  inventory$ = combineLatest([this.variantId$, this.addonId$]).pipe(
    switchMap(async ([variantId, addonId]) => {
      if (!variantId || !addonId) return null;

      const inventory = await this.inventorySvc.getVariantInventory(variantId);
      if (inventory) return inventory;
      else return await this.inventorySvc.createVariantInventory(addonId, variantId);
    })
  );

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const restockValue = this.formGroup.get('restock')?.value;
      if (!restockValue) throw new Error('Please enter a restock quantity');
      await this.inventorySvc.restockVariant(this.variantId, restockValue);
      this.snacks.open(
        `${restockValue > 0 ? 'Restocked' : 'Removed'} ${Math.abs(
          restockValue
        )} item(s). Please refresh to see updated value`,
        'Ok',
        {
          duration: 2500,
          panelClass: 'mat-primary',
        }
      );
      this.close(restockValue);
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
      this.close(null);
    }

    this.processing = false;
  }

  addRestock() {
    this.formGroup.setValue({ restock: 0 });
    this.formGroup.markAsDirty();
  }

  removeRestock() {
    this.formGroup.setValue({ restock: null });
    this.formGroup.markAsDirty();
  }
}
