import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { APP_CONFIG, AppConfig } from '@greco/ngx-app-config';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { CustomerSupportService } from '@greco/ngx-platform-customer-support';
import { TypeformService } from '@greco/ngx-typeform';
import { createPopup } from '@typeform/embed';
import moment from 'moment';
import { BehaviorSubject, Subject, combineLatest, from } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-onboarding-form',
  template: '<div #onboardingFormContainer></div>',
})
export class OnboardingFormComponent implements AfterViewInit, OnDestroy {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private typeformSvc: TypeformService,
    private customerSupportSvc: CustomerSupportService,
    private contactsSvc: ContactService,
    @Inject(APP_CONFIG) public appConfig: AppConfig
  ) {
    this.router.events
      .pipe(
        filter(
          event => event instanceof NavigationEnd && !event.url?.includes('/welcome/') && !event.url?.includes('/shop')
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.refresh());
  }

  @ViewChild('onboardingFormContainer') private container?: ElementRef;

  private onboardingFormId$ = from(this.customerSupportSvc.getCustomerSupportConfig(this.appConfig.name)).pipe(
    map(config => config?.onboardingTypeform || null),
    shareReplay(1)
  );

  private response$ = combineLatest([this.userSvc.user$, this.onboardingFormId$]).pipe(
    switchMap(async ([user, formId]) =>
      formId && user ? await this.typeformSvc.getMostRecentResponse(user.id, formId) : null
    )
  );

  private _onDestroy$ = new Subject();
  private _refresh$ = new BehaviorSubject<null>(null);

  private formOpen = false;

  private mutationObserver = new MutationObserver(mutations =>
    mutations.forEach(record =>
      record.removedNodes.forEach(node => {
        if (node instanceof HTMLElement && node.classList.contains('typeform-popup')) {
          this.formOpen = false;
        }
      })
    )
  );

  ngAfterViewInit() {
    if (this.container) this.mutationObserver.observe(this.container.nativeElement, { childList: true });

    combineLatest([this.onboardingFormId$, this.response$, this._refresh$, this.userSvc.refresh$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(async ([formId, response]) => {
        const user = await this.userSvc.getSelf();
        const subs = await this.contactsSvc.getUserContacts();

        if (
          !formId ||
          !user ||
          (user.phoneNumber && moment(user.created).isBefore(moment('2022-06-01'))) ||
          response ||
          !subs?.length
        ) {
          return;
        }
        if (subs.some(c => ['com_lvCy65NsFzk9tBgjhEhd', 'com_tH5UJJeV4v4gkhkAogUx'].includes(c.community.id))) {
          return;
        }
        const getMostRecentResponse = await this.typeformSvc.getMostRecentResponse(user.id, formId);
        if (!getMostRecentResponse) this.openForm(user, formId, subs);
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._refresh$.complete();
    this.mutationObserver.disconnect();
  }

  private openForm(user: User, formId: string, subscriptions: Contact[]) {
    if (this.formOpen && !(this.router.url === '/')) return;
    this.formOpen = true;

    const community = (subscriptions.find(c => c.community?.id !== 'com_greco') || subscriptions[0])?.community;
    if (this.router.url.includes('welcome')) return;

    createPopup(formId, {
      hidden: {
        // Community Fields
        community_name: community.name,
        community_email: community.email,
        community_phone_number: community.phoneNumber,
        community_offers_online: community.tags?.includes('online').toString() || 'false',
        community_offers_in_studio: community.tags?.includes('in-studio').toString() || 'false',
        // User Fields
        user_id: user.id,
        user_email: user.email || user.contactEmail,
        user_display_name: user.displayName,
        user_friendly_name: user.friendlyName || '',
      },
      container: this.container?.nativeElement,
      onSubmit: async ({ responseId }) => {
        await new Promise(res => setTimeout(res, 1000));
        const account = await this.typeformSvc.getAccount(null);
        await this.typeformSvc.responseReceived(formId, account.id, responseId);
      },
      autoClose: 5000,
      size: 80,
    }).open();
  }

  refresh() {
    this._refresh$.next(null);
  }
}
