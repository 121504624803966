import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product, ProductVariant, VariantTransferProrateTrigger } from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import { CurrencyMaskConfig } from 'ngx-currency';
import { VariantTransferService } from '../../services';

@Component({
  selector: 'greco-create-variant-transfer-dialog',
  templateUrl: './create-variant-transfer.dialog.html',
  styleUrls: ['./create-variant-transfer.dialog.scss'],
})
export class CreateVariantTransferDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private variantTransferSvc: VariantTransferService,
    private dialogRef: MatDialogRef<CreateVariantTransferDialog>,
    @Inject(MAT_DIALOG_DATA) data: { product: Product; variant: ProductVariant }
  ) {
    this.product = data.product;
    this.variant = data.variant;
  }

  submitting = false;

  product: Product;
  variant: ProductVariant;

  form = this.formBuilder.group({
    account: [null, Validators.required],
    title: [null, Validators.required],
    sendReceive: ['send', Validators.required],
    amount: [0.5, Validators.compose([Validators.required, Validators.min(0.5)])],
    trigger: [[], Validators.compose([Validators.required, Validators.minLength(1)])],
    applyRoyalties: [true],
    prorateTrigger: [VariantTransferProrateTrigger.NEVER, Validators.required],
  });

  readonly dialogData: DialogData = {
    title: 'Configure Fee Or Referral',
    subtitle: 'Configure a transfer between two accounts to execute when this product variant is sold.',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  cancel() {
    this.dialogRef.close();
  }

  async submit() {
    this.submitting = true;
    console.log('Submitting', this.form.value);

    await new Promise(res => setTimeout(res, 2000));

    try {
      const transfer = await this.variantTransferSvc.create(this.product.id, this.variant.id, {
        triggerEvents: this.form.value.trigger,
        amount: Math.round(this.form.value.amount * 100),
        title: this.form.value.title,

        destinationAccountId:
          this.form.value.sendReceive === 'send'
            ? this.form.value.account.id
            : this.product.community.financeAccount.id,

        sourceAccountId:
          this.form.value.sendReceive === 'receive'
            ? this.form.value.account.id
            : this.product.community.financeAccount.id,
        applyRoyalties: this.form.value.applyRoyalties ?? true,
        prorateTrigger: this.form.value.prorateTrigger,
      });

      this.dialogRef.close(transfer);
      this.snacks.open('Transfer created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
    }

    this.submitting = false;
  }
}
