import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tax } from '@greco/finance-tax';
import { CommunityService } from '@greco/ngx-identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProductsService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-create-product',
  templateUrl: './create-product.dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateProductDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private productService: ProductsService,
    private communitySvc: CommunityService,
    private dialogRef: MatDialogRef<CreateProductDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string; accountId: string }
  ) {
    this.communityId = data.communityId;
    this.accountId = data.accountId;
  }

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string | null>(null);
  communityId!: string;
  accountId!: string;
  community$ = this.communityId$.pipe(
    switchMap(async communityId => {
      return communityId ? await this.communitySvc.getCommunity(communityId) : undefined;
    })
  );
  dialogData: DialogData = {
    title: 'Create Product',
    subtitle: 'Fill out the necessary fields to create a product',
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({
    title: ['', Validators.required],
    saleCategory: [null, Validators.required],
    description: [''],
    taxes: [[]],
    ignoreTaxes: [false],
  });

  processing = false;

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const product = await this.productService.createProduct({
        description: this.form.value.description || '',
        communityId: this.communityId,
        title: this.form.value.title,
        saleCategoryId: this.form.value.saleCategory?.id,
        taxes: this.form.value.taxes ? this.form.value.taxes.map((tax: Tax) => tax.id) : [],
        ignoreTaxes: this.form.value.ignoreTaxes || false,
      });

      this.snacks.open('Product created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.close(product);
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      this.close(null);
    }

    this.processing = false;
  }
}
