import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyListener } from '@greco/property-listener-util';
import { ProductVariantInventory } from '@greco/sales-products';
import { DialogData } from '@greco/ui-dialog-simple';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { InventoryService, VariantsService } from '../../services';

interface VariantData {
  variantId: string;
  title: string;
  inventory?: ProductVariantInventory;
  quantity: number;
}

@Component({
  selector: 'greco-return-inventory-dialog',
  templateUrl: './return-to-inventory.dialog.html',
  styleUrls: ['./return-to-inventory.dialog.scss'],
})
export class ReturnInventoryDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private variantsSvc: VariantsService,
    private inventorySvc: InventoryService,
    private dialogRef: MatDialogRef<ReturnInventoryDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: { purchaseId: string; variants: VariantData[] }
  ) {
    this.purchaseId = data.purchaseId;
    this.variantData = data.variants;
  }

  dialogData: DialogData = {
    title: 'Return Inventory',
    subtitle: 'Select which product variant(s) and quantity to return to corresponding inventory',
    buttons: [
      {
        label: 'Cancel',
        role: 'no',
        resultFn: () => this.close(),
      },
      {
        label: 'Confirm',
        role: 'yes',
        resultFn: () => this.submit(),
      },
    ],
  };

  processing = false;

  controls: Map<string, FormControl> = new Map();

  @PropertyListener('purchaseId') purchaseId$ = new BehaviorSubject<string | undefined>(undefined);
  readonly purchaseId!: string;

  @PropertyListener('variantData') variantData$ = new BehaviorSubject<VariantData[]>([]);
  private readonly variantData: VariantData[] = [];

  loadedVariants$ = this.variantData$.pipe(
    tap(() => this.controls.clear()),
    switchMap(async variantData => {
      const loaded: VariantData[] = [];
      for (const data of variantData) {
        if (!('inventory' in data)) {
          data.inventory = await this.inventorySvc.getVariantInventory(data.variantId);
          if (data.inventory) loaded.push(data);
        }
      }

      return loaded;
    }),
    tap(loaded => loaded.forEach(data => this.controls.set(data.variantId, new FormControl(data.quantity))))
  );

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      await Promise.all(
        Array.from(this.controls.entries()).map(([variantId, quantity]) =>
          quantity.value > 0 ? this.inventorySvc.returnVariant(variantId, quantity.value, this.purchaseId) : {}
        )
      );
      this.close();
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
      this.close(null);
    }

    this.processing = false;
  }

  addVariant(variantId: string) {
    this.controls.set(variantId, new FormControl(0));
  }
}
