import { Component, Injectable, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { BuildSearchFilter, FilterBarComponent } from '@greco/ngx-filters';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SaleCategoryPickerComponent } from '@greco/ngx-sales-purchases';
import { Product, ProductStatus } from '@greco/sales-products';
import { SaleCategory } from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CreateProductDialog } from '../../dialogs';
import { InventoryService, ProductsService } from '../../services';

@Injectable({ providedIn: 'any' })
export class ProductSearchFilter extends BuildSearchFilter('ProductSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Component({
  selector: 'greco-products-page',
  templateUrl: './products.page.html',
  styleUrls: ['./products.page.scss'],
})
export class ProductsPage implements OnDestroy {
  constructor(
    private router: Router,
    private dialogs: MatDialog,
    private snacks: MatSnackBar,
    private route: ActivatedRoute,
    private productSvc: ProductsService,
    private communitySvc: CommunityService,
    private inventorySvc: InventoryService
  ) {}

  @ViewChild(FilterBarComponent) private filterBar?: FilterBarComponent;
  @ViewChild('saleCategoryPicker') saleCategoryPicker!: SaleCategoryPickerComponent;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() account!: Account;

  private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() get communityId() {
    return this._communityId$.value;
  }
  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }

  private accountId$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId ? (await this.communitySvc.getCommunity(communityId))?.financeAccountId : undefined;
    })
  );

  exporting = false;

  loading = true;
  active = ProductStatus.Active;

  pagination: null | IPaginationMeta = null;

  productsToActivate: Product[] = [];
  listIncludesInactiveProducts = false;

  filterOptions = [ProductSearchFilter];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<Required<PaginatedQueryParams>>({ page: 1, limit: 10 });

  showUncategorized$ = new BehaviorSubject<boolean>(true);
  selectedCategories$ = new ReplaySubject<SaleCategory[]>(1);

  products$ = combineLatest([
    // this.sort$,
    this.filters$,
    this._communityId$,
    this.page$,
    this.selectedCategories$,
    this.showUncategorized$,
  ]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([filters, communityId, pagination, categories, showUncategorized]) => {
      const categoryIds = categories.map(category => category.id);
      return communityId
        ? await this.productSvc.paginateProducts(filters, communityId, pagination, categoryIds, showUncategorized)
        : null;
    }),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(data =>
      data?.filter(product => product.status !== ProductStatus.Active)?.length
        ? (this.listIncludesInactiveProducts = true)
        : (this.listIncludesInactiveProducts = false)
    ),
    tap(() => (this.loading = false))
  );

  async createProduct() {
    const accountId = await toPromise(this.accountId$);
    const dialog = await toPromise(
      this.dialogs.open(CreateProductDialog, { data: { communityId: this.communityId, accountId } }).afterClosed()
    );
    if (dialog) {
      this.page$.next(this.page$.value);
      await this.router.navigate([dialog.id], { relativeTo: this.route });
    }
  }

  async openProduct(product: Product) {
    await this.router.navigate([product.id], { relativeTo: this.route });
  }

  async activateProduct(product: Product) {
    try {
      const oneVrtActive = product.variants.some(vrt => vrt.status === ProductStatus.Active);
      if (!oneVrtActive) throw new Error('Cannot activate product, there must be at least one active variant');

      await this.productSvc.activateProduct(product.id);
      this.page$.next(this.page$.value);
      this.snacks.open('Activated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
  }

  toggleActivateProduct(productToToggle: Product) {
    if (this.productsToActivate.map(product => product.id).includes(productToToggle.id)) {
      this.productsToActivate = this.productsToActivate.filter(product => product.id != productToToggle.id);
    } else this.productsToActivate.push(productToToggle);
  }

  productInList(product: Product) {
    return this.productsToActivate.map(p => p.id).includes(product.id);
  }

  async activateBulk() {
    const activatedProducts: string[] = [];
    const productsNotActivated: Product[] = [];

    await Promise.all(
      this.productsToActivate.map(async product => {
        try {
          const oneVrtActive = product.variants.some(vrt => vrt.status === ProductStatus.Active);
          if (!oneVrtActive) productsNotActivated.push(product);
          else {
            const activatedProduct = await this.productSvc.activateProduct(product.id);
            if (activatedProduct.status == ProductStatus.Active) activatedProducts.push(activatedProduct.id);
          }
        } catch (err) {
          this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
          console.error(err);
        }
      })
    );

    if (activatedProducts.length) this.page$.next(this.page$.value);
    if (activatedProducts.length === this.productsToActivate.length) {
      this.snacks.open('Activated all products!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } else {
      if (activatedProducts.length) {
        this.snacks.open('Activated some products!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }

      this.snacks.open(
        'Unable to activate products: ' +
          productsNotActivated.map(p => p.title).join(', ') +
          '. Products must have at least one active variant!',
        'Ok',
        { duration: 10000, panelClass: 'mat-warn' }
      );
    }

    this.productsToActivate = [];
  }

  async archiveProduct(product: Product) {
    try {
      await this.productSvc.archiveProduct(product.id);
      this.page$.next(this.page$.value);
      this.snacks.open('Archived!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
  }

  getLink(product: Product) {
    return window.location.host + '/shop/products/' + product.id;
  }

  copied() {
    this.snacks.open('Link copied to clipboard!', 'Ok', { panelClass: 'mat-primary', duration: 2500 });
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this._communityId$.complete();
  }

  async exportInventories(communityId: string) {
    this.exporting = true;
    try {
      InitiateExportDialog.open(this.dialogs, {
        processorId: 'InventoryDataExportProcessor',
        initiateExport: (skip?: number) => {
          return this.inventorySvc.export(communityId, skip);
        },
      });
    } catch (err) {
      console.error(err);
    }

    this.exporting = false;
  }
}
