import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../simple-dialog/simple.dialog';

export interface ErrorDialogData extends Partial<DialogData> {
  error: any;
}

@Component({
  selector: 'greco-error-dialog-page',
  templateUrl: 'error.dialog.html',
})
export class ErrorDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorDialogData, public dialogRef: MatDialogRef<ErrorDialog>) {}

  close(dialogResult?: any) {
    this.dialogRef.close(dialogResult);
  }
}
