import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { PaymentMethod } from '@greco/finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription, SubscriptionStatus } from '@greco/sales-subscriptions';
import { DialogData, SimpleDialog } from '@greco/ui-dialog-simple';
import moment from 'moment';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss'],
})
export class SubscriptionOptionsMenuComponent {
  constructor(
    private subscriptionSvc: SubscriptionsService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private userSvc: UserService
  ) {}

  @Output() paymentMethodUpdated = new EventEmitter<PaymentMethod>();
  @Output() subscriptionCancelled = new EventEmitter<{ endOfPeriod: boolean }>();

  @Input() subscription?: Subscription;
  @Input() communityId?: string | null;

  @Input() hideUpdatePaymentMethod?: boolean | null;
  @Input() hideViewCustomer?: boolean | null;
  @Input() hideViewDetails?: boolean | null;
  @Input() hideCancel?: boolean | null;

  @Input() canCancel = false;

  _hasAtLeastOneOption = false;

  _updatingPaymentMethod = false;
  _cancelling = false;

  async updatePaymentMethod(paymentMethod: PaymentMethod | null) {
    if (!paymentMethod?.id || !this.subscription) return;

    const isSame = this.subscription.paymentMethod?.id === paymentMethod.id;
    if (isSame || this.subscription.status !== SubscriptionStatus.ACTIVE) return;

    this._updatingPaymentMethod = true;

    try {
      this.subscription = await this.subscriptionSvc.updatePaymentMethod(this.subscription.id, paymentMethod.id);

      this.snacks.open('Payment Method Updated!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      this.paymentMethodUpdated.emit(paymentMethod);
    } catch (err) {
      console.error(err);
      this.snacks.open('Oops something went wrong. Please try again later.', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }

    this._updatingPaymentMethod = false;
  }

  cancelNow() {
    return this.cancel(false);
  }

  cancelAtPeriodEnd() {
    return this.cancel(true);
  }

  private async cancel(endOfPeriod: boolean) {
    if (!this.subscription) return;

    this._cancelling = true;

    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Cancel Subscription',
          subtitle: `${moment(this.subscription.periodStart).format('ll')} to ${moment(
            this.subscription.periodEnd
          ).format('ll')}`,
          content: endOfPeriod
            ? 'Are you sure you want to cancel this subscription at the end of the current period?'
            : 'Are you sure you want to cancel this subscription? This will take effect immediately.',
          showCloseButton: false,
          buttons: [
            { label: 'No, keep the subscription', role: 'no' },
            {
              label: 'Yes, cancel it ' + (endOfPeriod ? 'at the end of the period' : 'now'),
              role: 'yes',
            },
          ],
        } as DialogData,
      });

      const dialogResult = await toPromise(dialog.afterClosed());
      if (dialogResult == 'yes') {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: 'Cancel Subscription',
            subtitle: `${moment(this.subscription.periodStart).format('ll')} to ${moment(
              this.subscription.periodEnd
            ).format('ll')}`,
            content: 'Where should any unused time be refunded to?',
            showCloseButton: false,
            buttons: [
              { label: 'Refund to Balance', role: 'balance' },
              {
                label: 'Refund to Card',
                role: 'card',
              },
            ],
          } as DialogData,
        });
        const refundTo = await toPromise(dialog.afterClosed());
        const currentUserId = await toPromise(this.userSvc.getUserId());
        await this.subscriptionSvc.cancelSubscription(this.subscription.id, {
          endOfPeriod,
          proration: true,
          refundToCard: refundTo === 'card',
          createdById: currentUserId,
        });
        this.snacks.open(endOfPeriod ? 'Subscription scheduled to cancel!' : 'Subscription cancelled!', 'Ok', {
          duration: 2500,
          panelClass: 'mat-primary',
        });
        this.subscriptionCancelled.emit({ endOfPeriod });
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('Oops something went wrong. Please try again later.', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }

    this._cancelling = false;
  }

  @PropertyListener('options')
  @PropertyListener('canCancel')
  private _updateHasAtLeastOneOption() {
    const options = [];

    if (!this.hideCancel) options.push('cancel-now', 'cancel-end');
    if (!this.hideUpdatePaymentMethod) options.push('update-payment-method');
    if (!this.hideViewCustomer) options.push('view-customer');
    if (!this.hideViewDetails) options.push('view-details');

    if (!options.length) {
      this._hasAtLeastOneOption = false;
    } else if (options.length === 1 && options.includes('view-customer')) {
      this._hasAtLeastOneOption = !!this.communityId;
    } else if (options.includes('cancel-now') || options.includes('cancel-end')) {
      if (
        options.length === 1 ||
        (options.length === 2 && options.includes('cancel-now') && options.includes('cancel-end'))
      ) {
        this._hasAtLeastOneOption = this.canCancel;
      } else {
        this._hasAtLeastOneOption = true;
      }
    } else {
      this._hasAtLeastOneOption = true;
    }
  }
}
