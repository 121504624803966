<ng-container *grecoLet="syncEntriesTimer$ | async">
  <mat-list role="list" *ngIf="checkedInUsers$ | async as checkIns">
    <mat-list-item
      *ngFor="let checkInList of checkIns.items"
      role="listitem"
      (click)="openContact(checkInList.user?.id)"
    >
      <div mat-list-icon>
        <greco-user-avatar
          size="large"
          [showOnlineStatus]="false"
          [user]="checkInList.user || null"
        ></greco-user-avatar>
      </div>
      <div mat-line>
        <strong>{{ checkInList.user?.displayName }}</strong>
      </div>
      <div mat-line class="email">{{ checkInList.user?.contactEmail }}</div>
      <div mat-line style="margin-top: 4px">
        <button color="primary" mat-flat-button class="close-button" *ngIf="canDelete$ | async">
          <span *ngIf="checkInList.timestamp! | isToday">{{ checkInList.timestamp | date : 'shortTime' }}</span>
          <span *ngIf="!(checkInList.timestamp! | isToday)">{{ checkInList.timestamp | date : 'short' }}</span>
        </button>
      </div>
      <button mat-icon-button color="none" *ngIf="canDelete$ | async" (click)="openDialog(checkInList.id || '')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
  <mat-paginator
    *ngIf="currentPagination?.totalItems"
    showFirstLastButtons
    [length]="currentPagination!.totalItems || 0"
    [pageSize]="currentPagination!.itemsPerPage || pageSizes[0]"
    [hidePageSize]="true"
    (page)="paginatedParams$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</ng-container>
