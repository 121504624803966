import { Pipe, PipeTransform } from '@angular/core';
import { Purchase } from '@greco/sales-purchases';

@Pipe({ name: 'purchaseTotal' })
export class PurchaseTotalPipe implements PipeTransform {
  transform(purchase: Purchase): number {
    const subtotal = purchase.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const taxes = purchase.items.reduce(
      (acc, item) =>
        acc +
        (item.taxes?.reduce((acc, tax) => acc + tax.percentage / 100, 0) ||
          (purchase.taxes ?? []).reduce((acc, tax) => acc + tax.percentage / 100, 0)) *
          item.price *
          item.quantity,
      0
    );
    return Math.round(subtotal + taxes);
  }
}
