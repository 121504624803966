import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Booking, EventBookingSecurityResource, EventBookingSecurityResourceAction } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BookingService } from '../../services/booking.service';
import {
  BookingEventStartDateFilter,
  BookingFilterByStatusFilter,
  BookingOptionFilter,
  BookingResourceFilter,
  BookingsSearchFilter,
  BookingUserFilter,
} from './filters';
import { BookingTagFilter } from './filters/booking-tag.filter';

@Component({
  selector: 'greco-bookings-page',
  templateUrl: './bookings.page.html',
  styleUrls: ['./bookings.page.scss'],
})
export class BookingsPage implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private bookingSvc: BookingService,
    private bookingTagFilter: BookingTagFilter,
    private bookingUserFilter: BookingUserFilter,
    private bookingOptionFilter: BookingOptionFilter,
    private bookingResourceFilter: BookingResourceFilter,
    private communitySecuritySvc: CommunitySecurityService
  ) {}

  exporting = false;
  initialCalendars: string[] = [];

  bookingsToCheckIn: string[] = [];
  totalBookingsToCheckIn = 0;

  filterOptions = [
    BookingUserFilter,
    BookingResourceFilter,
    BookingOptionFilter,
    BookingsSearchFilter,
    BookingFilterByStatusFilter,
    BookingEventStartDateFilter,
    BookingTagFilter,
  ];

  readonly query$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() get community() {
    return this._community$.value;
  }
  set community(community: Community | null) {
    this._community$.next(community);
    this.bookingUserFilter.communityId = community?.id;
    this.bookingOptionFilter.communityId = community?.id;
    this.bookingTagFilter.communityId = community?.id;
    this.bookingResourceFilter.communityId = community?.id;
  }

  canExportBookings$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? this.communitySecuritySvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.EXPORT
          )
        : false;
    })
  );

  async openEvent(booking: Booking) {
    const communityId = this.community?.id;
    if (!communityId) throw new Error();

    await this.router.navigate(['/scheduling', communityId, booking.event.id]);
  }

  async checkInBookings() {
    await this.bookingSvc.checkInMultiple(this.bookingsToCheckIn);
    this.bookingsToCheckIn = [];

    this.refresh();
  }

  async export() {
    try {
      this.exporting = true;
      InitiateExportDialog.open(this.dialog, {
        processorId: 'BookingsDataExportProcessor',
        initiateExport: (skip?: number) => {
          const newQueryBuilder = new RequestQueryBuilder();
          newQueryBuilder.search({
            $and: [
              ...((this.query$.value.queryObject.s ? JSON.parse(this.query$.value.queryObject.s).$and : []) || []),
              {
                'calendar.id': {
                  $in: this.initialCalendars?.length ? this.initialCalendars : ['not_a_calendar'],
                },
              },
            ],
          });

          const communityId = this.community?.id;
          if (!communityId) throw new Error();

          return this.bookingSvc.export(newQueryBuilder, communityId, skip);
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.exporting = false;
    }
  }

  async calendarIdsFromRoute() {
    const query = await toPromise(this.route.queryParamMap);
    const calendarIds = query.get('calendarIds')?.split(',') ?? [];
    this.initialCalendars = calendarIds;
    return calendarIds;
  }

  async refresh() {
    this.initialCalendars = await this.calendarIdsFromRoute();
    this.query$.next(this.query$.value);
  }

  async ngOnInit() {
    this.initialCalendars = await this.calendarIdsFromRoute();
  }
}
