<h2 mat-dialog-title>{{data?.title || 'Oopsie! Something went wrong'}}</h2>
<mat-dialog-content class="mat-typography">
  <h3 *ngIf="data?.subtitle">{{data?.subtitle}}</h3>
  <p
    *ngIf="data?.content || data?.error?.msg || data?.error?.toString()"
    [innerHtml]="(data?.content || data?.error?.msg || data?.error?.toString()) | safe: 'html'"
  ></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="data.buttons?.length; else ok">
    <button
      *ngFor="let button of data.buttons"
      mat-button
      [color]="button.color || 'basic'"
      [class]="button.class"
      (click)="close(button.role)"
    >
      {{button.label}}
    </button>
  </ng-container>
  <ng-template #ok>
    <button mat-button (click)="close()">Ok</button>
  </ng-template>
</mat-dialog-actions>
