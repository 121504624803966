import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';
import { BookingService } from '../../services';

@Component({
  selector: 'greco-delete-user-from-event-dialog',
  templateUrl: './delete-user-from-event-dialog.html',
  styleUrls: ['./delete-user-from-event-dialog.scss'],
})
export class DeleteUserFromEventDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string; userId: string; eventId: string },
    private bookingSvc: BookingService,
    private snacks: MatSnackBar
  ) {}
  dialogData: DialogData = {
    title: 'Remove User',
    subtitle: 'Are you sure you want to remove the user from the event ?',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Confirm',
        role: 'confirm',

        resultFn: async () => {
          try {
            const { communityId, userId, eventId } = this.data;
            await this.bookingSvc.removeFromWaitlist(communityId, { userId, eventId });
            this.snacks.open('User Removed Successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return true;
          } catch (e) {
            console.error(e);
            this.snacks.open('Error while removing the user', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
            return false;
          }
        },
      },
    ],
  };
}
