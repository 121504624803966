import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BookingOption,
  bookingOptionCancellationPolicyLabel,
  CalendarEvent,
  EventSeries,
  getCancellationPolicyInnerHtml,
} from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-cancellation-policy-requirement',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss'],
})
export class CancellationPolicyRequirementComponent {
  constructor(private matDialog: MatDialog) {}

  @PropertyListener('bookingOption') private _bookingOption$ = new ReplaySubject<BookingOption>();
  @Input() bookingOption!: BookingOption;

  @Input() event!: CalendarEvent | EventSeries;

  readonly subtitle$ = this._bookingOption$.pipe(map(option => bookingOptionCancellationPolicyLabel(option)));

  async openDetailsDialog() {
    this.matDialog.open(SimpleDialog, {
      maxWidth: 700,
      data: {
        showCloseButton: false,
        title: 'Cancellation Policy',
        subtitle: `${this.event.title} - ${moment(this.event.startDate).format('MMM Do, hh:mm A')}`,
        content: getCancellationPolicyInnerHtml(this.bookingOption),
      } as DialogData,
    });
  }
}
