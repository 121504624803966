<!-- TODO(adaoust): Bug, unable to add fadeIn animation; components sometime show even if hidden by ngIf -->
<greco-onboarding-form></greco-onboarding-form>
<!-- TODO:  Need to replace with app provider application name and logo-->
<greco-platform-layout
  #platform
  layoutTitle="LF3"
  [subtitle]="adminView ? 'Administration' : undefined"
  logo="assets/lf3/logo.png"
  [links]="((adminView ? adminLinks$ : userLinks$) | async) || []"
  [showDefaultHomeLink]="!adminView"
  [hideNavigation]="hideNavigation"
  [hideEndContent]="hideEndContent"
  [hideBackButton]="hideBackButton"
  *grecoLet="user$ | async as user"
>
  <!-- Toolbar End -->
  <!-- <greco-cart-button slot="toolbar-end"></greco-cart-button> -->
  <greco-help-button slot="toolbar-end"></greco-help-button>
  <greco-profile-button slot="toolbar-end" #profileButton></greco-profile-button>
  <!-- Drawer Start -->
  <greco-nav-link
    *ngIf="adminView"
    class="return-button"
    color="primary"
    slot="drawer-start"
    [exact]="true"
    link="/"
    label="Return"
    icon="arrow_back"
  ></greco-nav-link>
  <ng-container *ngIf="adminView" slot="drawer-start">
    <ng-container *grecoLet="canCreatePurchaseAccounts$ | async as accounts">
      <ng-container *ngIf="accounts?.length === 1; else multipleAccounts">
        <button
          *ngIf="$any(accounts)[0] as account"
          mat-stroked-button
          color="primary"
          style="width: 100%; margin-bottom: 12px"
          (click)="openQuickPurchase(account.id, $any(account).community.id)"
        >
          Quick Purchase
        </button>
      </ng-container>
      <ng-template #multipleAccounts>
        <button
          *ngIf="accounts?.length"
          mat-stroked-button
          color="primary"
          style="width: 100%; margin-bottom: 12px"
          [matMenuTriggerFor]="accountMenu"
        >
          Quick Purchase
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #accountMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let account of accounts"
            (click)="openQuickPurchase(account.id, $any(account).community.id)"
          >
            {{ $any(account).community.name }}
          </button>
        </mat-menu>
      </ng-template>
    </ng-container>
  </ng-container>
  <mat-divider class="link-divider-overlay" slot="drawer-start" *ngIf="adminView"></mat-divider>
  <!-- Drawer End -->
  <!-- <mat-divider class="link-divider" slot="drawer-end" *ngIf="!adminView && (isStaff$ | async)"></mat-divider>
  <greco-nav-link
    *ngIf="!adminView && (isStaff$ | async)"
    slot="drawer-end"
    [link]="(adminRoute$ | async) || ''"
    label="Admin"
    icon="admin_panel_settings"
  ></greco-nav-link> -->
  <!-- <button
    class="edit-links-button"
    mat-icon-button
    slot="drawer-end"
    routerLink="/platform"
    routerLinkActive="hidden"
    (click)="platform.mobileQuery.matches && platform.drawer.close()"
  >
    <mat-icon>create</mat-icon>
  </button> -->

  <!-- Drawer Bottom -->
  <button
    mat-stroked-button
    class="drawer-bottom"
    style="margin: 8px; padding: 8px 8px 8px 12px"
    slot="drawer-bottom"
    [routerLink]="'/account'"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="enabled"
    *ngIf="user"
  >
    <div style="display: flex; align-items: center; gap: 8px; width: 100%">
      <greco-user-avatar [user]="user" size="small"></greco-user-avatar>
      <div style="display: flex; flex-direction: column; gap: 4px; text-align: left">
        <small>
          <p style="margin-bottom: 0; line-height: 12px">
            {{ user.friendlyName || user.displayName || 'My Profile' }}
          </p>
        </small>
        <small>
          <p style="margin-bottom: 0; line-height: 12px; color: grey; font-weight: normal">{{ user.email }}</p>
        </small>
      </div>
    </div>
    <button
      mat-icon-button
      color="warn"
      (click)="$event.stopImmediatePropagation(); profileButton.signOut()"
      style="color: var(--warn-color) !important"
    >
      <mat-icon color="warn">logout</mat-icon>
    </button>
  </button>

  <mat-divider slot="drawer-bottom"></mat-divider>
  <div class="drawer-bottom" slot="drawer-bottom">
    <button mat-button (click)="openTerms()">Terms of Service</button>
    <button mat-button (click)="openPrivacy()">Privacy Policy</button>
  </div>
  <!-- Overlay Start -->
  <greco-nav-link
    *ngIf="adminView"
    slot="overlay-start"
    color="primary"
    [exact]="true"
    link="/"
    tooltip="Return"
    icon="arrow_back"
    [full]="false"
  ></greco-nav-link>
  <mat-divider class="link-divider-overlay" slot="overlay-start" *ngIf="adminView"></mat-divider>
  <!-- Overlay End -->
  <!-- <mat-divider class="link-divider-overlay" slot="overlay-end" *ngIf="!adminView && (isStaff$ | async)"></mat-divider>
  <greco-nav-link
    *ngIf="!adminView && (isStaff$ | async)"
    [link]="(adminRoute$ | async) || ''"
    slot="overlay-end"
    tooltip="Admin"
    icon="admin_panel_settings"
    [full]="false"
  ></greco-nav-link> -->
</greco-platform-layout>
