import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DO_SECURITY_CHECKS } from './tokens';

@NgModule({
  imports: [CommonModule],
})
export class SecurityUtilsModule {
  static forRoot(doSecurityChecks: boolean = true): ModuleWithProviders<SecurityUtilsModule> {
    return {
      ngModule: SecurityUtilsModule,
      providers: [{ provide: DO_SECURITY_CHECKS, useValue: doSecurityChecks }],
    };
  }
}
