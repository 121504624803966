import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Booking, CalendarEvent } from '@greco/booking-events';
import { BookingService, UserEventsFavoritesService } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { heightExpansion } from '@greco/ui-animations';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardBookingsService } from '../../services/dashboard-bookings.service';

@Component({
  selector: 'greco-dashboard-bookings',
  templateUrl: './dashboard-bookings.component.html',
  styleUrls: ['./dashboard-bookings.component.scss'],
  animations: [heightExpansion],
})
export class DashboardBookingsComponent {
  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    public userSvc: UserService,
    private matDialog: MatDialog,
    public bottomSheet: MatBottomSheet,
    private bookingSvc: BookingService,
    private breakpointObserver: BreakpointObserver,
    public dashboardBookingsSvc: DashboardBookingsService,
    public dashboardFavoritesSvc: UserEventsFavoritesService
  ) {}

  @Input() trigger!: any;

  calendarPickerOpen = false;

  highlightDatesWithBooking = this.dashboardBookingsSvc.hasBooking;

  selectedBooking$ = new Subject<Booking | null>();

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(breakPoints => breakPoints.matches));

  async leaveWaitlist(event: CalendarEvent) {
    if (!event) return;

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Leave Waitlist',
        showCloseButton: false,
        subtitle: `${event.title} - ${moment(event.startDate).format('ll hh:mm A')}`,
        content: 'If you leave the waitlist you will no longer be notified as soon as a spot opens up.',
        buttons: [
          { label: 'No, stay in the waitlist', role: 'no' },
          { label: 'Yes, leave the waitlist', role: 'yes' },
        ],
      } as DialogData,
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const userId = (await this.userSvc.getSelf())?.id;
      if (!userId) return;

      await this.bookingSvc.removeFromWaitlist(event.community.id, {
        userId,
        eventId: event.id,
      });

      this.snacks.open('Removed from waitlist!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      await this.router.navigate(['/']);
    }
  }

  navigateTo(eventId: string) {
    this.router.navigate(['workouts', eventId]);
  }

  refresh() {
    location.reload();
  }
}
