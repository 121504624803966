import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { DialogData } from '@greco/ui-dialog-simple';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'variant-link-product',
  templateUrl: './variant-link.dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VariantLinkDialog {
  constructor(
    private snacks: MatSnackBar,
    private clipboardSvc: ClipboardService,
    private dialogRef: MatDialogRef<VariantLinkDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      communityId: string;
      productId: string;
      variantIds: string[];
      soldById?: string;
      referredById?: string;
    }
  ) {}

  includeSoldBy = !!this.data.soldById;
  includeReferredBy = !!this.data.referredById;

  referredById: string | null = this.data.referredById ?? null;

  link = this.makeLink();

  processing = false;

  dialogData: DialogData = {
    title: this.data.referredById ? 'Send Product to a Friend' : 'Create Variant Link',
    subtitle: this.data.referredById
      ? 'Copy the link to send this product to a friend'
      : 'Add optional parameters to your link',
    hideDefaultButton: true,
  };

  toggleSoldBy() {
    this.includeSoldBy = !this.includeSoldBy;
    this.makeLink();
  }

  toggleReferredBy() {
    this.includeReferredBy = !this.includeReferredBy;
    this.makeLink();
  }

  setReferredBy(referredById: string | null) {
    this.referredById = referredById;
    this.makeLink();
  }

  makeLink(): string {
    return (this.link = `${window.location.origin
      .replace('-admin.', '.')
      .replace('admin.', '')
      .replace('4300', '4200')}/shop/checkout?${this.data.variantIds
      .map(variant => `items=${variant}`)
      .join('&')}&productId=${this.data.productId}${
      this.includeReferredBy && this.referredById ? `&referredById=${this.referredById}` : ''
    }${this.includeSoldBy && this.data.soldById ? `&soldById=${this.data.soldById}` : ''}`);
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    if (!this.data) return;

    this.processing = true;

    this.clipboardSvc.copy(this.link, 'Checkout URL');
    this.snacks.open('Link copied to clipboard!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    this.close();

    this.processing = false;
  }
}
