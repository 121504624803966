import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { Promotion } from '@greco/promotions';
import { Product, ProductVariant } from '@greco/sales-products';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import moment from 'moment';
import { AddProductDialog } from '../../dialogs/add-product/add-product.dialog';
import { PromotionsService } from '../../services';

@Component({
  selector: 'greco-promotion-page',
  templateUrl: './promotion.page.html',
  styleUrls: ['./promotion.page.scss'],
})
export class PromotionPage implements OnInit {
  form = this.fb.group({
    title: ['My Promotion', Validators.required],
    subtitle: [''],
    startDate: [moment().add(1, 'day').toDate(), Validators.required],
    endDate: [moment().add(4, 'day').toDate(), Validators.required],
  });

  promotion!: Promotion;
  products!: Product[];

  resetValue = {};
  save = async () => {
    await this.promotionsSvc.update({
      id: this.promotion.id,
      title: this.form.value.title,
      subtitle: this.form.value.subtitle,
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
    });
    this.promotion.title = this.form.value.title;

    this.resetValue = this.form.value;
    this.form.markAsPristine();

    setTimeout(() =>
      this.breadcrumbsSvc.set([
        {
          label: 'Promotions',
          routerLink: '/sales/' + this.promotion.community.id + '/promotions',
          icon: 'local_offer',
        },
        {
          label: this.promotion.title,
          routerLink: `/sales/` + this.promotion.community.id + '/promotions/' + this.promotion.id,
        },
      ])
    );
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private promotionsSvc: PromotionsService,
    private breadcrumbsSvc: BreadcrumbService
  ) {
    this.promotion = this.route.snapshot.data.promotion;
    this.updateProducts();
    this.form = this.fb.group({
      title: [this.promotion.title, Validators.required],
      subtitle: [this.promotion.subtitle || ''],
      startDate: [this.promotion.startDate, Validators.required],
      endDate: [this.promotion.endDate, Validators.required],
    });
    this.resetValue = {
      title: this.promotion.title,
      subtitle: this.promotion.subtitle,
      startDate: this.promotion.startDate,
      endDate: this.promotion.endDate,
    };
  }

  ngOnInit() {
    setTimeout(() =>
      this.breadcrumbsSvc.set([
        {
          label: 'Promotions',
          routerLink: '/sales/' + this.promotion.community.id + '/promotions',
          icon: 'local_offer',
        },
        {
          label: this.promotion.title,
          routerLink: `/sales/` + this.promotion.community.id + '/promotions/' + this.promotion.id,
        },
      ])
    );
  }

  updateProducts() {
    this.products = this.promotion.productVariants.reduce((acc, cur) => {
      const products = acc;
      const currentProduct = products.find(p => p.id === cur.product?.id);
      if (currentProduct) {
        currentProduct.variants.push(cur);
      } else if (cur.product) {
        products.push(cur.product);
        cur.product.variants = [cur];
      }
      return products;
    }, [] as Product[]);
  }

  async addProduct() {
    await toPromise(
      this.dialog
        .open(AddProductDialog, {
          data: {
            communityId: this.promotion?.community?.id,
            promotion: this.promotion,
          },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
    this.promotion = await this.promotionsSvc.getPromotion(this.promotion.id);
    this.updateProducts();
  }

  async remove(product: Product) {
    console.log({ product });
    const result = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Remove Product',
            subtitle: 'Are you sure you wish to remove this product from the promotion?',
            content: 'Product: ' + product.title,
            buttons: [
              {
                label: 'Cancel',
                role: 'cancel',
              },
              {
                label: 'Remove from Promotion',
                role: 'remove',
              },
            ],
          },
        })
        .afterClosed()
    );
    if (result === 'remove') {
      const variants = this.promotion.productVariants.filter(v => v.productId !== product.id);
      await this.promotionsSvc.update({
        id: this.promotion.id,
        productVariantIds: variants.map(p => p.id),
      });
      this.promotion.productVariants = variants;
      this.updateProducts();
    }
  }

  async removeVar(variant: ProductVariant) {
    console.log({ variant });
    const result = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Remove Product Variant',
            subtitle: 'Are you sure you wish to remove this product variant from the promotion?',
            content: 'Variant: ' + (variant.title || variant?.variantOpts?.map(o => o.value).join(', ')),
            buttons: [
              {
                label: 'Cancel',
                role: 'cancel',
              },
              {
                label: 'Remove from Promotion',
                role: 'remove',
              },
            ],
          },
        })
        .afterClosed()
    );
    if (result === 'remove') {
      const variants = this.promotion.productVariants.filter(v => v.id !== variant.id);
      await this.promotionsSvc.update({
        id: this.promotion.id,
        productVariantIds: variants.map(p => p.id),
      });
      this.promotion.productVariants = variants;
      this.updateProducts();
    }
  }
}
