import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tag } from '@greco/booking-events';
import { DialogData } from '@greco/ui-dialog-simple';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { TagService } from '../../services';

@Component({
  templateUrl: './manage-booking-tags.component.html',
  styles: [
    `
      ::ng-deep #manage-booking-tags-container tr:last-child > td {
        border-bottom: none;
      }
    `,
  ],
})
export class ManageBookingTagsDialog {
  constructor(@Inject(MAT_DIALOG_DATA) private data: { communityId: string }, private tagSvc: TagService) {}

  dialogData: DialogData = {
    showCloseButton: true,
    hideDefaultButton: true,
    title: 'Manage Booking Tags',
    subtitle: 'Manage which booking tags can be used by members to filter the calendar.',
  };

  private refresh$ = new Subject<void>();

  totalItems = 0;
  loading = false;

  page$ = new BehaviorSubject(1);
  updating: Record<string, boolean> = {};

  tags$ = this.refresh$.pipe(
    startWith(null),
    tap(() => (this.loading = true)),

    switchMap(() => this.page$),
    switchMap(page =>
      this.tagSvc.paginate(
        RequestQueryBuilder.create({ sort: { field: 'label', order: 'ASC' } }),
        this.data.communityId,
        { limit: 20, page }
      )
    ),
    tap(data => (this.totalItems = data.meta.totalItems)),

    map(data => data.items),
    tap(() => (this.loading = false))
  );

  async updateFilterable(tag: Tag) {
    if (this.updating[tag.id]) return;
    this.updating[tag.id] = true;

    tag.hideFromFilters = !tag.hideFromFilters;
    await new Promise(res => setTimeout(res, 2000));
    await this.tagSvc.updateTag(tag.id, tag.hideFromFilters).catch(() => (tag.hideFromFilters = !tag.hideFromFilters));

    this.updating[tag.id] = false;
  }
}
