/* eslint-disable @typescript-eslint/member-ordering */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { TilesService } from '../../services/tiles.service';

@Component({
  selector: 'greco-create-tile',
  templateUrl: './create-tile.dialog.html',
  styleUrls: ['./create-tile.dialog.scss'],
})
export class CreateTileComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private fb: FormBuilder,
    private tileSvc: TilesService,
    private snacks: MatSnackBar
  ) {}

  createCalled = false;

  initialUrls: string[] = [];
  @Input() readonly = false;

  formGroup = this.fb.group({
    tileDetails: [null, Validators.required],
  });

  private _onDestroy$ = new Subject<void>();

  dialogData: DialogData = {
    title: 'Create Quick Access Tile',
    subtitle: 'Provide the details for the new tile',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        disabled: this.formGroup.errors?.length,

        resultFn: async () => {
          try {
            if (this.createCalled) return;
            this.createCalled = true;
            const tileDetails = await this.formGroup.value.tileDetails;
            const formData = new FormData();
            formData.append('file', tileDetails.image[0]);
            formData.append('communityId', this.data.communityId);
            formData.append('imageUrl', '');
            formData.append('label', tileDetails.label);
            formData.append('priority', tileDetails.priority);
            formData.append(
              'url',
              tileDetails.url
                ? !tileDetails.url.includes('https://')
                  ? `https://${tileDetails.url}`
                  : tileDetails.url
                : ''
            );
            formData.append('status', 'true');
            const groupObj = {
              name: tileDetails.group ? tileDetails.group : undefined,
              communityId: this.data.communityId,
            };

            formData.append('tileGroup', JSON.stringify(groupObj));

            const tile = await this.tileSvc.create(this.data.communityId, formData);

            this.snacks.open('New Tile created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return tile;
          } catch (err: any) {
            console.error(err);
            if (!(err instanceof HttpErrorResponse)) {
              this.snacks.open(`Error: ${err.message ? err.message : err}`, 'Ok', {
                duration: 2500,
                panelClass: 'mat-warn',
              });
            }
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.formGroup.setValidators(Validators.required);
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
