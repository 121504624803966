import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { BuildSearchFilter, FilterBarValue } from '@greco/ngx-filters';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { VideoSourceRequestDto } from '@greco/videos';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { WatchVideoComponent } from '../../dialogs';
import { VideosService } from '../../services';

@Injectable({ providedIn: 'any' })
export class TitleSearchFilter extends BuildSearchFilter('TitleSearchFilter', {
  properties: ['name'],
  propertyLabels: ['Title'],
}) {}

@Component({
  selector: 'greco-select-from-source',
  templateUrl: './select-from-source.component.html',
  styleUrls: ['./select-from-source.component.scss'],
})
export class SelectFromSourceComponent {
  checked: VideoSourceRequestDto[] = [];
  constructor(private videosSvc: VideosService, private matDialog: MatDialog) {}

  selectAll = false;
  loading = false;
  filterOptions = [TitleSearchFilter];
  filters$ = new BehaviorSubject<FilterBarValue[]>([]);
  pagination?: IPaginationMeta;
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  @Output() confirmed = new EventEmitter<VideoSourceRequestDto[]>();

  @PropertyListener('source') private _source$ = new BehaviorSubject<string>('vimeo');
  @Input() source = 'vimeo';

  videos$: Observable<{ video: VideoSourceRequestDto; checked: boolean }[]> = combineLatest([
    this.filters$,
    this.page$,
    this._source$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([query, page, source]) => {
      const title = query.length ? query[0]?.value : '';
      const data: { items: { video: VideoSourceRequestDto; checked: boolean }[]; meta: IPaginationMeta } = {
        items: [],
        meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 },
      };
      const response = await this.videosSvc.searchSourceVideos(source, page, title);
      data.meta = response.meta;
      const videos = response.items;
      for (const video of videos) {
        const checked = this.checked.some(item => item.externalId === video.externalId);
        data.items.push({ video, checked });
      }
      this.verifySelectAllCheck(data.items);
      return data;
    }),
    tap(data => (this.pagination = data.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  watch(video: VideoSourceRequestDto) {
    const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
    const instance = dialog.componentInstance;
    instance.video = video;
    instance.embedUrl = video.embedUrl;
    instance.displayTitle = false;
  }

  itemChecked(video: { video: VideoSourceRequestDto; checked: boolean }, checked: boolean) {
    video.checked = checked;
    const index = this.checked.findIndex(i => i.externalId === video.video.externalId);
    if (index > -1 && !checked) {
      this.checked.splice(index, 1);
    }
    if (index === -1 && checked) this.checked.push(video.video);
  }
  clear() {
    this.checked = [];
    this.page$.next(this.page$.value);
  }

  async save() {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Selection',
        subtitle: 'Are you sure you want to add these videos?',
        content: this.checked.map(item => item.title).join(', '),
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      this.confirmed.emit(this.checked);
    }
  }

  async checkAll(event: MatCheckboxChange, items: { video: VideoSourceRequestDto; checked: boolean }[]) {
    const checked = event.checked;
    this.selectAll = checked;
    for (const item of items) {
      this.itemChecked(item, checked);
    }
  }

  verifySelectAllCheck(items?: { video: VideoSourceRequestDto; checked: boolean }[]) {
    let check = true;
    if (items && items.length) {
      for (const item of items) {
        if (!item.checked) {
          check = false;
          break;
        }
      }
    } else {
      check = false;
    }
    this.selectAll = check;
  }

  rowClick(item: { video: VideoSourceRequestDto; checked: boolean }) {
    item.checked = !item.checked;
    this.itemChecked(item, item.checked);
  }
}
