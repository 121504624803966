import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  subtitle?: string;
  content?: string;
  showCloseButton?: boolean;
  hideDefaultButton?: boolean;
  contentInParagraph?: boolean;
  buttons?: {
    label: string;
    role: string;
    class?: string;
    color?: string;
    disabled?: boolean;
    resultFn?: () => Promise<any> | any;
  }[];
}

@Component({
  selector: 'greco-simple-dialog',
  templateUrl: 'simple.dialog.html',
  styleUrls: ['simple.dialog.scss'],
})
export class SimpleDialog {
  @Input() public data: DialogData;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: DialogData, public dialogRef: MatDialogRef<SimpleDialog>) {
    this.data = {
      ...this.dialogData,
      buttons: this.dialogData?.buttons || [{ label: 'Ok', role: 'ok' }],
    }; // Initial value
  }

  async close(dialogResult?: any) {
    const resultFn = this.data.buttons?.find(b => b.role === dialogResult)?.resultFn;
    this.dialogRef.close(resultFn ? await resultFn() : dialogResult);
  }
}
