import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingOption,
  BookingStatus,
  CalendarEvent,
  PersonResource,
  ResourceType,
  RoomResource,
  calculateBoosterRequirements,
} from '@greco/booking-events';
import { SignInComponent, UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import * as moment from 'moment';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BookingService } from '../../../services';

@Component({
  selector: 'greco-user-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss'],
})
export class UserEventListItemComponent implements OnDestroy {
  isMobile$ = this.breakpointObs.observe('(max-width: 600px)');

  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private bookingSvc: BookingService,
    private breakpointObs: BreakpointObserver
  ) {}

  @PropertyListener('userWaitlist') private userWaitlist$ = new BehaviorSubject<string[]>([]);
  @Input() userWaitlist?: string[];

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent;

  @PropertyListener('bookingOption') private _bookingOption$ = new ReplaySubject<BookingOption>(1);
  @Input() bookingOption!: BookingOption;

  @PropertyListener('bookings') private _bookings$ = new ReplaySubject<number>(1);
  @Input() bookings = 0;

  @PropertyListener('userBookings') private _userBookings$ = new BehaviorSubject<Booking[]>([]);
  @Input() userBookings?: Booking[];

  @Input() hasLinkedAccounts = false;

  booking$ = combineLatest([this._event$, this._userBookings$]).pipe(
    map(([event, bookings]) =>
      event
        ? (bookings || []).find(
            bkg =>
              [BookingStatus.CHECKED_IN, BookingStatus.CONFIRMED, BookingStatus.PENDING].includes(bkg.status) &&
              bkg.event.id === event.id
          ) || null
        : null
    )
  );

  readonly inWaitlist$ = combineLatest([this._event$, this.userWaitlist$]).pipe(
    map(([event, waitlist]) => waitlist?.includes(event.id)),
    shareReplay(1)
  );

  readonly trainers$ = this._event$.pipe(
    map(
      event =>
        (event?.resourceAssignments?.reduce((acc, assignment) => {
          if (assignment?.resource?.type === ResourceType.PERSON) acc.push(assignment.resource);
          return acc;
        }, [] as PersonResource[]) || []) as PersonResource[]
    ),
    shareReplay(1)
  );

  readonly photoUrls$ = this.trainers$.pipe(
    map(trainers => {
      const photoUrls = trainers.map(trainer => trainer.photoURL as string).filter(url => !!url);
      return photoUrls?.length ? photoUrls : ['assets/lf3/icon_square_pad.png'];
    })
  );

  readonly requiredBoosts$ = combineLatest([this._event$, this._bookingOption$]).pipe(
    map(([event, bookingOption]) => {
      if (!event || !bookingOption) return -1;
      return calculateBoosterRequirements(event, bookingOption);
    }),
    shareReplay(1)
  );

  readonly availableIn$ = combineLatest([this._event$, this._bookingOption$, this.requiredBoosts$]).pipe(
    map(([event, bookingOption, requiredBoosts]) => {
      if (!bookingOption.maxBoost || requiredBoosts <= bookingOption.maxBoost) return new Date();
      return moment().to(
        moment(event.startDate)
          .subtract(bookingOption.bookingWindow, 'minutes')
          .subtract(bookingOption.maxBoost === -1 ? 0 : bookingOption.maxBoost, 'days')
      );
    })
  );

  readonly canBook$ = combineLatest([
    this._event$,
    this._bookingOption$,
    this.requiredBoosts$,
    this._bookings$,
    this.booking$,
    this.inWaitlist$,
  ]).pipe(
    map(([event, bookingOption, requiredBoosts, bookings, booking, inWaitlist]) => {
      if (booking) return false;
      if (event.maxCapacity <= bookings && inWaitlist) return false;
      if (bookingOption.maxBoost !== -1 && requiredBoosts > (bookingOption.maxBoost || Infinity)) return false;
      if (requiredBoosts && bookingOption.maxBoost === -1) return false;
      return true;
    })
  );

  hoveringPerk = false;

  getRoom(event: CalendarEvent) {
    const assignment = event?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ROOM);
    return (assignment?.resource as RoomResource) || false;
  }

  ngOnDestroy() {
    this._event$.complete();
    this._bookings$.complete();
    this.userWaitlist$.complete();
    this._userBookings$.complete();
    this._bookingOption$.complete();
  }

  async bookNow() {
    const user = await toPromise(this.userSvc.user$);
    if (user == null) {
      const _dialog = this.matDialog.open(SignInComponent, {
        data: {},
        width: '100%',
        maxWidth: '400px',
      });
      await toPromise(_dialog.afterClosed());
      if (!(await toPromise(this.userSvc.user$))) {
        return;
      }
      //return;
    }
    const canBook = await toPromise(this.canBook$);
    if (!canBook) return;

    if (this.event.maxCapacity <= (this.event as any).bookings) {
      // Join waitlist
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Join Waitlist',
          showCloseButton: false,
          subtitle: `${this.event.title} - ${moment(this.event.startDate).format('ll hh:mm A')}`,
          content: 'The event is currently fully booked. Join the waitlist to be notified as soon as a spot opens up.',
          buttons: [
            { label: "No, I don't want to join the waitlist", role: 'no' },
            { label: 'Yes, add me to the waitlist', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        const userId = await toPromise(this.userSvc.getUserId());
        if (!userId) return;

        await this.bookingSvc.joinWaitlist({ userId, eventId: this.event.id });
        this.userWaitlist$.value.push(this.event.id);
        this.userWaitlist$.next(this.userWaitlist$.value);
        this.snacks.open('Added to waitlist!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } else {
      await this.router.navigate([this.event.id], { relativeTo: this.route });
    }
  }

  async bookAnother() {
    await this.router.navigate([this.event.id], { relativeTo: this.route });
  }
}
