<div class="dialog-container">
  <div class="header">
    <div style="display: flex; align-items: center">
      <h2 class="title">Data Export</h2>
    </div>
  </div>

  <div class="content">
    <div class="export-description">
      <p>
        {{ exporting ? !totalItems ? "Preparing your export..." : "Exported " + (items.length - 1) + " of " + totalItems
        : error ? "Error exporting data, please try again" : "Done! Ready to download." }}
      </p>

      <mat-progress-bar
        style="border-radius: 4px"
        mode="determinate"
        [value]="(items.length / totalItems) * 100"
      ></mat-progress-bar>

      <p *ngIf="exporting" style="margin-top: 12px">
        <small>Please keep this tab open while we process your data.</small>
      </p>
    </div>
  </div>

  <div class="footer buttons">
    <button mat-stroked-button (click)="close()">Close</button>

    <button mat-flat-button color="primary" [disabled]="exporting" (click)="download()">Download</button>
  </div>
</div>
