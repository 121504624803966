import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Account, AccountPaymentGateway } from '@greco/finance-accounts';
import Stripe from 'stripe';
import { StripeConnectService, TerminalService } from '../../services';

@Component({
  selector: 'greco-stripe-payment-gateway-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class StripePaymentGatewayConfigurationComponent implements OnInit {
  constructor(
    private connectSvc: StripeConnectService,
    private terminalSvc: TerminalService,
    private snacks: MatSnackBar
  ) {}

  @Input() hideGatewayFee = true;

  @Output() expanded = new EventEmitter<boolean>();

  @Input() account!: Account;
  accountGateway?: AccountPaymentGateway;

  stripeAccountId?: string;
  stripeConnectUrl?: string;

  stripeGatewayFee = 0;
  stripeGatewayFeeChanged = false;
  savingStripeGatewayFee = false;

  stripeAccountIncomplete = false;

  locations: Stripe.Terminal.Location[] = [];
  configuredLocationId: string | null = null;

  async ngOnInit() {
    this.accountGateway = this.account?.paymentGateways.find(pg => pg.gateway === 'stripe');
    this.stripeAccountId = this.accountGateway?.externalId;
    this.stripeGatewayFee = (this.accountGateway as any)?.feePercentage || 0;

    this.configuredLocationId = this.account
      ? (await this.terminalSvc.getAccountLocation(this.account.id))?.locationId || null
      : null;
    this.locations = await this.terminalSvc.listLocations();

    const url = window.location.origin + '/accounting';
    this.stripeConnectUrl = this.connectSvc.getAuthorizationLink(this.account, url);

    if (this.stripeAccountId) {
      this.stripeAccountIncomplete = await this.connectSvc.needsMoreInfo(this.account).catch(() => false);
      if (this.stripeAccountIncomplete) {
        const link = await this.connectSvc.getCompleteAccountLink(this.account, url);
        this.stripeConnectUrl = link?.url;
      }
    }
  }

  inputValueUpdated(feePercentage: string) {
    try {
      const numberValue = Number(feePercentage);
      this.stripeGatewayFee = numberValue;
      this.stripeGatewayFeeChanged = numberValue !== ((this.accountGateway as any)?.feePercentage || 0);
    } catch (err) {
      console.error(err);
    }
  }

  async updateGatewayFee() {
    this.savingStripeGatewayFee = true;

    try {
      this.accountGateway = <AccountPaymentGateway>(
        await this.connectSvc.setAccountFeePercentage(this.account.id, this.stripeGatewayFee)
      );
      await new Promise(res => setTimeout(res, 10000));

      this.stripeGatewayFeeChanged = false;
    } catch (err) {
      console.error(err);
    }

    this.savingStripeGatewayFee = false;
  }

  async selectLocation(locationId: string) {
    if (!locationId || !this.account) return;

    await this.terminalSvc.linkAccountLocation(this.account.id, locationId);
    this.configuredLocationId = locationId;

    this.snacks.open('Stripe location configured successfully, syncing terminals...', 'Ok!', {
      duration: 6000,
      panelClass: 'mat-primary',
    });

    //makes sure that all saved readers have an up to date locationId in our DB
    await this.terminalSvc.syncReaderLocations();
  }
}
