import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { heightExpansion } from '@greco/ui-animations';
import { COUNTRIES_DB, Country } from '@greco/ui-country-input';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Subject, Subscription } from 'rxjs';

export interface Address {
  formatted: string;
  line1: string;
  line2?: string;
  postalCode: string;
  state: string;
  country: Country;
  city: string;
  latitude?: number;
  longitude?: number;
  placeId?: string;
}

@Component({
  selector: 'greco-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AddressInputComponent }],
  animations: [heightExpansion],
})
export class AddressInputComponent implements ControlValueAccessor, MatFormFieldControl<Address>, OnDestroy, OnInit {
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;

  get empty() {
    const { formatted, line1, line2, postalCode, state, country, city } = this.form.value || {};
    return !formatted && !line1 && !line2 && !postalCode && !state && !country && !city;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty || !!this.value?.formatted;
  }
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): Address | null {
    return this.form.valid
      ? {
          ...this.form.getRawValue(),
          formatted: [
            this.form.get('line1')?.value,
            this.form.get('city')?.value,
            [this.form.get('state')?.value, this.form.get('postalCode')?.value].filter(i => !!i).join(' '),
            this.form.get('country')?.value?.name,
            this.form.get('line2')?.value ? '(' + this.form.get('line2')?.value + ')' : null,
          ]
            .map(i => i?.trim())
            .filter(i => !!i)
            .join(', '),
        }
      : null;
  }
  set value(address: Address | null) {
    if (address && this.form.value !== address) {
      this.form.get('formatted')?.setValue(
        [
          this.form.get('line1')?.value,
          this.form.get('city')?.value,
          [this.form.get('state')?.value, this.form.get('postalCode')?.value].filter(i => !!i).join(' '),
          this.form.get('country')?.value?.name,
          this.form.get('line2')?.value ? '(' + this.form.get('line2')?.value + ')' : null,
        ]
          .map(i => i?.trim())
          .filter(i => !!i)
          .join(', ')
      );
      this.dirty = true;
      this.form.setValue(address);
    }
    this.stateChanges.next();
    this.onChange(this.value);
  }

  get errorState(): boolean {
    return this.required ? this.form.invalid && this.dirty : false;
  }

  @Input() strict = false;
  _zipRequired = true;
  @Input()
  get zipRequired(): boolean {
    return this._zipRequired;
  }
  set zipRequired(value: boolean) {
    this._zipRequired = coerceBooleanProperty(value);
    const validator = [...(this._zipRequired ? [Validators.required] : [])];
    this.form.get('postalCode')?.setValidators(validator);
    this.stateChanges.next();
  }

  public form: FormGroup;
  public stateChanges = new Subject<void>();
  public id!: string;
  public focused = false;
  public dirty = false;
  public formChangesSub: Subscription;

  private _placeholder!: string;
  private _required = false;
  private _disabled = false;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      line1: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: [''],
      country: ['', Validators.required],
      formatted: [''],
      latitude: [''],
      longitude: [''],
      placeId: [''],
    });

    this.formChangesSub = this.form.valueChanges.subscribe(value => {
      this.value = value;
      const errors = {
        ...this.form.get('line1')?.errors,
        ...this.form.get('city')?.errors,
        ...this.form.get('state')?.errors,
        ...this.form.get('postalCode')?.errors,
        ...this.form.get('country')?.errors,
        ...(this.strict && this.form.get('latitude')?.errors),
        ...(this.strict && this.form.get('longitude')?.errors),
        ...(this.strict && this.form.get('placeId')?.errors),
      };
      this.ngControl?.control?.setErrors(Object.keys(errors)?.length ? errors : null);
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  mapAddress(address: GoogleAddress) {
    if (address && address.address_components) {
      const components: any =
        address?.address_components
          ?.filter(c =>
            c.types.some(t0 =>
              ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code'].some(
                t1 => t1 === t0
              )
            )
          )
          .reduce((acc, cur) => ({ ...acc, [cur.types[0]]: { short: cur.short_name, long: cur.long_name } }), {}) ||
        null;

      this.value = {
        formatted: address?.formatted_address || '',
        line1: [components['street_number']?.long, components['route']?.long].join(' ').trim() || '',
        line2: components['street2'] || this.form.get('line2')?.value || null,
        city: components['locality']?.long || null,
        state: components['administrative_area_level_1']?.long || null,
        postalCode: components['postal_code']?.long || null,
        country: COUNTRIES_DB.find(c => c.alpha2Code === components['country']?.short) || (null as unknown as Country),
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng(),
        placeId: address.place_id,
      };
    }
  }

  ngOnInit() {
    if (this.strict) {
      // Require Latitude and Longitude when strict (this is to ensure we can sort and query based on distance between locations).
      this.form.get('latitude')?.setValidators(Validators.required);
      this.form.get('longitude')?.setValidators(Validators.required);
      this.form.get('placeId')?.setValidators(Validators.required);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.formChangesSub.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  writeValue(address: Address | null): void {
    this.value = address;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDescribedByIds(_ids: string[]): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onContainerClick() {}
}
