import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunityAgreementUsage } from '@greco/community-agreements';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { DialogData } from '@greco/ui-dialog-simple';

@Component({
  selector: 'greco-preview-agreement-links-dialog',
  templateUrl: './preview-agreement-links.dialog.html',
  styleUrls: ['./preview-agreement-links.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PreviewAgreementLinksDialog {
  loading = false;
  origin = window.location.origin.replace('-admin.', '.').replace('admin.', '').replace('4300', '4200');
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      subtitle: string;
      links: CommunityAgreementUsage[];
    },
    private clipboardSvc: ClipboardService
  ) {}

  dialogData: DialogData = {
    title: this.data.title,
    subtitle: this.data.subtitle,
    showCloseButton: false,
    buttons: [
      { label: 'Close', role: 'cancel' },
      { label: 'Retry', role: 'retry' },
    ],
  };

  copylink(url: string) {
    this.clipboardSvc.copy(this.origin + url);
  }
}
