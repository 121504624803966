import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '@greco/ngx-finance-accounts';
import { PaymentMethodFormComponent } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { PadMetaData, StripeJsService } from '../../services';

@Component({
  selector: 'greco-fit-bank-account-payment-form',
  templateUrl: './bank-account-payment-form.component.html',
  styleUrls: ['./bank-account-payment-form.component.scss'],
})
export class BankAccountPaymentFormComponent extends PaymentMethodFormComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private stripejs: StripeJsService,
    private userSVC: UserService,
    private accountSvc: AccountService,
    private dialog: MatDialog
  ) {
    super();
  }
  readonly formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
  });

  async ngOnInit() {
    if (this.userId) {
      const user = await this.userSVC.getUser(this.userId);
      if (user) {
        this.formGroup.setValue({ name: user.displayName, email: user.email });
      }
    } else if (this.accountId) {
      const account = await this.accountSvc.getAccount(this.accountId);
      if (account) {
        this.formGroup.setValue({ name: account.name, email: account.email });
      }
    }
  }

  async save(): Promise<{ label: string; model: string; externalId: string; externalData: PadMetaData }> {
    let customer = null;
    if (this.userId) {
      customer = { id: this.userId, type: 'user' };
    } else if (this.accountId) {
      customer = { id: this.accountId, type: 'account' };
    }
    if (!customer) throw new Error('Stripe Customer details not provided');
    const createBankAccount = await this.stripejs.createBankAccountPaymentMethod(
      this.formGroup.get('name')?.value,
      this.formGroup.get('email')?.value,
      customer.id,
      customer.type
    );

    const contentData = await this.stripejs.displayContent(createBankAccount.externalData);

    this.dialog.open(SimpleDialog, {
      data: {
        title: contentData.title,
        content: contentData.content,
      } as DialogData,
    });

    return createBankAccount;
  }
}
