<ng-container *ngIf="subscription && _hasAtLeastOneOption">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu>
    <!-- View Details -->
    <a *ngIf="!hideViewDetails" mat-menu-item [routerLink]="[subscription.id]">
      <mat-icon>open_in_new</mat-icon>
      <span>View Details</span>
    </a>

    <!-- View Customer -->
    <a
      *ngIf="!hideViewCustomer && communityId"
      mat-menu-item
      [routerLink]="['/community', communityId, subscription.user.id]"
    >
      <mat-icon>people</mat-icon>
      <span>View Customer</span>
    </a>

    <ng-container *ngIf="subscription.status === 'ACTIVE' && canCancel">
      <!-- Cancel Now  -->
      <button *ngIf="!hideCancel" mat-menu-item [disabled]="_cancelling" (click)="cancelNow()">
        <mat-icon>cancel</mat-icon>
        <span>Cancel Now</span>
      </button>

      <!-- Cancel at Period End -->
      <button *ngIf="!hideCancel" mat-menu-item [disabled]="_cancelling" (click)="cancelAtPeriodEnd()">
        <mat-icon>cancel</mat-icon>
        <span>Cancel at Period End</span>
      </button>
    </ng-container>

    <!-- Update Payment Method -->
    <button
      *ngIf="!hideUpdatePaymentMethod"
      mat-menu-item
      [disabled]="_updatingPaymentMethod"
      (click)="paymentMethodPicker.open()"
    >
      <div style="position: relative">
        <greco-select-payment-method
          [required]="true"
          #paymentMethodPicker
          [userId]="subscription.user.id"
          [value]="subscription.paymentMethod"
          style="position: absolute; opacity: 0; pointer-events: none"
          (valueChange)="updatePaymentMethod($event)"
        ></greco-select-payment-method>
      </div>
      <mat-icon>payment</mat-icon>
      <span>Update Payment Method</span>
    </button>
  </mat-menu>
</ng-container>
