import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityGiftCardConfig, CommunityGiftCardOption } from '@greco/gift-cards';
import { UserService } from '@greco/ngx-identity-auth';

@Component({
  selector: 'greco-gift-card-shop-page',
  templateUrl: './gift-card-shop.page.html',
  styleUrls: ['./gift-card-shop.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GiftCardShopPage {
  constructor(private router: Router, private userSvc: UserService) {}

  @Input() configs: CommunityGiftCardConfig[] = [];
  @Input() selectedConfig?: CommunityGiftCardConfig | null = null;
  @Input() selectedOption?: CommunityGiftCardOption | 'custom' | null = null;

  self$ = this.userSvc.getSelf();

  goToShop() {
    this.router.navigate(['shop']);
  }
}
