import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@greco/ngx-identity-auth';

@Injectable()
export class OnboardingGuard implements CanActivateChild {
  constructor(private router: Router, private userSvc: UserService) {}

  async canActivateChild(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const url = route.url.map(segment => segment.path).join('/');

    if (url.includes('onboarding')) return true;

    const self = await this.userSvc.getSelf();

    if (!self || !self.isFlaggedForOnboarding) {
      //does not require onboarding
      return true;
    } else {
      //requires onboarding
      return this.router.navigateByUrl('/onboarding');
    }
  }
}
