import { Component } from '@angular/core';

@Component({
  selector: 'greco-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPage {
  // implements OnInit {
  //   constructor(private router: Router) {}
  //   async ngOnInit() {
  //     this.document = await this.legalSvc.getTermsOfService();
  //   }
}
