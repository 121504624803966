import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Booking, BookingStatus, getCancellationPolicyInnerHtml } from '@greco/booking-events';
import { EventWithUserDetails, SpotDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { BookingService } from '@greco/ngx-booking-events';
import { DialogData } from '@greco/ui-dialog-simple';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { EventService } from '../../services';

@Component({
  selector: 'alt-attendee-card',
  templateUrl: './attendee-card.component.html',
  styleUrls: ['./attendee-card.component.scss'],
})
export class AttendeeCardComponent {
  constructor(
    private matDialog: MatDialog,
    private snackbar: MatSnackBar,
    private eventSvc: EventService,
    public bottomSheet: MatBottomSheet,
    private bookingSvc: BookingService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @Input() event!: EventWithUserDetails;
  @Input() booking!: Booking;
  @Input() isStaffView = true;

  moment = moment;
  now = new Date();

  selectedSpot: SpotDetails | null = null;
  initialSpotId = this.booking?.spotId;

  confirming = false;

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  async cancel(freeOfCharge: boolean) {
    this.confirming = true;

    try {
      const booking = this.booking;

      const inLateWindow = moment(booking.event.startDate)
        .subtract(booking.bookingOption.cancellationWindow, 'minutes')
        .isBefore(moment());

      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Confirm Cancellation',
          subtitle: `${booking.event.title} - ${moment(booking.event.startDate).format('ll hh:mm A')}`,
          content: `
            ${getCancellationPolicyInnerHtml(booking.bookingOption, inLateWindow)}
            ${inLateWindow ? '<p>Are you sure you want to cancel your booking?<p>' : ''}
          `,
          buttons: [
            { label: 'No, keep my booking', role: 'no' },
            { label: 'Yes, cancel my booking', role: 'yes' },
          ],
          showCloseButton: false,
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.eventSvc.cancelBooking(this.booking.id, freeOfCharge);
        this.snackbar.open('Your booking has been cancelled.', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
    }

    this.confirming = false;
  }

  async join(booking: Booking) {
    if (booking.status === BookingStatus.CONFIRMED) {
      this.bookingSvc
        .checkIn(booking.id)
        .then(b => {
          booking = b;
        })
        .catch(err => console.error(err));
      // FIXME: Prevent users from joining if check-in fails.
    }

    const joinUrl = booking.event.zoomMeetingId
      ? `https://zoom.us/j/${booking.event.zoomMeetingId}`
      : booking.event.zoomEvent?.joinUrl;

    if (joinUrl) window.open(joinUrl, '_blank');
  }

  updateSelectedSpot(spot: SpotDetails) {
    this.selectedSpot = spot;
  }

  async updateSpot(booking: Booking) {
    if (!this.selectedSpot) return;

    await this.bookingSvc.updateSpot(booking.id, this.selectedSpot.spotId);

    this.updateAttendees(booking, this.selectedSpot.spotId == 'general' ? '' : this.selectedSpot.spotId);
    this.updateBookedSpots(booking.user, this.selectedSpot);

    this.selectedSpot = null;
  }

  updateAttendees(booking: Booking, spotId: string) {
    const bookings = this.eventSvc.attendees$.value;
    const userBooking = bookings.find(eventBooking => eventBooking.user.id === booking.user.id);
    if (!userBooking) return;

    const spot = this.event.requirements.spotBooking?.room.spots?.find(spot => spot.id === spotId);
    userBooking.spot = spot;
    userBooking.spotId = spotId;

    const bookingIndex = bookings.findIndex(eventBooking => eventBooking.user.id === booking.user.id);
    bookings[bookingIndex] = userBooking;

    this.eventSvc.attendees$.next(bookings);
  }

  updateBookedSpots(user: User, spot: SpotDetails) {
    const bookedSpots = this.eventSvc.bookedSpots$.value;
    const userSpotIndex = bookedSpots.findIndex(bookedSpot => bookedSpot.userId === user.id);

    if (spot.spotId === 'general') {
      this.eventSvc.bookedSpots$.next(bookedSpots.filter(bookedSpot => bookedSpot.userId !== user.id));
      return;
    }

    if (userSpotIndex !== -1) {
      bookedSpots[userSpotIndex].spotId = spot.spotId;
      bookedSpots[userSpotIndex].spotName = spot.spotName;
      bookedSpots[userSpotIndex].spotNumber = spot.spotNumber;
      bookedSpots[userSpotIndex].spotDescription = spot.spotDescription;

      this.eventSvc.bookedSpots$.next(bookedSpots);
    } else {
      const bookedSpot: SpotDetails = {
        ...spot,
        userId: user.id,
        photoUrl: user.photoURL || '',
      };

      this.eventSvc.bookedSpots$.next([...bookedSpots, bookedSpot]);
    }
  }
}
