import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { CommunityService } from '../../services';

@Component({
  selector: 'greco-create-community-dialog',
  templateUrl: './create-community.dialog.html',
  styleUrls: ['./create-community.dialog.scss'],
})
export class CreateCommunityDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { parentId: string },
    private formBuilder: FormBuilder,
    private communitySvc: CommunityService,
    private snacks: MatSnackBar
  ) {}

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', Validators.required],
    address: [null, Validators.required],
  });

  dialogData: DialogData = {
    title: 'Create New Sub-Community',
    subtitle: 'Provide the details for the new community',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          try {
            const community = await this.communitySvc.createCommunity({
              phoneNumber: this.formGroup.value.phoneNumber,
              address: this.formGroup.value.address,
              email: this.formGroup.value.email,
              name: this.formGroup.value.name,
              parentId: this.data.parentId,
              canBeParent: false,
              browsable: false,
            });

            this.snacks.open('New community created', 'Ok');
            return community;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.formGroup.valueChanges
      .pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$))

      .subscribe(() => {
        this.dialogData = {
          ...this.dialogData,
          buttons: this.dialogData.buttons?.map(btn => {
            if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
            return btn;
          }),
        };
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
