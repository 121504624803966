import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco/ui-dialog-simple';

export interface FundUserBalanceConfirmationDialogData {
  userId: string;
  communityId: string;
  amount: number;
}

@Component({
  selector: 'greco-fund-user-balance-confimation-dialog',
  templateUrl: './fund-user-balance-confimation.dialog.html',
  styleUrls: ['./fund-user-balance-confimation.dialog.scss'],
})
export class FundUserBalanceConfimationDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FundUserBalanceConfirmationDialogData,
    private dialogRef: MatDialogRef<FundUserBalanceConfimationDialog>
  ) {}

  dialogData: DialogData = {
    title: 'Confirmation',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  absAmount = Math.abs(this.data.amount / 100);
  positiveAmount = this.data.amount > 0;

  confirm() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(null);
  }
}
