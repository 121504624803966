<ng-container *ngIf="subscription">
  <div class="header">
    <greco-smart-img
      class="user-photo"
      [src]="subscription.user?.photoURL || 'assets/lf3/icon_square_pad.png'"
      [border]="true"
      [round]="true"
    >
    </greco-smart-img>

    <div class="billed-to">
      <h2>Subscribed User</h2>
      <a
        [routerLink]="['/community', communityId, subscription.user?.id]"
        [matTooltip]="subscription.user?.contactEmail || ''"
        (click)="$event.stopImmediatePropagation()"
      >
        <p>{{ subscription.user?.displayName }}</p>
        <p>
          <small>{{ subscription.user?.contactEmail }}</small>
        </p>
      </a>
    </div>

    <div class="detailsPurchase" *ngIf="showSubInfo">
      <strong>Account</strong>
      <span [matTooltip]="subscription.id">{{ subscription.account?.name }}</span>

      <strong>Subscription ID</strong>
      <span>{{ subscription.id }}</span>

      <strong>{{ 'Sub Status' }}</strong>
      <span>{{ subscription.status }}</span>

      <strong>{{ 'Sub Recurrence' }}</strong>
      <span>{{ subscription.recurrence.frequency + ' - ' + subscription.recurrence.period }}</span>

      <strong>{{ 'Created on' }}</strong>
      <span>{{ subscription.created | date }}</span>

      <strong>{{ 'Started on' }}</strong>
      <span>{{ subscription.periodStart | date }}</span>

      <strong>{{ 'End of Commitment' }}</strong>
      <span>{{ subscription.minimumCommitmentDate | date }}</span>

      <strong>{{ 'Expired on' }}</strong>
      <span>{{ subscription.periodEnd | date }}</span>
    </div>
  </div>

  <div class="content">
    <greco-table [data]="(items$ | async) || []" [class.with-totals]="showTotals">
      <div *grecoTableCol="'Description'; let item" class="item-description">
        <ng-container *ngIf="item.description">
          <div class="item-image">
            <greco-smart-img
              class="userImageToExport"
              [src]="item.description.photoURL || 'assets/lf3/icon_square_pad.png'"
            ></greco-smart-img>
          </div>

          <div>
            <h3>{{ item.description.displayName }}</h3>
            <small>{{ item.description.description }}</small>
          </div>
        </ng-container>
      </div>

      <p *grecoTableCol="'Qty'; let item; alignRight: true">
        {{ item.quantity }}
      </p>

      <p *grecoTableCol="'Unit Price'; let item; alignRight: true" [class]="item.unitPriceClass">
        {{ item.unitPrice }}
      </p>

      <div
        *grecoTableCol="'Amount'; let item; alignRight: true; stickyEnd: true; fitContent: true"
        [class]="item.amountClass"
      >
        <img *ngIf="item.amountType === 'img'" style="height: 24px; vertical-align: middle" [src]="item.amount" />
        <ng-container *ngIf="item.amountType !== 'img'">{{ item.amount }}</ng-container>
      </div>
    </greco-table>
  </div>
</ng-container>
